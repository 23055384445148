import React, { useState, useEffect } from 'react';
import { adminService } from '../../services/adminService';
import { auth } from '../../firebase/index';
import { useNavigate } from 'react-router-dom';
import { getDoc, doc, onSnapshot, query, collection, where, orderBy } from 'firebase/firestore';
import { db } from '../../firebase/index';
import MainHeader from '../../components/Headers/MainHeader';

function AdminChatDashboard() {
  const [isAdmin, setIsAdmin] = useState(false);
  const [conversations, setConversations] = useState([]);
  const [selectedConv, setSelectedConv] = useState(null);
  const [loading, setLoading] = useState(true);
  const [messages, setMessages] = useState([]);
  const [participants, setParticipants] = useState([]);
  const navigate = useNavigate();
  const messagesEndRef = React.useRef(null);

  useEffect(() => {
    const checkAdmin = async () => {
      if (!auth.currentUser) {
        navigate('/login');
        return;
      }

      const adminStatus = await adminService.isAdmin(auth.currentUser.uid);
      setIsAdmin(adminStatus);
      
      if (!adminStatus) {
        navigate('/');
      }
    };

    checkAdmin();
  }, [navigate]);

  useEffect(() => {
    const loadConversations = async () => {
      if (!isAdmin) return;
      
      try {
        const convs = await adminService.getAllConversations();
        // Fetch user details for each conversation
        const conversationsWithDetails = await Promise.all(
          convs.map(async (conv) => {
            const otherUserId = conv.participants.find(id => id !== auth.currentUser.uid);
            const userDoc = await getDoc(doc(db, 'users', otherUserId));
            const userData = userDoc.data();
            
            return {
              ...conv,
              otherUser: {
                id: otherUserId,
                name: `${userData?.firstName || ''} ${userData?.lastName || ''}`,
                profilePic: userData?.profilePicture
              }
            };
          })
        );
        setConversations(conversationsWithDetails);
      } catch (error) {
        console.error('Error loading conversations:', error);
      } finally {
        setLoading(false);
      }
    };

    loadConversations();
  }, [isAdmin]);

  // Listen to messages for selected conversation
  useEffect(() => {
    if (!selectedConv?.id) return;

    const q = query(
      collection(db, 'messages'),
      where('conversationId', '==', selectedConv.id),
      orderBy('timestamp', 'asc')
    );

    const unsubscribe = onSnapshot(q, (snapshot) => {
      const messagesData = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setMessages(messagesData);
      
      // Scroll to bottom after messages are loaded
      setTimeout(() => {
        messagesEndRef.current?.scrollIntoView({ behavior: 'instant' });
      }, 100);
    });

    return () => unsubscribe();
  }, [selectedConv?.id]);

  // Load participants details when conversation is selected
  useEffect(() => {
    const loadParticipantsDetails = async () => {
      if (!selectedConv?.participants) return;

      try {
        const participantsData = await Promise.all(
          selectedConv.participants.map(async (userId) => {
            const userDoc = await getDoc(doc(db, 'users', userId));
            const userData = userDoc.data();
            return {
              id: userId,
              name: `${userData?.firstName || ''} ${userData?.lastName || ''}`,
              email: userData?.email,
              profilePic: userData?.profilePicture
            };
          })
        );
        setParticipants(participantsData);
      } catch (error) {
        console.error('Error loading participants details:', error);
      }
    };

    loadParticipantsDetails();
  }, [selectedConv?.participants]);

  // Determine host and tenant
  const getParticipantRoles = () => {
    if (!selectedConv || !participants.length) return null;
    
    if (selectedConv.type === 'roommate') {
      // For roommate chats, just show both participants
      return {
        participant1: participants[0] || {
          id: 'unknown',
          name: 'Unknown User',
          email: 'unknown@email.com',
          profilePic: null
        },
        participant2: participants[1] || {
          id: 'unknown',
          name: 'Unknown User',
          email: 'unknown@email.com',
          profilePic: null
        }
      };
    } else {
      // For sublease chats, use hostId to determine roles
      const host = participants.find(p => p.id === selectedConv.hostId) || {
        id: 'unknown',
        name: 'Unknown Host',
        email: 'unknown@email.com',
        profilePic: null
      };
      const tenant = participants.find(p => p.id !== selectedConv.hostId) || {
        id: 'unknown',
        name: 'Unknown Tenant',
        email: 'unknown@email.com',
        profilePic: null
      };
      
      return { host, tenant };
    }
  };

  // Format timestamp helper
  const formatTimestamp = (timestamp) => {
    if (!timestamp) return '';
    
    const date = timestamp.toDate ? timestamp.toDate() : new Date(timestamp);
    const now = new Date();
    const diff = now - date;
    
    if (diff < 24 * 60 * 60 * 1000) {
      return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    }
    if (diff < 48 * 60 * 60 * 1000) {
      return 'Yesterday';
    }
    if (diff < 7 * 24 * 60 * 60 * 1000) {
      return date.toLocaleDateString([], { weekday: 'long' });
    }
    return date.toLocaleDateString([], { month: 'short', day: 'numeric' });
  };

  // Add this new timestamp formatter for more detailed dates
  const formatDetailedTimestamp = (timestamp) => {
    if (!timestamp) return '';
    
    const date = timestamp.toDate ? timestamp.toDate() : new Date(timestamp);
    return {
      time: date.toLocaleTimeString([], {hour: '2-digit', minute: '2-digit'}),
      date: date.toLocaleDateString([], {month: 'short', day: 'numeric', year: 'numeric'})
    };
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!isAdmin) {
    return <div>Access denied</div>;
  }

  return (
    <div className="flex flex-col h-screen bg-gray-50">
      <MainHeader />
      
      <div className="flex flex-1 border-t border-gray-200 overflow-hidden md:flex-row flex-col">
        {/* Conversations Sidebar */}
        <div className={`md:w-1/3 w-full border-r bg-white ${selectedConv ? 'hidden md:block' : 'block'}`}>
          <div className="p-4 border-b flex justify-between items-center sticky top-0 bg-white z-10">
            <h2 className="text-xl font-semibold">All Conversations</h2>
            {selectedConv && (
              <button 
                onClick={() => setSelectedConv(null)}
                className="md:hidden text-gray-600 hover:text-gray-800"
              >
                Back to List
              </button>
            )}
          </div>
          <div className="overflow-y-auto md:h-[calc(100vh-8rem)] h-[calc(100vh-4rem)]">
            {conversations.map(conv => (
              <div
                key={conv.id}
                onClick={() => setSelectedConv(conv)}
                className={`flex items-center p-4 cursor-pointer hover:bg-gray-50 ${
                  selectedConv?.id === conv.id ? 'bg-gray-50' : ''
                }`}
              >
                <img
                  src={conv.otherUser?.profilePic || 'https://ui-avatars.com/api/?name=' + encodeURIComponent(conv.otherUser?.name || 'User') + '&background=random'}
                  alt="Profile"
                  className="w-10 h-10 rounded-full mr-3"
                />
                <div className="flex-1 min-w-0">
                  <div className="flex items-center gap-2 mb-1">
                    <span className="font-base truncate">{conv.otherUser?.name}</span>
                    <span className={`px-2 py-0.5 rounded-full text-xs font-medium ${
                      conv.type === 'roommate' 
                        ? 'bg-purple-100 text-purple-800' 
                        : 'bg-blue-100 text-blue-800'
                    }`}>
                      {conv.type === 'roommate' ? 'Roommate' : 'Sublease'}
                    </span>
                  </div>
                  <div className="flex items-center justify-between">
                    <div className="text-xs text-gray-500 truncate max-w-[70%]">
                      {conv.lastMessage?.content || 'No messages yet'}
                    </div>
                    <div className="text-xs text-gray-600 flex items-center gap-1">
                      <span className="text-gray-500">{formatDetailedTimestamp(conv.lastMessageAt).time}</span>
                      <span className="text-gray-300">•</span>
                      <span className="text-gray-400">{formatDetailedTimestamp(conv.lastMessageAt).date}</span>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Chat Area */}
        <div className={`flex-1 flex flex-col ${!selectedConv ? 'hidden md:flex' : 'flex'} h-full`}>
          {selectedConv ? (
            <>
              {/* Chat Header */}
              <div className="p-2 border-b bg-white sticky top-0 z-10">
                <div className="flex flex-col gap-2">
                  {/* Top Row - Back Button, Type Badge, and Sublease Link */}
                  <div className="flex items-center justify-between">
                    <div className="flex items-center gap-2">
                      {/* Mobile Back Button */}
                      <button 
                        onClick={() => setSelectedConv(null)}
                        className="md:hidden text-gray-600 hover:text-gray-800"
                      >
                        ← Back
                      </button>
                      
                      {/* Conversation Type Badge */}
                      <span className={`px-2 py-0.5 rounded-full text-xs font-medium ${
                        selectedConv.type === 'roommate' 
                          ? 'bg-purple-100 text-purple-800' 
                          : 'bg-blue-100 text-blue-800'
                      }`}>
                        {selectedConv.typeLabel}
                      </span>
                    </div>

                    {/* Sublease ID as clickable link */}
                    {selectedConv.subleaseId && (
                      <a 
                        href={`/sublease/${selectedConv.subleaseId}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-sm text-blue-600 hover:text-blue-800 hover:underline flex items-center gap-1"
                      >
                        <span className="text-gray-500">ID:</span> {selectedConv.subleaseId} →
                      </a>
                    )}
                  </div>
                  
                  {/* Participants Row */}
                  <div className="flex items-center justify-between">
                    <div className="flex items-center gap-4">
                      {(() => {
                        const roles = getParticipantRoles();
                        if (!roles) return null;
                        
                        if (selectedConv.type === 'roommate') {
                          return (
                            <div className="flex items-center gap-4">
                              {/* Participant 1 */}
                              <a 
                                href={`/profile/${roles.participant1?.id}`}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="flex items-center hover:bg-gray-50 p-1 rounded-lg transition-colors"
                              >
                                <img
                                  src={roles.participant1?.profilePic || 'https://ui-avatars.com/api/?name=' + encodeURIComponent(roles.participant1?.name || 'User') + '&background=random'}
                                  alt="Participant 1"
                                  className="w-8 h-8 rounded-full mr-2"
                                />
                                <div>
                                  <div className="font-medium text-sm">{roles.participant1?.name || 'Unknown User'}</div>
                                  <p className="text-xs text-gray-500">{roles.participant1?.email || 'No email'}</p>
                                </div>
                              </a>

                              {/* Participant 2 */}
                              <a 
                                href={`/profile/${roles.participant2?.id}`}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="flex items-center hover:bg-gray-50 p-1 rounded-lg transition-colors"
                              >
                                <img
                                  src={roles.participant2?.profilePic || 'https://ui-avatars.com/api/?name=' + encodeURIComponent(roles.participant2?.name || 'User') + '&background=random'}
                                  alt="Participant 2"
                                  className="w-8 h-8 rounded-full mr-2"
                                />
                                <div>
                                  <div className="font-medium text-sm">{roles.participant2?.name || 'Unknown User'}</div>
                                  <p className="text-xs text-gray-500">{roles.participant2?.email || 'No email'}</p>
                                </div>
                              </a>
                            </div>
                          );
                        } else {
                          return (
                            <div className="flex items-center gap-4">
                              {/* Host */}
                              <a 
                                href={`/profile/${roles.host?.id}`}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="flex items-center hover:bg-gray-50 p-1 rounded-lg transition-colors"
                              >
                                <div className="relative">
                                  <img
                                    src={roles.host?.profilePic || 'https://ui-avatars.com/api/?name=' + encodeURIComponent(roles.host?.name || 'Host') + '&background=random'}
                                    alt="Host Profile"
                                    className="w-8 h-8 rounded-full mr-2"
                                  />
                                  <span className="absolute -top-1 -right-1 bg-green-500 text-white text-xs px-1.5 py-0.5 rounded-full text-[10px]">
                                    Host
                                  </span>
                                </div>
                                <div>
                                  <div className="font-medium text-sm">{roles.host?.name || 'Unknown Host'}</div>
                                  <p className="text-xs text-gray-500">{roles.host?.email || 'No email'}</p>
                                </div>
                              </a>

                              {/* Tenant */}
                              <a 
                                href={`/profile/${roles.tenant?.id}`}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="flex items-center hover:bg-gray-50 p-1 rounded-lg transition-colors"
                              >
                                <div className="relative">
                                  <img
                                    src={roles.tenant?.profilePic || 'https://ui-avatars.com/api/?name=' + encodeURIComponent(roles.tenant?.name || 'Tenant') + '&background=random'}
                                    alt="Tenant Profile"
                                    className="w-8 h-8 rounded-full mr-2"
                                  />
                                  <span className="absolute -top-1 -right-1 bg-blue-500 text-white text-xs px-1.5 py-0.5 rounded-full text-[10px]">
                                    Tenant
                                  </span>
                                </div>
                                <div>
                                  <div className="font-medium text-sm">{roles.tenant?.name || 'Unknown Tenant'}</div>
                                  <p className="text-xs text-gray-500">{roles.tenant?.email || 'No email'}</p>
                                </div>
                              </a>
                            </div>
                          );
                        }
                      })()}
                    </div>

                    {/* Created Date - Only show on desktop */}
                    <div className="hidden md:block text-xs text-gray-500">
                      Created: {selectedConv.createdAt?.toDate().toLocaleDateString()}
                    </div>
                  </div>
                </div>
              </div>

              {/* Messages */}
              <div className="flex-1 overflow-y-auto p-4 h-[calc(100vh-16rem)]">
                <div className="flex flex-col gap-2">
                  {messages.map((message, index) => {
                    const prevMessage = messages[index - 1];
                    const messageDate = message.timestamp?.toDate ? message.timestamp.toDate() : new Date(message.timestamp);
                    const prevMessageDate = prevMessage?.timestamp?.toDate ? prevMessage.timestamp.toDate() : new Date(prevMessage?.timestamp);
                    
                    const showDateSeparator = index > 0 && 
                      (prevMessageDate.getDate() !== messageDate.getDate() ||
                       prevMessageDate.getHours() !== messageDate.getHours());

                    const roles = getParticipantRoles();
                    
                    // Get sender info based on chat type
                    let senderLabel;
                    if (selectedConv.type === 'roommate') {
                      const sender = participants.find(p => p.id === message.senderId);
                      senderLabel = sender?.name || 'Unknown User';
                    } else {
                      // Sublease chat
                      const isHost = message.senderId === roles?.host?.id;
                      senderLabel = isHost ? 'Host' : 'Tenant';
                    }

                    return (
                      <div key={message.id}>
                        {showDateSeparator && (
                          <div className="text-center py-2">
                            <span className="bg-gray-100 px-3 py-1 rounded-full text-xs text-gray-500">
                              {formatTimestamp(message.timestamp)}
                            </span>
                          </div>
                        )}
                        
                        <div
                          className={`flex flex-col ${
                            message.senderId === participants[0]?.id ? 'items-start' : 'items-end'
                          }`}
                        >
                          <div className="text-xs text-gray-500 mb-1">
                            {senderLabel}
                          </div>
                          {message.imageUrl && (
                            <div className="relative">
                              <img 
                                src={message.imageUrl} 
                                alt="Shared" 
                                className="max-w-[70%] rounded-lg cursor-pointer"
                              />
                            </div>
                          )}
                          {message.content && (
                            <div
                              className={`max-w-[70%] rounded-lg p-3 mt-1 ${
                                message.senderId === participants[0]?.id
                                  ? 'bg-white shadow-sm'
                                  : 'bg-blue-500 text-white'
                              }`}
                            >
                              <div>{message.content}</div>
                            </div>
                          )}
                          <div className="text-xs text-gray-500 mt-1">
                            {formatTimestamp(message.timestamp)}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
                <div ref={messagesEndRef} />
              </div>
            </>
          ) : (
            <div className="flex-1 flex items-center justify-center text-gray-500 p-4 text-center">
              Select a conversation to view details
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default AdminChatDashboard; 