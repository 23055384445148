import React, { useState } from 'react';
import { auth, db, storage } from '../firebase/index';
import { deleteUser } from 'firebase/auth';
import { doc, deleteDoc, getDoc, updateDoc, query, collection, where, getDocs, writeBatch } from 'firebase/firestore';
import { ref, deleteObject } from 'firebase/storage';
import { notificationService } from '../services/notificationService';

function DeleteAccount({ onDeleteSuccess }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [error, setError] = useState('');

  const handleDeleteAccount = async () => {
    setIsDeleting(true);
    setError('');
    
    try {
      const user = auth.currentUser;
      if (!user) throw new Error('No user found');

      // Get user document to check for any related data
      const userDoc = await getDoc(doc(db, 'users', user.uid));
      const userData = userDoc.data();

      // Send notification to admin about user deletion
      await notificationService.sendUserDeletionNotification({
        id: user.uid,
        firstName: userData.firstName,
        lastName: userData.lastName,
        email: userData.email
      });

      // 1. Delete profile picture from Storage if exists
      if (userData?.profilePicture) {
        try {
          const storageRef = ref(storage, `profilePictures/${user.uid}`);
          await deleteObject(storageRef);
        } catch (err) {
          console.error('Error deleting profile picture:', err);
        }
      }

      // 2. Remove from apartments
      if (userData?.apartmentIds) {
        for (const apartmentId of userData.apartmentIds) {
          const apartmentRef = doc(db, 'apartments', apartmentId);
          const apartmentDoc = await getDoc(apartmentRef);
          
          if (apartmentDoc.exists()) {
            const apartmentData = apartmentDoc.data();
            const updatedUserIds = apartmentData.userIds.filter(id => id !== user.uid);
            await updateDoc(apartmentRef, { userIds: updatedUserIds });
          }
        }
      }

      // 6. Delete user activity data
      const userActivityRef = doc(db, 'userActivity', user.uid);
      const userActivityDoc = await getDoc(userActivityRef);
      if (userActivityDoc.exists()) {
        await deleteDoc(userActivityRef);
      }

      // 7. Update sublease status instead of deleting
      const subleasesQuery = query(
        collection(db, 'subleases'),
        where('userId', '==', user.uid)
      );
      const subleasesSnapshot = await getDocs(subleasesQuery);
      for (const subleaseDoc of subleasesSnapshot.docs) {
        await updateDoc(subleaseDoc.ref, {
          status: 'deleted',
          deletionReason: 'user_account_deleted'
        });
      }

      // 8. Update messages and conversations
      // First, get all conversations where user is a participant
      const conversationsQuery = query(
        collection(db, 'conversations'),
        where('participants', 'array-contains', user.uid)
      );
      const conversationsSnapshot = await getDocs(conversationsQuery);
      
      // Update each conversation
      for (const convDoc of conversationsSnapshot.docs) {
        const convData = convDoc.data();
        const updatedParticipants = convData.participants.filter(id => id !== user.uid);
        
        // If this was a 1-on-1 conversation and now has no participants, delete it
        if (updatedParticipants.length === 0) {
          await deleteDoc(convDoc.ref);
        } else {
          // Otherwise update the conversation
          await updateDoc(convDoc.ref, {
            participants: updatedParticipants,
            lastMessageSenderId: convData.lastMessageSenderId === user.uid ? null : convData.lastMessageSenderId
          });
        }
      }

      // Update all messages where user is the sender
      const messagesQuery = query(
        collection(db, 'messages'),
        where('senderId', '==', user.uid)
      );
      const messagesSnapshot = await getDocs(messagesQuery);
      
      // Use a batch to update all messages
      const batch = writeBatch(db);
      messagesSnapshot.docs.forEach(doc => {
        batch.update(doc.ref, {
          senderId: null,
          senderName: 'Deleted User',
          senderProfilePic: null
        });
      });
      await batch.commit();

      // 9. Delete user document from Firestore
      await deleteDoc(doc(db, 'users', user.uid));

      // 10. Delete user from Firebase Auth
      await deleteUser(user);

      // Call the success callback
      onDeleteSuccess();
    } catch (err) {
      console.error('Error deleting account:', err);
      setError('Failed to delete account. Please try again later.');
    } finally {
      setIsDeleting(false);
      setIsModalOpen(false);
    }
  };

  return (
    <div className="mt-8 border-t pt-6">
      <h3 className="text-xl font-semibold text-red-600">Danger Zone</h3>
      <div className="mt-4">
        <p className="text-gray-600 mb-4">
          Once you delete your account, there is no going back. Please be certain.
        </p>
        <button
          onClick={() => setIsModalOpen(true)}
          className="bg-red-600 hover:bg-red-700 text-white px-4 py-2 rounded-lg transition-colors"
        >
          Delete Account
        </button>
      </div>

      {/* Confirmation Modal */}
      {isModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
          <div className="bg-white rounded-lg p-6 max-w-md w-full">
            <h4 className="text-lg font-semibold mb-4">Delete Account</h4>
            <p className="text-gray-600 mb-6">
              Are you sure you want to delete your account? This action cannot be undone.
            </p>
            {error && (
              <p className="text-red-600 mb-4">{error}</p>
            )}
            <div className="flex justify-end space-x-4">
              <button
                onClick={() => setIsModalOpen(false)}
                className="px-4 py-2 text-gray-600 hover:text-gray-800"
                disabled={isDeleting}
              >
                Cancel
              </button>
              <button
                onClick={handleDeleteAccount}
                className="bg-red-600 hover:bg-red-700 text-white px-4 py-2 rounded-lg transition-colors flex items-center"
                disabled={isDeleting}
              >
                {isDeleting ? (
                  <>
                    <svg className="animate-spin -ml-1 mr-2 h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                      <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                    Deleting...
                  </>
                ) : (
                  'Delete Account'
                )}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default DeleteAccount; 