import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { format } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import { db } from '../../../firebase';
import { collection, query, where, getDocs, orderBy, limit, Timestamp } from 'firebase/firestore';

function AnimatedNumber({ end, duration = 2000 }) {
  // Calculate starting value as 75% of the end value
  const start = Math.floor(end * 0.75);
  const [count, setCount] = useState(start);

  useEffect(() => {
    let startTime = null;
    
    // Using easeOutQuart for even smoother slow-down at the end
    const easeOutQuart = t => 1 - Math.pow(1 - t, 4);

    const animate = timestamp => {
      if (!startTime) startTime = timestamp;
      const progress = Math.min((timestamp - startTime) / duration, 1);
      
      const easedProgress = easeOutQuart(progress);
      const currentCount = Math.floor(start + (end - start) * easedProgress);
      
      setCount(currentCount);

      if (progress < 1) {
        requestAnimationFrame(animate);
      }
    };

    requestAnimationFrame(animate);
  }, [end, start, duration]);

  return (
    <motion.span
      key={end}
      initial={{ opacity: 0, scale: 0.8 }}
      animate={{ 
        opacity: 1, 
        scale: 1
      }}
      transition={{
        duration: 0.8,
        ease: "easeOut"
      }}
      className="text-sm font-medium"
    >
      {count.toLocaleString()}
    </motion.span>
  );
}

const SubleaseHeader = ({ 
  userSubleases, 
  onPostSublease, 
  handleNavigate, 
  newSubleasesThisWeek,
  userPriceRange = "0-10000" // Default range if not provided
}) => {
  const [showTimestamp, setShowTimestamp] = useState(true);
  const [currentTime, setCurrentTime] = useState(new Date());
  const navigate = useNavigate();

  useEffect(() => {
    // Update time every second
    const timer = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);

    // Hide timestamp after 5 seconds
    const hideTimer = setTimeout(() => {
      setShowTimestamp(false);
    }, 5000);

    return () => {
      clearInterval(timer);
      clearTimeout(hideTimer);
    };
  }, []);

  return (
    <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-4 sm:gap-0">
      <div className="flex flex-col sm:flex-row items-start sm:items-center gap-4 w-full sm:w-auto">
        <h1 className="text-xl sm:text-2xl font-medium py-2 sm:py-4">Short term stays</h1>
        <AnimatePresence>
          {newSubleasesThisWeek > 0 && (
            <motion.div
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: -20 }}
              transition={{ duration: 0.3 }}
              className="text-sm text-gray-600 bg-gray-50 px-3 py-1.5 rounded-full border border-gray-200 w-full sm:w-auto"
            >
              <div className="flex items-center flex-wrap gap-1">
                <span className="mr-1">✨</span>
                <AnimatedNumber end={newSubleasesThisWeek} duration={1500} />
                <span>subleases posted this week</span>
                <AnimatePresence>
                  {showTimestamp && (
                    <motion.span
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      transition={{ duration: 0.3 }}
                      className="ml-2 text-gray-400"
                    >
                      • {format(currentTime, 'MMM d, h:mm:ss a')}
                    </motion.span>
                  )}
                </AnimatePresence>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
      <div className="flex items-center gap-4 w-full sm:w-auto justify-end">
        {userSubleases && userSubleases.length > 0 && (
          <span className="text-sm">
            Your listing:{' '}
            <span
              className="font-medium text-blue-600 hover:underline cursor-pointer"
              onClick={() => handleNavigate(userSubleases[0].id)}
            >
              {userSubleases[0].apartmentName}
            </span>
          </span>
        )}
        {/* <button
          onClick={() => navigate('/sublease-guide')}
          className="text-sm font-medium text-gray-600 hover:text-gray-900"
        >
          How it works
        </button> */}
        <button
          className="text-sm font-medium underline"
          onClick={onPostSublease}
        >
          List your apartment
        </button>
      </div>
    </div>
  );
};

export default SubleaseHeader; 