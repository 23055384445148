import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Toaster } from 'react-hot-toast';
import BetaPage from "./pages/BetaPage";
import SavedPage from "./pages/SavedPage";
import ApartmentDetail from "./pages/ApartmentDetail";
import SubleaseDetail from "./pages/Sublease/SubleaseDetail";
import UIUCApartmentListings from "./pages/UIUCApartmentsPage";
import HomePage from "./pages/HomePage";
import { SignUpPage } from "./components/Auth/signup";
import { Analytics } from "@vercel/analytics/react";
import { SpeedInsights } from "@vercel/speed-insights/react";
import { AuthScreen } from "./components/Auth/AuthScreen";
import { LoginPage } from "./components/Auth/login";
import FriendsPage from "./pages/FriendsPage";
import GroupsPage from "./pages/GroupsPage";
import InterestedUsers from "./pages/InterestedUsers";
import ProfilePage from "./pages/ProfilePage";
import SubleaseIntroPage from "./pages/Sublease/SubleaseIntroPage";
import SubleaseFormPage from "./pages/Sublease/SubleaseFormPage";
import ApplyIntroPage from "./pages/ApplyIntroPage";
import SubleasePage from "./pages/Sublease/SubleasePage";
import SubleaseProcessGuide from "./pages/Sublease/SubleaseProcessGuide";
import { AppProvider } from "./AppContext";
import { GuestSignUpPage } from "./components/Auth/guestSignup";
import GroupApartmentDetail from "./pages/GroupApartmentDetail";
import CreateRoommateProfile from "./pages/CreateRoommateProfile";
import RoommateDetailPage from "./pages/RoommateDetailPage";
import { Unauthorized, NotFound } from './pages/ErrorPages';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

import QuickSignupPage from "./pages/QuickSignupPage";
import UserSavedPage from "./pages/UserSavedPage";
import TestPage from "./pages/TestPage";
import ApartmentEditor from "./pages/ApartmentEditor";
import BulkEmailSender from "./pages/Email/BulkEmailSender";
import AboutUs from "./pages/AboutUs";
import SubleaseEdit from "./pages/Sublease/SubleaseEdit";
import { ChatProvider } from './contexts/ChatContext';
import MessagesPage from './pages/Messages/MessagesPage';
import AdminChatDashboard from './pages/Admin/AdminChatDashboard';
import AdminDashboard from './pages/Admin/AdminDashboard';
import About from "./pages/About/About";
import BookTour from './pages/Sublease/BookTour';
import TermsAgreement from './pages/Sublease/TermsAgreement';
import TourDetailsPage from './pages/Sublease/TourDetailsPage';

function App() {
  return (
    <ChatProvider>
      <AppProvider>
        <Router>
          <div>
            <Toaster position="top-center" />
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path="/about" element={<About />} />
              <Route path="/about-us" element={<AboutUs />} />
              <Route path="/secret-bulk-email-sender" element={<BulkEmailSender />} />
              <Route path="/secret-apartment-editor" element={<ApartmentEditor />} />
              <Route path="/secret-test" element={<TestPage />} />

              <Route path="/listings" element={<BetaPage />} />

              <Route path="/listing/:id" element={<ApartmentDetail />} />
              <Route
                path="/group-listing/:groupId/:apartmentId"
                element={<GroupApartmentDetail />}
              />
              <Route path="/apply-now/:id/:secondParam?" element={<ApplyIntroPage />} />

              <Route path="/interestedusers/:apartmentId/:secondParam?" element={<InterestedUsers />} />

              <Route path="/UIUC" element={<UIUCApartmentListings />} />

              <Route path="/profile/:userId" element={<ProfilePage />} />
              <Route path="/saved" element={<SavedPage />} />
              <Route path="/saved/:userId" element={<UserSavedPage />} />

              <Route
                path="/create-roommate-profile"
                element={<CreateRoommateProfile />}
              />
              <Route
                path="/create-roommate-profile/:aptId"
                element={<CreateRoommateProfile />}
              />
              <Route path="/roommate/:userId" element={<RoommateDetailPage />} />

              {/* Sublease Routes */}
              <Route path="/sublease" element={<SubleasePage />} />
              <Route path="/sublease/:id" element={<SubleaseDetail />} />
              <Route path="/sublease/:id/book-tour" element={<BookTour />} />
              <Route path="/sublease/:id/tour-details" element={<TourDetailsPage />} />
              <Route path="/sublease/:id/terms" element={<TermsAgreement />} />
              <Route path="/sublease-intro" element={<SubleaseIntroPage />} />
              <Route path="/sublease-form" element={<SubleaseFormPage />} />
              <Route path="/sublease/edit/:id" element={<SubleaseEdit />} />
              <Route path="/sublease-guide" element={<SubleaseProcessGuide />} />

              <Route path="/friends" element={<FriendsPage />} />
              <Route path="/groups" element={<GroupsPage />} />

              <Route path="/signup" element={<SignUpPage />} />
              <Route path="/guest-signup" element={<GuestSignUpPage />} />
              <Route path="/login" element={<LoginPage />} />
              <Route path="/auth" element={<AuthScreen />} />

              <Route path="/quick-signup" element={<QuickSignupPage />} />

              <Route path="/messages" element={<MessagesPage />} />
              <Route path="/messages/:conversationId" element={<MessagesPage />} />

              {/* Admin Routes */}
              <Route path="/admin/chat" element={<AdminChatDashboard />} />
              <Route path="/admin/dashboard" element={<AdminDashboard />} />

              {/* Error Pages */}
              <Route path="/unauthorized" element={<Unauthorized />} />
              <Route path="/not-found" element={<NotFound />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
            <Analytics />
            <SpeedInsights />
          </div>
        </Router>
      </AppProvider>
    </ChatProvider>
  );
}

export default App;
