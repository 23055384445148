import { useState, useEffect } from 'react';
import { collection, getDocs, doc, getDoc, updateDoc, arrayRemove } from 'firebase/firestore';
import { db, auth } from '../../../firebase';

export const useSubleaseData = (user) => {
  const [subleases, setSubleases] = useState([]);
  const [userSubleases, setUserSubleases] = useState([]);
  const [dataLoading, setDataLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [subleaseToDelete, setSubleaseToDelete] = useState(null);

  const fetchUserSubleases = async () => {
    if (!user) return;
    
    try {
      const userDocRef = doc(db, "users", user.uid);
      const userDoc = await getDoc(userDocRef);
      if (userDoc.exists()) {
        const userSubleaseIds = userDoc.data().subleases || [];
        const userSubleasesData = await Promise.all(
          userSubleaseIds.map(async (subleaseId) => {
            const subleaseDocRef = doc(db, "subleases", subleaseId);
            const subleaseDoc = await getDoc(subleaseDocRef);
            return { id: subleaseDoc.id, ...subleaseDoc.data() };
          })
        );
        setUserSubleases(userSubleasesData);
      }
    } catch (error) {
      console.error("Error fetching user's subleases:", error);
    }
  };

  const fetchSubleases = async () => {
    if (!user) return;
    
    setDataLoading(true);
    try {
      const subleasesCollectionRef = collection(db, "subleases");
      const subleasesSnapshot = await getDocs(subleasesCollectionRef);
      const subleasesData = subleasesSnapshot.docs
        .map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }))
        .filter((sublease) => sublease.showOnWebsite);

      setSubleases(subleasesData);
    } catch (error) {
      console.error("Error fetching subleases:", error);
    } finally {
      setDataLoading(false);
    }
  };

  const confirmDelete = async () => {
    if (!subleaseToDelete) return;

    try {
      const userId = auth.currentUser.uid;

      const userDocRef = doc(db, "users", userId);
      await updateDoc(userDocRef, {
        subleases: arrayRemove(subleaseToDelete),
      });

      const subleaseDocRef = doc(db, "subleases", subleaseToDelete);
      await updateDoc(subleaseDocRef, {
        showOnWebsite: false,
      });

      setShowModal(false);
      fetchUserSubleases();
      fetchSubleases();
    } catch (error) {
      console.error("Error deleting sublease:", error);
      setShowModal(false);
    }
  };

  const cancelDelete = () => {
    setShowModal(false);
    setSubleaseToDelete(null);
  };

  useEffect(() => {
    const initializeData = async () => {
      if (!user) return;
      
      try {
        await Promise.all([
          fetchUserSubleases(),
          fetchSubleases()
        ]);
      } catch (error) {
        console.error("Error initializing data:", error);
      }
    };

    initializeData();
  }, [user]);

  return {
    subleases,
    userSubleases,
    dataLoading,
    showModal,
    subleaseToDelete,
    setSubleaseToDelete,
    setShowModal,
    confirmDelete,
    cancelDelete,
    fetchSubleases,
    fetchUserSubleases
  };
}; 