import React, { useState, useEffect } from 'react';
import { adminService } from '../../services/adminService';
import { auth } from '../../firebase/index';
import { useNavigate } from 'react-router-dom';
import { getDoc, onSnapshot, query, collection, orderBy, doc, updateDoc, getDocs } from 'firebase/firestore';
import { db } from '../../firebase/index';
import MainHeader from '../../components/Headers/MainHeader';
import DeletedSubleases from './DeletedSubleases';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import SubleasesPage from './pages/SubleasesPage';

function AdminDashboard() {
  const [isAdmin, setIsAdmin] = useState(false);    
  const [activeTab, setActiveTab] = useState('overview');
  const [loading, setLoading] = useState(true);
  const [subleases, setSubleases] = useState([]);
  const [selectedSublease, setSelectedSublease] = useState(null);
  const [subleaseId, setSubleaseId] = useState('');
  const [editingSublease, setEditingSublease] = useState(null);
  const [imageOrder, setImageOrder] = useState([]);
  const [newImage, setNewImage] = useState('');
  const [stats, setStats] = useState({
    total: 0,
    active: 0,
    newThisWeek: 0,
    totalViews: 0
  });
  const navigate = useNavigate();

  useEffect(() => {
    const checkAdmin = async () => {
      if (!auth.currentUser) {
        navigate('/login');
        return;
      }

      const adminStatus = await adminService.isAdmin(auth.currentUser.uid);
      setIsAdmin(adminStatus);
      
      if (!adminStatus) {
        navigate('/');
      }
      setLoading(false);
    };

    checkAdmin();
  }, [navigate]);

  const formatDate = (timestamp) => {
    if (!timestamp) return '';
    const date = timestamp.toDate ? timestamp.toDate() : new Date(timestamp);
    return date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    });
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!isAdmin) {
    return <div>Access denied</div>;
  }

  const tabs = [
    { id: 'overview', label: 'Overview', icon: 'fa-solid fa-chart-line' },
    { id: 'subleases', label: 'Subleases', icon: 'fa-solid fa-home' },
    { id: 'deleted', label: 'Deleted', icon: 'fa-solid fa-trash' },
    { id: 'users', label: 'Users', icon: 'fa-solid fa-users' },
    { id: 'chat', label: 'Chat', icon: 'fa-solid fa-comments' },
    { id: 'settings', label: 'Settings', icon: 'fa-solid fa-gear' }
  ];

  return (
    <div className="flex flex-col min-h-screen bg-gray-50">
      <MainHeader />
      
      <div className="flex-1 flex">
        {/* Desktop Sidebar */}
        <div className="hidden md:block w-64 bg-white border-r border-gray-200">
          <div className="p-4 sticky top-0">
            <h2 className="text-lg font-semibold mb-4">Admin Dashboard</h2>
            <div className="space-y-2">
              {tabs.map(tab => (
                <button
                  key={tab.id}
                  onClick={() => setActiveTab(tab.id)}
                  className={`flex items-center w-full px-4 py-2 rounded-lg text-left ${
                    activeTab === tab.id 
                      ? 'bg-blue-50 text-blue-600' 
                      : 'text-gray-600 hover:bg-gray-50'
                  }`}
                >
                  <i className={`${tab.icon} mr-3`}></i>
                  {tab.label}
                </button>
              ))}
            </div>
          </div>
        </div>

        {/* Main Content Area */}
        <div className="flex-1 overflow-x-hidden">
          <div className="p-4 md:p-6">
            {activeTab === 'overview' && (
              <div className="text-center text-gray-500 py-8">
                Overview Page (Coming Soon)
              </div>
            )}
            {activeTab === 'subleases' && <SubleasesPage />}
            {activeTab === 'deleted' && <DeletedSubleases />}
            {activeTab === 'users' && (
              <div className="text-center text-gray-500 py-8">
                Users Page (Coming Soon)
              </div>
            )}
            {activeTab === 'chat' && (
              <div className="text-center text-gray-500 py-8">
                Chat Page (Coming Soon)
              </div>
            )}
            {activeTab === 'settings' && (
              <div className="text-center text-gray-500 py-8">
                Settings Page (Coming Soon)
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Mobile Tab Bar */}
      <div className="fixed bottom-0 left-0 right-0 bg-white border-t border-gray-200 md:hidden">
        <div className="flex justify-around items-center h-16">
          {tabs.map(tab => (
            <button
              key={tab.id}
              onClick={() => setActiveTab(tab.id)}
              className={`flex flex-col items-center justify-center flex-1 h-full ${
                activeTab === tab.id ? 'text-blue-600' : 'text-gray-500'
              }`}
            >
              <i className={`${tab.icon} text-xl mb-1`}></i>
              <span className="text-xs">{tab.label}</span>
            </button>
          ))}
        </div>
      </div>
    </div>
  );
}

export default AdminDashboard; 