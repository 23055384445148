import React, { useState } from 'react';
import { getDoc, doc } from 'firebase/firestore';
import { db } from '../../../firebase/index';

const SubleaseSearch = ({ onSubleaseLoad }) => {
  const [subleaseId, setSubleaseId] = useState('');
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleSearch = async (e) => {
    e.preventDefault();
    if (!subleaseId) return;

    setIsLoading(true);
    setError(null);

    try {
      const subleaseDoc = await getDoc(doc(db, 'subleases', subleaseId));
      if (!subleaseDoc.exists()) {
        setError('Sublease not found');
        return;
      }

      const subleaseData = {
        id: subleaseDoc.id,
        ...subleaseDoc.data()
      };
      onSubleaseLoad(subleaseData);
    } catch (error) {
      console.error('Error fetching sublease:', error);
      setError('Error fetching sublease. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="bg-white rounded-lg shadow p-6 mb-6">
      <h2 className="text-xl font-semibold mb-4">Search Sublease</h2>
      
      {error && (
        <div className="mb-4 p-4 bg-red-50 text-red-700 rounded-lg">
          {error}
        </div>
      )}

      <form onSubmit={handleSearch}>
        <div className="flex gap-4">
          <input
            type="text"
            value={subleaseId}
            onChange={(e) => setSubleaseId(e.target.value)}
            placeholder="Enter Sublease ID"
            className="flex-1 px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            disabled={isLoading}
          />
          <button
            type="submit"
            disabled={isLoading}
            className={`px-6 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 ${
              isLoading ? 'opacity-50 cursor-not-allowed' : ''
            }`}
          >
            {isLoading ? 'Loading...' : 'Load Sublease'}
          </button>
        </div>
      </form>
    </div>
  );
};

export default SubleaseSearch; 