import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  collection,
  updateDoc,
  arrayUnion,
  doc,
  addDoc,
  Timestamp,
} from "firebase/firestore";
import { auth, db, storage } from "../../firebase";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import deleteIcon from "../../data/icons/delete.png";
import { useAuthState } from "react-firebase-hooks/auth";
import MainHeader from "../../components/Headers/MainHeader";
import { ReactComponent as ChevronLeft } from "../../data/imgs/chevron-left.svg";
import Footer from "../../components/Headers/Footer";
import Modal from "react-modal";
import { format } from "date-fns";
import { motion, AnimatePresence } from "framer-motion";
import heic2any from 'heic2any';

import {
  fetchUserData,
  uploadProfilePicture,
  updateContactInfo,
  updateBio,
} from "../../backend/dbAPI";
import { notificationService } from "../../services/notificationService";

function SubleaseFormPage() {
  const [user, setUser] = useAuthState(auth);

  const [subleaseData, setSubleaseData] = useState({
    apartmentName: "",
    configuration: "",
    bedrooms: "",
    bathrooms: "",
    startDate: "",
    endDate: "",
    description: "",
    rent: "",
    genderRequirement: "",
    feeInfo: "",
    address: "",
    websiteLink: "",
  });

  const [apartment, setApartment] = useState(null);
  const [images, setImages] = useState([]);
  const [imageURLs, setImageURLs] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [roommates, setRoommates] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSubleaseData({
      ...subleaseData,
      [name]: value,
    });
  };

  const sanitizeFileName = (fileName) => {
    // Remove spaces and special characters, replace with underscores
    return fileName
      .replace(/[^a-zA-Z0-9.-]/g, '_')
      .replace(/_+/g, '_')
      .toLowerCase();
  };

  const handleImagesChange = async (e) => {
    const files = Array.from(e.target.files);
    if (images.length + files.length > 10) {
      alert("You can only upload a maximum of 10 images.");
      e.target.value = null;
      return;
    }

    try {
      const processedFiles = await Promise.all(
        files.map(async (file) => {
          // Check if file is HEIC
          if (file.type === 'image/heic' || file.name.toLowerCase().endsWith('.heic')) {
            // Convert HEIC to JPEG
            const jpegBlob = await heic2any({
              blob: file,
              toType: "image/jpeg",
              quality: 0.8
            });
            // Create a new File object from the blob with sanitized name
            const sanitizedName = sanitizeFileName(file.name.replace('.heic', '.jpg'));
            return new File([jpegBlob], sanitizedName, {
              type: 'image/jpeg'
            });
          }
          // For non-HEIC files, create a new File with sanitized name
          const sanitizedName = sanitizeFileName(file.name);
          return new File([file], sanitizedName, {
            type: file.type
          });
        })
      );
      
      setImages(prev => [...prev, ...processedFiles]);
      const newImageURLs = processedFiles.map((file) => URL.createObjectURL(file));
      setImageURLs(prev => [...prev, ...newImageURLs]);
    } catch (error) {
      console.error("Error processing images:", error);
      alert("Error processing images. Please try again.");
    }
  };

  const handleRemoveImage = (index) => {
    setImages(prev => prev.filter((_, i) => i !== index));
    setImageURLs(prev => prev.filter((_, i) => i !== index));
  };

  const openModal = (url) => {
    setSelectedImage(url);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setSelectedImage(null);
  };

  const handleRoommateChange = (index, e) => {
    const { name, value } = e.target;
    const updatedRoommates = [...roommates];
    updatedRoommates[index] = {
      ...updatedRoommates[index],
      [name]: value,
    };
    setRoommates(updatedRoommates);
  };

  const handleRemoveRoommate = (index) => {
    const updatedRoommates = roommates.filter((_, i) => i !== index);
    setRoommates(updatedRoommates);
  };

  const [userData, setUserData] = useState({
    phone: "",
    email: "",
    instagramHandle: "",
    linkedinHandle: "",
    preferredContact: "",
    profilePic: "",
    description: "",
  });

  const [uploading, setUploading] = useState(false);
  const [bio, setBio] = useState("");

  useEffect(() => {
    const getUserData = async () => {
      try {
        const fetchedUserData = await fetchUserData(user.uid);
        if (fetchedUserData) {
          setUserData({
            phone: fetchedUserData.phone || "",
            email: fetchedUserData.email || "",
            instagramHandle: fetchedUserData.instagramHandle || "",
            linkedinHandle: fetchedUserData.linkedinHandle || "",
            preferredContact: fetchedUserData.preferredContact || "",
            profilePic: fetchedUserData.profilePicture || null,
            description: fetchedUserData.description || "",
          });
          setBio(fetchedUserData.bio || "");
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };
    getUserData();
  }, [user.uid]);

  const handleUserInfoChange = (e) => {
    const { name, value } = e.target;
    setUserData({
      ...userData,
      [name]: value,
    });
  };

  const handleFileChange = async (e) => {
    if (e.target.files[0]) {
      setUploading(true);
      try {
        const downloadURL = await uploadProfilePicture(
          user.uid,
          e.target.files[0]
        );
        setUploading(false);
        setUserData({ ...userData, profilePic: downloadURL });
      } catch (error) {
        console.error("Error uploading file:", error);
        setUploading(false);
      }
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    setSubmitting(true);
    setErrorMessage("");
    
    // Add image validation check
    if (images.length < 5) {
      setErrorMessage("Please upload at least 5 images before submitting.");
      return;
    }
    
    try {
      const docRef = await addDoc(collection(db, "subleases"), {
        ...subleaseData,
        userId: user.uid,
        rent: parseFloat(subleaseData.rent),
        bedrooms: parseFloat(subleaseData.bedrooms),
        bathrooms: parseFloat(subleaseData.bathrooms),
        startDate: format(new Date(subleaseData.startDate), "MM-dd-yyyy"),
        endDate: format(new Date(subleaseData.endDate), "MM-dd-yyyy"),
        showOnWebsite: true,
        createdAt: Timestamp.now(),
        updatedAt: Timestamp.now()
      });

      const imageUploadPromises = images.map(async (image, index) => {
        const imageRef = ref(
          storage,
          `sublease_images/${docRef.id}/${image.name}`
        );
        await uploadBytes(imageRef, image);
        const url = await getDownloadURL(imageRef);
        
        return {
          url,
          name: image.name,
          order: index,
          path: `sublease_images/${docRef.id}/${image.name}`
        };
      });

      const uploadedImages = await Promise.all(imageUploadPromises);

      await updateDoc(doc(db, "subleases", docRef.id), {
        images: uploadedImages
      });

      const roommatesCollectionRef = collection(
        db,
        "subleases",
        docRef.id,
        "roommates"
      );
      const roommatePromises = roommates.map((roommate) =>
        addDoc(roommatesCollectionRef, {
          gender: roommate.gender,
          age: parseFloat(roommate.age),
          occupation: roommate.occupation,
        })
      );
      await Promise.all(roommatePromises);

      await updateDoc(doc(db, "users", user.uid), {
        subleases: arrayUnion(docRef.id),
      });

      await updateBio(user.uid, bio);
      await updateContactInfo(user.uid, {
        description: userData.description,
        phone: userData.phone,
        instagramHandle: userData.instagramHandle,
        linkedinHandle: userData.linkedinHandle,
        preferredContact: userData.preferredContact,
      });

      // Send notification to admin about the term agreement
      await notificationService.sendTermAgreementNotification({
        id: docRef.id,
        propertyName: subleaseData.apartmentName,
        subleaserName: `${userData.firstName} ${userData.lastName}`,
        subleaseeName: "To be determined", // This will be filled in when a subleasee is found
        startDate: subleaseData.startDate,
        endDate: subleaseData.endDate,
        rentAmount: subleaseData.rent
      });

      setSubleaseData({
        name: "",
        apartmentName: "",
        configuration: "",
        bedrooms: "",
        bathrooms: "",
        startDate: "",
        endDate: "",
        description: "",
        rent: "",
        genderRequirement: "",
        feeInfo: "",
        address: "",
        websiteLink: "",
      });
      setUserData({
        phone: "",
        email: "",
        instagramHandle: "",
        linkedinHandle: "",
        preferredContact: "",
        profilePic: "",
        description: "",
      });
      setImages([]);
      setRoommates([]);

      setSubmitting(false);
      setSubmittedSuccessfully(true);
    } catch (error) {
      console.error("Error adding sublease", error);
      setErrorMessage("There was an error adding sublease");
      setSubmitting(false);
    }
  };

  const handleShareClick = () => {
    // console.log(`${apartment.id} Share clicked`);
  };

  const handleSaveClick = () => {
    // console.log(`${apartment.id} Save clicked`);
  };

  const navigate = useNavigate();

  const navigateBack = () => {
    navigate(-1);
  };

  const [page, setPage] = useState(1);

  const nextPage = (e) => {
    e.preventDefault();
    if (images.length < 5) {
      alert("Please upload at least 5 images.");
      return;
    }
    setPage(2);
  };

  const prevPage = () => {
    setPage(1);
  };

  const [submitting, setSubmitting] = useState(false);
  const [submittedSuccessfully, setSubmittedSuccessfully] = useState(false);

  const Header = () => {
    return (
      <>
        <div className="hidden md:block">
          <MainHeader />
        </div>
        <div>
          <div className="flex items-center justify-between px-6 py-4 bg-white md:hidden">
            <ChevronLeft
              className="w-6 h-6 cursor-pointer"
              onClick={navigateBack}
            />
            <div className="flex gap-x-8 text-gray-700">
              <button onClick={handleShareClick} className="flex items-center">
                <i className="fa-solid fa-arrow-up-from-bracket fa-sm"></i>
              </button>
              <button onClick={handleSaveClick} className="flex items-center">
                <i className="fa-regular fa-heart fa-sm"></i>
              </button>
            </div>
          </div>
        </div>
      </>
    );
  };

  const moveImage = (fromIndex, toIndex) => {
    const updatedImages = [...images];
    const updatedURLs = [...imageURLs];
    
    const [movedImage] = updatedImages.splice(fromIndex, 1);
    updatedImages.splice(toIndex, 0, movedImage);
    
    const [movedURL] = updatedURLs.splice(fromIndex, 1);
    updatedURLs.splice(toIndex, 0, movedURL);
    
    setImages(updatedImages);
    setImageURLs(updatedURLs);
  };

  return (
    <div className="bg-white relative">
      <Header />
      <div className="max-w-3xl mt-1 mx-auto p-8 bg-white rounded-md">
        {page === 1 && (
          <>
            <h1 className="text-3xl font-semibold mb-6 text-center">
              List Your Apartment to Sublease
            </h1>
            <form onSubmit={nextPage}>
              {errorMessage && (
                <div className="mb-4 text-red-500">{errorMessage}</div>
              )}
              <div className="mb-4 flex space-x-4">
                <div className="flex-1">
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Apartment Name
                  </label>
                  <input
                    type="text"
                    placeholder="(e.g. Here Champaign, 102 E. Gregory, etc.)"
                    name="apartmentName"
                    value={subleaseData.apartmentName}
                    onChange={handleChange}
                    className="w-full p-2 border border-gray-300 rounded-md shadow-sm"
                    required
                  />
                </div>
                <div className="flex-1">
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Apartment Community Website Link
                  </label>
                  <input
                    type="url"
                    placeholder="(e.g. https://www.herechampaign.com/)"
                    name="websiteLink"
                    value={subleaseData.websiteLink}
                    onChange={handleChange}
                    className="w-full p-2 border border-gray-300 rounded-md shadow-sm"
                    required
                  />
                </div>
              </div>
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Apartment Configuration
                </label>
                <div className="flex space-x-4">
                  <input
                    type="text"
                    name="configuration"
                    value={subleaseData.configuration}
                    onChange={handleChange}
                    placeholder="(e.g. 4x2 Aerial)"
                    className="flex-1 p-2 border border-gray-300 rounded-md shadow-sm"
                    required
                  />
                  <input
                    type="number"
                    name="bedrooms"
                    value={subleaseData.bedrooms}
                    onChange={handleChange}
                    placeholder="Beds"
                    className="w-20 p-2 border border-gray-300 rounded-md shadow-sm"
                    required
                  />
                  <input
                    type="number"
                    name="bathrooms"
                    value={subleaseData.bathrooms}
                    onChange={handleChange}
                    placeholder="Baths"
                    className="w-20 p-2 border border-gray-300 rounded-md shadow-sm"
                    required
                  />
                </div>
              </div>
              <div className="mb-4 flex space-x-4">
                <div className="flex-1">
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Rent ($)
                  </label>
                  <input
                    type="number"
                    placeholder="(e.g. 929)"
                    name="rent"
                    value={subleaseData.rent}
                    onChange={handleChange}
                    className="w-full p-2 border border-gray-300 rounded-md shadow-sm"
                    required
                  />
                </div>
                <div className="flex-1">
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Apartment Gender Preference
                  </label>
                  <select
                    name="genderRequirement"
                    value={subleaseData.genderRequirement}
                    onChange={handleChange}
                    className="w-full p-2 border border-gray-300 rounded-md shadow-sm"
                    required
                  >
                    <option value="" disabled>
                      Select
                    </option>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                    <option value="Any">Any</option>
                  </select>
                </div>
              </div>
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Description
                </label>
                <textarea
                  name="description"
                  value={subleaseData.description}
                  onChange={handleChange}
                  className="w-full p-2 border border-gray-300 rounded-md shadow-sm"
                  rows="8"
                  placeholder="Hey, I'm Jesse and am subletting a fully furnished room in my 4-bedroom, 2-bathroom apartment at HERE Champaign! It's got great amentities: Fully furnished, high-speed internet, in-unit laundry, balcony with a great view, and access to the building's gym, pool, and study rooms. Why You'll Love It:
              Here is convineient as it's Right in the heart of campus, close to dining, shopping, and public transport.
              Great community. Comfortable with modern furnishings and plenty of space to relax or study.
              Feel free to reach out to me on Abode for more details or to tour the place. Looking forward to hearing from you."
                  required
                ></textarea>
              </div>
              <div className="mb-8">
                <label className="block text-sm font-medium text-gray-700 mb-4">
                  Quick Select Dates
                </label>
                <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
                  <motion.button
                    whileHover={{ scale: 1.02 }}
                    whileTap={{ scale: 0.98 }}
                    className="p-4 rounded-lg border-2 transition-all duration-200 hover:border-blue-500 hover:bg-blue-50"
                    type="button" 
                    onClick={() => {
                      setSubleaseData(prevData => ({
                        ...prevData,
                        startDate: '2025-08-26',
                        endDate: '2025-12-19'
                      }));
                    }}
                  >
                    <div className="text-left">
                      <h4 className="font-semibold text-gray-800">Fall 2025</h4>
                      <p className="text-sm text-gray-600">Aug 26 - Dec 19</p>
                    </div>
                  </motion.button>
                  
                  <motion.button 
                    whileHover={{ scale: 1.02 }}
                    whileTap={{ scale: 0.98 }}
                    className="p-4 rounded-lg border-2 transition-all duration-200 hover:border-blue-500 hover:bg-blue-50"
                    type="button" 
                    onClick={() => {
                      setSubleaseData(prevData => ({
                        ...prevData,
                        startDate: '2025-01-20',
                        endDate: '2025-05-17'
                      }));
                    }}
                  >
                    <div className="text-left">
                      <h4 className="font-semibold text-gray-800">Spring 2025</h4>
                      <p className="text-sm text-gray-600">Jan 20 - May 17</p>
                    </div>
                  </motion.button>

                  <motion.button 
                    whileHover={{ scale: 1.02 }}
                    whileTap={{ scale: 0.98 }}
                    className="p-4 rounded-lg border-2 transition-all duration-200 hover:border-blue-500 hover:bg-blue-50"
                    type="button" 
                    onClick={() => {
                      setSubleaseData(prevData => ({
                        ...prevData,
                        startDate: '2025-05-20',
                        endDate: '2025-07-31'
                      }));
                    }}
                  >
                    <div className="text-left">
                      <h4 className="font-semibold text-gray-800">Summer 2025</h4>
                      <p className="text-sm text-gray-600">May 20 - Jul 31</p>
                    </div>
                  </motion.button>

                  <motion.button 
                    whileHover={{ scale: 1.02 }}
                    whileTap={{ scale: 0.98 }}
                    className="p-4 rounded-lg border-2 transition-all duration-200 hover:border-blue-500 hover:bg-blue-50"
                    type="button" 
                    onClick={() => {
                      setSubleaseData(prevData => ({
                        ...prevData,
                        startDate: '2025-08-26',
                        endDate: '2026-05-17'
                      }));
                    }}
                  >
                    <div className="text-left">
                      <h4 className="font-semibold text-gray-800">Fall 2025-2026</h4>
                      <p className="text-sm text-gray-600">Aug 26 - May 17</p>
                    </div>
                  </motion.button>
                </div>
              </div>

              <div className="mb-8">
                <label className="block text-sm font-medium text-gray-700 mb-4">
                  Custom Date Range
                </label>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.3 }}
                  >
                    <label className="block text-sm font-medium text-gray-700 mb-2">
                      Start Date
                    </label>
                    <input
                      type="date"
                      name="startDate"
                      value={subleaseData.startDate}
                      onChange={handleChange}
                      className="w-full p-3 border border-gray-300 rounded-lg shadow-sm focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                      required
                    />
                  </motion.div>
                  <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.3, delay: 0.1 }}
                  >
                    <label className="block text-sm font-medium text-gray-700 mb-2">
                      End Date
                    </label>
                    <input
                      type="date"
                      name="endDate"
                      value={subleaseData.endDate}
                      onChange={handleChange}
                      className="w-full p-3 border border-gray-300 rounded-lg shadow-sm focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                      required
                    />
                  </motion.div>
                </div>
              </div>
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Fee Information
                </label>
                <input
                  type="text"
                  placeholder="(e.g. Utilties: $30)"
                  name="feeInfo"
                  value={subleaseData.feeInfo}
                  onChange={handleChange}
                  className="w-full p-2 border border-gray-300 rounded-md shadow-sm"
                  required
                />
              </div>
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Address
                </label>
                <input
                  type="text"
                  placeholder="(e.g. 308 E Green St, Champaign, IL 61820, USA)"
                  name="address"
                  value={subleaseData.address}
                  onChange={handleChange}
                  className="w-full p-2 border border-gray-300 rounded-md shadow-sm"
                  required
                />
              </div>

              {roommates.map((roommate, index) => (
                <div key={index} className="mb-4 flex items-center space-x-4">
                  <label className="block text-sm font-medium text-gray-700">
                    Roommate {index + 1}
                  </label>
                  <input
                    type="text"
                    name="occupation"
                    value={roommate.occupation || ""}
                    placeholder="Occupation (e.g. Student)"
                    onChange={(e) => handleRoommateChange(index, e)}
                    className="flex-1 p-2 border border-gray-300 rounded-md shadow-sm"
                    required
                  />
                  <select
                    name="gender"
                    value={roommate.gender || ""}
                    onChange={(e) => handleRoommateChange(index, e)}
                    className="w-24 p-2 border border-gray-300 rounded-md shadow-sm"
                    required
                  >
                    <option value="" disabled>
                      Gender
                    </option>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                  </select>
                  <input
                    type="number"
                    name="age"
                    value={roommate.age || ""}
                    placeholder="Age"
                    onChange={(e) => handleRoommateChange(index, e)}
                    className="w-16 p-2 border border-gray-300 rounded-md shadow-sm"
                    required
                  />
                  <button
                    type="button"
                    onClick={() => handleRemoveRoommate(index)}
                    className="hover:text-red-700"
                  >
                    <img src={deleteIcon} alt="Delete" className="h-6" />
                  </button>
                </div>
              ))}
              <div className="mb-4">
                <button
                  type="button"
                  onClick={() =>
                    setRoommates([
                      ...roommates,
                      { gender: "", age: "", occupation: "" },
                    ])
                  }
                  className="mt-2 px-2 py-2 font-small border border-black rounded-md hover:bg-gray-100"
                >
                  + Add a Roommate
                </button>
              </div>
              <div className="mt-4 mb-8">
                <label className="block text-sm font-medium text-gray-700 mb-4">
                  Upload 5 or More Images
                </label>
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 0.3 }}
                  className="relative min-h-[120px]"
                >
                  <input
                    type="file"
                    multiple
                    accept="image/*,.heic"
                    onChange={(e) => handleImagesChange(e)}
                    className="absolute inset-0 w-full h-full opacity-0 cursor-pointer z-10"
                  />
                  <div className="absolute inset-0 flex flex-col items-center justify-center border-2 border-dashed border-gray-300 rounded-lg hover:border-blue-500 transition-colors">
                    <p className="text-gray-500 text-center px-4">Upload images</p>
                    <p className="text-gray-400 text-sm mt-2 text-center px-4">(Supports JPG, PNG, HEIC)</p>
                  </div>
                </motion.div>
              </div>

              <motion.div 
                className="mt-4 mb-8 flex flex-wrap gap-4"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.3 }}
              >
                <AnimatePresence>
                  {imageURLs.map((url, index) => (
                    <motion.div
                      key={index}
                      initial={{ opacity: 0, scale: 0.8 }}
                      animate={{ opacity: 1, scale: 1 }}
                      exit={{ opacity: 0, scale: 0.8 }}
                      transition={{ duration: 0.2 }}
                      className="relative w-24 h-24 group"
                    >
                      <img
                        src={url}
                        alt={`Preview ${index + 1}`}
                        className="w-full h-full object-cover rounded-lg cursor-pointer shadow-sm hover:shadow-md transition-shadow"
                        onClick={() => openModal(url)}
                      />
                      <motion.button
                        whileHover={{ scale: 1.1 }}
                        whileTap={{ scale: 0.9 }}
                        onClick={(e) => {
                          e.stopPropagation();
                          handleRemoveImage(index);
                        }}
                        className="absolute -top-2 -right-2 bg-red-500 text-white rounded-full w-6 h-6 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity shadow-md"
                      >
                        ×
                      </motion.button>
                    </motion.div>
                  ))}
                </AnimatePresence>
              </motion.div>
              <div className="text-center">
                <button
                  type="submit"
                  className="mt-8 px-4 py-2 font-medium border border-black rounded-md hover:bg-gray-100"
                >
                  Next
                </button>
              </div>
            </form>
          </>
        )}

        {page === 2 && (
          <>
            <h1 className="text-3xl font-semibold mb-6 text-center">
              Confirm your Contact Information
            </h1>
            <form onSubmit={onSubmit}>
              {errorMessage && (
                <div className="mb-4 text-red-500">{errorMessage}</div>
              )}
              <div className="mt-10 flex flex-col items-center">
                <div className="relative mb-4 group">
                  <div className="absolute inset-0 w-full h-full flex items-center justify-center text-white opacity-0 group-hover:opacity-100 transition-opacity">
                    Change Profile
                  </div>
                  {userData.profilePic ? (
                    <img
                      src={userData.profilePic}
                      alt="Profile"
                      className="w-48 h-48 rounded-full object-cover"
                    />
                  ) : (
                    <div className="w-48 h-48 bg-gray-300 rounded-full flex items-center justify-center">
                      <span>No Image</span>
                    </div>
                  )}
                  <input
                    type="file"
                    accept="image/*"
                    onChange={handleFileChange}
                    className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
                  />
                  {uploading && (
                    <div className="absolute inset-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center text-white">
                      Uploading...
                    </div>
                  )}
                </div>
              </div>
              <div className="mb-4 flex space-x-4">
                <div className="flex-1">
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Personal Email
                  </label>
                  <input
                    type="email"
                    placeholder="(e.g. johndoe@gmail.com)"
                    name="email"
                    value={userData.email}
                    onChange={handleUserInfoChange}
                    className="w-full p-2 border border-gray-300 rounded-md shadow-sm"
                    required
                  />
                </div>
                <div className="flex-1">
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Phone Number
                  </label>
                  <input
                    type="tel"
                    placeholder="(e.g. 1234567890)"
                    name="phone"
                    value={userData.phone}
                    onChange={handleUserInfoChange}
                    className="w-full p-2 border border-gray-300 rounded-md shadow-sm"
                    pattern="[0-9]{10}"
                    required
                  />
                </div>
              </div>
              <div className="mb-4 flex space-x-4">
                <div className="flex-1">
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Instagram Handle
                  </label>
                  <input
                    type="text"
                    placeholder="(e.g. johndoe_123)"
                    name="instagramHandle"
                    value={userData.instagramHandle}
                    onChange={handleUserInfoChange}
                    className="w-full p-2 border border-gray-300 rounded-md shadow-sm"
                    required
                  />
                </div>
                <div className="flex-1">
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    LinkedIn Handle
                  </label>
                  <input
                    type="text"
                    placeholder="(e.g. johnathon_doe)"
                    name="linkedinHandle"
                    value={userData.linkedinHandle}
                    onChange={handleUserInfoChange}
                    className="w-full p-2 border border-gray-300 rounded-md shadow-sm"
                    required
                  />
                </div>
              </div>
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Bio
                </label>
                <textarea
                  name="bio"
                  value={bio}
                  onChange={(e) => setBio(e.target.value)}
                  className="w-full p-2 border border-gray-300 rounded-md shadow-sm"
                  rows="4"
                  required
                ></textarea>
              </div>
              <div className="text-center flex justify-between">
                <button
                  type="button"
                  onClick={prevPage}
                  className="mt-8 px-4 py-2 font-medium border border-black rounded-md hover:bg-gray-100"
                >
                  Back
                </button>
                <button
                  type="submit"
                  className="mt-8 px-4 py-2 font-medium border border-black rounded-md hover:bg-gray-100"
                >
                  Submit
                </button>
              </div>
            </form>
          </>
        )}
      </div>
      <Footer />

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        shouldCloseOnOverlayClick={true}
        contentLabel="Image Modal"
        className="flex items-center justify-center fixed inset-0 z-50 outline-none focus:outline-none"
        overlayClassName="fixed inset-0 bg-black bg-opacity-75"
      >
        <div className="relative bg-white rounded-md p-4 max-w-4xl mx-auto">
          <button
            onClick={closeModal}
            className="absolute top-0 right-1 text-gray-700 hover:text-gray-900"
          >
            &times;
          </button>
          {selectedImage && (
            <img
              src={selectedImage}
              alt="Selected"
              className="w-full h-auto rounded-md"
            />
          )}
        </div>
      </Modal>

      <Modal
        isOpen={submitting || submittedSuccessfully}
        onRequestClose={() => {
          setSubmittedSuccessfully(false); // Reset state when modal closes
          setSubmitting(false);
        }}
        contentLabel="Submission Status Modal"
        className="flex items-center justify-center fixed inset-0 z-50 outline-none focus:outline-none"
        overlayClassName="fixed inset-0 bg-black bg-opacity-75"
      >
        <div className="relative bg-white rounded-md p-4 max-w-md mx-auto text-center">
          {submitting && !submittedSuccessfully && (
            <>
              <p className="text-lg font-semibold">Submitting...</p>
            </>
          )}
          {submittedSuccessfully && (
            <>
              <p className="text-3xl font-semibold">Congratulations!</p>
              <p className="text-md text-gray-700 mt-3">
                You have successfully added your sublease to our listings!
              </p>
              <p className="text-md text-gray-700 mt-3">
                Abode users will be able to directly find your listing, view its
                details, and contact you through Abode itself. In the meanwhile,
                make sure to check you personal email as students who are
                looking to stay in your place or ask any further questions may
                reach out to you.
              </p>
              <button
                onClick={() => {
                  setSubmittedSuccessfully(false);
                  setSubmitting(false);
                  navigate("/listings");
                }}
                className="mt-4 px-4 py-2 font-medium border border-black rounded-md hover:bg-gray-100"
              >
                Back to Listings
              </button>
            </>
          )}
        </div>
      </Modal>
    </div>
  );
}

export default SubleaseFormPage;
