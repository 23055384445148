import React, { useState, useEffect } from 'react';
import { collection, getDocs, query, orderBy } from 'firebase/firestore';
import { db } from '../../../firebase/index';
import SubleaseSearch from '../components/SubleaseSearch';
import SubleaseEditor from '../components/SubleaseEditor';

const SubleasesPage = () => {
  const [selectedSublease, setSelectedSublease] = useState(null);
  const [subleases, setSubleases] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    loadSubleases();
  }, []);

  const loadSubleases = async () => {
    try {
      const subleasesQuery = query(
        collection(db, 'subleases'),
        orderBy('createdAt', 'desc')
      );
      const querySnapshot = await getDocs(subleasesQuery);
      const subleasesData = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setSubleases(subleasesData);
    } catch (error) {
      console.error('Error loading subleases:', error);
      setError('Failed to load subleases');
    } finally {
      setLoading(false);
    }
  };

  const handleSubleaseLoad = (sublease) => {
    setSelectedSublease(sublease);
  };

  const handleSubleaseUpdate = () => {
    loadSubleases(); // Reload the subleases list after an update
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-[200px]">
        <div className="text-gray-500">Loading...</div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex items-center justify-center min-h-[200px]">
        <div className="text-red-500">{error}</div>
      </div>
    );
  }

  return (
    <div className="space-y-6 pb-16 md:pb-0">
      <SubleaseSearch onSubleaseLoad={handleSubleaseLoad} />
      
      {selectedSublease ? (
        <div className="mb-16 md:mb-0">
          <SubleaseEditor 
            sublease={selectedSublease} 
            onUpdate={handleSubleaseUpdate}
          />
        </div>
      ) : (
        <div className="bg-white rounded-lg shadow overflow-hidden">
          <div className="p-4 border-b">
            <h2 className="text-xl font-semibold">Recent Subleases</h2>
          </div>
          <div className="overflow-x-auto">
            <table className="w-full">
              <thead>
                <tr className="bg-gray-50">
                  <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">ID</th>
                  <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Apartment</th>
                  <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Status</th>
                  <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Created</th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {subleases.map((sublease) => (
                  <tr 
                    key={sublease.id} 
                    className="hover:bg-gray-50 cursor-pointer"
                    onClick={() => handleSubleaseLoad(sublease)}
                  >
                    <td className="px-4 py-3 whitespace-nowrap text-sm font-medium text-gray-900">
                      {sublease.id}
                    </td>
                    <td className="px-4 py-3">
                      <div className="text-sm font-medium text-gray-900">{sublease.apartmentName}</div>
                      <div className="text-sm text-gray-500">{sublease.address}</div>
                    </td>
                    <td className="px-4 py-3 whitespace-nowrap">
                      <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                        sublease.status === 'active' 
                          ? 'bg-green-100 text-green-800'
                          : 'bg-red-100 text-red-800'
                      }`}>
                        {sublease.status}
                      </span>
                    </td>
                    <td className="px-4 py-3 whitespace-nowrap text-sm text-gray-500">
                      {sublease.createdAt?.toDate().toLocaleDateString()}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
};

export default SubleasesPage; 