import {
  doc,
  arrayUnion,
  arrayRemove,
  getDoc,
  setDoc,
  updateDoc,
  serverTimestamp,
  query,
  collection,
  orderBy,
  increment,
  getDocs,
  deleteDoc,
  where,
  limit,
  deleteField
} from "firebase/firestore";
import { db, storage } from "../firebase/index";
import { getDownloadURL, ref, uploadBytes, getStorage } from "firebase/storage";


export async function addUserToRoommateList(userId, apartmentId) {
  try {
    const apartmentDocRef = doc(db, "apartments", apartmentId);
    const userDocRef = doc(db, "users", userId);

    // Check if the apartment document exists
    const apartmentDoc = await getDoc(apartmentDocRef);
    if (!apartmentDoc.exists()) {
      // Create the apartment document if it doesn't exist
      await setDoc(apartmentDocRef, {
        userIds: [userId], // Add the userId to the userIds array
      });
    } else {
      // Update the apartment document if it exists
      await updateDoc(apartmentDocRef, {
        userIds: arrayUnion(userId), // Adds the userId to the userIds array field
      });
    }

    // Update the user document with the apartmentId in roommateAptIds
    await updateDoc(userDocRef, {
      roommateAptIds: arrayUnion(apartmentId),
    });

    const isOnGeneral = isUserOnAptRoommateList(userId, "general");
    if (!isOnGeneral) {
      await addUserToRoommateList(userId, "general")
    }

  } catch (error) {
    console.error("Error updating documents: ", error);
  }
}

export async function removeUserFromRoommateList(userId, apartmentId) {
  try {
    const apartmentDocRef = doc(db, "apartments", apartmentId);
    const userDocRef = doc(db, "users", userId);

    // Check if the apartment document exists
    const apartmentDoc = await getDoc(apartmentDocRef);
    if (!apartmentDoc.exists()) {
      console.error("Apartment document does not exist.");
      return;
    }

    // Update the apartment document to remove the userId from the userIds array
    await updateDoc(apartmentDocRef, {
      userIds: arrayRemove(userId),
    });

    // Update the user document to remove the apartmentId from roommateAptIds array
    await updateDoc(userDocRef, {
      roommateAptIds: arrayRemove(apartmentId),
    });

    

  } catch (error) {
    console.error("Error updating documents: ", error);
  }
}

export async function removeRoommateAptIdsFromAllUsers() {
  try {
    // Step 1: Get all users from the "users" collection
    const usersCollectionRef = collection(db, "users");
    const usersSnapshot = await getDocs(usersCollectionRef);

    // Step 2: Iterate over each user document and remove the "roommateAptIds" field
    const updatePromises = usersSnapshot.docs.map((userDoc) => {
      const userDocRef = doc(db, "users", userDoc.id);
      return updateDoc(userDocRef, {
        roommateAptIds: deleteField(),
      });
    });

    // Wait for all update operations to complete
    await Promise.all(updatePromises);

    // console.log("roommateAptIds field successfully removed from all users.");
  } catch (error) {
    console.error("Error removing roommateAptIds from users: ", error);
  }
}

export async function trackClick(userId, apartmentId) {
  if (userId === null || apartmentId === null) {
    return;
  }

  const userActivityRef = doc(db, "userActivity", userId);
  const apartmentClickRef = doc(userActivityRef, "clicks", apartmentId);
  const clicksCollectionRef = collection(userActivityRef, "clicks");

  try {
    const docSnap = await getDoc(apartmentClickRef);

    if (docSnap.exists()) {
      // Document exists, update timestamp
      await updateDoc(apartmentClickRef, {
        clicks: increment(1),
        timestamp: serverTimestamp(),
      });
    } else {
      // Before adding a new apartment click, check if the user already has clicks on 10 apartments
      const clicksQuery = query(
        clicksCollectionRef,
        orderBy("timestamp", "asc")
      );
      const querySnapshot = await getDocs(clicksQuery);

      if (querySnapshot.docs.length >= 10) {
        // Delete the oldest click document to maintain only 10
        await deleteDoc(
          doc(db, "userActivity", userId, "clicks", querySnapshot.docs[0].id)
        );
      }

      // Add the new apartment click
      await setDoc(apartmentClickRef, {
        apartmentId: apartmentId,
        timestamp: serverTimestamp(),
        clicks: 1, // Initial click count can be set here if tracking individual clicks per apartment is needed
      });
    }

    // console.log("Click tracked successfully.");
  } catch (error) {
    console.error("Error tracking click:", error);
  }
}

export async function addApartmentDocument(userId, apartmentId) {
  if (apartmentId === null || userId === null) {
    return;
  }

  const apartmentDocRef = doc(db, "apartments", apartmentId);

  try {
    const docSnap = await getDoc(apartmentDocRef);

    if (docSnap.exists()) {
      // Document exists, check if the userId is in the array
      const data = docSnap.data();
      if (data.userIds && data.userIds.includes(userId)) {
        // console.log("User already interested.");
      } else {
        await updateDoc(apartmentDocRef, {
          userIds: arrayUnion(userId),
          numberInterested: increment(1),
        });
        // console.log(
        //   "User added and number Interested incremented successfully."
        // );
      }
    } else {
      await setDoc(apartmentDocRef, {
        numberInterested: 1,
        userIds: [userId],
      });
      // console.log("Apartment document added successfully.");
    }
  } catch (error) {
    console.error("Error adding apartment document:", error);
  }
}

export async function removeApartmentDocument(userId, apartmentId) {
  if (apartmentId === null || userId === null) {
    return;
  }

  const apartmentDocRef = doc(db, "apartments", apartmentId);

  try {
    const docSnap = await getDoc(apartmentDocRef);

    if (docSnap.exists()) {
      // Document exists, check if the userId is in the array
      const data = docSnap.data();
      if (data.userIds && data.userIds.includes(userId)) {
        await updateDoc(apartmentDocRef, {
          userIds: arrayRemove(userId),
          numberInterested: increment(-1),
        });
        // console.log("User removed and numberInterested decremented successfully.");
      } else {
        // console.log("User not in the interest list.");
      }
    } else {
      console.error("No such apartment document!");
    }
  } catch (error) {
    console.error("Error removing apartment document:", error);
  }
}

export async function addRoommate(userId1, userId2) {
  if (!userId1 || !userId2) return;

  // Check if the roommate pair already exists
  const roommateQuery = query(
    collection(db, "roommates"),
    where("User1ID", "in", [userId1, userId2]),
    where("User2ID", "in", [userId1, userId2])
  );

  const querySnapshot = await getDocs(roommateQuery);

  if (!querySnapshot.empty) {
    return false;
  }

  const roommateDocRef = doc(collection(db, "roommates"));

  try {
    await setDoc(roommateDocRef, {
      User1ID: userId1,
      User2ID: userId2,
      timestamp: serverTimestamp(),
    });
    // console.log("Roommate document added successfully.");
    return true;
  } catch (error) {
    console.error("Error adding roommate document:", error);
    throw error;
  }
}

export async function updateBio(userId, bio) {
  if (!userId || !bio) {
    throw new Error("User ID and bio must be provided.");
  }

  const userDocRef = doc(db, "users", userId);

  try {
    await updateDoc(userDocRef, {
      bio: bio,
    });
  } catch (error) {
    console.error("Error updating bio:", error);
    throw error;
  }
}

export async function updateHobbies(userId, hobbies) {
  if (!userId || !hobbies) {
    throw new Error("User ID and hobbies must be provided.");
  }

  const userDocRef = doc(db, "users", userId);

  try {
    await updateDoc(userDocRef, {
      hobbies: hobbies,
    });
    // console.log("Hobbies updated successfully");
  } catch (error) {
    console.error("Error updating hobbies:", error);
    throw error;
  }
}

export async function updateShowRoommateProfile(userId, showProfile) {
  if (!userId || typeof showProfile !== "boolean") {
    throw new Error("User ID and showProfile (boolean) must be provided.");
  }

  const userDocRef = doc(db, "users", userId);

  try {
    await updateDoc(userDocRef, {
      showRoommateProfile: showProfile,
    });
    // console.log(`showRoommateProfile updated to ${showProfile} successfully`);
  } catch (error) {
    console.error("Error updating showRoommateProfile:", error);
    throw error;
  }
}


export async function isUserOnAptRoommateList(userId, aptId) {
  if (!aptId) {
    return false; // If aptId is null or undefined, return false
  }

  try {
    // Reference the user's document in the "users" collection
    const userDocRef = doc(db, "users", userId);
    const userDoc = await getDoc(userDocRef);

    if (userDoc.exists()) {
      const userData = userDoc.data();
      const roommatesAptId = userData.roommateAptIds || [];

      // Check if the aptId is in the roommatesAptId array
      return roommatesAptId.includes(aptId);
    } else {
      // If the user document does not exist, return false
      return false;
    }
  } catch (error) {
    console.error("Error fetching user data:", error);
    throw error;
  }
}

export async function fetchUserData(userId) {
  if (userId === null) {
    return;
  }
  
  const userDocRef = doc(db, "users", userId);

  try {
    const userDocSnap = await getDoc(userDocRef);

    if (userDocSnap.exists()) {
      return userDocSnap.data();
    } else {
      console.error("No such user document!");
      return null;
    }
  } catch (error) {
    console.error("Error fetching user data:", error);
    throw error;
  }
}

export async function fetchUserPhoneNumber(userId) {
  if (userId === null) {
    return;
  }

  const userDocRef = doc(db, "users", userId);

  try {
    // Fetch only the 'phoneNumber' field
    const userDocSnap = await getDoc(userDocRef, {
      fieldMask: ["phone"],
    });

    if (userDocSnap.exists()) {
      return userDocSnap.get("phone");
    } else {
      console.error("No such user document!");
      return null;
    }
  } catch (error) {
    console.error("Error fetching user phone number:", error);
    throw error;
  }
}

export async function uploadProfilePicture(userId, file) {
  if (!userId || !file) {
    return;
  }

  const storageRef = ref(storage, `profile_pictures/${userId}`);

  try {
    await uploadBytes(storageRef, file);
    // console.log("File uploaded successfully");

    const downloadURL = await getDownloadURL(storageRef);

    const userDocRef = doc(db, "users", userId);
    await updateDoc(userDocRef, {
      profilePicture: downloadURL,
    });

    // console.log("Profile picture updated successfully");
    return downloadURL;
  } catch (error) {
    console.error("Error uploading file:", error);
    throw error;
  }
}

export async function updateContactInfo(userId, contactInfo) {
  if (!userId || !contactInfo) {
    throw new Error("User ID and contact information must be provided.");
  }

  const userDocRef = doc(db, "users", userId);

  //only the provided keys
  const updateData = {};
  if (contactInfo.description !== undefined)
    updateData.description = contactInfo.description;
  if (contactInfo.phone !== undefined) updateData.phone = contactInfo.phone;
  if (contactInfo.instagramHandle !== undefined)
    updateData.instagramHandle = contactInfo.instagramHandle;
  if (contactInfo.linkedinHandle !== undefined)
    updateData.linkedinHandle = contactInfo.linkedinHandle;
  if (contactInfo.preferredContact !== undefined)
    updateData.preferredContact = contactInfo.preferredContact;

  try {
    await updateDoc(userDocRef, updateData);
    // console.log("Contact information updated successfully");
  } catch (error) {
    console.error("Error updating contact information:", error);
    throw error;
  }
}

export async function fetchFavorites(userId) {
  // console.log("fetching favorites");
  const userDocRef = doc(db, "users", userId);

  try {
    const userDocSnap = await getDoc(userDocRef);
    if (userDocSnap.exists()) {
      const favorites = userDocSnap.get("favorites");
      // console.log(favorites);
      return favorites || [];
    } else {
      // console.log("No such document!");
    }
  } catch (error) {
    console.error("Error getting favorites: ", error);
  }
  return [];
}

export async function removeFavorite(userId, apartmentId) {
  // console.log("removing favorite");
  const userDocRef = doc(db, "users", userId);
  try {
    await updateDoc(userDocRef, {
      favorites: arrayRemove(apartmentId),
    });
  } catch (error) {
    console.error("Error adding favorite:", error);
  }
}

export async function addFavorite(userId, apartmentId) {
  // console.log("adding favorite");
  const userDocRef = doc(db, "users", userId);
  try {
    await updateDoc(userDocRef, {
      favorites: arrayUnion(apartmentId),
    });
  } catch (error) {
    console.error("Error adding favorite:", error);
  }
}

export async function getReviewDoc(apartmentId) {
  const reviewDoc = await getDoc(doc(db, "reviews", apartmentId));
  return reviewDoc;
}

export async function getTopReviews(apartmentId) {
  const reviewsCollection = collection(db, `reviews/${apartmentId}/apartmentReviews`);
  const reviewsQuery = query(reviewsCollection, limit(6));
  const reviewDocsSnapshot = await getDocs(reviewsQuery);
  return reviewDocsSnapshot;
}

export async function getAllReviews(apartmentId) {
  const querySnapshot = await getDocs(
    collection(db, "reviews", apartmentId, "apartmentReviews")
  );
  return querySnapshot;
}

export const fetchUserQuestionnaireResponses = async (userId) => {
  const userDocRef = doc(db, "users", userId);
  const userDocSnap = await getDoc(userDocRef);

  if (userDocSnap.exists()) {
    const userData = userDocSnap.data();
    return userData.questionnaireResponses || Array(10).fill(null);
  } else {
    throw new Error("User not found");
  }
};

export const saveUserQuestionnaireResponses = async (userId, responses) => {
  const userDocRef = doc(db, "users", userId);
  await updateDoc(userDocRef, { questionnaireResponses: responses });
};

export async function getSubleaseDoc(subleaseId) {
  const subleaseDoc = await getDoc(doc(db, "subleases", subleaseId));
  return subleaseDoc;
}

export async function getSubleaseRoommatesDoc(subleaseId) {
  const subleaseRoommatesCollection = collection(
    db,
    "subleases",
    subleaseId,
    "roommates"
  );
  const roommatesSnapshot = await getDocs(subleaseRoommatesCollection);
  const roommatesList = await roommatesSnapshot.docs.map((doc) => doc.data());
  return roommatesList;
}

export async function getSubleaseImages(subleaseId) {
  const storage = getStorage();
  const listRef = ref(storage, `sublease_images/${subleaseId}/`);
  return listRef;
}

export async function addPhoneNumber(phoneNumber) {
  if (!phoneNumber) {
    throw new Error("Phone number must be provided.");
  }

  const phoneNumberDocRef = doc(collection(db, "phoneNumbers"));

  try {
    await setDoc(phoneNumberDocRef, {
      phone: phoneNumber,
      timestamp: serverTimestamp()
    });
    // console.log("Phone number added successfully.");
  } catch (error) {
    console.error("Error adding phone number:", error);
    throw error;
  }
}

export async function updateUserProfile(userId, updateFields) {
  if (!userId || !updateFields || Object.keys(updateFields).length === 0) {
    throw new Error("User ID and update fields must be provided.");
  }

  const userDocRef = doc(db, "users", userId);

  try {
    await updateDoc(userDocRef, updateFields);
  } catch (error) {
    console.error("Error updating user profile:", error);
    throw error;
  }
}

// Sublease Process Status Tracking
export const subleaseProcessService = {
  // Initialize sublease process tracking
  async initializeSubleaseProcess(subleaseId, participants) {
    const processRef = doc(db, 'subleaseProcesses', subleaseId);
    await setDoc(processRef, {
      subleaseId,
      participants,
      status: 'initialized',
      currentStep: 'chat',
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString()
    });
  },

  // Update process status and log activity
  async updateProcessStatus(subleaseId, status, step, activity) {
    const processRef = doc(db, 'subleaseProcesses', subleaseId);
    const activityLogRef = doc(collection(db, 'subleaseActivityLogs'));
    
    const activityLog = {
      subleaseId,
      timestamp: new Date().toISOString(),
      activity,
      status,
      step
    };

    // Update process status
    await updateDoc(processRef, {
      status,
      currentStep: step,
      updatedAt: new Date().toISOString()
    });

    // Store activity log separately
    await setDoc(activityLogRef, activityLog);
  },

  // Get process status
  async getProcessStatus(subleaseId) {
    const processRef = doc(db, 'subleaseProcesses', subleaseId);
    const docSnap = await getDoc(processRef);
    return docSnap.exists() ? docSnap.data() : null;
  },

  // Get activity logs for a sublease
  async getActivityLogs(subleaseId, limit = 50) {
    const logsQuery = query(
      collection(db, 'subleaseActivityLogs'),
      where('subleaseId', '==', subleaseId),
      orderBy('timestamp', 'desc'),
      limit(limit)
    );
    
    const querySnapshot = await getDocs(logsQuery);
    return querySnapshot.docs.map(doc => doc.data());
  },

  // Track tour booking
  async trackTourBooking(subleaseId, bookingDetails) {
    const processRef = doc(db, 'subleaseProcesses', subleaseId);
    const activityLogRef = doc(collection(db, 'subleaseActivityLogs'));
    
    const activityLog = {
      subleaseId,
      timestamp: new Date().toISOString(),
      activity: 'tour_booked',
      details: bookingDetails,
      status: 'tour_scheduled',
      step: 'tour'
    };

    await updateDoc(processRef, {
      status: 'tour_scheduled',
      currentStep: 'tour',
      updatedAt: new Date().toISOString(),
      tourDetails: bookingDetails
    });

    await setDoc(activityLogRef, activityLog);
  },

  // Track terms agreement
  async trackTermsAgreement(subleaseId, termsDetails) {
    const processRef = doc(db, 'subleaseProcesses', subleaseId);
    const activityLogRef = doc(collection(db, 'subleaseActivityLogs'));
    
    const activityLog = {
      subleaseId,
      timestamp: new Date().toISOString(),
      activity: 'terms_agreed',
      details: termsDetails,
      status: 'terms_agreed',
      step: 'terms'
    };

    await updateDoc(processRef, {
      status: 'terms_agreed',
      currentStep: 'terms',
      updatedAt: new Date().toISOString(),
      termsDetails
    });

    await setDoc(activityLogRef, activityLog);
  },

  // Track application submission
  async trackApplicationSubmission(subleaseId, applicationDetails) {
    const processRef = doc(db, 'subleaseProcesses', subleaseId);
    const activityLogRef = doc(collection(db, 'subleaseActivityLogs'));
    
    const activityLog = {
      subleaseId,
      timestamp: new Date().toISOString(),
      activity: 'application_submitted',
      details: applicationDetails,
      status: 'application_submitted',
      step: 'application'
    };

    await updateDoc(processRef, {
      status: 'application_submitted',
      currentStep: 'application',
      updatedAt: new Date().toISOString(),
      applicationDetails
    });

    await setDoc(activityLogRef, activityLog);
  },

  // Track payment completion
  async trackPaymentCompletion(subleaseId, paymentDetails) {
    const processRef = doc(db, 'subleaseProcesses', subleaseId);
    const activityLogRef = doc(collection(db, 'subleaseActivityLogs'));
    
    const activityLog = {
      subleaseId,
      timestamp: new Date().toISOString(),
      activity: 'payment_completed',
      details: paymentDetails,
      status: 'payment_completed',
      step: 'payment'
    };

    await updateDoc(processRef, {
      status: 'payment_completed',
      currentStep: 'payment',
      updatedAt: new Date().toISOString(),
      paymentDetails
    });

    await setDoc(activityLogRef, activityLog);
  },

  // Track move-in completion
  async trackMoveInCompletion(subleaseId, moveInDetails) {
    const processRef = doc(db, 'subleaseProcesses', subleaseId);
    const activityLogRef = doc(collection(db, 'subleaseActivityLogs'));
    
    const activityLog = {
      subleaseId,
      timestamp: new Date().toISOString(),
      activity: 'move_in_completed',
      details: moveInDetails,
      status: 'completed',
      step: 'move_in'
    };

    await updateDoc(processRef, {
      status: 'completed',
      currentStep: 'move_in',
      updatedAt: new Date().toISOString(),
      moveInDetails
    });

    await setDoc(activityLogRef, activityLog);
  }
};