import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../../firebase';
import { subleaseProcessService } from '../../backend/subleaseAPI';
import { sendTourBookingEmail } from '../../backend/emailAPI';
import MainHeader from '../../components/Headers/MainHeader';
import Footer from '../../components/Headers/Footer';

function TourDetailsPage() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [user] = useAuthState(auth);
  const [tourDetails, setTourDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isCanceling, setIsCanceling] = useState(false);
  const [showCancelConfirm, setShowCancelConfirm] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editedDetails, setEditedDetails] = useState(null);
  const [isSaving, setIsSaving] = useState(false);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });
  };

  const formatTime = (timeString) => {
    const [hours, minutes] = timeString.split(':');
    const date = new Date();
    date.setHours(parseInt(hours), parseInt(minutes));
    return date.toLocaleTimeString('en-US', {
      hour: 'numeric',
      minute: '2-digit',
      hour12: true,
      timeZoneName: 'short'
    });
  };

  const formatEmailDate = (dateString, timeString) => {
    const date = new Date(dateString);
    const time = new Date(timeString);
    return date.toLocaleDateString('en-US', {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    }) + ' at ' + time.toLocaleTimeString('en-US', {
      hour: 'numeric',
      minute: '2-digit',
      hour12: true
    });
  };

  useEffect(() => {
    const fetchTourDetails = async () => {
      if (!id) {
        console.error('No sublease ID found in URL parameters');
        setError('Invalid sublease ID');
        setLoading(false);
        return;
      }

      console.log('Fetching tour details for sublease ID:', id);
      
      try {
        const status = await subleaseProcessService.getProcessStatus(id);
        if (!status) {
          setError('No sublease process found');
          return;
        }
        
        if (!status.tourDetails) {
          setError('No tour details found for this sublease');
          return;
        }
        
        setTourDetails(status.tourDetails);
        setEditedDetails(status.tourDetails);
      } catch (err) {
        console.error('Error fetching tour details:', err);
        setError('Failed to load tour details');
      } finally {
        setLoading(false);
      }
    };

    fetchTourDetails();
  }, [id]);

  const handleCancelTour = async () => {
    if (!window.confirm('Are you sure you want to cancel this tour?')) {
      return;
    }

    setIsCanceling(true);
    try {
      // Step 1: Get current process status
      const processStatus = await subleaseProcessService.getProcessStatus(id);
      if (!processStatus) {
        throw new Error('No process status found');
      }

      // Step 2: Update process status and log activity
      await subleaseProcessService.updateProcessStatus(
        id,
        'in_discussion',  // status
        'chat',           // step
        {
          type: 'tour_cancelled',
          cancelledBy: user.uid,
          previousTourDetails: tourDetails
        }                // activity
      );

      // Step 3: Send cancellation email
      await sendTourBookingEmail({
        subleaseId: id,
        participants: [user.uid, tourDetails.bookedBy],
        tourDetails: {
          ...tourDetails,
          status: 'cancelled',
          formattedDateTime: formatEmailDate(tourDetails.date, tourDetails.time)
        },
        type: 'cancellation'
      });

      // Step 4: Navigate back to sublease page
      navigate(`/sublease/${id}`);
    } catch (err) {
      console.error('Error canceling tour:', err);
      setError('Failed to cancel tour. Please try again.');
    } finally {
      setIsCanceling(false);
      setShowCancelConfirm(false);
    }
  };

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleSave = async () => {
    setIsSaving(true);
    try {
      // Update the tour details
      await subleaseProcessService.updateTourDetails(id, editedDetails);
      
      // Send update email
      await sendTourBookingEmail({
        subleaseId: id,
        participants: [user.uid, tourDetails.bookedBy],
        tourDetails: editedDetails,
        type: 'update'
      });

      setTourDetails(editedDetails);
      setIsEditing(false);
    } catch (error) {
      console.error('Error updating tour details:', error);
      setError('Failed to update tour details');
    } finally {
      setIsSaving(false);
    }
  };

  const handleCancelEdit = () => {
    setEditedDetails(tourDetails);
    setIsEditing(false);
  };

  if (loading) {
    return (
      <div className="min-h-screen bg-gray-50">
        <MainHeader />
        <div className="container max-w-4xl mx-auto px-4 py-8">
          <div className="animate-pulse">
            <div className="h-8 bg-gray-200 rounded w-1/4 mb-4"></div>
            <div className="h-4 bg-gray-200 rounded w-1/2 mb-8"></div>
            <div className="space-y-4">
              <div className="h-12 bg-gray-200 rounded"></div>
              <div className="h-12 bg-gray-200 rounded"></div>
              <div className="h-12 bg-gray-200 rounded"></div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen bg-gray-50">
        <MainHeader />
        <div className="container max-w-4xl mx-auto px-4 py-8">
          <div className="bg-white rounded-lg shadow-sm p-6">
            <div className="text-center">
              <div className="w-16 h-16 mx-auto mb-4 rounded-full bg-red-100 flex items-center justify-center">
                <i className="fa-solid fa-exclamation text-red-500 text-2xl"></i>
              </div>
              <h2 className="text-xl font-semibold mb-2">Error</h2>
              <p className="text-gray-600 mb-4">{error}</p>
              <button
                onClick={() => navigate(`/sublease/${id}`)}
                className="px-4 py-2 bg-logo_blue text-white rounded-lg hover:bg-blue-600"
              >
                Back to Sublease
              </button>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50">
      <MainHeader />
      <div className="container max-w-4xl mx-auto px-4 py-8">
        <div className="bg-white rounded-lg shadow-sm p-6">
          <div className="flex justify-between items-start mb-6">
            <div>
              <h1 className="text-2xl font-bold text-gray-800 mb-2">Tour Details</h1>
              <p className="text-gray-600">Scheduled tour information</p>
            </div>
            <div className="flex space-x-2">
              {!isEditing ? (
                <>
                  <button
                    onClick={handleEdit}
                    className="px-3 py-1.5 text-sm text-gray-600 hover:text-gray-800 border border-gray-300 rounded-lg hover:bg-gray-50"
                  >
                    Edit Tour
                  </button>
                  <button
                    onClick={() => setShowCancelConfirm(true)}
                    className="px-3 py-1.5 text-sm text-red-600 hover:text-red-800 border border-red-300 rounded-lg hover:bg-red-50"
                  >
                    Cancel Tour
                  </button>
                </>
              ) : (
                <div className="flex space-x-2">
                  <button
                    onClick={handleCancelEdit}
                    className="px-3 py-1.5 text-sm text-gray-600 hover:text-gray-800 border border-gray-300 rounded-lg hover:bg-gray-50"
                  >
                    Cancel
                  </button>
                  <button
                    onClick={handleSave}
                    disabled={isSaving}
                    className="px-3 py-1.5 text-sm bg-logo_blue text-white rounded-lg hover:bg-blue-600 disabled:opacity-50"
                  >
                    {isSaving ? 'Saving...' : 'Save Changes'}
                  </button>
                </div>
              )}
            </div>
          </div>

          <div className="space-y-6">
            <div className="grid grid-cols-2 gap-4">
              <div>
                <label className="block text-sm font-medium text-gray-500">Date</label>
                {isEditing ? (
                  <input
                    type="date"
                    value={editedDetails.date}
                    onChange={(e) => setEditedDetails({ ...editedDetails, date: e.target.value })}
                    className="mt-1 w-full p-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                    min={new Date().toISOString().split('T')[0]}
                  />
                ) : (
                  <p className="mt-1 text-gray-900">{formatDate(tourDetails.date)}</p>
                )}
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-500">Time</label>
                {isEditing ? (
                  <input
                    type="time"
                    value={editedDetails.time}
                    onChange={(e) => setEditedDetails({ ...editedDetails, time: e.target.value })}
                    className="mt-1 w-full p-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                  />
                ) : (
                  <p className="mt-1 text-gray-900">{formatTime(tourDetails.time)}</p>
                )}
              </div>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-500">Type</label>
              {isEditing ? (
                <select
                  value={editedDetails.type}
                  onChange={(e) => setEditedDetails({ ...editedDetails, type: e.target.value })}
                  className="mt-1 w-full p-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                >
                  <option value="in-person">In-Person Tour</option>
                  <option value="virtual">Virtual Tour</option>
                </select>
              ) : (
                <p className="mt-1 text-gray-900 capitalize">{tourDetails.type}</p>
              )}
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-500">Location</label>
              {isEditing ? (
                <input
                  type="text"
                  value={editedDetails.location}
                  onChange={(e) => setEditedDetails({ ...editedDetails, location: e.target.value })}
                  className="mt-1 w-full p-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
              ) : (
                <p className="mt-1 text-gray-900">{tourDetails.location}</p>
              )}
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-500">Notes</label>
              {isEditing ? (
                <textarea
                  value={editedDetails.notes || ''}
                  onChange={(e) => setEditedDetails({ ...editedDetails, notes: e.target.value })}
                  className="mt-1 w-full p-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                  rows="3"
                  placeholder="Add any additional notes..."
                />
              ) : (
                <p className="mt-1 text-gray-900">{tourDetails.notes || 'No notes provided'}</p>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* Cancel Confirmation Modal */}
      {showCancelConfirm && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4">
          <div className="bg-white rounded-lg p-6 max-w-md w-full">
            <h3 className="text-lg font-semibold mb-4">Cancel Tour</h3>
            <p className="text-gray-600 mb-6">
              Are you sure you want to cancel this tour? This action cannot be undone.
            </p>
            <div className="flex justify-end space-x-4">
              <button
                onClick={() => setShowCancelConfirm(false)}
                className="px-4 py-2 text-gray-600 hover:text-gray-800"
              >
                Keep Tour
              </button>
              <button
                onClick={handleCancelTour}
                disabled={isCanceling}
                className="px-4 py-2 bg-red-600 text-white rounded-lg hover:bg-red-700 disabled:opacity-50"
              >
                {isCanceling ? 'Canceling...' : 'Cancel Tour'}
              </button>
            </div>
          </div>
        </div>
      )}

      <Footer />
    </div>
  );
}

export default TourDetailsPage; 