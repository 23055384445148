import React, { useState, useEffect } from "react";
import AWS from "aws-sdk";
import { auth } from "../firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { Link } from "react-router-dom";

// Initialize AWS DynamoDB client
AWS.config.update({
  region: process.env.REACT_APP_AWS_REGION || "us-east-2",
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
});

const dynamodb = new AWS.DynamoDB.DocumentClient();
const TABLE_NAME = "ApartmentsTable-restored"; // Your table name

const ApartmentEditor = () => {
  const [user, loading, error] = useAuthState(auth);
  const [groupId, setGroupId] = useState("single_apartments");
  const [apartmentId, setApartmentId] = useState("");
  const [apartmentData, setApartmentData] = useState("");
  const [originalData, setOriginalData] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  // Add authentication check with loading state
  if (loading) {
    return <div className="container mx-auto p-6">Loading...</div>;
  }

  if (error) {
    return <div className="container mx-auto p-6">Error: {error.message}</div>;
  }

  if (!user || user.email !== "adarshdayal7@gmail.com" || user.email !== "1804chistopher@gmail.com") {
    return (
      <div className="container mx-auto p-6 bg-white shadow-lg rounded-lg">
        <h1 className="text-2xl font-bold mb-6 text-red-600">Access Denied</h1>
        <p className="mb-4">You do not have permission to access this page.</p>
        <Link 
          to="/" 
          className="text-blue-500 hover:text-blue-700 underline"
        >
          Return to Home Page
        </Link>
      </div>
    );
  }

  // Fetch the apartment by group id and id
  const fetchApartment = async () => {
    if (!groupId || !apartmentId) {
      alert("Please enter both group ID and apartment ID");
      return;
    }

    setIsLoading(true);
    const params = {
      TableName: TABLE_NAME,
      Key: {
        apartment_group_id: groupId,
        id: apartmentId,
      },
    };

    try {
      const data = await dynamodb.get(params).promise();
      if (data.Item) {
        setApartmentData(JSON.stringify(data.Item, null, 2)); // Display the JSON in a formatted manner
        setOriginalData(JSON.stringify(data.Item, null, 2));  // Save original data for comparison
      } else {
        alert("No data found for the given groupId and apartmentId");
        setApartmentData("");
        setOriginalData("");
      }
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching apartment:", error);
      setIsLoading(false);
    }
  };

  // Find the floorPlan by name
  const findFloorPlanByName = (floorPlans, name) => {
    return floorPlans.find((floorPlan) => floorPlan.name === name);
  };

  // Compare and log changes in the floorPlans array
  const logFloorPlansChanges = (original, modified) => {
    const originalFloorPlans = original.floorPlans || [];
    const modifiedFloorPlans = modified.floorPlans || [];

    const originalNames = originalFloorPlans.map((fp) => fp.name);
    const modifiedNames = modifiedFloorPlans.map((fp) => fp.name);

    // Log added floor plans
    modifiedNames.forEach((name) => {
      if (!originalNames.includes(name)) {
        // console.log(`Added floor plan: ${name}`);
      }
    });

    // Log removed floor plans
    originalNames.forEach((name) => {
      if (!modifiedNames.includes(name)) {
        // console.log(`Removed floor plan: ${name}`);
      }
    });

    // Log changes in rent and availability for existing floor plans
    originalFloorPlans.forEach((originalPlan) => {
      const modifiedPlan = findFloorPlanByName(modifiedFloorPlans, originalPlan.name);
      if (modifiedPlan) {
        if (originalPlan.rent !== modifiedPlan.rent) {
          // console.log(`Rent changed for floor plan ${originalPlan.name}: from ${originalPlan.rent} to ${modifiedPlan.rent}`);
        }

        if (originalPlan.availability !== modifiedPlan.availability) {
          // console.log(`Availability changed for floor plan ${originalPlan.name}: from ${originalPlan.availability} to ${modifiedPlan.availability}`);
        }
      }
    });
  };

  // Save the JSON back to DynamoDB
  const saveJsonData = async () => {
    try {
      // Validate JSON by attempting to parse it
      const parsedData = JSON.parse(apartmentData);
      const parsedOriginalData = JSON.parse(originalData);

      // Log changes for rent and availability in floorPlans
      logFloorPlansChanges(parsedOriginalData, parsedData);

      // Prepare the parameters to update the DynamoDB item
      const params = {
        TableName: TABLE_NAME,
        Item: parsedData, // Save the entire modified JSON object back to DynamoDB
      };

      // Save the updated item to DynamoDB
      await dynamodb.put(params).promise();
      alert("Data saved successfully!");
      setIsEditing(false); // Exit edit mode
    } catch (error) {
      // If the JSON is invalid, show an error message
      console.error("Error saving data:", error);
      alert("Invalid JSON. Please correct the format.");
    }
  };

  return (
    <div className="container mx-auto p-6 bg-white shadow-lg rounded-lg">
      <h1 className="text-2xl font-bold mb-6">JSON Editor</h1>

      {/* Search Input Section */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-6">
        <div>
          <label className="block font-medium mb-1">Group ID</label>
          <input
            type="text"
            value={groupId}
            onChange={(e) => setGroupId(e.target.value)}
            className="border p-2 w-full rounded"
            placeholder="Enter group ID"
          />
        </div>
        <div>
          <label className="block font-medium mb-1">Apartment ID</label>
          <input
            type="text"
            value={apartmentId}
            onChange={(e) => setApartmentId(e.target.value)}
            className="border p-2 w-full rounded"
            placeholder="Enter apartment ID"
          />
        </div>
      </div>

      {/* Search Button */}
      <div className="mb-6">
        <button
          onClick={fetchApartment}
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
        >
          Search
        </button>
      </div>

      {/* JSON Text Area */}
      {isLoading ? (
        <p>Loading apartment details...</p>
      ) : (
        <>
          <textarea
            className="border p-4 w-full h-96 rounded"
            value={apartmentData}
            onChange={(e) => setApartmentData(e.target.value)}
            disabled={!isEditing}
            placeholder="Apartment data will appear here..."
          />
          <div className="flex justify-end space-x-4 mt-6">
            {isEditing ? (
              <>
                <button
                  onClick={saveJsonData}
                  className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
                >
                  Save Changes
                </button>
                <button
                  onClick={() => setIsEditing(false)}
                  className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                >
                  Cancel
                </button>
              </>
            ) : (
              <button
                onClick={() => setIsEditing(true)}
                className="bg-yellow-500 hover:bg-yellow-700 text-white font-bold py-2 px-4 rounded"
              >
                Edit JSON
              </button>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default ApartmentEditor;
