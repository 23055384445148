import React, { createContext, useContext, useState, useEffect } from 'react';
import { auth, db } from '../firebase';
import { collection, addDoc, query, where, onSnapshot, orderBy, limit, startAfter, getDocs, serverTimestamp } from 'firebase/firestore';
import { chatService } from '../services/chatService';

const ChatContext = createContext();

export function ChatProvider({ children }) {
  const [conversations, setConversations] = useState([]);
  const [activeConversation, setActiveConversation] = useState(null);
  const [messages, setMessages] = useState([]);

  // Listen to user's conversations
  useEffect(() => {
    if (!auth.currentUser) return;

    const q = query(
      collection(db, 'conversations'),
      where('participants', 'array-contains', auth.currentUser.uid),
      orderBy('lastMessageAt', 'desc')
    );

    const unsubscribe = onSnapshot(q, (snapshot) => {
      const conversationsData = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      // console.log('ChatContext: Conversations updated', new Date().toISOString());
      setConversations(conversationsData);
    });

    return () => unsubscribe();
  }, []);

  // Listen to active conversation messages
  useEffect(() => {
    if (!activeConversation) return;

    const q = query(
      collection(db, 'messages'),
      where('conversationId', '==', activeConversation.id),
      orderBy('timestamp', 'asc')
    );

    const unsubscribe = onSnapshot(q, (snapshot) => {
      const messagesData = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      console.log('ChatContext: Messages updated', new Date().toISOString());
      setMessages(messagesData);
    });

    return () => unsubscribe();
  }, [activeConversation]);

  const startConversation = async (subleaseId, otherUserId) => {
    const newConversation = await addDoc(collection(db, 'conversations'), {
      participants: [auth.currentUser.uid, otherUserId],
      subleaseId,
      createdAt: serverTimestamp(),
      lastMessageAt: serverTimestamp()
    });
    return newConversation;
  };

  const sendMessage = async (conversationId, content) => {
    await chatService.sendMessage(conversationId, {
      text: content,
      senderId: auth.currentUser.uid,
      timestamp: new Date()
    });
  };

  return (
    <ChatContext.Provider value={{
      conversations,
      activeConversation,
      setActiveConversation,
      messages,
      startConversation,
      sendMessage
    }}>
      {children}
    </ChatContext.Provider>
  );
}

export const useChat = () => useContext(ChatContext); 