import React, { Suspense } from 'react';
import { useInView } from 'react-intersection-observer';
import SubleaseCard from './SubleaseCard';
import LoadingAnimation from '../../../components/Listings/LoadingAnimation';

const SubleaseCardContainer = React.memo(({ sublease }) => {
  const { ref, inView } = useInView({
    threshold: 0.1,
    triggerOnce: false,
    rootMargin: '200px 0px',
  });

  return (
    <div
      ref={ref}
      key={sublease.id}
      className="transform transition-opacity duration-300"
      style={{ opacity: inView ? 1 : 0 }}
    >
      {inView && (
        <Suspense fallback={<LoadingAnimation />}>
          <SubleaseCard sublease={sublease} />
        </Suspense>
      )}
      {!inView && <LoadingAnimation />}
    </div>
  );
});

const SubleaseGrid = ({ subleases, dataLoading }) => {
  return (
    <div
      className="grid gap-y-8 gap-x-8"
      style={{
        gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))",
      }}
    >
      {dataLoading ? (
        Array.from({ length: 6 }).map((_, index) => (
          <LoadingAnimation key={index} />
        ))
      ) : (
        subleases.map((sublease) => (
          <SubleaseCardContainer
            key={sublease.id}
            sublease={sublease}
          />
        ))
      )}
    </div>
  );
};

export default SubleaseGrid; 