import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { db } from "../../firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import SubleaseFilters from "./SubleaseFilters";
import SubleaseLanding from "./SubleaseLanding";
import SubleasePreferencesModal from "../../components/SubleasePreferences/SubleasePreferencesModal";
import SubleaseHeader from "./components/SubleaseHeader";
import SubleaseGrid from "./components/SubleaseGrid";
import { useSubleaseData } from "./hooks/useSubleaseData";
import { filterSubleases } from "./utils/filterUtils";
import { auth } from "../../firebase/index";

// Default filter state
const defaultFilters = {
  priceRange: [400, 2000],
  dateRange: {
    start: null,
    end: null
  },
  bedRange: [0, 5],
  bathRange: [1, 4],
  quickDateFilter: 'all'
};

function SubleasePage() {
  const navigate = useNavigate();
  const [user] = useAuthState(auth);
  const [showPreferencesModal, setShowPreferencesModal] = useState(false);
  const [isPageReady, setIsPageReady] = useState(false);
  const [filters, setFilters] = useState(() => {
    // Try to load filters from localStorage on initial render
    // const savedFilters = localStorage.getItem('subleaseFilters');
    // return savedFilters ? JSON.parse(savedFilters) : defaultFilters;
    return defaultFilters;
    
  });
  const [sortedSubleases, setSortedSubleases] = useState([]);

  // Save filters to localStorage whenever they change
  useEffect(() => {
    localStorage.setItem('subleaseFilters', JSON.stringify(filters));
  }, [filters]);

  const {
    subleases,
    userSubleases,
    dataLoading,
    showModal,
    confirmDelete,
    cancelDelete,
  } = useSubleaseData(user);

  // Handle sorting when filters change
  const handleSort = (sortFunction) => {
    if (!subleases) return;
    const sorted = sortFunction(subleases);
    setSortedSubleases(sorted);
  };

  // Check if user has preferences set
  useEffect(() => {
    const checkUserPreferences = async () => {
      if (!user) return;
      
      try {
        // console.log('SubleasePage: Checking user preferences', new Date().toISOString());
        const userDoc = await getDoc(doc(db, "users", user.uid));
        if (userDoc.exists()) {
          const userData = userDoc.data();
          const hasUserIntent = userData.subleaseUserIntent;
          
          // console.log('SubleasePage: User data:', {
          //   hasUserIntent,
          //   timestamp: new Date().toISOString()
          // });
          
          setShowPreferencesModal(!hasUserIntent);
        } else {
          // console.log('SubleasePage: No user document found', new Date().toISOString());
          setShowPreferencesModal(true);
        }
      } catch (error) {
        // console.error("Error checking user preferences:", error);
        setShowPreferencesModal(true);
      }
    };

    checkUserPreferences();
  }, [user]);

  const handlePreferencesSave = async (preferences) => {
    if (!user) return;
    
    try {
      // console.log('SubleasePage: Saving preferences', preferences, new Date().toISOString());
      await setDoc(doc(db, "users", user.uid), preferences, { merge: true });
      setShowPreferencesModal(false);
    } catch (error) {
      console.error("Error saving preferences:", error);
    }
  };

  const onPostSublease = () => {
    if (!user) {
      navigate("/signup", { 
        state: { 
          message: "Please create a student account first to list your sublease",
          returnPath: "/sublease-intro"
        } 
      });
      return;
    }
    navigate("/sublease-intro");
  };

  const handleNavigate = (id) => {
    window.open(`/sublease/${id}`, '_blank');
  };

  useEffect(() => {
    if (user) {
      setIsPageReady(true);
    }
  }, [user]);

  const filteredSubleases = filterSubleases(subleases, filters);

  // Use sorted subleases if available, otherwise use filtered subleases
  const displaySubleases = sortedSubleases.length > 0 ? sortedSubleases : filteredSubleases;

  // Calculate new subleases this week
  const newSubleasesThisWeek = subleases.filter(sublease => {
    if (!sublease.updatedAt) return false;
    const updatedDate = sublease.updatedAt.toDate ? sublease.updatedAt.toDate() : new Date(sublease.updatedAt);
    const now = new Date();
    const diffDays = Math.floor((now - updatedDate) / (1000 * 60 * 60 * 24));
    return diffDays <= 7;
  }).length;

  return (
    <div className="min-h-screen bg-gray-50">
      {isPageReady && (
        <SubleasePreferencesModal
          isOpen={showPreferencesModal}
          onClose={() => setShowPreferencesModal(false)}
          onSave={handlePreferencesSave}
        />
      )}
      
      {showModal && (
        <div
          className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50"
          style={{ zIndex: 2 }}
        >
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <h3 className="text-lg font-medium">
              Are you sure you want to delete this sublease?
            </h3>
            <div className="mt-4 flex justify-end">
              <button
                onClick={confirmDelete}
                className="bg-red-500 text-white px-4 py-2 rounded mr-2"
              >
                Delete
              </button>
              <button
                onClick={cancelDelete}
                className="bg-gray-300 text-black px-4 py-2 rounded"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}

      {!user ? (
        <SubleaseLanding />
      ) : (
        <div className="mx-auto p-4 md:px-16 max-w-8xl">
          <SubleaseHeader
            userSubleases={userSubleases}
            onPostSublease={onPostSublease}
            handleNavigate={handleNavigate}
            newSubleasesThisWeek={newSubleasesThisWeek}
          />

          <SubleaseFilters 
            filters={filters} 
            setFilters={setFilters} 
            onSort={handleSort}
          />
          <SubleaseGrid
            subleases={displaySubleases}
            dataLoading={dataLoading}
          />
        </div>
      )}
    </div>
  );
}

export default SubleasePage;