import React, { useState } from "react";
import Logo from "../../data/new_abode_logo.svg";
import { useNavigate, Link } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { signOut } from "firebase/auth";
import { auth } from "../../firebase"; // Ensure this path matches your Firebase config file
import { Divider } from "@mui/material";
import { VscAccount } from "react-icons/vsc";
import { FiMessageSquare, FiHeart } from "react-icons/fi";

const MainHeader = ({ setPage }) => {
  const navigate = useNavigate();
  const [user, loading, error] = useAuthState(auth);
  const [selected, setSelected] = useState("Apartments");
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const goToHome = () => {
    navigate("/listings");
  };

  const goToLogin = () => {
    navigate("/signup"); // Adjust as per your login route
  };

  const goToBeta = () => {
    navigate("/listings");
  };

  const goToSaved = () => {
    navigate("/saved");
  };

  const goToMessages = () => {
    navigate("/messages");
  };

  const goToAccount = () => {
    navigate(`/profile/${user.uid}`);
  };

  const goToMyGroup = () => {
    navigate("/groups");
  };

  const goToFriends = () => {
    navigate("/friends");
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const handleLogout = async () => {
    try {
      await signOut(auth);
      goToHome();
    } catch (error) {
      console.error("Error logging out:", error);
    }
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  return (
    <nav className="bg-white shadow-md">
      <div className="px-4 md:px-16 mx-auto">
        <div className="flex justify-between items-center py-4">
          <button className="flex items-center" onClick={goToHome}>
            <img src={Logo} alt="Abode Logo" className="h-10 w-auto" />
            <span className="text-xl font-medium text-logo_blue">Abode</span>
          </button>

          {/* Mobile Menu Toggle */}
          <div className="md:hidden">
            <button
              className="text-logo_blue hover:text-gray-600 focus:outline-none"
              onClick={toggleMobileMenu}
            >
              {/* Menu Icon */}
              <svg className="h-6 w-6 fill-current" viewBox="0 0 24 24">
                {isMobileMenuOpen ? (
                  <path
                    fillRule="evenodd"
                    d="M18.278 16.864a1 1 0 01-1.414 1.414l-4.829-4.828-4.828 4.828a1 1 0 01-1.414-1.414l4.828-4.829-4.828-4.828a1 1 0 011.414-1.414l4.829 4.828 4.828-4.828a1 1 0 111.414 1.414l-4.828 4.829 4.828 4.828z"
                  />
                ) : (
                  <path
                    fillRule="evenodd"
                    d="M4 6h16v2H4V6zm0 5h16v2H4v-2zm0 5h16v2H4v-2z"
                  />
                )}
              </svg>
            </button>
          </div>

          {/* Account/Login Button for Desktop */}
          <div className="hidden md:flex items-center space-x-4">
            {!user ? (
              <button
                onClick={goToLogin}
                className="px-4 py-2 text-base font-medium text-white bg-logo_blue rounded-md transition-colors"
              >
                Sign Up
              </button>
            ) : (
              <div className="relative">
                {/* Account Icon */}
                <div className="flex space-x-1 text-black">
                  {/* Heart Icon */}
                  <button
                    onClick={goToSaved}
                  >
                    <FiHeart className="block p-2 h-9 w-9 rounded hover:bg-gray-100"/>
                  </button>

                  {/* Messages Icon */}
                  <button
                    onClick={goToMessages}
                  >
                    <FiMessageSquare className="block p-2 h-9 w-9 rounded hover:bg-gray-100"/>
                  </button>

                  {/* Account Icon */}
                  <button
                    onClick={toggleDropdown}
                  >
                   <VscAccount className="block p-2 h-9 w-9 rounded hover:bg-gray-100"/>
                  </button>
                </div>
                {/* Dropdown Menu */}
                <div
                  className={`${
                    isDropdownOpen ? "block" : "hidden"
                  } absolute right-0 mt-2 py-2 w-48 bg-white border rounded-md shadow-xl z-20`}
                >
                  <button
                    onClick={goToSaved}
                    className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left"
                  >
                    Liked Apartments
                  </button>
                  <button
                    onClick={goToMessages}
                    className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left"
                  >
                    Messages
                  </button>
                  {/* <button
                    onClick={goToMyGroup}
                    className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left"
                  >
                    My Group
                  </button> */}
                  <button
                    onClick={goToFriends}
                    className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left"
                  >
                    Friends
                  </button>
                  <Divider />
                  <button
                    onClick={goToAccount}
                    className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left"
                  >
                    Account
                  </button>
                  <button
                    className="block px-4 py-2 text-sm text-red-500 hover:bg-gray-100 w-full text-left"
                    onClick={handleLogout}
                  >
                    Log out
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>

        {/* Mobile Menu */}
        <div className={`md:hidden ${isMobileMenuOpen ? "block" : "hidden"}`}>
          <div className="px-2 pt-2 pb-3 space-y-1">
            {!user ? (
              <div>
                <button
                  onClick={goToBeta}
                  className="block px-4 py-2 text-md text-gray-700 hover:bg-gray-100 w-full text-left"
                >
                  Explore Apartments
                </button>
                <button
                  onClick={goToLogin}
                  className="block w-full mt-2 px-4 py-2 text-center text-base font-medium text-white bg-logo_blue rounded-md"
                >
                  Sign Up
                </button>
              </div>
            ) : (
              <div>
                <button
                  onClick={goToBeta}
                  className="block px-4 py-2 text-md text-gray-700 hover:bg-gray-100 w-full text-left"
                >
                  Explore Apartments
                </button>
                <button className="block px-4 py-2 text-sm text-gray-700 w-full text-left">
                  {user.email}
                </button>
                <button
                  className="block w-full px-4 py-2 text-center text-base font-medium text-red-500 rounded-md"
                  onClick={handleLogout}
                >
                  Log out
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </nav>
  );
};

const NavLink = ({ text, selected, setSelected, to }) => {
  const isSelected = text === selected;

  return (
    <div className="relative flex justify-center">
      <div className="w-32">
        <Link
          to={to}
          className={`px-3 py-2 ${
            isSelected ? "font-medium" : "text-gray-700 hover:text-gray-900"
          } block text-center`}
          onClick={() => setSelected(text)}
        >
          {text}
        </Link>
      </div>
    </div>
  );
};

export default MainHeader;
