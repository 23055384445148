import React, { memo } from 'react';
import 'react-loading-skeleton/dist/skeleton.css';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

// Optimized skeleton component with animation
const ImageSkeleton = memo(() => (
  <SkeletonTheme color="#e0e0e0" highlightColor="#f5f5f5" duration={1.5}>
    <div className="absolute inset-0 bg-gray-100 rounded-lg overflow-hidden">
      <Skeleton height="100%" width="100%" baseColor="#f0f0f0" highlightColor="#f8f8f8" />
      
      {/* Shimmer effect overlay */}
      <div className="absolute inset-0 animate-pulse bg-gradient-to-r from-transparent via-white to-transparent opacity-30" 
           style={{ backgroundSize: '200% 100%', animation: 'shimmer 1.5s infinite' }}></div>
    </div>
  </SkeletonTheme>
));

export default ImageSkeleton;