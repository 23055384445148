import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import MainHeader from "../components/Headers/MainHeader";
import Footer from "../components/Headers/Footer";
import {
  fetchUserData,
  uploadProfilePicture,
  updateUserProfile,
} from "../backend/dbAPI";
import { sendFriendRequest } from "../backend/dbFriendsAPI";
import studentHobbies from "../data/student_hobbies.json";
import editProfileIcon from "../data/imgs/editProfileIcon.jpg";
import { inviteToGroup } from "../backend/dbGroupsAPI";
import RoommateQuestionnaire from "../components/RoommateQuestionnaire";
import { auth } from "../firebase/index";
import { useAuthState } from "react-firebase-hooks/auth";
import { updateShowRoommateProfile } from "../backend/dbAPI";
import { serverTimestamp } from "firebase/firestore";
import { doc, updateDoc, getDoc, deleteDoc } from "firebase/firestore";
import { db } from "../firebase/index";
import { deleteUser } from "firebase/auth";
import DeleteAccount from '../components/DeleteAccount';

function ProfilePage() {
  const { userId } = useParams();
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [profilePic, setProfilePic] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [savingProfile, setSavingProfile] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isEditProfileModalOpen, setIsEditProfileModalOpen] = useState(false);
  const [instagramHandle, setInstagramHandle] = useState("");
  const [linkedinHandle, setLinkedinHandle] = useState("");
  // const [preferredContact, setPreferredContact] = useState('');
  const [showQuestionnaire, setShowQuestionnaire] = useState(false);

  const [profileVisible, setProfileVisible] = useState(
    user?.showRoommateProfile
  );

  const [authUser] = useAuthState(auth);
  const [authUserGroupId, setAuthUserGroupId] = useState("");

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [major, setMajor] = useState("");
  const [schoolEmail, setSchoolEmail] = useState("");
  const [roommateDescription, setRoommateDescription] = useState("");

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [error, setError] = useState("");

  const handleProfileToggle = async () => {
    const newVisibility = !profileVisible;
    setProfileVisible(newVisibility);

    // Call the function to update the profile visibility in the database
    try {
      await updateShowRoommateProfile(userId, newVisibility);
      // console.log("Profile visibility updated successfully");
    } catch (error) {
      console.error("Error updating profile visibility:", error);
    }
  };

  useEffect(() => {
    const getUserData = async () => {
      try {
        const userData = await fetchUserData(userId);
        const authUserData = await fetchUserData(authUser.uid);
        if (authUserData) {
          setAuthUserGroupId(authUserData.groupID || "");
        }
        // console.log(authUserGroupId);
        if (userData) {
          setUser(userData);
          // setSelectedHobbies(userData.hobbies || []);
          // setDescription(userData.roommateDescription || "");
          // setPhone(userData.phone || "");
          // setEmail(userData.email || "");
          setInstagramHandle(userData.instagramHandle || "");
          setLinkedinHandle(userData.linkedinHandle || "");
          setProfileVisible(userData.showRoommateProfile);
          // setPreferredContact(userData.preferredContact || '');
          setFirstName(userData.firstName || "");
          setLastName(userData.lastName || "");
          setMajor(userData.major || "");
          setSchoolEmail(userData.schoolEmail || "");
          setRoommateDescription(userData.roommateDescription || "");
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    getUserData();
  }, [userId]);

  const handleSendGroupRequest = async () => {
    try {
      if (authUserGroupId || authUserGroupId !== "") {
        await inviteToGroup(authUserGroupId, userId, authUser.uid);
        alert("Group request sent.");
      } else {
        alert("Failed to send group request. Do you have a group?");
      }
    } catch (error) {
      alert("Failed to send group request. Do you have a group?");
    }
  };

  const handleFileChange = async (e) => {
    if (e.target.files[0]) {
      setProfilePic(e.target.files[0]);
      setUploading(true);

      try {
        const downloadURL = await uploadProfilePicture(
          userId,
          e.target.files[0]
        );

        setUser((prevUser) => ({
          ...prevUser,
          profilePicture: downloadURL,
        }));
        setUploading(false);
        setProfilePic(null);
      } catch (error) {
        console.error("Error uploading file:", error);
        setUploading(false);
      }
    }
  };

  const handleSendFriendRequest = async (fromUserId, toUserId) => {
    try {
      await sendFriendRequest(fromUserId, toUserId);
      alert("Friend request sent successfully.");
    } catch (error) {
      alert("User already is friend or already sent friend request.");
    }
  };

  const handleSaveProfile = async () => {
    try {
      setSavingProfile(true);
      const userDocRef = doc(db, "users", userId);
      await updateDoc(userDocRef, {
        firstName,
        lastName,
        major,
        schoolEmail,
        roommateDescription,
        roommateProfileUpdatedAt: serverTimestamp()
      });
      setIsEditing(false);
      setSavingProfile(false);
      // Refresh user data
      const updatedUserData = await fetchUserData(userId);
      setUser(updatedUserData);
    } catch (error) {
      console.error("Error saving profile:", error);
      setSavingProfile(false);
      alert("Error saving profile. Please try again.");
    }
  };

  const toggleEditProfileModal = () => {
    setIsEditProfileModalOpen(!isEditProfileModalOpen);
  };

  const isCurrentUser = authUser && authUser.uid === userId;

  const handleEditClick = async () => {
    if (isEditing) {
      // If we're currently editing, save the changes
      await handleSaveProfile();
    } else {
      // If we're not editing, enter edit mode
      setIsEditing(true);
    }
  };

  const handleDeleteAccount = async () => {
    setIsDeleting(true);
    setError('');
    
    try {
      const user = auth.currentUser;
      if (!user) throw new Error('No user found');

      // Get user document to check for any related data
      const userDoc = await getDoc(doc(db, 'users', user.uid));
      const userData = userDoc.data();

      // Delete from any apartments where user is listed
      if (userData?.apartmentIds) {
        for (const apartmentId of userData.apartmentIds) {
          const apartmentRef = doc(db, 'apartments', apartmentId);
          const apartmentDoc = await getDoc(apartmentRef);
          
          if (apartmentDoc.exists()) {
            const apartmentData = apartmentDoc.data();
            // Remove user from userIds array
            const updatedUserIds = apartmentData.userIds.filter(id => id !== user.uid);
            await updateDoc(apartmentRef, { userIds: updatedUserIds });
          }
        }
      }

      // Delete user document from Firestore
      await deleteDoc(doc(db, 'users', user.uid));

      // Delete user from Firebase Auth
      await deleteUser(user);

      // Navigate to home page
      navigate('/');
    } catch (err) {
      console.error('Error deleting account:', err);
      setError('Failed to delete account. Please try again later.');
    } finally {
      setIsDeleting(false);
      setIsModalOpen(false);
    }
  };

  if (!user) {
    return <div>Loading...</div>;
  }

  return (
    <div className="bg-gray-50 relative">
      <MainHeader />
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="flex justify-between items-center">
          <h1 className="text-2xl font-bold my-4">Profile</h1>
          {isCurrentUser && (
            <button
              onClick={handleEditClick}
              disabled={savingProfile}
              className={`px-4 py-2 ${
                savingProfile ? 'bg-gray-400' : 'bg-logo_blue hover:bg-blue-700'
              } text-white rounded-lg transition-colors`}
            >
              {savingProfile ? 'Saving...' : isEditing ? 'Save Changes' : 'Edit Profile'}
            </button>
          )}
        </div>
        <div className="flex">
          <div className="w-2/5 pr-8 flex flex-col items-center">
            <div className="relative mb-4 group">
              {isCurrentUser && (
                <div className="absolute inset-0 w-full h-full flex items-center justify-center text-white opacity-0 group-hover:opacity-100 transition-opacity">
                  Change Profile
                </div>
              )}
              {user?.profilePicture ? (
                <img
                  src={user.profilePicture}
                  alt="Profile"
                  className="w-48 h-48 rounded-full object-cover"
                />
              ) : (
                <div className="w-48 h-48 bg-gray-300 rounded-full flex items-center justify-center">
                  <span>No Image</span>
                </div>
              )}
              {!isCurrentUser && (
                <>
                  <button
                    onClick={() =>
                      handleSendFriendRequest(authUser?.uid, userId)
                    }
                    className="absolute top-1 right-3 border border-logo_blue bg-white px-1 py-1 rounded-full focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50"
                  >
                    <svg
                      className="w-7 h-7 text-logo_blue hover:text-green-500 transition-colors"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M12 4v16m8-8H4"
                      />
                    </svg>
                  </button>
                </>
              )}
              {isCurrentUser && (
                <input
                  type="file"
                  accept="image/*"
                  onChange={handleFileChange}
                  className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
                />
              )}
              {uploading && (
                <div className="absolute inset-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center text-white">
                  Uploading...
                </div>
              )}
            </div>
            {isCurrentUser && (
              <div className="relative mb-4 group flex flex-col items-center p-6 bg-white shadow-lg rounded-lg max-w-lg mx-auto">
                <div className="w-full">
                  {user?.roommateDescription && (
                    <div className="mb-4">
                      <p className="font-bold text-lg text-logo_blue">
                        Roommate Description:
                      </p>
                      <p className="text-gray-700">
                        {user.roommateDescription}
                      </p>
                    </div>
                  )}

                  <div className="relative">
                    {/* Header indicating profile visibility */}
                    {profileVisible ? (
                      <div className="bg-green-100 p-4 rounded-lg mb-4 w-full">
                        <p className="text-green-700 font-medium">
                          Your roommate profile is visible to other verified
                          UIUC students looking for roommates.
                        </p>
                        <p className="text-gray-600">
                          If you're no longer looking for roommates and don't
                          want to be contacted, you can turn off your profile
                          visibility below.
                        </p>
                      </div>
                    ) : (
                      <div className="bg-red-100 p-4 rounded-lg mb-4 w-full">
                        <p className="text-red-700 font-medium">
                          Your roommate profile is currently hidden, and you
                          won't be contacted by students.
                        </p>
                        <p className="text-gray-600">
                          If you're looking for a roommate again, you can make
                          your profile visible to other verified UIUC students.
                        </p>
                      </div>
                    )}

                    {/* Toggle button to show or hide profile visibility */}
                    <button
                      onClick={handleProfileToggle}
                      className={`w-full mt-4 px-4 py-2 rounded-lg text-white font-semibold transition-colors duration-200 ${
                        profileVisible
                          ? "bg-logo_blue hover:bg-blue-700"
                          : "bg-logo_blue hover:bg-blue-700"
                      }`}
                    >
                      {profileVisible
                        ? "Turn Off Profile Visibility"
                        : "Turn On Profile Visibility"}
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="w-3/5">
            <div className="bg-white p-6">
              <div className="mb-4">
                <p className="font-bold">First Name:</p>
                {isEditing ? (
                  <input
                    type="text"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    className="w-full p-2 border rounded"
                  />
                ) : (
                  <p>{user.firstName}</p>
                )}
              </div>
              <div className="mb-4">
                <p className="font-bold">Last Name:</p>
                {isEditing ? (
                  <input
                    type="text"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    className="w-full p-2 border rounded"
                  />
                ) : (
                  <p>{user.lastName}</p>
                )}
              </div>
              <div className="mb-4">
                <p className="font-bold">Major:</p>
                {isEditing ? (
                  <input
                    type="text"
                    value={major}
                    onChange={(e) => setMajor(e.target.value)}
                    className="w-full p-2 border rounded"
                  />
                ) : (
                  <p>{user.major}</p>
                )}
              </div>
              <div className="mb-4">
                <p className="font-bold">School Email:</p>
                {isEditing ? (
                  <input
                    type="email"
                    value={schoolEmail}
                    onChange={(e) => setSchoolEmail(e.target.value)}
                    className="w-full p-2 border rounded"
                  />
                ) : (
                  <p>{user.schoolEmail}</p>
                )}
              </div>
              <div className="mb-4">
                <p className="font-bold">Roommate Description:</p>
                {isEditing ? (
                  <textarea
                    value={roommateDescription}
                    onChange={(e) => setRoommateDescription(e.target.value)}
                    className="w-full p-2 border rounded"
                    rows="4"
                  />
                ) : (
                  <p>{user.roommateDescription}</p>
                )}
              </div>
              <div className="mb-4">
                <p className="font-bold">Instagram Handle:</p>
                {isEditing ? (
                  <div className="flex items-center">
                    <span className="bg-gray-100 p-2 rounded-l border border-r-0">@</span>
                    <input
                      type="text"
                      value={instagramHandle}
                      onChange={(e) => setInstagramHandle(e.target.value.replace('@', ''))}
                      placeholder="username (no @)"
                      className="w-full p-2 border rounded-r focus:outline-none focus:ring-2 focus:ring-logo_blue"
                    />
                  </div>
                ) : (
                  <p className="flex items-center">
                    {user.instagramHandle && (
                      <>
                        <span className="text-logo_blue">@</span>
                        {user.instagramHandle}
                      </>
                    )}
                  </p>
                )}
              </div>
              {user?.gender && (
                <div className="mb-4">
                  <p className="font-bold">Gender:</p>
                  <p>{user.gender}</p>
                </div>
              )}
              {user?.year && (
                <div className="mb-4">
                  <p className="font-bold">Year:</p>
                  <p>{user.year}</p>
                </div>
              )}
              {user?.email && (
                <div className="mb-4">
                  <p className="font-bold">Email:</p>
                  <p>{user.email}</p>
                </div>
              )}
              {user?.hobbies?.length > 0 && (
                <div className="mb-4">
                  <p className="font-bold">Hobbies:</p>
                  <ul className="list-disc list-inside">
                    {user.hobbies.map((hobby, index) => (
                      <li key={index}>{hobby}</li>
                    ))}
                  </ul>
                </div>
              )}
              {user?.priceRange && (
                <div className="mb-4">
                  <p className="font-bold">Price Range:</p>
                  {typeof user.priceRange === "string" ? (
                    <p>{user.priceRange}</p>
                  ) : (
                    <p>
                      ${user.priceRange?.lowerLimit} - $
                      {user.priceRange?.upperLimit}
                    </p>
                  )}
                </div>
              )}

              {/* Replace the delete account section with the new component */}
              {isCurrentUser && (
                <DeleteAccount onDeleteSuccess={() => navigate('/')} />
              )}
            </div>
          </div>
        </div>
      </div>
      <Footer />
      {/* Other Modals and Overlays */}
    </div>
  );
}

export default ProfilePage;
