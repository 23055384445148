import React, { useState, useEffect } from 'react';
import { collection, query, where, getDocs, doc, getDoc } from 'firebase/firestore';
import { db } from '../../firebase';
import { format } from 'date-fns';

const DeletedSubleases = () => {
  const [deletedSubleases, setDeletedSubleases] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedSublease, setSelectedSublease] = useState(null);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    fetchDeletedSubleases();
  }, []);

  const fetchDeletedSubleases = async () => {
    try {
      const subleasesRef = collection(db, 'subleases');
      const q = query(subleasesRef, where('showOnWebsite', '==', false));
      const querySnapshot = await getDocs(q);
      
      const subleasesData = await Promise.all(
        querySnapshot.docs.map(async (docSnapshot) => {
          const data = docSnapshot.data();
          // Get user info for each sublease
          const userDoc = await getDoc(doc(db, 'users', data.userId));
          const userData = userDoc.exists() ? userDoc.data() : null;
          
          return {
            id: docSnapshot.id,
            ...data,
            user: userData ? {
              firstName: userData.firstName || 'Anonymous',
              lastName: userData.lastName || '',
              email: userData.email || ''
            } : null
          };
        })
      );

      // Sort by deletion date (most recent first)
      subleasesData.sort((a, b) => {
        const dateA = a.deletedAt?.toDate() || new Date(0);
        const dateB = b.deletedAt?.toDate() || new Date(0);
        return dateB - dateA;
      });

      setDeletedSubleases(subleasesData);
    } catch (error) {
      console.error('Error fetching deleted subleases:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleSubleaseClick = (sublease) => {
    setSelectedSublease(sublease);
    setShowModal(true);
  };

  if (loading) {
    return <div className="p-4">Loading deleted subleases...</div>;
  }

  return (
    <div className="p-4">
      <h2 className="text-2xl font-bold mb-4">Deleted Subleases</h2>
      
      <div className="grid gap-4">
        {deletedSubleases.map((sublease) => (
          <div
            key={sublease.id}
            className="bg-white rounded-lg shadow p-4 cursor-pointer hover:shadow-md transition-shadow"
            onClick={() => handleSubleaseClick(sublease)}
          >
            <div className="flex justify-between items-start">
              <div>
                <h3 className="text-lg font-semibold">{sublease.apartmentName}</h3>
                <p className="text-gray-600">${sublease.rent}/month</p>
                <p className="text-sm text-gray-500">
                  {sublease.bedrooms} bed • {sublease.bathrooms} bath
                </p>
                <p className="text-sm text-gray-500">
                  {sublease.startDate} - {sublease.endDate}
                </p>
              </div>
              <div className="text-right">
                <p className="text-sm text-gray-500">
                  Deleted: {sublease.deletedAt ? format(sublease.deletedAt.toDate(), 'MMM d, yyyy') : 'Unknown'}
                </p>
                <p className="text-sm text-gray-500">
                  By: {sublease.user?.firstName} {sublease.user?.lastName}
                </p>
                {sublease.deleteReason && (
                  <p className="text-sm text-red-500 mt-1">
                    Reason: {sublease.deleteReason}
                  </p>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>

      {/* Modal */}
      {showModal && selectedSublease && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4">
          <div className="bg-white rounded-lg max-w-2xl w-full max-h-[90vh] overflow-y-auto">
            <div className="p-6">
              <div className="flex justify-between items-start mb-4">
                <h3 className="text-xl font-bold">{selectedSublease.apartmentName}</h3>
                <button
                  onClick={() => setShowModal(false)}
                  className="text-gray-500 hover:text-gray-700"
                >
                  ✕
                </button>
              </div>
              
              <div className="grid grid-cols-2 gap-4 mb-6">
                <div>
                  <h4 className="font-semibold mb-2">Details</h4>
                  <p>Rent: ${selectedSublease.rent}/month</p>
                  <p>{selectedSublease.bedrooms} bed • {selectedSublease.bathrooms} bath</p>
                  <p>Dates: {selectedSublease.startDate} - {selectedSublease.endDate}</p>
                  <p>Address: {selectedSublease.address}</p>
                </div>
                <div>
                  <h4 className="font-semibold mb-2">Host Information</h4>
                  <p>Name: {selectedSublease.user?.firstName} {selectedSublease.user?.lastName}</p>
                  <p>Email: {selectedSublease.user?.email}</p>
                  <p>Deleted: {selectedSublease.deletedAt ? format(selectedSublease.deletedAt.toDate(), 'MMM d, yyyy') : 'Unknown'}</p>
                  {selectedSublease.deleteReason && (
                    <p className="text-red-500">Reason: {selectedSublease.deleteReason}</p>
                  )}
                </div>
              </div>

              <div className="mb-6">
                <h4 className="font-semibold mb-2">Description</h4>
                <p className="whitespace-pre-wrap">{selectedSublease.description}</p>
              </div>

              {selectedSublease.images && selectedSublease.images.length > 0 && (
                <div>
                  <h4 className="font-semibold mb-2">Images</h4>
                  <div className="grid grid-cols-2 gap-4">
                    {selectedSublease.images.map((image, index) => (
                      <img
                        key={index}
                        src={image.url}
                        alt={`Sublease image ${index + 1}`}
                        className="rounded-lg w-full h-48 object-cover"
                      />
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default DeletedSubleases; 