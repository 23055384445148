import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../../firebase/index';
import { chatService } from '../../services/chatService';

const ContactHost = ({ sublease, onClose }) => {
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const [user] = useAuthState(auth);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!message.trim()) return;

    setIsLoading(true);
    setError('');

    try {
      if (!user) {
        navigate('/login');
        return;
      }

      // Prevent messaging yourself
      if (user.uid === sublease.userId) {
        setError('You cannot message your own sublease listing');
        setIsLoading(false);
        return;
      }

      // Create or get existing conversation
      try {
        const conversation = await chatService.startSubleaseChat(
          sublease.id,
          [user.uid, sublease.userId],
          sublease.userId
        );

        // Send the initial message
        await chatService.sendMessage(conversation.id, {
          text: message,
          senderId: user.uid,
          timestamp: new Date(),
          subleaseId: sublease.id
        });

        // Navigate to the conversation
        navigate(`/messages/${conversation.id}`);
      } catch (err) {
        if (err.message.includes('cannot start a conversation with yourself') ||
            err.message.includes('not authorized') ||
            err.message.includes('Invalid conversation participants')) {
          setError(err.message);
        } else {
          console.error('Error sending message:', err);
          setError('Failed to send message. Please try again.');
        }
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="w-full bg-white rounded-lg shadow-lg overflow-hidden">
      <div className="relative">
        <button
          onClick={onClose}
          className="absolute top-2 right-4 text-gray-600 text-2xl hover:text-gray-800"
        >
          &times;
        </button>
        <div className="md:flex">
          <div className="md:w-2/3 p-8">
            <div className="flex items-center mb-6">
              <img
                src={sublease.hostProfile?.pfpImg || "https://cdn-icons-png.flaticon.com/512/149/149071.png"}
                alt="profile"
                className="w-20 h-20 rounded-full object-cover"
              />
              <div className="ml-4">
                <h2 className="text-2xl font-semibold">
                  {sublease.hostProfile?.firstName} {sublease.hostProfile?.lastName}
                </h2>
                <p className="text-sm text-gray-500">
                  {sublease.hostProfile?.major} {sublease.hostProfile?.schoolYear} at{" "}
                  {sublease.hostProfile?.school}
                </p>
              </div>
            </div>
            <textarea
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              placeholder="Ask a question about the sublease..."
              className={`w-full h-96 p-4 border rounded-lg focus:outline-none focus:ring-2 focus:ring-logo_blue ${
                error ? 'border-red-500' : 'border-gray-300'
              }`}
              disabled={isLoading}
            />
            {error && (
              <p className="text-red-500 text-sm mt-2">{error}</p>
            )}
            <button
              onClick={handleSubmit}
              disabled={isLoading || !message.trim()}
              className={`mt-4 w-full bg-logo_blue text-white py-3 rounded-lg font-semibold hover:bg-blue-700 ${
                isLoading || !message.trim() ? 'opacity-50 cursor-not-allowed' : ''
              }`}
            >
              {isLoading ? (
                <span className="flex items-center justify-center">
                  <i className="fas fa-spinner fa-spin mr-2"></i>
                  Sending...
                </span>
              ) : (
                'Send Message'
              )}
            </button>
          </div>
          <div className="md:w-1/3 p-8 bg-gray-50">
            <h3 className="text-lg font-semibold mb-4">
              Contact Information
            </h3>

            <p className="text-sm mb-4">
              Interested in booking, setting up a tour, or applying for the
              sublease? Reach out to the student directly on Abode!
            </p>
            {/* <p className="text-sm mb-2">
              <span className="font-semibold">Contact Email: </span>
              {sublease.hostProfile?.contact?.email}
            </p> */}
            <div className="flex flex-col text-sm">
              {sublease.hostProfile?.contact?.instagramHandle && (
                <div className="mb-2 flex items-center gap-2">
                  
                  <a 
                    href={`https://instagram.com/${sublease.hostProfile.contact.instagramHandle.replace('@', '')}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="inline-flex items-center gap-1 hover:opacity-80 transition-opacity"
                  >
                    <i className="fab fa-instagram text-sm bg-gradient-to-r from-[#fd5949] via-[#d6249f] to-[#285AEB] bg-clip-text text-transparent"></i>
                    <span className="font-medium bg-gradient-to-r from-[#fd5949] via-[#d6249f] to-[#285AEB] bg-clip-text text-transparent">
                      {sublease.hostProfile.contact.instagramHandle.startsWith('@') 
                        ? sublease.hostProfile.contact.instagramHandle 
                        : `@${sublease.hostProfile.contact.instagramHandle}`}
                    </span>
                  </a>
                </div>
              )}
              {sublease.hostProfile?.contact?.linkedinHandle && (
                <p className="mb-2">
                  LinkedIn: {sublease.hostProfile.contact.linkedinHandle}
                </p>
              )}
              {sublease.hostProfile?.contact?.wechatHandle && (
                <p className="mb-2">
                  WeChat: {sublease.hostProfile.contact.wechatHandle}
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactHost; 