import { parse } from "date-fns";

export const filterSubleases = (subleases, filters) => {
  const scoredSubleases = subleases.filter(sublease => {
    // Apply price, beds, baths filters
    const meetsPrice = sublease.rent >= filters.priceRange[0] && 
                      sublease.rent <= filters.priceRange[1];
    const meetsBeds = sublease.bedrooms >= filters.bedRange[0] && 
                     sublease.bedrooms <= filters.bedRange[1];
    const meetsBaths = sublease.bathrooms >= filters.bathRange[0] && 
                      sublease.bathrooms <= filters.bathRange[1];

    // Date filtering logic
    let meetsDate = true;
    if (filters.dateRange.start && filters.dateRange.end) {
      const subleaseStart = parse(sublease.startDate, "MM-dd-yyyy", new Date());
      const subleaseEnd = parse(sublease.endDate, "MM-dd-yyyy", new Date());
      const filterStart = new Date(filters.dateRange.start);
      const filterEnd = new Date(filters.dateRange.end);

      meetsDate = !(subleaseEnd < filterStart || subleaseStart > filterEnd);
    }

    return meetsPrice && meetsBeds && meetsBaths && meetsDate;
  });

  // First, separate listings into two groups: with images and without images
  const withImages = [];
  const withoutImages = [];

  scoredSubleases.forEach(sublease => {
    const hasImages = Boolean(
      (sublease.images && sublease.images.length > 0) || 
      (sublease.imageUrls && sublease.imageUrls.length > 0) ||
      sublease.hasImages
    );
    
    if (hasImages) {
      withImages.push(sublease);
    } else {
      withoutImages.push(sublease);
    }
  });

  // Sort each group by timestamp
  const sortByTimestamp = (a, b) => {
    const getTimestamp = (sublease) => {
      if (!sublease.updatedAt) return 0;
      
      if (sublease.updatedAt?.toDate) {
        return sublease.updatedAt.toDate().getTime();
      }
      
      if (sublease.updatedAt instanceof Date) {
        return sublease.updatedAt.getTime();
      }
      
      if (typeof sublease.updatedAt === 'number') {
        return sublease.updatedAt;
      }
      
      return new Date(sublease.updatedAt).getTime() || 0;
    };

    const timeA = getTimestamp(a);
    const timeB = getTimestamp(b);
    
    return timeB - timeA; // Sort descending (newest first)
  };

  // Sort each group independently
  withImages.sort(sortByTimestamp);
  withoutImages.sort(sortByTimestamp);

  // Combine the groups, with images first
  return [...withImages];
}; 