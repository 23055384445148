import React from 'react';
import { useNavigate } from 'react-router-dom';
import MainHeader from '../../components/Headers/MainHeader';

const Unauthorized = () => {
  const navigate = useNavigate();

  return (
    <div className="min-h-screen bg-gray-50">
      <MainHeader />
      <div className="max-w-4xl mx-auto px-4 py-16">
        <div className="text-center">
          <div className="mb-8">
            <i className="fas fa-lock text-6xl text-gray-400"></i>
          </div>
          <h1 className="text-4xl font-bold text-gray-900 mb-4">
            Access Denied
          </h1>
          <p className="text-xl text-gray-600 mb-8">
            Oops! You don't have permission to view this page. Please log in or create an account to continue.
          </p>
          <div className="flex flex-col sm:flex-row gap-4 justify-center">
            <button
              onClick={() => navigate('/login')}
              className="px-8 py-3 bg-logo_blue text-white rounded-lg font-semibold hover:bg-blue-600 transition-colors"
            >
              Log In
            </button>
            <button
              onClick={() => navigate('/signup')}
              className="px-8 py-3 bg-white text-logo_blue border-2 border-logo_blue rounded-lg font-semibold hover:bg-blue-50 transition-colors"
            >
              Sign Up
            </button>
            <button
              onClick={() => navigate('/')}
              className="px-8 py-3 bg-gray-100 text-gray-700 rounded-lg font-semibold hover:bg-gray-200 transition-colors"
            >
              Go Home
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Unauthorized; 