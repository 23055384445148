import { 
  collection,
  query,
  where,
  orderBy,
  addDoc,
  getDoc,
  getDocs,
  updateDoc,
  doc,
  serverTimestamp,
  arrayUnion,
  writeBatch
} from 'firebase/firestore';
import { db, storage } from '../firebase/index';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { auth } from '../firebase/index';
import { notificationService } from './notificationService';

export const chatService = {
  // Helper function to validate participants
  validateParticipants(participants) {
    if (!Array.isArray(participants) || participants.length !== 2) {
      throw new Error('Conversation must have exactly 2 participants');
    }
    if (participants[0] === participants[1]) {
      throw new Error('Users cannot message themselves');
    }
    return participants.sort(); // Sort for consistent querying
  },

  // Helper function to create conversation document
  createConversationDoc(participants, type, subleaseId = null, hostId = null) {
    return {
      participants,
      type,
      createdAt: serverTimestamp(),
      lastMessage: null,
      lastMessageAt: null,
      lastMessageSenderId: null,
      updatedAt: serverTimestamp(),
      subleaseId: subleaseId || null,
      hostId: type === 'sublease' ? hostId : null // Only include hostId for sublease conversations
    };
  },

  // Get existing conversation or create new one
  async getOrCreateConversation(participants, type, subleaseId = null, hostId = null) {
    try {
      // Validate participants
      const sortedParticipants = this.validateParticipants(participants);
      
      // Build query based on conversation type
      const conversationsRef = collection(db, 'conversations');
      let q;
      
      if (type === 'sublease') {
        // For sublease chats, query by subleaseId and participants
        q = query(
          conversationsRef,
          where('subleaseId', '==', subleaseId),
          where('participants', '==', sortedParticipants)
        );
      } else {
        // For roommate chats, query by participants and type
        q = query(
          conversationsRef,
          where('participants', '==', sortedParticipants),
          where('type', '==', type)
        );
      }
      
      // Check for existing conversation
      const querySnapshot = await getDocs(q);
      
      if (!querySnapshot.empty) {
        return {
          id: querySnapshot.docs[0].id,
          ...querySnapshot.docs[0].data()
        };
      }

      // Create new conversation
      const newConversation = this.createConversationDoc(
        sortedParticipants,
        type,
        subleaseId,
        hostId
      );

      const docRef = await addDoc(conversationsRef, newConversation);
      return {
        id: docRef.id,
        ...newConversation
      };
    } catch (error) {
      console.error('Error in getOrCreateConversation:', error);
      throw error;
    }
  },

  // Start a sublease chat
  async startSubleaseChat(subleaseId, participants, hostId) {
    return this.getOrCreateConversation(participants, 'sublease', subleaseId, hostId);
  },

  // Start a roommate chat
  async startRoommateChat(participants) {
    return this.getOrCreateConversation(participants, 'roommate');
  },

  // Send a message
  async sendMessage(conversationId, messageData) {
    try {
      const { text, senderId, timestamp, subleaseId, imageUrl } = messageData;
      
      if (!text && !imageUrl) {
        throw new Error('Message must contain either text or an image');
      }

      const messageRef = collection(db, 'messages');
      const newMessage = {
        conversationId,
        content: text || null,
        senderId,
        timestamp: timestamp instanceof Date ? timestamp : new Date(timestamp),
        subleaseId: subleaseId || null,
        imageUrl: imageUrl || null,
        status: 'sending',
        readBy: [senderId]
      };

      const docRef = await addDoc(messageRef, newMessage);

      // Update conversation's last message
      const conversationRef = doc(db, 'conversations', conversationId);
      await updateDoc(conversationRef, {
        lastMessage: text || 'Image message',
        lastMessageAt: newMessage.timestamp,
        lastMessageSenderId: senderId,
        updatedAt: newMessage.timestamp
      });

      // Get conversation participants for notification
      const conversationDoc = await getDoc(conversationRef);
      const participants = conversationDoc.data().participants;

      // Update message status to sent
      await updateDoc(docRef, {
        status: 'sent'
      });

      // Send notification for new message
      try {
        await notificationService.sendNewMessageNotification(conversationId, newMessage, participants);
      } catch (notificationError) {
        console.error('Error sending notification:', notificationError);
        // Don't throw here, as the message was sent successfully
      }

      return docRef.id;
    } catch (error) {
      console.error('Error sending message:', error);
      throw error;
    }
  },

  // Get messages for a conversation
  async getMessages(conversationId) {
    try {
      const q = query(
        collection(db, 'messages'),
        where('conversationId', '==', conversationId),
        orderBy('timestamp', 'asc')
      );

      const querySnapshot = await getDocs(q);
      return querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
    } catch (error) {
      console.error('Error getting messages:', error);
      throw error;
    }
  },

  // Mark messages as read
  async markMessagesAsRead(conversationId, messageIds) {
    try {
      const batch = writeBatch(db);
      
      messageIds.forEach(messageId => {
        const messageRef = doc(db, 'messages', messageId);
        batch.update(messageRef, {
          readBy: arrayUnion(auth.currentUser.uid)
        });
      });

      await batch.commit();
    } catch (error) {
      console.error('Error marking messages as read:', error);
      throw error;
    }
  },

  async uploadImage(file) {
    try {
      const storageRef = ref(storage, `chat-images/${Date.now()}-${file.name}`);
      const snapshot = await uploadBytes(storageRef, file);
      const downloadURL = await getDownloadURL(snapshot.ref);
      return downloadURL;
    } catch (error) {
      console.error('Error uploading image:', error);
      throw error;
    }
  }
}; 