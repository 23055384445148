import {
  doc,
  getDoc,
  setDoc,
  updateDoc,
  collection,
  query,
  where,
  orderBy,
  limit,
  getDocs,
  arrayUnion,
  arrayRemove,
  deleteDoc
} from "firebase/firestore";
import { db } from "../firebase";

// Sublease Process Status Tracking
export const subleaseProcessService = {
  // Initialize sublease process tracking
  async initializeSubleaseProcess(subleaseId, participants) {
    const processRef = doc(db, 'subleaseProcesses', subleaseId);
    await setDoc(processRef, {
      subleaseId,
      participants,
      status: 'initialized',
      currentStep: 'chat',
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString()
    });
  },

  // Update process status and log activity
  async updateProcessStatus(subleaseId, status, step, activity) {
    const processRef = doc(db, 'subleaseProcesses', subleaseId);
    const activityLogRef = doc(collection(db, 'subleaseActivityLogs'));
    
    const activityLog = {
      subleaseId,
      timestamp: new Date().toISOString(),
      activity,
      status,
      step
    };

    // Update process status
    await updateDoc(processRef, {
      status,
      currentStep: step,
      updatedAt: new Date().toISOString()
    });

    // Store activity log separately
    await setDoc(activityLogRef, activityLog);
  },

  // Get process status
  async getProcessStatus(subleaseId) {
    if (!subleaseId) {
      console.error('No subleaseId provided to getProcessStatus');
      return null;
    }

    try {
      const processRef = doc(db, 'subleaseProcesses', subleaseId);
      const docSnap = await getDoc(processRef);
      return docSnap.exists() ? docSnap.data() : null;
    } catch (error) {
      console.error('Error getting process status:', error);
      return null;
    }
  },

  // Get activity logs for a sublease
  async getActivityLogs(subleaseId, limit = 50) {
    const logsQuery = query(
      collection(db, 'subleaseActivityLogs'),
      where('subleaseId', '==', subleaseId),
      orderBy('timestamp', 'desc'),
      limit(limit)
    );
    
    const querySnapshot = await getDocs(logsQuery);
    return querySnapshot.docs.map(doc => doc.data());
  },

  // Track tour booking
  async trackTourBooking(subleaseId, bookingDetails) {
    const processRef = doc(db, 'subleaseProcesses', subleaseId);
    const activityLogRef = doc(collection(db, 'subleaseActivityLogs'));
    
    // Check if the document exists
    const docSnap = await getDoc(processRef);
    
    if (!docSnap.exists()) {
      // Initialize the process if it doesn't exist
      await setDoc(processRef, {
        subleaseId,
        status: 'tour_scheduled',
        currentStep: 'tour',
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString(),
        tourDetails: bookingDetails
      });
    } else {
      // Update existing document
      await updateDoc(processRef, {
        status: 'tour_scheduled',
        currentStep: 'tour',
        updatedAt: new Date().toISOString(),
        tourDetails: bookingDetails
      });
    }

    // Create activity log
    const activityLog = {
      subleaseId,
      timestamp: new Date().toISOString(),
      activity: 'tour_booked',
      details: bookingDetails,
      status: 'tour_scheduled',
      step: 'tour'
    };

    await setDoc(activityLogRef, activityLog);
  },

  // Track terms agreement
  async trackTermsAgreement(subleaseId, termsDetails) {
    const processRef = doc(db, 'subleaseProcesses', subleaseId);
    const activityLogRef = doc(collection(db, 'subleaseActivityLogs'));
    
    const activityLog = {
      subleaseId,
      timestamp: new Date().toISOString(),
      activity: 'terms_agreed',
      details: termsDetails,
      status: 'terms_agreed',
      step: 'terms'
    };

    await updateDoc(processRef, {
      status: 'terms_agreed',
      currentStep: 'terms',
      updatedAt: new Date().toISOString(),
      termsDetails
    });

    await setDoc(activityLogRef, activityLog);
  },

  // Track application submission
  async trackApplicationSubmission(subleaseId, applicationDetails) {
    const processRef = doc(db, 'subleaseProcesses', subleaseId);
    const activityLogRef = doc(collection(db, 'subleaseActivityLogs'));
    
    const activityLog = {
      subleaseId,
      timestamp: new Date().toISOString(),
      activity: 'application_submitted',
      details: applicationDetails,
      status: 'application_submitted',
      step: 'application'
    };

    await updateDoc(processRef, {
      status: 'application_submitted',
      currentStep: 'application',
      updatedAt: new Date().toISOString(),
      applicationDetails
    });

    await setDoc(activityLogRef, activityLog);
  },

  // Track payment completion
  async trackPaymentCompletion(subleaseId, paymentDetails) {
    const processRef = doc(db, 'subleaseProcesses', subleaseId);
    const activityLogRef = doc(collection(db, 'subleaseActivityLogs'));
    
    const activityLog = {
      subleaseId,
      timestamp: new Date().toISOString(),
      activity: 'payment_completed',
      details: paymentDetails,
      status: 'payment_completed',
      step: 'payment'
    };

    await updateDoc(processRef, {
      status: 'payment_completed',
      currentStep: 'payment',
      updatedAt: new Date().toISOString(),
      paymentDetails
    });

    await setDoc(activityLogRef, activityLog);
  },

  // Track move-in completion
  async trackMoveInCompletion(subleaseId, moveInDetails) {
    const processRef = doc(db, 'subleaseProcesses', subleaseId);
    const activityLogRef = doc(collection(db, 'subleaseActivityLogs'));
    
    const activityLog = {
      subleaseId,
      timestamp: new Date().toISOString(),
      activity: 'move_in_completed',
      details: moveInDetails,
      status: 'completed',
      step: 'move_in'
    };

    await updateDoc(processRef, {
      status: 'completed',
      currentStep: 'move_in',
      updatedAt: new Date().toISOString(),
      moveInDetails
    });

    await setDoc(activityLogRef, activityLog);
  },

  // Track tour completion
  async trackTourCompletion(subleaseId, completionDetails) {
    const processRef = doc(db, 'subleaseProcesses', subleaseId);
    const activityLogRef = doc(collection(db, 'subleaseActivityLogs'));
    
    const activityLog = {
      subleaseId,
      timestamp: new Date().toISOString(),
      activity: 'tour_completed',
      details: completionDetails,
      status: 'tour_completed',
      step: 'tour'
    };

    await updateDoc(processRef, {
      status: 'tour_completed',
      currentStep: 'terms', // Move to next step
      updatedAt: new Date().toISOString(),
      tourCompletionDetails: completionDetails
    });

    await setDoc(activityLogRef, activityLog);
  },

  // Update tour details
  async updateTourDetails(subleaseId, tourDetails) {
    const processRef = doc(db, 'subleaseProcesses', subleaseId);
    const activityLogRef = doc(collection(db, 'subleaseActivityLogs'));
    
    const activityLog = {
      subleaseId,
      timestamp: new Date().toISOString(),
      activity: 'tour_updated',
      details: tourDetails,
      status: 'tour_scheduled',
      step: 'tour'
    };

    await updateDoc(processRef, {
      status: 'tour_scheduled',
      currentStep: 'tour',
      updatedAt: new Date().toISOString(),
      tourDetails
    });

    await setDoc(activityLogRef, activityLog);
  },

  // Get tour status
  async getTourStatus(subleaseId) {
    const processRef = doc(db, 'subleaseProcesses', subleaseId);
    const docSnap = await getDoc(processRef);
    
    if (!docSnap.exists()) return null;
    
    const data = docSnap.data();
    return {
      status: data.status,
      tourDetails: data.tourDetails,
      tourCompletionDetails: data.tourCompletionDetails
    };
  }
};

// Sublease Listing Management
export const subleaseListingService = {
  // Create a new sublease listing
  async createSubleaseListing(userId, listingData) {
    const subleaseRef = doc(collection(db, 'subleases'));
    const subleaseId = subleaseRef.id;

    await setDoc(subleaseRef, {
      ...listingData,
      userId,
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString(),
      status: 'active'
    });

    // Add sublease to user's listings
    const userRef = doc(db, 'users', userId);
    await updateDoc(userRef, {
      subleases: arrayUnion(subleaseId)
    });

    return subleaseId;
  },

  // Update sublease listing
  async updateSubleaseListing(subleaseId, updateData) {
    const subleaseRef = doc(db, 'subleases', subleaseId);
    await updateDoc(subleaseRef, {
      ...updateData,
      updatedAt: new Date().toISOString()
    });
  },

  // Get sublease listing
  async getSubleaseListing(subleaseId) {
    const subleaseRef = doc(db, 'subleases', subleaseId);
    const docSnap = await getDoc(subleaseRef);
    return docSnap.exists() ? docSnap.data() : null;
  },

  // Get user's sublease listings
  async getUserSubleaseListings(userId) {
    const subleasesQuery = query(
      collection(db, 'subleases'),
      where('userId', '==', userId)
    );
    
    const querySnapshot = await getDocs(subleasesQuery);
    return querySnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    }));
  },

  // Delete sublease listing
  async deleteSubleaseListing(subleaseId, userId) {
    const subleaseRef = doc(db, 'subleases', subleaseId);
    const userRef = doc(db, 'users', userId);

    // Remove sublease from user's listings
    await updateDoc(userRef, {
      subleases: arrayRemove(subleaseId)
    });

    // Delete sublease document
    // await deleteDoc(subleaseRef);
  }
};

// Sublease Roommate Management
export const subleaseRoommateService = {
  // Add roommate to sublease
  async addRoommate(subleaseId, roommateData) {
    const roommatesRef = collection(db, 'subleases', subleaseId, 'roommates');
    const roommateRef = doc(roommatesRef);
    
    await setDoc(roommateRef, {
      ...roommateData,
      createdAt: new Date().toISOString()
    });

    return roommateRef.id;
  },

  // Get roommates for a sublease
  async getRoommates(subleaseId) {
    const roommatesRef = collection(db, 'subleases', subleaseId, 'roommates');
    const querySnapshot = await getDocs(roommatesRef);
    return querySnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    }));
  },

  // Update roommate information
  async updateRoommate(subleaseId, roommateId, updateData) {
    const roommateRef = doc(db, 'subleases', subleaseId, 'roommates', roommateId);
    await updateDoc(roommateRef, {
      ...updateData,
      updatedAt: new Date().toISOString()
    });
  },

  // Remove roommate from sublease
  async removeRoommate(subleaseId, roommateId) {
    const roommateRef = doc(db, 'subleases', subleaseId, 'roommates', roommateId);
    await deleteDoc(roommateRef);
  }
}; 