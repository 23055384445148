import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../../firebase';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../../firebase';
import { subleaseProcessService } from '../../backend/subleaseAPI';
import MainHeader from '../../components/Headers/MainHeader';
import Footer from '../../components/Headers/Footer';

function TermsAgreement() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [user] = useAuthState(auth);
  const [sublease, setSublease] = useState(null);
  const [hostProfile, setHostProfile] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [agreed, setAgreed] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const subleaseDoc = await getDoc(doc(db, 'subleases', id));
        if (subleaseDoc.exists()) {
          const subleaseData = subleaseDoc.data();
          setSublease(subleaseData);

          const hostDoc = await getDoc(doc(db, 'users', subleaseData.userId));
          if (hostDoc.exists()) {
            setHostProfile(hostDoc.data());
          }
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        setError('Failed to load sublease details');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [id]);

  const handleAgree = async () => {
    try {
      await subleaseProcessService.trackTermsAgreement(id, {
        agreedBy: user.uid,
        agreedAt: new Date().toISOString()
      });

      setSuccess(true);
      setTimeout(() => {
        navigate(`/sublease/${id}`);
      }, 3000);
    } catch (error) {
      console.error('Error agreeing to terms:', error);
      setError('Failed to save agreement. Please try again.');
    }
  };

  if (loading) {
    return (
      <div className="min-h-screen bg-gray-50">
        <MainHeader />
        <div className="container max-w-4xl mx-auto px-4 py-8">
          <div className="animate-pulse">
            <div className="h-8 bg-gray-200 rounded w-1/4 mb-4"></div>
            <div className="h-4 bg-gray-200 rounded w-1/2 mb-8"></div>
            <div className="space-y-4">
              <div className="h-12 bg-gray-200 rounded"></div>
              <div className="h-12 bg-gray-200 rounded"></div>
              <div className="h-12 bg-gray-200 rounded"></div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }

  if (!sublease || !hostProfile) {
    return (
      <div className="min-h-screen bg-gray-50">
        <MainHeader />
        <div className="container max-w-4xl mx-auto px-4 py-8">
          <div className="text-center">
            <h1 className="text-2xl font-bold text-gray-800 mb-4">Sublease Not Found</h1>
            <p className="text-gray-600">The sublease you're looking for doesn't exist or has been removed.</p>
          </div>
        </div>
        <Footer />
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50">
      <MainHeader />
      <div className="container max-w-4xl mx-auto px-4 py-8">
        <div className="bg-white rounded-lg shadow-sm p-6">
          <h1 className="text-2xl font-bold text-gray-800 mb-2">Term Agreement</h1>
          <p className="text-gray-600 mb-6">
            Review and agree to the terms for {sublease.apartmentName}
          </p>

          {success ? (
            <div className="text-center py-8">
              <div className="w-16 h-16 mx-auto mb-4 rounded-full bg-green-100 flex items-center justify-center">
                <i className="fa-solid fa-check text-green-500 text-2xl"></i>
              </div>
              <h2 className="text-xl font-semibold mb-2">Terms Agreed Successfully!</h2>
              <p className="text-gray-600 mb-4">
                You've agreed to the terms for this sublease. The host will be notified.
              </p>
              <p className="text-sm text-gray-500">
                Redirecting you back to the listing...
              </p>
            </div>
          ) : (
            <div className="space-y-6">
              <div className="prose max-w-none">
                <h3 className="text-lg font-semibold mb-4">Sublease Terms</h3>
                <div className="space-y-4">
                  <p>
                    <strong>Property:</strong> {sublease.apartmentName}
                  </p>
                  <p>
                    <strong>Address:</strong> {sublease.address}
                  </p>
                  <p>
                    <strong>Rent:</strong> ${sublease.rent}/month
                  </p>
                  <p>
                    <strong>Duration:</strong> {sublease.startDate} to {sublease.endDate}
                  </p>
                  <p>
                    <strong>Host:</strong> {hostProfile.firstName} {hostProfile.lastName}
                  </p>
                </div>

                <h3 className="text-lg font-semibold mt-8 mb-4">Agreement Terms</h3>
                <ol className="list-decimal pl-6 space-y-4">
                  <li>
                    The sublessee agrees to pay the monthly rent of ${sublease.rent} on time each month.
                  </li>
                  <li>
                    The sublessee agrees to maintain the property in good condition and follow all community rules.
                  </li>
                  <li>
                    The sublessee understands that this is a sublease agreement and will need to coordinate with the host for any maintenance or issues.
                  </li>
                  <li>
                    The sublessee agrees to provide proper notice before vacating the property.
                  </li>
                  <li>
                    The sublessee understands that they are responsible for any damages beyond normal wear and tear.
                  </li>
                </ol>
              </div>

              <div className="flex items-center space-x-3">
                <input
                  type="checkbox"
                  id="agree"
                  checked={agreed}
                  onChange={(e) => setAgreed(e.target.checked)}
                  className="h-4 w-4 text-logo_blue focus:ring-logo_blue border-gray-300 rounded"
                />
                <label htmlFor="agree" className="text-sm text-gray-700">
                  I have read and agree to the terms and conditions above
                </label>
              </div>

              {error && (
                <div className="text-red-500 text-sm">{error}</div>
              )}

              <div className="flex justify-end space-x-4">
                <button
                  onClick={() => navigate(`/sublease/${id}`)}
                  className="px-4 py-2 text-gray-600 hover:text-gray-800"
                >
                  Cancel
                </button>
                <button
                  onClick={handleAgree}
                  disabled={!agreed}
                  className={`px-4 py-2 rounded-lg ${
                    agreed
                      ? 'bg-logo_blue text-white hover:bg-blue-600'
                      : 'bg-gray-300 text-gray-500 cursor-not-allowed'
                  }`}
                >
                  Agree to Terms
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default TermsAgreement; 