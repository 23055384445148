import React, { useEffect, useState } from "react";
import {
  addUserToRoommateList,
  fetchUserData,
  uploadProfilePicture,
} from "../backend/dbAPI";
import { updateDoc, doc, getDoc, arrayUnion, serverTimestamp } from "firebase/firestore";
import { useAuthState } from "react-firebase-hooks/auth";
import { db, auth } from "../firebase/index";
import LivingHabitsQuestionnaire from "./LivingHabitsQuestionnaire";
import LifestylePreferencesQuestionnaire from "./LifestylePreferencesQuestionnaire.js";
import InterestTiles from "./InterestTiles";
import ProfileCarousel from "./ExampleRoommateProfiles";
import { useParams, useNavigate } from "react-router-dom";

const CreateRoommateProfile = () => {
  const navigate = useNavigate();
  const [my_user] = useAuthState(auth);
  const [userData, setUserData] = useState(null);
  const [slide, setSlide] = useState(1);
  const { apartmentId = "general" } = useParams();
  const [answers, setAnswers] = useState({
    // profilePicture: "",
    roommateDescription: "",
    priceRange: { lowerLimit: null, upperLimit: null },
    instagramHandle: "",
    ethnicity: "",
    homeState: "",
    isInternational: false,
    interests: {},
    shows: null,
    livingHabits: {},
    lifestylePreferences: {},
    showRoommateProfile: true,
  });

  const nextSlide = () => {
    if (validateSlide(slide)) {
      setSlide(slide + 1);
      window.scrollTo(0, 0);
    } else {
      alert("Please fill in all required fields before moving on.");
    }
  };

  const prevSlide = () => {
    setSlide(slide - 1);
    window.scrollTo(0, 0);
  };

  const handleSubmit = async () => {
    try {
      if (!my_user) {
        alert("Must create an Abode account and log in");
        return;
      }

      const userDocRef = doc(db, "users", my_user.uid);

      await updateDoc(userDocRef, {
        roommateDescription: answers.roommateDescription,
        priceRange: answers.priceRange,
        instagramHandle: answers.instagramHandle,
        ethnicity: answers.ethnicity,
        homeState: answers.homeState,
        isInternational: answers.isInternational,
        interests: answers.interests,
        shows: answers.shows,
        livingHabits: answers.livingHabits,
        lifestylePreferences: answers.lifestylePreferences,
        showRoommateProfile: true,
        roommateProfileCreatedAt: serverTimestamp(),
        roommateProfileUpdatedAt: serverTimestamp()
      });

      await addUserToRoommateList(my_user.uid, apartmentId);

      alert("Profile updated successfully.");
    } catch (error) {
      console.error("Error updating profile:", error);
      alert("Error updating profile.");
    }

    navigate("/listings");
  };

  const loadUserData = async (e) => {
    const userDataResp = await fetchUserData(my_user.uid);
    if (userDataResp) {
      setUserData(userDataResp);
      setAnswers((prev) => ({
        ...prev,
        profilePicture: userDataResp.profilePicture || "",
        roommateDescription: userDataResp.roommateDescription || "",
        priceRange: {
          lowerLimit: userDataResp.priceRange?.lowerLimit || null,
          upperLimit: userDataResp.priceRange?.upperLimit || null,
        },
        instagramHandle: userDataResp.instagramHandle || "",
        ethnicity: userDataResp.ethnicity || "",
        homeState: userDataResp.homeState || "",
        isInternational: userDataResp.isInternational || false,
        interests: userDataResp.interests || {},
        shows: userDataResp.shows || null,
        livingHabits: userDataResp.livingHabits || {},
        lifestylePreferences: userDataResp.lifestylePreferences || {},
      }));
    }
  };

  const PrevNextButtons = () => {
    return (
      <div className="flex justify-between">
        {slide > 1 && (
          <button
            onClick={prevSlide}
            className="bg-gray-300 text-gray-700 px-4 py-2 rounded"
          >
            Back
          </button>
        )}
        {slide < 6 && (
          <button
            onClick={nextSlide}
            className="bg-logo_blue text-white px-4 py-2 rounded"
          >
            Next
          </button>
        )}
        {slide == 6 && (
          <button
            onClick={handleSubmit}
            className="bg-logo_blue text-white px-4 py-2 rounded"
          >
            Done
          </button>
        )}
      </div>
    );
  };

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      try {
        const profilePictureURL = await uploadProfilePicture(my_user.uid, file);
        const userDocRef = doc(db, "users", my_user.uid);
        await updateDoc(userDocRef, { profilePicture: profilePictureURL });
        updateUserData("profilePicture", profilePictureURL);
        alert("Profile picture updated successfully.");
      } catch (error) {
        alert("Error uploading profile picture");
      }
    }
  };

  const updateUserData = (field, value) => {
    setUserData((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const updateUserResponses = (field, value) => {
    setAnswers((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  useEffect(() => {
    if (my_user) {
      loadUserData();
    }
  }, [my_user]);

  const formatYearMajor = (year, major) => {
    if (!year) return "";
    return (
      year.charAt(0).toUpperCase() +
      year.slice(1).toLowerCase() +
      ` in ${userData.major}`
    );
  };

  const validateSlide = (currentSlide) => {
    switch (currentSlide) {
      case 1:
        return (
          // answers.profilePicture &&
          answers.roommateDescription &&
          answers.priceRange
          // answers.instagramHandle
        );
      case 2:
        return Object.keys(answers.interests).length > 0;
      case 3:
        return answers.ethnicity && answers.homeState;
      case 4:
        const allValuesFilled =
          answers.livingHabits &&
          Object.keys(answers.livingHabits).length >= 6 &&
          Object.values(answers.livingHabits).every((value) => value !== null);
        return allValuesFilled;
      case 5:
        // console.log(answers);
        const allLifestyleValuesFilled =
          answers.lifestylePreferences &&
          Object.keys(answers.lifestylePreferences).length >= 7 &&
          Object.values(answers.lifestylePreferences).every(
            (value) => value !== null
          );
        return allLifestyleValuesFilled;
      default:
        return true;
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100 p-4">
      {userData ? (
        <div className="items-center justify-center w-full ">
          <div className="text-center p-4 rounded w-full max-w-4xl mx-auto">
            
            {slide == 1 && (
              <div>
                <p className="text-md text-gray-500">
                  Connect and live with roommates that match your vibe and
                  ensure you have a great college living experience!{" "}
                  <span className="font-medium text-black">{`(< 5 min)`}</span>
                </p>
              </div>
            )}
            {slide == 2 && (
              <h1 className="text-md text-gray-500">
                Hobbies and interests play a crucial role in matching vibes and
                compatibility with potential roommates. Please be genuine and
                take your time filling this out, as it can greatly impact your
                living experience and help match personalities effectively!
              </h1>
            )}
            <div className="w-full bg-gray-200 rounded-full my-6">
              <div
                className={`bg-logo_blue h-2 rounded-full`}
                style={{ width: `${(slide / 5) * 100}%` }}
              ></div>
            </div>
          </div>

          <div className="flex w-full items-center justify-center">
            {slide === 1 && (
              <div className="flex justify-center w-full items-center gap-x-12">
                <div className="w-full max-w-2xl">
                  <div className="bg-white shadow-lg rounded-lg p-6 w-full">
                    <div className="flex items-center mb-4">
                      <div className="relative">
                        {userData.profilePicture ? (
                          <img
                            src={userData.profilePicture}
                            alt="Profile"
                            className="w-32 h-32 rounded-full object-cover"
                          />
                        ) : (
                          <div className="w-32 h-32 rounded-full bg-gray-200 items-center justify-center flex">
                            <h2 className="text-black text-xs flex">
                              Upload Profile Picture
                            </h2>
                          </div>
                        )}
                        <input
                          type="file"
                          accept="image/*"
                          onChange={handleFileChange}
                          className="absolute inset-0 w-48 h-48 opacity-0 cursor-pointer"
                          style={{
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                          }}
                        />
                      </div>
                      <div className="ml-4">
                        <div className="text-lg font-semibold">
                          {userData.firstName} {userData.lastName}
                        </div>
                        <div className="text-gray-600">
                          {formatYearMajor(userData.year, userData.major)}
                        </div>
                      </div>
                    </div>
                    <div>
                      <h2 className="text-md font-medium">
                        Roommate Description
                      </h2>
                      <textarea
                        placeholder="Tell us a bit more about yourself and the roommates you are looking for... Only other students will see this so feel free to talk casually :)"
                        className="block w-full border rounded p-2 mb-4"
                        rows="4"
                        value={answers.roommateDescription}
                        onChange={(e) =>
                          updateUserResponses(
                            "roommateDescription",
                            e.target.value
                          )
                        }
                      ></textarea>

                      <ApartmentPreferences
                        preferences={answers.priceRange}
                        updatePreferences={(preferences) =>
                          updateUserResponses("priceRange", preferences)
                        }
                      />

                      <h2 className="text-md font-medium">Instagram Handle</h2>
                      <div className="flex items-center mb-4">
                        <i className="fab fa-instagram text-xl text-instagram-purple"></i>
                        <input
                          type="text"
                          placeholder="john.doe (no @)"
                          className="block w-full border rounded p-2 ml-2"
                          value={answers.instagramHandle}
                          onChange={(e) =>
                            updateUserResponses(
                              "instagramHandle",
                              e.target.value
                            )
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <h2 className="text-md font-semibold mb-4">
                    Example Roommate Profiles
                  </h2>
                  <div className="border bg-slate-100 shadow-lg rounded-lg w-full max-w-md">
                    <ProfileCarousel />
                  </div>
                  <h2 className="text-xs text-gray-500 font-semibold mt-4">
                    *These are only for example purposes and not real profiles
                  </h2>
                </div>
              </div>
            )}

            {slide === 2 && (
              <div className="w-full max-w-4xl">
                <div className="bg-white shadow-lg rounded-lg p-6 w-full">
                  <InterestTiles
                    interests={answers.interests}
                    setInterests={(interests) =>
                      updateUserResponses("interests", interests)
                    }
                    shows={answers.shows}
                    setShows={(show) => updateUserResponses("shows", show)}
                  />
                  <div className="p-4"></div>
                </div>
              </div>
            )}

            {slide === 3 && (
              <div className="bg-white shadow-lg rounded-lg p-6 w-full max-w-2xl">
                <h2 className="text-md font-medium">Ethnicity</h2>
                <select
                  className="block w-full border rounded p-2 mb-4"
                  placeholder="Ethnicity"
                  value={answers.ethnicity}
                  onChange={(e) =>
                    updateUserResponses("ethnicity", e.target.value)
                  }
                >
                  <option value="">Select Ethnicity</option>
                  <option value="Black/African American">
                    Black/African American
                  </option>
                  <option value="Hispanic/Latino">Hispanic/Latino</option>
                  <option value="White">White</option>
                  <option value="Middle Eastern">Middle Eastern</option>
                  <option value="South Asian">
                    South Asian (Indian, Pakistani, Bangladeshi, Sri Lankan)
                  </option>
                  <option value="East Asian">
                    East Asian (Chinese, Japanese, Korean)
                  </option>
                  <option value="Southeast Asian">
                    Southeast Asian (Vietnamese, Filipino, Thai, Indonesian)
                  </option>
                  <option value="Native American">Native American</option>
                  <option value="Other">Other</option>
                  <option value="Prefer not to say">Prefer not to say</option>
                </select>
                <h2 className="text-md font-medium">Home State/Country</h2>
                <input
                  type="text"
                  placeholder="Illinois, USA"
                  className="block w-full border rounded p-2 mb-4"
                  value={answers.homeState}
                  onChange={(e) =>
                    updateUserResponses("homeState", e.target.value)
                  }
                />
                <div className="flex items-center mb-4">
                  <label htmlFor="internationalStudent">
                    Are you an international student?
                  </label>
                  <input
                    type="checkbox"
                    className="ml-2"
                    id="internationalStudent"
                    checked={answers.isInternational}
                    onChange={(e) =>
                      updateUserResponses("isInternational", e.target.checked)
                    }
                  />
                </div>
              </div>
            )}

            {slide === 4 && (
              <LivingHabitsQuestionnaire
                livingHabits={answers.livingHabits}
                setLivingHabits={(livingHabits) =>
                  updateUserResponses("livingHabits", livingHabits)
                }
              />
            )}

            {slide === 5 && (
              <LifestylePreferencesQuestionnaire
                lifestylePreferences={answers.lifestylePreferences}
                setLifestylePreferences={(lifestylePreferences) =>
                  updateUserResponses(
                    "lifestylePreferences",
                    lifestylePreferences
                  )
                }
              />
            )}
          </div>
          <div className="w-full max-w-4xl mx-auto py-8">
            <PrevNextButtons />
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

const ApartmentPreferences = ({ preferences, updatePreferences }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedPreferences, setSelectedPreferences] = useState([]);
  const [priceRange, setPriceRange] = useState({
    lowerLimit: preferences.lowerLimit,
    upperLimit: preferences.upperLimit,
  });

  const priceRanges = {
    economical: { lowerLimit: 200, upperLimit: 600 },
    budget: { lowerLimit: 600, upperLimit: 800 },
    modern: { lowerLimit: 800, upperLimit: 1000 },
    premium: { lowerLimit: 1000, upperLimit: 1200 },
    luxury: { lowerLimit: 1200, upperLimit: 3000 },
  };

  useEffect(() => {
    const selectedRanges = selectedPreferences.filter(
      (pref) => priceRanges[pref]
    );
    const lowerLimit =
      selectedRanges.length > 0
        ? Math.min(
            ...selectedRanges.map((pref) => priceRanges[pref].lowerLimit)
          )
        : "";
    const upperLimit =
      selectedRanges.length > 0
        ? Math.max(
            ...selectedRanges.map((pref) => priceRanges[pref].upperLimit)
          )
        : "";

    setPriceRange({ lowerLimit, upperLimit });
    // updatePreferences({ lowerLimit, upperLimit })
    // console.log(lowerLimit, upperLimit);
  }, [selectedPreferences]);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleCheckboxChange = (e) => {
    const value = e.target.value;
    setSelectedPreferences((prev) =>
      prev.includes(value)
        ? prev.filter((preference) => preference !== value)
        : [...prev, value]
    );
  };

  const confirmSelection = () => {
    updatePreferences(priceRange);
    setIsDropdownOpen(false);
  };

  return (
    <div className="mb-4">
      <label className="text-md font-medium">Preferred Price Range</label>
      <div className="relative">
        <button
          onClick={toggleDropdown}
          className="w-full text-left bg-white border rounded px-4 py-2 focus:outline-none focus:ring"
        >
          Select Price Range
        </button>
        {isDropdownOpen && (
          <div className="absolute z-10 w-full bg-white border rounded shadow mt-1">
            <div className="flex flex-col p-2">
              <label className="flex items-center mb-2">
                <input
                  type="checkbox"
                  value="economical"
                  className="mr-2"
                  checked={selectedPreferences.includes("economical")}
                  onChange={handleCheckboxChange}
                />
                Economical Student Living (Under $600)
              </label>
              <label className="flex items-center mb-2">
                <input
                  type="checkbox"
                  value="budget"
                  className="mr-2"
                  checked={selectedPreferences.includes("budget")}
                  onChange={handleCheckboxChange}
                />
                Budget Student Living ($600-$800)
              </label>
              <label className="flex items-center mb-2">
                <input
                  type="checkbox"
                  value="modern"
                  className="mr-2"
                  checked={selectedPreferences.includes("modern")}
                  onChange={handleCheckboxChange}
                />
                Modern Student Living ($800-$1000)
              </label>
              <label className="flex items-center mb-2">
                <input
                  type="checkbox"
                  value="premium"
                  className="mr-2"
                  checked={selectedPreferences.includes("premium")}
                  onChange={handleCheckboxChange}
                />
                Premium Student Living ($1000-$1200)
              </label>
              <label className="flex items-center mb-2">
                <input
                  type="checkbox"
                  value="luxury"
                  className="mr-2"
                  checked={selectedPreferences.includes("luxury")}
                  onChange={handleCheckboxChange}
                />
                Luxury Student Living ($1200+)
              </label>
              <button
                onClick={confirmSelection}
                className="bg-logo_blue text-white px-4 py-2 rounded mt-2"
              >
                Confirm
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CreateRoommateProfile;
