import React, { useEffect } from 'react';
import { motion } from 'framer-motion';
import MainHeader from '../../components/Headers/MainHeader';

const About = () => {
  useEffect(() => {
    // Handle smooth scrolling when URL has hash
    const hash = window.location.hash;
    if (hash) {
      const element = document.querySelector(hash);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, []);

  const scrollToSection = (sectionId) => {
    const element = document.getElementById(sectionId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
      // Update URL without page reload
      window.history.pushState(null, '', `#${sectionId}`);
    }
  };

  const sections = [
    {
      id: 'tours',
      title: 'Abode Tours',
      description: 'Experience your future home through our virtual and in-person tour system.',
      features: [
        'Schedule tours at your convenience',
        'Virtual tour options for remote viewing',
        'In-person tours with verified hosts',
        'Real-time availability calendar',
        'Secure messaging system for tour coordination'
      ],
      icon: '📅'
    },
    {
      id: 'terms',
      title: 'Abode Term Agreement',
      description: 'Streamline your sublease process with our comprehensive term agreement system.',
      features: [
        'Standardized sublease agreements',
        'Digital document signing',
        'Secure storage of all documents',
        'Clear terms and conditions',
        '2-3 business day processing time'
      ],
      icon: '📝'
    },
    {
      id: 'partners',
      title: 'Abode Partner',
      description: 'Join our network of trusted property management partners.',
      features: [
        'Direct integration with property management systems',
        'Streamlined application process',
        'Verified property listings',
        'Priority support and assistance',
        'Marketing and exposure to UIUC students'
      ],
      icon: '🤝'
    }
  ];

  return (
    <div className="min-h-screen bg-gray-50">
      <MainHeader />
      <div className="max-w-4xl mx-auto px-4 py-8">
        <h1 className="text-3xl font-bold text-center mb-8">
          <span>About Abode</span>
        </h1>

        {/* Navigation Menu */}
        {/* <div className="sticky top-20 bg-white shadow-sm rounded-lg p-4 mb-8 z-10">
          <div className="flex flex-wrap justify-center gap-4">
            {sections.map((section) => (
              <button
                key={section.id}
                onClick={() => scrollToSection(section.id)}
                className="px-4 py-2 bg-blue-50 text-blue-600 rounded-md hover:bg-blue-100 transition-colors"
              >
                <span>{section.title}</span>
              </button>
            ))}
          </div>
        </div> */}

        {/* Sections */}
        <div className="space-y-12">
          {sections.map((section) => (
            <motion.section
              key={section.id}
              id={section.id}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              className="bg-white rounded-lg p-8 shadow-sm"
            >
              <div className="flex items-start gap-4">
                <div className="text-4xl">
                  <span>{section.icon}</span>
                </div>
                <div>
                  <h2 className="text-2xl font-semibold mb-4">
                    <span>{section.title}</span>
                  </h2>
                  <p className="text-gray-600 mb-6">
                    <span>{section.description}</span>
                  </p>
                  <div className="bg-blue-50 rounded-lg p-4">
                    <h3 className="font-medium text-blue-800 mb-3">
                      <span>Key Features:</span>
                    </h3>
                    <ul className="list-disc list-inside text-blue-700 space-y-2">
                      {section.features.map((feature, index) => (
                        <li key={index}>
                          <span>{feature}</span>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </motion.section>
          ))}
        </div>

        {/* Call to Action */}
        <div className="mt-12 text-center">
          <h2 className="text-2xl font-semibold mb-4">
            <span>Ready to Get Started?</span>
          </h2>
          <div className="flex justify-center gap-4">
            <button
              onClick={() => window.location.href = '/sublease-intro'}
              className="px-6 py-3 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors"
            >
              <span>List Your Sublease</span>
            </button>
            <button
              onClick={() => window.location.href = '/listings#subleases'}
              className="px-6 py-3 bg-white text-blue-500 border border-blue-500 rounded-lg hover:bg-blue-50 transition-colors"
            >
              <span>Browse Subleases</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About; 