import React, { useState } from 'react';
import { format, parse, differenceInDays } from 'date-fns';
import { motion } from 'framer-motion';

// Define quickDateOptions outside component
const quickDateOptions = [
  { 
    label: 'All', 
    value: 'all',
    startDate: null,
    endDate: null
  },
  { 
    label: 'Fall 2025', 
    value: 'fall2025',
    startDate: '2025-08-26',
    endDate: '2025-12-19'
  },
  { 
    label: 'Spring 2025', 
    value: 'spring2025',
    startDate: '2025-01-20',
    endDate: '2025-05-17'
  },
  { 
    label: 'Summer 2025', 
    value: 'summer2025',
    startDate: '2025-05-20',
    endDate: '2025-07-31'
  },
  { 
    label: 'Fall 2025-2026', 
    value: 'fall2025-2026',
    startDate: '2025-08-01',
    endDate: '2026-07-31'
  }
];

// Define calculateDateOverlap outside component
const calculateDateOverlap = (subleaseStart, subleaseEnd, userStart, userEnd) => {
  if (!subleaseStart || !subleaseEnd || !userStart || !userEnd) return 0;
  
  // Parse dates if they're strings
  const subStart = typeof subleaseStart === 'string' ? parse(subleaseStart, 'MM-dd-yyyy', new Date()) : subleaseStart;
  const subEnd = typeof subleaseEnd === 'string' ? parse(subleaseEnd, 'MM-dd-yyyy', new Date()) : subleaseEnd;
  const userStartDate = new Date(userStart);
  const userEndDate = new Date(userEnd);
  
  // Calculate the overlap
  const overlapStart = new Date(Math.max(subStart.getTime(), userStartDate.getTime()));
  const overlapEnd = new Date(Math.min(subEnd.getTime(), userEndDate.getTime()));
  
  if (overlapEnd < overlapStart) return 0;
  
  // Calculate overlap in days
  const overlapDays = differenceInDays(overlapEnd, overlapStart);
  const userRangeDays = differenceInDays(userEndDate, userStartDate);
  
  // Calculate base overlap percentage
  const baseOverlap = (overlapDays / userRangeDays) * 100;
  
  // Define term ranges
  const terms = [
    {
      name: 'Fall 2025',
      start: new Date('2025-08-26'),
      end: new Date('2025-12-19'),
      weight: 1.0
    },
    {
      name: 'Spring 2025',
      start: new Date('2025-01-20'),
      end: new Date('2025-05-17'),
      weight: 1.0
    },
    {
      name: 'Summer 2025',
      start: new Date('2025-05-20'),
      end: new Date('2025-07-31'),
      weight: 1.0
    },
    {
      name: 'Fall 2025-2026',
      start: new Date('2025-08-01'),
      end: new Date('2026-07-31'),
      weight: 1.0
    }
  ];

  // Calculate term overlaps
  const termOverlaps = terms.map(term => {
    const termOverlapStart = new Date(Math.max(subStart.getTime(), term.start.getTime()));
    const termOverlapEnd = new Date(Math.min(subEnd.getTime(), term.end.getTime()));
    
    if (termOverlapEnd < termOverlapStart) return 0;
    
    const termOverlapDays = differenceInDays(termOverlapEnd, termOverlapStart);
    const termDuration = differenceInDays(term.end, term.start);
    
    return (termOverlapDays / termDuration) * term.weight;
  });

  // Get the maximum term overlap
  const maxTermOverlap = Math.max(...termOverlaps);
  
  // Calculate how well the sublease dates match the user's selected dates
  const startDiff = Math.abs(differenceInDays(subStart, userStartDate));
  const endDiff = Math.abs(differenceInDays(subEnd, userEndDate));
  const dateMatchScore = Math.max(0, 1 - (startDiff + endDiff) / (userRangeDays * 2));
  
  // Combine scores with emphasis on exact date matches
  const finalScore = (baseOverlap * 0.7) + (dateMatchScore * 100 * 0.3) + (maxTermOverlap * 20);
  
  return finalScore;
};

function SubleaseFilters({ filters, setFilters, onSort }) {
  const [activeFilter, setActiveFilter] = useState(null);

  const handleDateChange = (type, date) => {
    setFilters(prev => ({
      ...prev,
      dateRange: {...prev.dateRange, [type]: date},
      quickDateFilter: 'all' // Reset quick date filter when custom dates are selected
    }));
  };

  const handleBedroomChange = (value) => {
    setFilters(prev => ({...prev, bedRange: value}));
  };

  const handleBathroomChange = (value) => {
    setFilters(prev => ({...prev, bathRange: value}));
  };

  const handlePriceChange = (value) => {
    setFilters(prev => ({...prev, priceRange: value}));
  };

  const handleQuickDateSelect = (value) => {
    const selectedOption = quickDateOptions.find(opt => opt.value === value);
    
    if (value === 'all') {
      setFilters(prev => ({
        ...prev,
        quickDateFilter: 'all',
        dateRange: { start: null, end: null }
      }));
      // Sort by creation date for "All" option
      onSort((subleases) => {
        return [...subleases].sort((a, b) => {
          return new Date(a.updatedAt) - new Date(b.updatedAt);
        });
      });
    } else {
      setFilters(prev => ({
        ...prev,
        quickDateFilter: value,
        dateRange: {
          start: selectedOption.startDate,
          end: selectedOption.endDate
        }
      }));
      // Sort by date overlap for specific terms
      onSort((subleases) => {
        return [...subleases].sort((a, b) => {
          const aOverlap = calculateDateOverlap(
            a.startDate,
            a.endDate,
            selectedOption.startDate,
            selectedOption.endDate
          );
          const bOverlap = calculateDateOverlap(
            b.startDate,
            b.endDate,
            selectedOption.startDate,
            selectedOption.endDate
          );
          
          if (aOverlap !== bOverlap) {
            return bOverlap - aOverlap;
          }
          
          return new Date(b.createdAt) - new Date(a.createdAt);
        });
      });
    }
  };

  return (
    <div className="w-full mb-8">
      <div className="flex flex-wrap gap-4">
        {/* Date Range Filter */}
        <div className="relative flex-1 min-w-[240px]">
          <motion.button
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: 0.98 }}
            onClick={() => {
              setActiveFilter(activeFilter === 'date' ? null : 'date');
            }}
            className="w-full px-6 py-3 bg-white border border-gray-200 rounded-lg hover:border-gray-300 transition-all"
          >
            <div className="flex items-center justify-between">
              <div>
                <p className="text-sm font-medium text-gray-500">Dates</p>
                <p className="text-sm font-semibold">
                  {filters.quickDateFilter === 'all' ? 
                    'All dates' :
                    filters.quickDateFilter ? 
                      quickDateOptions.find(opt => opt.value === filters.quickDateFilter)?.label :
                      filters.dateRange.start && filters.dateRange.end ?
                        `${format(new Date(filters.dateRange.start), 'MMM d')} - ${format(new Date(filters.dateRange.end), 'MMM d')}` :
                        'Add dates'}
                </p>
              </div>
              <i className="fas fa-calendar-alt text-gray-400"></i>
            </div>
          </motion.button>
          {activeFilter === 'date' && (
            <motion.div 
              initial={{ opacity: 0, y: 10 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 10 }}
              className="absolute top-full left-0 mt-2 w-full bg-white rounded-lg shadow-xl border border-gray-100 p-4 z-50"
            >
              <div className="space-y-4">
                {/* Quick Date Options */}
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">Common Periods</label>
                  <div className="grid grid-cols-2 gap-2">
                    {quickDateOptions.map(option => (
                      <motion.button
                        key={option.value}
                        whileHover={{ scale: 1.02 }}
                        whileTap={{ scale: 0.98 }}
                        onClick={() => handleQuickDateSelect(option.value)}
                        className={`p-3 rounded-lg text-sm font-medium transition-all text-left
                          ${filters.quickDateFilter === option.value
                            ? 'bg-blue-500 text-white'
                            : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                          }`}
                      >
                        <div>
                          <div className="font-semibold">{option.label}</div>
                          {option.startDate && option.endDate && (
                            <div className="text-xs opacity-80">
                              {format(new Date(option.startDate), 'MMM d')} - {format(new Date(option.endDate), 'MMM d')}
                            </div>
                          )}
                        </div>
                      </motion.button>
                    ))}
                  </div>
                </div>
                <div className="border-t border-gray-100 pt-4">
                  <label className="block text-sm font-medium text-gray-700 mb-2">Custom Dates</label>
                  <div className="space-y-2">
                    <input
                      type="date"
                      value={filters.dateRange.start || ''}
                      onChange={(e) => handleDateChange('start', e.target.value)}
                      className="w-full p-2 border border-gray-200 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                    />
                    <input
                      type="date"
                      value={filters.dateRange.end || ''}
                      onChange={(e) => handleDateChange('end', e.target.value)}
                      className="w-full p-2 border border-gray-200 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                    />
                  </div>
                </div>
              </div>
            </motion.div>
          )}
        </div>

        {/* Beds & Baths Filter */}
        <div className="relative flex-1 min-w-[240px]">
          <button
            onClick={() => setActiveFilter(activeFilter === 'rooms' ? null : 'rooms')}
            className="w-full px-6 py-3 bg-white border border-gray-200 rounded-lg hover:border-gray-300 transition-all"
          >
            <div className="flex items-center justify-between">
              <div>
                <p className="text-sm font-medium text-gray-500">Rooms</p>
                <p className="text-sm font-semibold">
                  {`${filters.bedRange[0] === 0 && filters.bedRange[1] === 5 ? 'All' : `${filters.bedRange[0]}-${filters.bedRange[1]}`} beds • 
                    ${filters.bathRange[0] === 1 && filters.bathRange[1] === 4 ? 'All' : `${filters.bathRange[0]}-${filters.bathRange[1]}`} baths`}
                </p>
              </div>
              <i className="fas fa-bed text-gray-400"></i>
            </div>
          </button>
          {activeFilter === 'rooms' && (
            <div className="absolute top-full left-0 mt-2 w-full bg-white rounded-lg shadow-xl border border-gray-100 p-4 z-50">
              <div className="space-y-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">Bedrooms</label>
                  <div className="flex gap-2">
                    <button
                      onClick={() => handleBedroomChange([0, 5])}
                      className={`px-4 py-2 rounded-lg text-sm font-medium ${
                        filters.bedRange[0] === 0 && filters.bedRange[1] === 5
                          ? 'bg-blue-500 text-white'
                          : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                      }`}
                    >
                      All
                    </button>
                    {[0, 1, 2, 3, 4, 5].map((num) => (
                      <button
                        key={num}
                        onClick={() => handleBedroomChange([num, num])}
                        className={`px-4 py-2 rounded-lg text-sm font-medium ${
                          filters.bedRange[0] === num && filters.bedRange[1] === num
                            ? 'bg-blue-500 text-white'
                            : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                        }`}
                      >
                        {num === 0 ? 'Studio' : num}
                      </button>
                    ))}
                  </div>
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">Bathrooms</label>
                  <div className="flex gap-2">
                    <button
                      onClick={() => handleBathroomChange([1, 4])}
                      className={`px-4 py-2 rounded-lg text-sm font-medium ${
                        filters.bathRange[0] === 1 && filters.bathRange[1] === 4
                          ? 'bg-blue-500 text-white'
                          : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                      }`}
                    >
                      All
                    </button>
                    {[1, 2, 3, 4].map((num) => (
                      <button
                        key={num}
                        onClick={() => handleBathroomChange([num, num])}
                        className={`px-4 py-2 rounded-lg text-sm font-medium ${
                          filters.bathRange[0] === num && filters.bathRange[1] === num
                            ? 'bg-blue-500 text-white'
                            : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                        }`}
                      >
                        {num}
                      </button>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>

        {/* Price Range Filter */}
        <div className="relative flex-1 min-w-[240px]">
          <button
            onClick={() => setActiveFilter(activeFilter === 'price' ? null : 'price')}
            className="w-full px-6 py-3 bg-white border border-gray-200 rounded-lg hover:border-gray-300 transition-all"
          >
            <div className="flex items-center justify-between">
              <div>
                <p className="text-sm font-medium text-gray-500">Price</p>
                <p className="text-sm font-semibold">
                  ${filters.priceRange[0]} - ${filters.priceRange[1]}
                </p>
              </div>
              <i className="fas fa-dollar-sign text-gray-400"></i>
            </div>
          </button>
          {activeFilter === 'price' && (
            <div className="absolute top-full left-0 mt-2 w-full bg-white rounded-lg shadow-xl border border-gray-100 p-4 z-50">
              <div className="space-y-4">
                <div className="flex gap-2">
                  {[800, 1000, 1200, 1500].map((price) => (
                    <button
                      key={price}
                      onClick={() => handlePriceChange([0, price])}
                      className="px-4 py-2 rounded-lg text-sm font-medium bg-gray-100 text-gray-700 hover:bg-gray-200"
                    >
                      Under ${price}
                    </button>
                  ))}
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">Price Range</label>
                  <div className="flex gap-4 items-center">
                    <input
                      type="number"
                      value={filters.priceRange[0]}
                      onChange={(e) => handlePriceChange([parseInt(e.target.value), filters.priceRange[1]])}
                      className="w-full p-2 border border-gray-200 rounded-lg"
                      placeholder="Min"
                    />
                    <span className="text-gray-500">to</span>
                    <input
                      type="number"
                      value={filters.priceRange[1]}
                      onChange={(e) => handlePriceChange([filters.priceRange[0], parseInt(e.target.value)])}
                      className="w-full p-2 border border-gray-200 rounded-lg"
                      placeholder="Max"
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default SubleaseFilters;