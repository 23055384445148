import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { parse, format } from "date-fns";
import { ImageSlider } from "../../../components/Listings/ImageSlider";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../../firebase";
import { memo } from "react";
import { logEvent, analytics } from '../../../firebase';

const SubleaseCard = memo(function SubleaseCard({ sublease }) {
  const id = sublease.id;
  const beds = sublease.bedrooms;
  const baths = sublease.bathrooms;
  const startDate = sublease.startDate;
  const endDate = sublease.endDate;
  const rent = sublease.rent;
  const [hostProfile, setHostProfile] = useState(null);
  const [imagesLoaded, setImagesLoaded] = useState(false);
  const [fullImages, setFullImages] = useState(null);

  // Function to determine the term based on date range
  const getTermTag = useCallback((start, end) => {
    const startDate = parse(start, "MM-dd-yyyy", new Date());
    const endDate = parse(end, "MM-dd-yyyy", new Date());
    
    // Define term ranges with distinct colors
    const terms = [
      {
        name: 'Fall 2025',
        start: new Date('2025-08-26'),
        end: new Date('2025-12-19'),
        color: 'bg-[#7C3AED] text-white' // Purple
      },
      {
        name: 'Spring 2025',
        start: new Date('2025-01-20'),
        end: new Date('2025-05-17'),
        color: 'bg-[#EC4899] text-white' // Pink
      },
      {
        name: 'Summer 2025',
        start: new Date('2025-05-20'),
        end: new Date('2025-07-31'),
        color: 'bg-[#10B981] text-white' // Emerald
      },
      {
        name: 'Fall 2025-2026',
        start: new Date('2025-08-01'),
        end: new Date('2026-07-31'),
        color: 'bg-[#F97316] text-white' // Orange
      }
    ];

    // Calculate overlap with each term
    const overlaps = terms.map(term => {
      const overlapStart = new Date(Math.max(startDate.getTime(), term.start.getTime()));
      const overlapEnd = new Date(Math.min(endDate.getTime(), term.end.getTime()));
      
      if (overlapStart > overlapEnd) return { term, overlap: 0 };
      
      const overlapDuration = overlapEnd - overlapStart;
      const termDuration = term.end - term.start;
      const overlapPercentage = overlapDuration / termDuration;
      
      return { term, overlap: overlapPercentage };
    });

    // Sort overlaps by percentage in descending order
    const sortedOverlaps = overlaps.sort((a, b) => b.overlap - a.overlap);
    
    // Special handling for Fall 2025-2026 vs Fall 2025
    const fall2025_2026 = sortedOverlaps.find(o => o.term.name === 'Fall 2025-2026');
    const fall2025 = sortedOverlaps.find(o => o.term.name === 'Fall 2025');
    
    if (fall2025_2026 && fall2025) {
      // Special case: If the date range spans from December to July, it's Spring-Summer
      const startMonth = startDate.getMonth() + 1; // 1-12
      const endMonth = endDate.getMonth() + 1; // 1-12
      const isSpringSummer = (startMonth === 12 && endMonth <= 8);
      
      if (isSpringSummer) {
        // Find Spring and Summer terms
        const spring2025 = sortedOverlaps.find(o => o.term.name === 'Spring 2025');
        const summer2025 = sortedOverlaps.find(o => o.term.name === 'Summer 2025');
        
        if (spring2025 && summer2025) {
          return {
            label: 'Spring-Summer 2025',
            color: 'bg-[#EC4899] text-white' // Using Spring's color
          };
        }
      }

      // Original academic year logic
      const isAcademicYear = (startMonth >= 8 && (endMonth >= 5 && endMonth <= 7));
      
      if (isAcademicYear) {
        return {
          label: fall2025_2026.term.name,
          color: fall2025_2026.term.color
        };
      }

      // If the overlap with Fall 2025-2026 is significantly larger than Fall 2025
      if (fall2025_2026.overlap > 0.3 && fall2025_2026.overlap > fall2025.overlap * 1.2) {
        return {
          label: fall2025_2026.term.name,
          color: fall2025_2026.term.color
        };
      }
      // If the overlap with Fall 2025 is significantly larger than Fall 2025-2026
      if (fall2025.overlap > 0.3 && fall2025.overlap > fall2025_2026.overlap * 1.2) {
        return {
          label: fall2025.term.name,
          color: fall2025.term.color
        };
      }
      // If neither is significantly larger, prefer Fall 2025-2026 if it has decent overlap
      if (fall2025_2026.overlap > 0.3) {
        return {
          label: fall2025_2026.term.name,
          color: fall2025_2026.term.color
        };
      }
    }
    
    // For other terms, only show if there's a clear dominant match
    if (sortedOverlaps[0].overlap > 0.4 && 
        (sortedOverlaps.length === 1 || sortedOverlaps[1].overlap < 0.2)) {
      return {
        label: sortedOverlaps[0].term.name,
        color: sortedOverlaps[0].term.color
      };
    }

    return null;
  }, []);

  // Memoize the loadFullImages function
  const loadFullImages = useCallback(async () => {
    if (fullImages) return;
    
    try {
      if (sublease.images && Array.isArray(sublease.images)) {
        const sortedImages = [...sublease.images].sort((a, b) => a.order - b.order);
        const orderedUrls = sortedImages.map(img => img.url);
        setFullImages(orderedUrls);
        setImagesLoaded(true);
      }
    } catch (error) {
      console.error("Error loading images:", error);
      setImagesLoaded(true);
    }
  }, [id, sublease.images, fullImages]);

  const thumbnailImage = useMemo(() => {
    if (sublease.images && sublease.images.length > 0) {
      const sortedImages = [...sublease.images].sort((a, b) => a.order - b.order);
      return [sortedImages[0].url];
    }
    return [];
  }, [sublease.images]);

  useEffect(() => {
    const fetchHostProfile = async () => {
      if (sublease.userId) {
        const docRef = doc(db, "users", sublease.userId);
        try {
          const docSnap = await getDoc(docRef);
          if (docSnap.exists()) {
            setHostProfile(docSnap.data());
          }
        } catch (error) {
          console.error("Error fetching host profile:", error);
        }
      }
    };

    fetchHostProfile();
  }, [sublease.userId]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && !imagesLoaded) {
          loadFullImages();
        }
      },
      {
        rootMargin: '200px',
        threshold: 0.1,
      }
    );
    
    const currentElement = document.getElementById(`sublease-card-${id}`);
    if (currentElement) {
      observer.observe(currentElement);
    }

    return () => {
      if (currentElement) {
        observer.unobserve(currentElement);
      }
    };
  }, [id, imagesLoaded, loadFullImages]);

  const parseDate = useCallback((dateString) => {
    const parsedDate = parse(dateString, "MM-dd-yyyy", new Date());
    const formattedDate = format(parsedDate, "MMM d");
    return formattedDate;
  }, []);

  const onImageClick = (e) => {
    if (sublease) {
      logEvent(analytics, "sublease_click", {
        subleaseId: id,
        userId: sublease.userId,
        timestamp: Date.now(),
      });
    }
    e.preventDefault();
    window.open(`/sublease/${id}`, '_blank');
  };

  const handleFavClick = () => {
    // TODO: Implement favorites functionality
  };

  // Check if there are any images
  const hasImages = Boolean(
    (sublease.images && sublease.images.length > 0) || 
    (sublease.imageUrls && sublease.imageUrls.length > 0) ||
    sublease.hasImages
  );

  return (
    <div className="relative" id={`sublease-card-${id}`}>
      <div className="absolute top-0 right-0 z-10">
        <button onClick={handleFavClick}>
          <div className="p-2 m-2">
            <i className="fa-regular fa-heart fa-lg hover:scale-125 text-white"></i>
          </div>
        </button>
      </div>

      <div key={sublease.id}>
        <div className="relative flex flex-col rounded-md overflow-hidden">
          <div className="w-full overflow-hidden relative aspect-square">
            {!hasImages ? (
              <div className="w-full h-full bg-gray-100 flex items-center justify-center">
                <div className="text-gray-400 text-center">
                  <i className="fa-solid fa-image fa-2x mb-2"></i>
                  <p>No images available</p>
                </div>
              </div>
            ) : (
              <>
                <ImageSlider
                  images={imagesLoaded ? fullImages : thumbnailImage}
                  onImageClick={onImageClick}
                  className="absolute top-0 left-0 w-full h-full object-cover"
                  priority={true}
                />
                {getTermTag(startDate, endDate) && (
                  <div className="absolute bottom-2 right-1 z-10">
                    <span className={`text-xs font-medium px-3 py-1.5 rounded-full ${getTermTag(startDate, endDate).color} shadow-lg backdrop-blur-sm bg-opacity-90`}>
                      {getTermTag(startDate, endDate).label}
                    </span>
                  </div>
                )}
              </>
            )}
          </div>
          <div className="cursor-pointer py-1" onClick={onImageClick}>
            <div className="flex justify-between">
              <div className="flex flex-col w-full">
                <div className="flex justify-between items-center">
                  <div className="text-md font-medium text-black truncate">
                    {sublease.apartmentName}
                  </div>
                  {hostProfile?.profilePicture && (
                    <img
                      src={hostProfile.profilePicture}
                      alt="Host"
                      className="w-8 h-8 rounded-full object-cover"
                      loading="lazy"
                    />
                  )}
                </div>
                <div className="text-sm font-normal text-gray-600">
                  {parseDate(startDate)} - {parseDate(endDate)}
                </div>
                <div className="text-sm font-normal text-gray-600">
                  {beds} beds • {baths} bath
                </div>
              </div>
            </div>

            <div className="flex pt-2">
              <div className="text-sm font-semibold text-black">${rent}</div>
              <div className="text-sm text-black ml-1">month</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default SubleaseCard; 