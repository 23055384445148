import React, { createContext, useState, useEffect } from 'react';
import { fetchFavorites, addFavorite, removeFavorite } from './backend/dbAPI';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from './firebase/index';

const AppContext = createContext();

const AppLoadingSkeleton = () => {
  return (
    <div className="min-h-screen bg-white">
      {/* Header Skeleton */}
      <div className="h-16 bg-white border-b animate-pulse">
        <div className="max-w-7xl mx-auto px-4">
          <div className="h-full flex items-center justify-between">
            <div className="h-8 w-24 bg-gray-200 rounded"></div>
            <div className="flex space-x-4">
              <div className="h-8 w-20 bg-gray-200 rounded"></div>
              <div className="h-8 w-20 bg-gray-200 rounded"></div>
            </div>
          </div>
        </div>
      </div>

      {/* Content Skeleton */}
      <div className="max-w-7xl mx-auto px-4 py-8">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          {[...Array(6)].map((_, index) => (
            <div key={index} className="animate-pulse">
              <div className="aspect-[4/3] bg-gray-200 rounded-lg mb-4"></div>
              <div className="h-4 bg-gray-200 rounded w-3/4 mb-2"></div>
              <div className="h-4 bg-gray-200 rounded w-1/2"></div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export const AppProvider = ({ children }) => {
  const [favorites, setFavorites] = useState([]);
  const [user, loading, error] = useAuthState(auth);

  useEffect(() => {
    const getFavorites = async () => {
      if (user && !loading && !error) {
        try {
          const fetchedFavorites = await fetchFavorites(user.uid);
          setFavorites(fetchedFavorites);
        } catch (fetchError) {
          console.error('Error fetching favorites:', fetchError);
        }
      } else if (!user) {
        // Clear favorites when user logs out
        setFavorites([]);
      }
    };

    getFavorites();
  }, [user, loading, error]);

  const addToFavorites = async (userId, listingId) => {
    if (user) {
      try {
        await addFavorite(userId, listingId);
        setFavorites((prevFavorites) => [...prevFavorites, listingId]);
      } catch (addError) {
        console.error('Error adding favorite:', addError);
      }
    }
  };

  const removeFromFavorites = async (userId, listingId) => {
    if (user) {
      try {
        await removeFavorite(userId, listingId);
        setFavorites((prevFavorites) => prevFavorites.filter(id => id !== listingId));
      } catch (removeError) {
        console.error('Error removing favorite:', removeError);
      }
    }
  };

  if (loading) {
    return (
      <div className="transition-opacity duration-300 ease-in-out">
        <AppLoadingSkeleton />
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="text-center p-8 rounded-lg bg-red-50">
          <i className="fas fa-exclamation-circle text-red-500 text-2xl mb-4"></i>
          <h2 className="text-lg font-semibold text-red-700 mb-2">Error Loading App</h2>
          <p className="text-red-600">{error.message}</p>
        </div>
      </div>
    );
  }

  return (
    <AppContext.Provider value={{ favorites, addToFavorites, removeFromFavorites }}>
      {children}
    </AppContext.Provider>
  );
};

export default AppContext;
