import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../firebase/index";

function AnimatedNumber({ end, duration = 2000 }) {
  // Calculate starting value as 75% of the end value
  const start = Math.floor(end * 0.75);
  const [count, setCount] = useState(start);

  useEffect(() => {
    let startTime = null;
    
    // Using easeOutQuart for even smoother slow-down at the end
    const easeOutQuart = t => 1 - Math.pow(1 - t, 4);

    const animate = timestamp => {
      if (!startTime) startTime = timestamp;
      const progress = Math.min((timestamp - startTime) / duration, 1);
      
      const easedProgress = easeOutQuart(progress);
      const currentCount = Math.floor(start + (end - start) * easedProgress);
      
      setCount(currentCount);

      if (progress < 1) {
        requestAnimationFrame(animate);
      }
    };

    requestAnimationFrame(animate);
  }, [end, start, duration]);

  return count.toLocaleString();
}

function SubleaseLanding() {
  const navigate = useNavigate();
  const [user] = useAuthState(auth);
  const [imageLoaded, setImageLoaded] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const handleListSublease = () => {
    if (!user) {
      navigate("/signup", { 
        state: { 
          message: "Please create a student account first to list your sublease",
          from: "/sublease-intro"
        } 
      });
      return;
    }
    navigate("/sublease-intro");
  };

  const handleViewSubleases = () => {
    if (!user) {
      navigate("/signup", {
        state: {
          message: "Please sign up to view available subleases",
          from: "/listings#subleases"
        }
      });
      return;
    }
    navigate("/listings#subleases");
  };

  useEffect(() => {
    // Preload the image
    const img = new Image();
    img.src = "https://firebasestorage.googleapis.com/v0/b/adobe-64bfc.appspot.com/o/Abode-sublease-hero.png?alt=media&token=fa6e8f78-4073-4279-b153-789f30415a4b";
    img.onload = () => {
      setImageLoaded(true);
      // Add a small delay to ensure smooth transition
      setTimeout(() => setIsLoading(false), 300);
    };
  }, []);

  return (
    <>
      {/* Loading Screen - positioned below header */}
      <div className={`fixed top-[64px] inset-x-0 bottom-0 bg-white z-40 transition-opacity duration-500 ${
        isLoading ? 'opacity-100' : 'opacity-0 pointer-events-none'
      }`}>
        <div className="h-full flex flex-col items-center justify-start pt-16">
          <div className="w-full max-w-7xl mx-auto px-4">
            {/* Skeleton Loading matching the actual layout */}
            <div className="flex flex-col md:flex-row items-center gap-12">
              {/* Left column skeleton */}
              <div className="flex-1 max-w-xl w-full">
                <div className="animate-pulse">
                  <div className="h-16 bg-gray-200 rounded-lg w-3/4 mb-4"></div>
                  <div className="h-6 bg-gray-200 rounded w-1/2 mb-8"></div>
                  
                  <div className="flex gap-4 mb-8">
                    <div className="h-14 bg-gray-200 rounded-lg flex-1"></div>
                    <div className="h-14 bg-gray-200 rounded-lg flex-1"></div>
                  </div>
                </div>
              </div>

              {/* Right column skeleton */}
              <div className="flex-1 w-full">
                <div className="animate-pulse">
                  <div className="aspect-[4/3] bg-gray-200 rounded-xl w-full max-w-xl mx-auto"></div>
                </div>
              </div>
            </div>

            {/* Stats skeleton */}
            <div className="mt-24 grid grid-cols-2 md:grid-cols-4 gap-12">
              {[...Array(4)].map((_, i) => (
                <div key={i} className="animate-pulse">
                  <div className="h-10 bg-gray-200 rounded-lg w-24 mx-auto mb-2"></div>
                  <div className="h-4 bg-gray-200 rounded w-20 mx-auto"></div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      {/* Main Content */}
      <div className={`relative min-h-screen flex flex-col bg-gray-50 transition-opacity duration-500 ${
        isLoading ? 'opacity-0' : 'opacity-100'
      }`}>
        <div className="relative z-10 flex-1 flex flex-col items-center justify-center px-4 py-16 md:py-24">
          <div className="max-w-7xl mx-auto flex flex-col md:flex-row items-center gap-12">
            {/* Left Column - CTAs */}
            <div className="flex-1 max-w-xl">
              <h1 className="text-4xl md:text-6xl font-bold mb-4 bg-gradient-to-r from-logo_blue to-blue-600 bg-clip-text text-transparent leading-tight">
                UIUC Subleases Made Simple
              </h1>

              <p className="text-lg text-gray-600 mb-8">
                Verify and join 2000+ UIUC students already using Abode
              </p>

              {/* Simplified side-by-side CTAs */}
              <div className="grid grid-cols-2 gap-4 mb-8">
                <button
                  onClick={handleViewSubleases}
                  className="bg-logo_blue text-white px-6 py-4 rounded-lg font-medium hover:bg-blue-700 transition duration-300 flex items-center justify-center gap-2 shadow-sm"
                >
                  <span>Browse Listings</span>
                  <i className="fas fa-arrow-right text-sm"></i>
                </button>

                <button
                  onClick={handleListSublease}
                  className="bg-white text-logo_blue border border-logo_blue/30 px-6 py-4 rounded-lg font-medium hover:bg-gray-50 transition duration-300 flex items-center justify-center gap-2"
                >
                  <span>List Your Place</span>
                  <i className="fas fa-arrow-right text-sm"></i>
                </button>
              </div>

              {/* Verification badge */}
              {/* <div className="flex items-center gap-3 bg-blue-50 p-4 rounded-lg">
                <i className="fas fa-shield-check text-logo_blue text-xl"></i>
                <div>
                  <div className="font-medium text-gray-900">UIUC Verified Only</div>
                  <div className="text-sm text-gray-600">Must verify your student status to list or view subleases</div>
                </div>
              </div> */}
            </div>

            {/* Right Column - Hero Image with Placeholder */}
            <div className="flex-1 relative">
              <img
                src="https://firebasestorage.googleapis.com/v0/b/adobe-64bfc.appspot.com/o/Abode-sublease-hero.png?alt=media&token=fa6e8f78-4073-4279-b153-789f30415a4b"
                alt="UIUC Subleases on Abode"
                className="rounded-xl shadow-2xl w-full max-w-xl mx-auto"
              />
              <div className="absolute -z-10 -inset-4 bg-gradient-to-r from-logo_blue/5 to-blue-600/5 rounded-xl blur-xl"></div>
            </div>
          </div>

          {/* Stats section with loading state */}
          <div className="max-w-5xl mx-auto px-6 pt-24">
            <div className="grid grid-cols-2 md:grid-cols-4 gap-12 text-center">
              <div className="transform transition-transform duration-300 hover:scale-110">
                <div className="text-4xl font-bold text-logo_blue mb-3 h-12 flex items-center justify-center">
                  {!imageLoaded ? (
                    <span className="text-gray-300">87+</span>
                  ) : (
                    <><AnimatedNumber end={87} duration={2500} />+</>
                  )}
                </div>
                <div className="text-gray-600 font-medium">Active Listings</div>
              </div>
              <div className="transform transition-transform duration-300 hover:scale-110">
                <div className="text-4xl font-bold text-logo_blue mb-3 h-12 flex items-center justify-center">
                  {!imageLoaded ? (
                    <span className="text-gray-300">2,345</span>
                  ) : (
                    <AnimatedNumber end={2345} duration={3000} />
                  )}
                </div>
                <div className="text-gray-600 font-medium">Verified Students</div>
              </div>
              <div className="transform transition-transform duration-300 hover:scale-110">
                <div className="text-4xl font-bold text-logo_blue mb-3">24/7</div>
                <div className="text-gray-600 font-medium">Support</div>
              </div>
              <div className="transform transition-transform duration-300 hover:scale-110">
                <div className="text-4xl font-bold text-logo_blue mb-3">4.8/5</div>
                <div className="text-gray-600 font-medium">User Rating</div>
              </div>
            </div>
          </div>
        </div>

        {/* Value Props Section */}
        <div className="relative z-10 bg-white py-16">
          <div className="max-w-7xl mx-auto px-4">
            <div className="grid md:grid-cols-3 gap-8 mb-20">
              <div className="bg-gray-50 rounded-xl p-8 transform transition hover:scale-105 duration-300 hover:shadow-lg">
                <div className="w-12 h-12 rounded-full bg-blue-100 flex items-center justify-center mb-6">
                  <i className="fas fa-shield text-logo_blue text-xl"></i>
                </div>
                <h3 className="text-xl font-semibold text-gray-900 mb-3">Verified Students</h3>
                <p className="text-gray-600 leading-relaxed">
                  Connect with verified UIUC students for safe and reliable housing
                </p>
              </div>

              <div className="bg-gray-50 rounded-xl p-8 transform transition hover:scale-105 duration-300 hover:shadow-lg">
                <div className="w-12 h-12 rounded-full bg-blue-100 flex items-center justify-center mb-6">
                  <i className="fas fa-calendar text-logo_blue text-xl"></i>
                </div>
                <h3 className="text-xl font-semibold text-gray-900 mb-3">Flexible Dates</h3>
                <p className="text-gray-600 leading-relaxed">
                  Find stays that match your academic calendar perfectly
                </p>
              </div>

              <div className="bg-gray-50 rounded-xl p-8 transform transition hover:scale-105 duration-300 hover:shadow-lg">
                <div className="w-12 h-12 rounded-full bg-blue-100 flex items-center justify-center mb-6">
                  <i className="fas fa-comments text-logo_blue text-xl"></i>
                </div>
                <h3 className="text-xl font-semibold text-gray-900 mb-3">Direct Communication</h3>
                <p className="text-gray-600 leading-relaxed">
                  Chat directly with hosts and get quick responses
                </p>
              </div>
            </div>

            {/* New Bottom CTA Section */}
            <div className="max-w-3xl mx-auto text-center">
              <h2 className="text-3xl font-bold mb-6">
                Ready to Find Your Perfect Sublease?
              </h2>
              <div className="flex flex-col sm:flex-row gap-4 justify-center">
                <button
                  onClick={handleViewSubleases}
                  className="bg-logo_blue text-white px-8 py-4 rounded-lg font-medium hover:bg-blue-700 transition duration-300 flex items-center justify-center gap-2 shadow-md text-lg"
                >
                  <span>Verify & Browse Listings</span>
                  <i className="fas fa-arrow-right"></i>
                </button>
                <button
                  onClick={handleListSublease}
                  className="bg-white text-logo_blue border border-logo_blue/30 px-8 py-4 rounded-lg font-medium hover:bg-gray-50 transition duration-300 flex items-center justify-center gap-2 text-lg"
                >
                  <span>List Your Place</span>
                  <i className="fas fa-arrow-right"></i>
                </button>
              </div>
              <p className="text-gray-600 mt-6">
                Join thousands of UIUC students finding their perfect housing match
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SubleaseLanding; 