import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { chatService } from '../../services/chatService';
import { auth } from '../../firebase/index';
import { getDoc, doc, onSnapshot, getDocs, query, collection, where, orderBy, startAfter, limit } from 'firebase/firestore';
import { db } from '../../firebase/index';
import MainHeader from '../../components/Headers/MainHeader';
import { motion, AnimatePresence } from 'framer-motion';
import { subleaseProcessService } from '../../backend/subleaseAPI';
import SubleaseStatusBar from '../../components/Chat/SubleaseStatusBar';
import { adminService } from '../../services/adminService';

function MessagesPage() {
  const { conversationId } = useParams();
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [conversation, setConversation] = useState(null);
  const [conversations, setConversations] = useState([]);
  const [otherUser, setOtherUser] = useState(null);
  const messagesEndRef = useRef(null);
  const messagesStartRef = useRef(null);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [hasMoreMessages, setHasMoreMessages] = useState(true);
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const [sendError, setSendError] = useState(null);
  const inputRef = useRef(null);
  const fileInputRef = useRef(null);
  const MESSAGES_PER_PAGE = 20;
  const [selectedImage, setSelectedImage] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [viewingImage, setViewingImage] = useState(null);
  const [uploadingImages, setUploadingImages] = useState(new Set());

  // Handle sublease ID in URL
  useEffect(() => {
    const handleSubleaseId = async () => {
      if (!auth.currentUser) {
        navigate('/login', { 
          state: { 
            from: `/messages/${conversationId}`,
            message: "Please log in to view your messages"
          } 
        });
        return;
      }

      // If we have a sublease ID in the URL, create or get the conversation
      if (conversationId && conversationId.length > 20) { // Assuming sublease IDs are longer than 20 chars
        try {
          // Get sublease details
          const subleaseDoc = await getDoc(doc(db, 'subleases', conversationId));
          if (!subleaseDoc.exists()) {
            throw new Error('Sublease not found');
          }

          const sublease = subleaseDoc.data();
          
          // Create or get conversation
          const conversation = await chatService.getOrCreateConversation(
            conversationId,
            [auth.currentUser.uid, sublease.userId],
            'sublease',
            conversationId,
            sublease.userId
          );

          // Navigate to the conversation
          navigate(`/messages/${conversation.id}`);
        } catch (error) {
          console.error('Error handling sublease ID:', error);
          setError('Failed to start conversation. Please try again.');
        }
      }
    };

    handleSubleaseId();
  }, [conversationId, navigate]);

  // Listen to conversations
  useEffect(() => {
    if (!auth.currentUser) {
      navigate('/login', { 
        state: { 
          from: `/messages/${conversationId}`,
          message: "Please log in to view your messages"
        } 
      });
      return;
    }

    const q = query(
      collection(db, 'conversations'),
      where('participants', 'array-contains', auth.currentUser.uid)
    );

    const unsubscribe = onSnapshot(q, 
      async (snapshot) => {
        try {
          const conversationsData = await Promise.all(
            snapshot.docs.map(async (docSnapshot) => {
              const conv = { id: docSnapshot.id, ...docSnapshot.data() };
              // Add safety checks for participants
              if (!conv.participants || !Array.isArray(conv.participants)) {
                console.warn(`Invalid participants for conversation ${conv.id}`);
                return {
                  ...conv,
                  otherUser: { id: 'unknown', name: 'Unknown User', profilePic: null }
                };
              }
              
              const otherUserId = conv.participants.find(id => id !== auth.currentUser?.uid);
              if (!otherUserId) {
                console.warn(`Could not find other user in conversation ${conv.id}`);
                return {
                  ...conv,
                  otherUser: { id: 'unknown', name: 'Unknown User', profilePic: null }
                };
              }

              try {
                const userDoc = await getDoc(doc(db, 'users', otherUserId));
                const userData = userDoc.data() || {};
                return {
                  ...conv,
                  otherUser: {
                    id: otherUserId,
                    name: `${userData.firstName || ''} ${userData.lastName || ''}`.trim() || 'Unknown User',
                    profilePic: userData.profilePicture
                  }
                };
              } catch (userError) {
                console.error(`Error fetching user ${otherUserId}:`, userError);
                return {
                  ...conv,
                  otherUser: { id: otherUserId, name: 'Unknown User', profilePic: null }
                };
              }
            })
          );
          conversationsData.sort((a, b) => 
            (b.lastMessageAt?.toDate() || new Date(0)) - (a.lastMessageAt?.toDate() || new Date(0))
          );
          setConversations(conversationsData);
          setError(null);
        } catch (err) {
          console.error('Error loading conversations:', err);
          setError('Failed to load conversations. Please try again.');
        }
      },
      (error) => {
        console.error('Error in conversations listener:', error);
        setError('Failed to load conversations. Please try again.');
      }
    );

    return () => unsubscribe();
  }, [navigate]);

  // Load conversation details
  useEffect(() => {
    if (!conversationId) return;

    const loadConversation = async () => {
      try {
        const convDoc = await getDoc(doc(db, 'conversations', conversationId));
        if (!convDoc.exists()) {
          setError('Conversation not found');
          return;
        }

        const conv = { id: convDoc.id, ...convDoc.data() };
        
        // Check if user is a participant or admin
        const isParticipant = conv.participants.includes(auth.currentUser.uid);
        const isAdmin = await adminService.isAdmin(auth.currentUser.uid);
        
        if (!isParticipant && !isAdmin) {
          setError('You do not have permission to view this conversation');
          return;
        }

        setConversation(conv);
        
        // Get other user's details
        const otherUserId = conv.participants.find(id => id !== auth.currentUser.uid);
        const userDoc = await getDoc(doc(db, 'users', otherUserId));
        const userData = userDoc.data();
        setOtherUser({
          id: otherUserId,
          name: `${userData?.firstName || ''} ${userData?.lastName || ''}`,
          profilePic: userData?.profilePicture
        });
      } catch (error) {
        console.error('Error loading conversation:', error);
        setError('Failed to load conversation. Please try again.');
      }
    };

    loadConversation();
  }, [conversationId]);

  // Listen to messages for current conversation
  useEffect(() => {
    if (!conversationId || !conversation) return;

    const q = query(
      collection(db, 'messages'),
      where('conversationId', '==', conversationId),
      orderBy('timestamp', 'asc')
    );

    const unsubscribe = onSnapshot(q, (snapshot) => {
      const messagesData = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      
      // Only update messages if we're not currently loading more
      if (!isLoadingMore) {
        setMessages(messagesData);
        setHasMoreMessages(snapshot.docs.length === MESSAGES_PER_PAGE);
        
        // Scroll to bottom after messages are loaded
        setTimeout(() => {
          messagesEndRef.current?.scrollIntoView({ behavior: 'instant' });
        }, 100);
      }
    });

    return () => unsubscribe();
  }, [conversationId, conversation, isLoadingMore]);

  // Mark messages as read
  useEffect(() => {
    if (!conversationId || !auth.currentUser) return;

    const markMessagesAsRead = async () => {
      try {
        const unreadMessages = messages.filter(
          msg => !msg.readBy?.includes(auth.currentUser.uid) && 
                 msg.senderId !== auth.currentUser.uid
        );

        if (unreadMessages.length > 0) {
          await chatService.markMessagesAsRead(conversationId, unreadMessages.map(msg => msg.id));
        }
      } catch (error) {
        console.error('Error marking messages as read:', error);
      }
    };

    markMessagesAsRead();
  }, [conversationId, messages]);

  // Load more messages when scrolling up
  const loadMoreMessages = async () => {
    if (!conversationId || isLoadingMore || !hasMoreMessages) return;

    setIsLoadingMore(true);
    try {
      const lastMessage = messages[messages.length - 1];
      const q = query(
        collection(db, 'messages'),
        where('conversationId', '==', conversationId),
        orderBy('timestamp', 'asc'),
        startAfter(lastMessage.timestamp),
        limit(MESSAGES_PER_PAGE)
      );

      const snapshot = await getDocs(q);
      const newMessages = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));

      if (newMessages.length > 0) {
        setMessages(prev => [...prev, ...newMessages]);
        setHasMoreMessages(newMessages.length === MESSAGES_PER_PAGE);
      } else {
        setHasMoreMessages(false);
      }
    } catch (error) {
      console.error('Error loading more messages:', error);
    } finally {
      setIsLoadingMore(false);
    }
  };

  // Handle scroll
  const handleScroll = (e) => {
    const container = e.target;
    if (container.scrollTop === 0 && hasMoreMessages) {
      loadMoreMessages();
    }
  };

  const handleSend = async (e) => {
    e.preventDefault();
    if (!newMessage.trim() && !selectedImage) return;

    const messageContent = newMessage.trim();
    const tempId = `temp-${Date.now()}`;
    const tempMessage = {
      id: tempId,
      content: messageContent,
      senderId: auth.currentUser.uid,
      timestamp: new Date(),
      imageUrl: imagePreview,
      isImageUploading: !!selectedImage,
      status: 'sending'
    };

    // Clear input and image preview immediately
    setNewMessage('');
    setSelectedImage(null);
    setImagePreview(null);

    // Add temporary message to the end of the array since we're using asc order
    setMessages(prev => [...prev, tempMessage]);
    messagesEndRef.current?.scrollIntoView({ behavior: 'instant' });

    if (selectedImage) {
      setUploadingImages(prev => new Set(prev).add(tempId));
    }

    try {
      let imageUrl = null;
      if (selectedImage) {
        imageUrl = await chatService.uploadImage(selectedImage);
        setUploadingImages(prev => {
          const newSet = new Set(prev);
          newSet.delete(tempId);
          return newSet;
        });
      }

      await chatService.sendMessage(
        conversationId,
        {
          text: messageContent,
          senderId: auth.currentUser.uid,
          timestamp: new Date(),
          imageUrl: imageUrl || null
        }
      );
    } catch (error) {
      console.error('Error sending message:', error);
      // Update the temporary message to show error state
      setMessages(prev => prev.map(msg => 
        msg.id === tempId ? { ...msg, status: 'error' } : msg
      ));
      setUploadingImages(prev => {
        const newSet = new Set(prev);
        newSet.delete(tempId);
        return newSet;
      });
      setSendError('Failed to send message. Please try again.');
    }
  };

  const handleImageSelect = (e) => {
    const file = e.target.files[0];
    if (!file) return;

    if (file.size > 5 * 1024 * 1024) { // 5MB limit
      alert('Image size should be less than 5MB');
      return;
    }

    setSelectedImage(file);
    setImagePreview(URL.createObjectURL(file));
  };

  // Format timestamp helper
  const formatTimestamp = (timestamp) => {
    if (!timestamp) return '';
    
    // Handle both Firestore Timestamp and regular Date objects
    const date = timestamp.toDate ? timestamp.toDate() : new Date(timestamp);
    const now = new Date();
    const diff = now - date;
    
    // Today
    if (diff < 24 * 60 * 60 * 1000) {
      return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    }
    // Yesterday
    if (diff < 48 * 60 * 60 * 1000) {
      return 'Yesterday';
    }
    // This week
    if (diff < 7 * 24 * 60 * 60 * 1000) {
      return date.toLocaleDateString([], { weekday: 'long' });
    }
    // Older
    return date.toLocaleDateString([], { month: 'short', day: 'numeric' });
  };

  const formatDetailedTimestamp = (timestamp) => {
    if (!timestamp) return { time: '', date: '' };
    
    const date = timestamp.toDate ? timestamp.toDate() : new Date(timestamp);
    const now = new Date();
    const diff = now - date;
    
    let time = date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    let dateStr;
    
    // Less than 1 minute ago
    if (diff < 60 * 1000) {
      return { time: 'Just now', date: '' };
    }
    
    // Less than 1 hour ago
    if (diff < 60 * 60 * 1000) {
      const minutes = Math.floor(diff / (60 * 1000));
      return { time: `${minutes}m ago`, date: '' };
    }
    
    // Today
    if (date.toDateString() === now.toDateString()) {
      return { time, date: 'Today' };
    }
    
    // Yesterday
    const yesterday = new Date(now);
    yesterday.setDate(yesterday.getDate() - 1);
    if (date.toDateString() === yesterday.toDateString()) {
      return { time, date: 'Yesterday' };
    }
    
    // Within last 7 days
    if (diff < 7 * 24 * 60 * 60 * 1000) {
      return { 
        time,
        date: date.toLocaleDateString([], { weekday: 'short' })
      };
    }
    
    // Older
    return {
      time,
      date: date.toLocaleDateString([], { month: 'short', day: 'numeric' })
    };
  };

  return (
    <div className="flex flex-col h-screen bg-gray-50">
      <MainHeader />
      
      <div className="flex flex-1 border-t border-gray-200 overflow-hidden md:flex-row flex-col">
        {/* Conversations Sidebar */}
        <div className={`md:w-1/3 w-full border-r bg-white ${conversationId ? 'hidden md:block' : 'block'}`}>
          <div className="p-4 border-b flex justify-between items-center sticky top-0 bg-white z-10">
            <div className="flex items-center">
              <button
                onClick={() => navigate(-1)}
                className="mr-4 text-gray-500 hover:text-gray-700"
              >
                <i className="fas fa-arrow-left text-xl"></i>
              </button>
              <h2 className="text-xl font-semibold">Messages</h2>
            </div>
          </div>
          <div className="overflow-y-auto h-[calc(100vh-8rem)]">
            {conversations.map(conv => (
              <div
                key={conv.id}
                onClick={() => navigate(`/messages/${conv.id}`)}
                className={`flex items-center p-4 cursor-pointer hover:bg-gray-50 ${
                  conversationId === conv.id ? 'bg-gray-50' : ''
                }`}
              >
                <img
                  src={conv.otherUser?.profilePic || 'https://ui-avatars.com/api/?name=' + encodeURIComponent(conv.otherUser?.name || 'User') + '&background=random'}
                  alt="Profile"
                  className="w-10 h-10 rounded-full mr-3"
                />
                <div className="flex-1 min-w-0">
                  <div className="flex items-center gap-2 mb-1">
                    <span className="font-base truncate">{conv.otherUser?.name}</span>
                    <span className={`px-2 py-0.5 rounded-full text-xs font-medium ${
                      conv.type === 'roommate' 
                        ? 'bg-purple-100 text-purple-800' 
                        : 'bg-blue-100 text-blue-800'
                    }`}>
                      {conv.type === 'roommate' ? 'Roommate' : 'Sublease'}
                    </span>
                  </div>
                  <div className="flex items-center justify-between">
                    <div className="text-xs text-gray-500 truncate max-w-[70%]">
                      {conv.lastMessage?.lastMessage || 'No messages yet'}
                    </div>
                    <div className="text-xs text-gray-400 flex items-center gap-1">
                      <span>{formatDetailedTimestamp(conv.lastMessageAt).time}</span>
                      <span className="text-gray-300">•</span>
                      <span>{formatDetailedTimestamp(conv.lastMessageAt).date}</span>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Chat Area */}
        <div className={`flex-1 flex flex-col ${!conversationId ? 'hidden md:flex' : 'flex'} h-full`}>
          {conversationId ? (
            error ? (
              <div className="flex-1 flex items-center justify-center">
                <div className="text-center p-6">
                  <i className="fas fa-lock text-4xl text-gray-400 mb-4"></i>
                  <h2 className="text-xl font-semibold text-gray-800 mb-2">Access Denied</h2>
                  <p className="text-gray-600">{error}</p>
                  <button
                    onClick={() => navigate('/messages')}
                    className="mt-4 px-4 py-2 bg-logo_blue text-white rounded-lg hover:bg-logo_blue_dark"
                  >
                    Back to Messages
                  </button>
                </div>
              </div>
            ) : (
              <>
                {/* Chat Header */}
                <div className="p-2 border-b bg-white sticky top-0 z-10">
                  <div className="flex flex-col gap-2">
                    {/* Top Row - Back Button, Type Badge, and Sublease Link */}
                    <div className="flex items-center justify-between">
                      {/* Mobile Back Button */}
                      <button 
                        onClick={() => navigate(`/messages`)}
                        className="md:hidden text-gray-600 hover:text-gray-800 p-2 rounded-lg hover:bg-gray-100"
                      >
                        ←
                      </button>
                      
                      {/* Profile Button */}
                      <a 
                        href={`/profile/${otherUser?.id}`}
                        className="flex items-center p-2 rounded-lg bg-gray-100 transition-colors group"
                      >
                        <div className="relative">
                          <img
                            src={otherUser?.profilePic || 'https://ui-avatars.com/api/?name=' + encodeURIComponent(otherUser?.name || 'User') + '&background=random'}
                            alt="Profile"
                            className="w-8 h-8 rounded-full mr-2"
                          />
                        </div>
                        <div>
                          <div className="font-medium text-sm group-hover:text-blue-600">{otherUser?.name}</div>
                        </div>
                      </a>
                    </div>

                    {/* Sublease Status and Actions */}
                    <SubleaseStatusBar subleaseId={conversation?.subleaseId} conversationId={conversationId} />
                  </div>
                </div>

                {/* Messages */}
                <div 
                  className="flex-1 overflow-y-auto p-4 h-[calc(100vh-16rem)]"
                  onScroll={handleScroll}
                >
                  {isLoadingMore && (
                    <div className="flex justify-center py-2">
                      <div className="animate-spin rounded-full h-6 w-6 border-b-2 border-gray-900"></div>
                    </div>
                  )}
                  <div ref={messagesStartRef} />
                  <div className="flex flex-col gap-2">
                    {messages.map((message, index) => {
                      const prevMessage = messages[index - 1];
                      const messageDate = message.timestamp?.toDate ? message.timestamp.toDate() : new Date(message.timestamp);
                      const prevMessageDate = prevMessage?.timestamp?.toDate ? prevMessage.timestamp.toDate() : new Date(prevMessage?.timestamp);
                      
                      const showDateSeparator = index > 0 && 
                        (prevMessageDate.getDate() !== messageDate.getDate() ||
                         prevMessageDate.getHours() !== messageDate.getHours());

                      return (
                        <div key={message.id}>
                          {showDateSeparator && (
                            <div className="text-center py-2">
                              <span className="bg-gray-100 px-3 py-1 rounded-full text-xs text-gray-500">
                                {formatTimestamp(message.timestamp)}
                              </span>
                            </div>
                          )}
                          
                          <div
                            className={`flex flex-col ${
                              message.senderId === auth.currentUser.uid
                                ? 'items-end'
                                : 'items-start'
                            }`}
                          >
                            {message.imageUrl && (
                              <div className="relative">
                                <img 
                                  src={message.imageUrl} 
                                  alt="Shared" 
                                  className={`max-w-[70%] rounded-lg cursor-pointer ${
                                    uploadingImages.has(message.id) ? 'opacity-70' : ''
                                  }`}
                                  onClick={() => !uploadingImages.has(message.id) && setViewingImage(message.imageUrl)}
                                />
                                {uploadingImages.has(message.id) && (
                                  <div className="absolute inset-0 flex items-center justify-center">
                                    <div className="h-8 w-8 rounded-full border-2 border-blue-500 border-t-transparent animate-spin"></div>
                                  </div>
                                )}
                              </div>
                            )}
                            {message.content && (
                              <div
                                className={`max-w-[70%] rounded-lg p-3 mt-1 ${
                                  message.senderId === auth.currentUser.uid
                                    ? 'bg-blue-500 text-white'
                                    : 'bg-white shadow-sm'
                                }`}
                              >
                                <div>{message.content}</div>
                              </div>
                            )}
                            {message.senderId === auth.currentUser.uid && 
                             message.id === messages[0]?.id && 
                             message.status === 'delivered' && (
                              <span className="text-xs text-gray-500 mt-1 opacity-70">
                                {message.readBy?.includes(otherUser?.id) ? 'Read' : 'Delivered'}
                              </span>
                            )}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  <div ref={messagesEndRef} />
                </div>

                {/* Message Input */}
                <form onSubmit={handleSend} className="p-4 border-t bg-white">
                  {imagePreview && (
                    <div className="relative mb-2">
                      <img 
                        src={imagePreview} 
                        alt="Preview" 
                        className="max-h-32 rounded-lg"
                      />
                      <button
                        type="button"
                        onClick={() => {
                          setSelectedImage(null);
                          setImagePreview(null);
                        }}
                        className="absolute top-1 right-1 bg-black bg-opacity-50 rounded-full p-1"
                      >
                        <i className="fas fa-times text-white text-xs" />
                      </button>
                    </div>
                  )}
                  <div className="flex gap-2">
                    <input
                      ref={fileInputRef}
                      type="file"
                      accept="image/*"
                      className="hidden"
                      onChange={handleImageSelect}
                    />
                    <button
                      type="button"
                      onClick={() => fileInputRef.current?.click()}
                      className="text-gray-500 hover:text-gray-700"
                    >
                      <i className="fas fa-image text-xl" />
                    </button>
                    <input
                      ref={inputRef}
                      type="text"
                      value={newMessage}
                      onChange={(e) => setNewMessage(e.target.value)}
                      className="flex-1 border rounded-lg px-4 py-2 focus:outline-none focus:ring-2 focus:ring-gray-500"
                      placeholder="Type a message..."
                      autoFocus
                    />
                    <button
                      type="submit"
                      className="bg-black text-white px-6 py-2 rounded-lg hover:bg-gray-800"
                      disabled={!newMessage.trim() && !selectedImage}
                    >
                      Send
                    </button>
                  </div>
                </form>
              </>
            )
          ) : (
            <div className="flex-1 flex items-center justify-center text-gray-500">
              Select a conversation to start chatting
            </div>
          )}
        </div>
      </div>

      {/* Image Viewer */}
      {viewingImage && (
        <div 
          className="fixed inset-0 bg-black bg-opacity-90 z-50 flex items-center justify-center"
          onClick={() => setViewingImage(null)}
        >
          <img 
            src={viewingImage} 
            alt="Full size" 
            className="max-w-[90%] max-h-[90vh] object-contain"
          />
        </div>
      )}
    </div>
  );
}

export default MessagesPage; 