import React, { useState } from 'react';
import { motion } from 'framer-motion';
import MainHeader from '../../components/Headers/MainHeader';

const SubleaseProcessGuide = () => {
  const [activeTab, setActiveTab] = useState('subleasee');

  const subleaseeSteps = [
    {
      title: 'Create Student Account',
      description: 'Get access to hundreds of sublease listings only for UIUC verified students.',
      tips: [
        'Verify your UIUC student status',
        'Complete your profile information',
        'Set up your preferences'
      ],
      icon: '👨‍🎓'
    },
    {
      title: 'Browse Listings',
      description: 'Intuitively browse through available listings and find your perfect match.',
      tips: [
        'Use filters to narrow down your search',
        'Save your favorite listings',
        'Check the host\'s profile and verification status'
      ],
      icon: '🔍'
    },
    {
      title: 'Chat and Connect',
      description: 'Reach out to current tenants to ask about the apartment and any questions you have.',
      tips: [
        'Introduce yourself professionally',
        'Ask about availability and key details',
        'Respond promptly and with good vibes',
        'Be clear about your requirements'
      ],
      icon: '💬'
    },
    {
      title: 'Book a Tour',
      description: 'Schedule a tour (virtual or in-person) through <a href="/about#tours" class="text-[#2563EB] font-bold underline">Abode Tours</a> in the chat.',
      tips: [
        'Be flexible with timing',
        'Prepare questions about the apartment',
        'Confirm the meeting details in the chat'
      ],
      icon: '📅'
    },
    {
      title: 'Discuss and Finalize Terms',
      description: 'Agree on key details using the <a href="/about#terms" class="text-[#2563EB] font-bold underline">Abode Term Agreement</a> and wait 2-3 business days for processing.',
      tips: [
        'Discuss exact duration and rent payments',
        'Clarify utilities and fees',
        'Understand liabilities and responsibilities',
        'Document all agreed terms in the chat'
      ],
      icon: '🤝'
    },
    {
      title: 'Submit Application and Sign Lease',
      description: 'Complete the application process. Depending on if your apartment is an <a href="/about#partners" class="text-[#2563EB] font-bold underline">Abode Partner</a>, this will be done through Abode or your apartment\'s leasing company.',
      tips: [
        'Have your documents ready',
        'Be prepared for either Abode or leasing company process',
        'Stay in touch for next steps and email you from contact@getabode.co',
        'We\'ll support you through the entire process'
      ],
      icon: '📝'
    },
    {
      title: 'Payment and Security',
      description: 'Complete payment for application fee and security deposit through Abode.',
      tips: [
        'Security deposit will be refunded upon move-out',
        'Payments are processed securely through Abode',
        'Keep records of all payments',
        'Understand the refund conditions'
      ],
      icon: '💰'
    },
    {
      title: 'Move-In',
      description: 'Pick up keys from the tenant and move into your new apartment.',
      tips: [
        'Coordinate key pickup with the tenant',
        'Document the condition of the apartment',
        'Enjoy your new home!'
      ],
      icon: '🔑'
    }
  ];

  const subleaserSteps = [
    {
      title: 'List Your Place',
      description: 'Input your apartment details to list your spot in front of thousands of UIUC students.',
      tips: [
        'Add high-quality photos of your space',
        'Be specific about dates and requirements',
        'Set a competitive price',
        'Highlight unique features of your apartment'
      ],
      icon: '📸'
    },
    {
      title: 'Chat and Connect',
      description: 'Respond to inquiries and connect with potential subleasees.',
      tips: [
        'Respond promptly to messages',
        'Maintain good vibes in communication',
        'Be clear about your requirements',
        'Ask relevant questions to screen applicants'
      ],
      icon: '💬'
    },
    {
      title: 'Give Tours',
      description: 'Schedule and conduct tours (virtual or in-person) through <a href="/about#tours" class="text-[#2563EB] font-bold underline">Abode Tours</a>.',
      tips: [
        'Keep your space clean and presentable',
        'Be prepared to answer questions',
        'Document who has viewed the space',
        'Be flexible with scheduling'
      ],
      icon: '📅'
    },
    {
      title: 'Discuss and Finalize Terms',
      description: 'Agree on key details using the <a href="/about#terms" class="text-[#2563EB] font-bold underline">Abode Term Agreement</a> and wait 2-3 business days for processing.',
      tips: [
        'Discuss exact duration and rent payments',
        'Clarify utilities and fees',
        'Understand liabilities and responsibilities',
        'Document all agreed terms in the chat'
      ],
      icon: '🤝'
    },
    {
      title: 'Submit Application and Sign Lease',
      description: 'Complete the application process. Depending on if your apartment is an <a href="/about#partners" class="text-[#2563EB] font-bold underline">Abode Partner</a>, this will be done through Abode or your apartment\'s leasing company.',
      tips: [
        'Ensure all paperwork is complete',
        'Be prepared for either Abode or leasing company process',
        'Stay in touch for next steps and email you from contact@getabode.co',
        'We\'ll support you through the entire process'
      ],
      icon: '📋'
    },
    {
      title: 'Move-Out',
      description: 'Complete the move-out process and hand over the apartment.',
      tips: [
        'Clean the apartment thoroughly',
        'Move out all your belongings',
        'Drop off keys to the subleasee',
        'Document the final condition'
      ],
      icon: '🚪'
    }
  ];

  const generalTips = [
    {
      title: 'Documentation',
      tips: [
        'Keep records of all communications',
        'Take photos of the apartment condition',
        'Save all signed documents',
        'Document any damages or issues'
      ]
    },
    {
      title: 'Communication',
      tips: [
        'Be clear and professional in all communications',
        'Respond promptly to messages',
        'Document any agreements made',
        'Keep property management informed of the process'
      ]
    },
    {
      title: 'Legal Considerations',
      tips: [
        'Review your current lease terms',
        'Understand subleasing policies',
        'Consult with your leasing office',
        'Ensure all parties sign required documents'
      ]
    },
    {
      title: 'Financial',
      tips: [
        'Discuss payment schedules clearly',
        'Understand all fees and deposits',
        'Keep records of all payments',
        'Be aware of any application fees'
      ]
    }
  ];

  return (
    <div className="min-h-screen bg-gray-50">
      <MainHeader />
      <div className="max-w-4xl mx-auto px-4 py-8">
        <h1 className="text-3xl font-bold text-center mb-4">
          <span>How Subleasing Works</span>
        </h1>
        <div className="bg-blue-50 border border-blue-200 rounded-lg p-4 mb-8">
          <h3 className="font-semibold text-blue-800 mb-2">
            <span>Important Note About Subleasing</span>
          </h3>
          <p className="text-blue-700">
            <span>Subleasing allows you to rent out your apartment for a short period of time. The original lease remains in your name, and you maintain responsibility for the apartment. This is different from re-letting, which transfers the entire lease to a new tenant.</span>
          </p>
        </div>
        
        {/* Tab Switcher */}
        <div className="flex justify-center mb-8">
          <div className="bg-white rounded-lg p-1 shadow-sm">
            <button
              onClick={() => setActiveTab('subleasee')}
              className={`px-6 py-2 rounded-md transition-all ${
                activeTab === 'subleasee'
                  ? 'bg-blue-500 text-white'
                  : 'text-gray-600 hover:bg-gray-100'
              }`}
            >
              <span>Looking for a Sublease</span>
            </button>
            <button
              onClick={() => setActiveTab('subleaser')}
              className={`px-6 py-2 rounded-md transition-all ${
                activeTab === 'subleaser'
                  ? 'bg-blue-500 text-white'
                  : 'text-gray-600 hover:bg-gray-100'
              }`}
            >
              <span>Looking to Sublease</span>
            </button>
          </div>
        </div>

        {/* Important Notice
        <div className="bg-blue-50 border border-blue-200 rounded-lg p-4 mb-8">
          <h3 className="font-semibold text-blue-800 mb-2">Important Notice</h3>
          <p className="text-blue-700">
            All lease agreements and document processing must be handled through the property management company. Abode facilitates the connection between parties and provides tools for communication and documentation, but does not handle the legal aspects of the sublease.
          </p>
        </div> */}

        {/* Steps */}
        <div className="space-y-6 mb-12">
          {(activeTab === 'subleasee' ? subleaseeSteps : subleaserSteps).map((step, index) => (
            <motion.div
              key={step.title}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: index * 0.1 }}
              className="bg-white rounded-lg p-6 shadow-sm"
            >
              <div className="flex items-start gap-4">
                <div className="text-3xl"><span>{step.icon}</span></div>
                <div>
                  <h3 className="text-xl font-semibold mb-2"><span>{step.title}</span></h3>
                  <p 
                    className="text-gray-600 mb-4"
                    dangerouslySetInnerHTML={{ __html: step.description }}
                  />
                  <div className="bg-blue-50 rounded-lg p-4">
                    <h4 className="font-medium text-blue-800 mb-2"><span>Tips:</span></h4>
                    <ul className="list-disc list-inside text-blue-700 space-y-1">
                      {step.tips.map((tip, i) => (
                        <li key={i}><span>{tip}</span></li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </motion.div>
          ))}
        </div>

        {/* General Tips */}
        <div className="bg-white rounded-lg p-6 shadow-sm">
          <h2 className="text-2xl font-semibold mb-6"><span>Important Tips for Both Parties</span></h2>
          <div className="grid md:grid-cols-2 gap-6">
            {generalTips.map((section) => (
              <div key={section.title} className="bg-gray-50 rounded-lg p-4">
                <h3 className="font-semibold text-lg mb-3"><span>{section.title}</span></h3>
                <ul className="list-disc list-inside text-gray-600 space-y-2">
                  {section.tips.map((tip, i) => (
                    <li key={i}><span>{tip}</span></li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </div>

        {/* Call to Action */}
        <div className="mt-12 text-center">
          <h2 className="text-2xl font-semibold mb-4"><span>Ready to Get Started?</span></h2>
          <div className="flex justify-center gap-4">
            <button
              onClick={() => window.location.href = '/sublease-intro'}
              className="px-6 py-3 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors"
            >
              <span>List Your Sublease</span>
            </button>
            <button
              onClick={() => window.location.href = '/listings#subleases'}
              className="px-6 py-3 bg-white text-blue-500 border border-blue-500 rounded-lg hover:bg-blue-50 transition-colors"
            >
              <span>Browse Subleases</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubleaseProcessGuide; 