import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { signInWithEmailAndPassword, sendPasswordResetEmail } from "firebase/auth";
import { auth } from "../../firebase";
import Logo from "../../data/new_abode_logo.svg";

export const LoginPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const message = location.state?.message;
  const returnPath = location.state?.from || location.state?.returnPath || "/listings#subleases";

  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [loginError, setLoginError] = useState("");
  const [loginLoading, setLoginLoading] = useState(false);
  const [resetError, setResetError] = useState("");
  const [resetSuccess, setResetSuccess] = useState("");

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoginLoading(true);
    setLoginError("");

    try {
      await signInWithEmailAndPassword(auth, formData.email, formData.password);
      navigate(returnPath);
    } catch (error) {
      setLoginError("Invalid email or password");
      setLoginLoading(false);
    }
  };

  const handlePasswordReset = async () => {
    if (!formData.email) {
      setResetError("Please enter your email first.");
      return;
    }
    const actionCodeSettings = {
      url: 'https://www.getabode.co/login',
      handleCodeInApp: true,
    };

    try {
      await sendPasswordResetEmail(auth, formData.email, actionCodeSettings);
      setResetSuccess("Password reset email sent! Check your inbox.");
      setResetError("");
    } catch (error) {
      setResetError(error.message);
    }
  };

  const goToSignup = () => {
    navigate('/signup', { 
      state: { 
        from: returnPath,
        message: message || "Create an account to continue",
        returnPath: returnPath
      } 
    });
  };

  return (
    <div className="min-h-screen bg-gray-50 flex flex-col items-center justify-center p-4">
      <div className="text-center mb-8">
        <img src={Logo} alt="Abode Logo" className="h-12 mx-auto mb-4" />
        <h1 className="text-3xl font-bold text-gray-900 mb-2">Welcome to Abode</h1>
        <p className="text-gray-600">
          For Illinois students, by Illinois students
        </p>
      </div>

      <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-md">
        {message && (
          <div className="mb-6 p-4 bg-blue-50 border border-blue-200 rounded-md">
            <p className="text-blue-700 text-sm">{message}</p>
          </div>
        )}

        <form onSubmit={handleSubmit} className="space-y-6">
          <div>
            <label htmlFor="email" className="block text-sm font-medium text-gray-700 mb-1">
              Email
            </label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-logo_blue focus:border-transparent"
              placeholder="youremail@gmail.com"
              required
            />
          </div>

          <div>
            <label htmlFor="password" className="block text-sm font-medium text-gray-700 mb-1">
              Password
            </label>
            <input
              type="password"
              id="password"
              name="password"
              value={formData.password}
              onChange={handleChange}
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-logo_blue focus:border-transparent"
              placeholder="••••••••"
              required
            />
          </div>

          {loginError && (
            <div className="text-red-600 text-sm">{loginError}</div>
          )}

          <div className="flex items-center justify-between">
            <button
              type="button"
              onClick={handlePasswordReset}
              className="text-sm font-medium text-logo_blue hover:text-blue-600"
            >
              Forgot password?
            </button>
          </div>

          <button
            type="submit"
            disabled={loginLoading}
            className={`w-full flex justify-center py-3 px-4 border border-transparent rounded-lg shadow-sm text-sm font-medium text-white bg-logo_blue hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-logo_blue transition-colors ${
              loginLoading ? "opacity-50 cursor-not-allowed" : ""
            }`}
          >
            {loginLoading ? (
              <div className="flex items-center gap-2">
                <i className="fa-solid fa-circle-notch fa-spin"></i>
                Signing in...
              </div>
            ) : (
              "Sign in"
            )}
          </button>
        </form>

        {resetError && (
          <div className="mt-4 text-red-600 text-sm">{resetError}</div>
        )}
        {resetSuccess && (
          <div className="mt-4 text-green-600 text-sm">{resetSuccess}</div>
        )}

<div className="mt-6 text-center">
          <p className="text-sm text-gray-600">
            Don't have an account?{" "}
            <button
              onClick={goToSignup}
              className="text-logo_blue hover:text-blue-600 font-medium"
            >
              Sign up
            </button>
          </p>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
