import React, { useState, useEffect, memo } from "react";
import Slider from "react-slick";
import ImageSkeleton from "../ImageSekeleton";

export const ImageSlider = memo(function ImageSlider({
  images = [],
  onImageClick,
  listingTag = null,
  imageBanner = null,
  priority = false,
}) {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [loadedIndexes, setLoadedIndexes] = useState(1);
  const [isHover, setIsHover] = useState(false);
  const [imagesLoaded, setImagesLoaded] = useState({});
  
  // Track which images have been loaded
  const handleImageLoad = (index) => {
    setImagesLoaded(prev => ({...prev, [index]: true}));
  };

  useEffect(() => {
    if (currentIndex + 1 > loadedIndexes) {
      setLoadedIndexes(currentIndex + 1);
    }
  }, [currentIndex, loadedIndexes]);

  function handleDotClick(index) {
    setCurrentIndex(index);
  }

  const settings = {
    dots: false,
    infinite: true,
    speed: 400,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    lazyLoad: 'progressive',
    nextArrow: <CustomNextArrow show={isHover} />,
    prevArrow: <CustomPrevArrow show={isHover} />,
    beforeChange: (oldIndex, newIndex) => setCurrentIndex(newIndex),
  };

  if (!images || images.length === 0) {
    return (
      <div className="w-full aspect-square bg-gray-200 flex items-center justify-center">
        <ImageSkeleton />
      </div>
    );
  }

  // Optimized rendering for multiple images
  if (images.length > 1) {
    return (
      <div
        className="relative w-full"
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
      >
        <Slider {...settings}>
          {images.map((image, index) => (
            <div key={index} className="w-full aspect-square relative">
              {index <= loadedIndexes ? (
                <button
                  className="w-full h-full object-cover focus:outline-none"
                  onClick={onImageClick}
                  aria-label={`View apartment image ${index + 1}`}
                >
                  {!imagesLoaded[index] && <ImageSkeleton />}
                  <img
                    src={image}
                    alt={`Apartment ${index + 1}`}
                    className={`w-full h-full object-cover ${!imagesLoaded[index] ? 'invisible' : 'visible'}`}
                    loading={index === 0 && priority ? "eager" : "lazy"}
                    onLoad={() => handleImageLoad(index)}
                    // fetchPriority={index === 0 && priority ? "high" : "auto"}
                  />
                </button>
              ) : (
                <ImageSkeleton />
              )}

              {/* Conditionally render the listing tag on the first image */}
              {index === 0 && !imageBanner && listingTag && (
                <div className="absolute bottom-4 right-2 bg-white text-black text-sm py-1 px-2 rounded-lg">
                  {listingTag}
                </div>
              )}
              {index === 0 && imageBanner && (
                <div className="absolute bottom-1 bg-logo_blue text-white w-full text-center mx-auto items-center justify-center">
                  {imageBanner}
                </div>
              )}
            </div>
          ))}
        </Slider>
        <CustomDotNavigation
          totalSlides={images.length}
          currentIndex={currentIndex}
          show={isHover}
          onClick={handleDotClick}
        />
      </div>
    );
  } 
  
  // Optimized rendering for single image
  return (
    <div className="w-full aspect-square relative">
      <button
        onClick={onImageClick}
        className="w-full h-full object-cover rounded-lg"
        aria-label="View apartment details"
      >
        {!imagesLoaded[0] && <ImageSkeleton />}
        <img
          src={images[0]}
          alt="Apartment"
          className={`w-full h-full object-cover rounded-lg ${!imagesLoaded[0] ? 'invisible' : 'visible'}`}
          loading={priority ? "eager" : "lazy"}
          onLoad={() => handleImageLoad(0)}
          // fetchPriority={priority ? "high" : "auto"}
        />

        {/* Conditionally render the listing tag on a single image */}
        {!imageBanner && listingTag && (
          <div className="absolute bottom-4 right-2 bg-white text-black text-sm py-1 px-2 rounded-lg">
            {listingTag}
          </div>
        )}

        {imageBanner && (
          <div className="absolute bottom-1 bg-logo_blue text-white w-full text-center mx-auto items-center justify-center">
            {imageBanner}
          </div>
        )}
      </button>
    </div>
  );
});

const CustomNextArrow = ({ style, onClick, show }) => {
  return show ? (
    <div
      className={`absolute z-10 bg-black bg-opacity-30 hover:bg-opacity-70 text-white top-1/2 -translate-y-1/2 right-1 py-1 px-3 rounded-full cursor-pointer`}
      onClick={onClick}
      style={{ ...style, display: "block" }}
    >
      <i className="fa-solid fa-chevron-right text-sm"></i>
    </div>
  ) : null;
};

const CustomPrevArrow = ({ style, onClick, show }) => {
  return show ? (
    <div
      className={`absolute z-10 bg-black bg-opacity-30 hover:bg-opacity-70 text-white top-1/2 -translate-y-1/2 left-1 py-1 px-3 rounded-full cursor-pointer`}
      onClick={onClick}
      style={{ ...style, display: "block" }}
    >
      <i className="fa-solid fa-chevron-left text-sm"></i>
    </div>
  ) : null;
};

const CustomDotNavigation = ({ totalSlides, currentIndex, onClick, show }) => {
  const dotsToShow = 5;
  const shouldShow = isMobileDevice() || show;

  const dots = [];
  for (let i = 0; i < totalSlides && i < dotsToShow; i++) {
    dots.push(
      <button
        key={i}
        className={`mx-1 p-1 rounded-full ${
          currentIndex === i ? "bg-white" : "bg-white bg-opacity-30"
        }`}
        style={{ width: "8px", height: "8px" }}
        onClick={() => onClick(i)}
      ></button>
    );
  }

  return shouldShow ? (
    <div className="absolute bottom-4 left-0 right-0 flex justify-center items-center">
      <div className="rounded-lg p-1 flex justify-center items-center">
        {dots}
      </div>
    </div>
  ) : null;
};

const isMobileDevice = () => {
  return false;
  // typeof window.orientation !== "undefined" ||
  // navigator.userAgent.indexOf("IEMobile") !== -1
};
