import React, { useState, useEffect } from 'react';
import { doc, updateDoc, Timestamp, getDoc } from 'firebase/firestore';
import { ref, uploadBytes, deleteObject, getDownloadURL } from 'firebase/storage';
import { db, storage } from '../../../firebase/index';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import heic2any from 'heic2any';
import { toast } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

const SubleaseEditor = ({ sublease, onUpdate }) => {
  const navigate = useNavigate();
  const [editingSublease, setEditingSublease] = useState(sublease);
  const [existingImages, setExistingImages] = useState(sublease.images || []);
  const [imageURLs, setImageURLs] = useState([]);
  const [isSaving, setIsSaving] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [hostProfile, setHostProfile] = useState(null);
  const [showContactInfo, setShowContactInfo] = useState(false);

  useEffect(() => {
    const fetchHostProfile = async () => {
      if (sublease.userId) {
        try {
          const hostDoc = await getDoc(doc(db, 'users', sublease.userId));
          if (hostDoc.exists()) {
            setHostProfile(hostDoc.data());
          }
        } catch (error) {
          console.error('Error fetching host profile:', error);
        }
      }
    };
    fetchHostProfile();
  }, [sublease.userId]);

  const handleFieldChange = (field, value) => {
    setEditingSublease(prev => ({
      ...prev,
      [field]: value
    }));
  };

  const sanitizeFileName = (fileName) => {
    return fileName
      .replace(/[^a-zA-Z0-9.-]/g, '_')
      .replace(/_+/g, '_')
      .toLowerCase();
  };

  const handleImagesChange = async (e) => {
    const files = Array.from(e.target.files);
    const totalImages = existingImages.length + files.length;
    
    if (totalImages > 10) {
      alert("You can only have a maximum of 10 images.");
      e.target.value = null;
      return;
    }

    try {
      // Process and convert HEIC images if needed
      const processedFiles = await Promise.all(
        files.map(async (file) => {
          if (file.type === 'image/heic' || file.name.toLowerCase().endsWith('.heic')) {
            const jpegBlob = await heic2any({
              blob: file,
              toType: "image/jpeg",
              quality: 0.8
            });
            const sanitizedName = sanitizeFileName(file.name.replace('.heic', '.jpg'));
            return new File([jpegBlob], sanitizedName, {
              type: 'image/jpeg'
            });
          }
          const sanitizedName = sanitizeFileName(file.name);
          return new File([file], sanitizedName, {
            type: file.type
          });
        })
      );

      // Upload each new image and get its URL
      const uploadPromises = processedFiles.map(async (file) => {
        const imageRef = ref(storage, `sublease_images/${sublease.id}/${file.name}`);
        await uploadBytes(imageRef, file);
        const url = await getDownloadURL(imageRef);
        return { 
          url,
          name: file.name,
          order: existingImages.length + processedFiles.indexOf(file),
          path: `sublease_images/${sublease.id}/${file.name}`
        };
      });

      const newImages = await Promise.all(uploadPromises);
      setExistingImages(prev => [...prev, ...newImages]);
      
      // Update the sublease document with the new images
      const subleaseRef = doc(db, "subleases", sublease.id);
      await updateDoc(subleaseRef, {
        images: [...existingImages, ...newImages],
        updatedAt: Timestamp.now()
      });
      
      e.target.value = null;
    } catch (error) {
      console.error("Error uploading images:", error);
      alert("Error uploading images. Please try again.");
    }
  };

  const handleDeleteImage = async (image) => {
    try {
      // Delete from storage
      const imageRef = ref(storage, image.path);
      await deleteObject(imageRef);

      // Remove from existingImages state
      const updatedImages = existingImages.filter(img => img.path !== image.path);
      
      // Reorder remaining images
      const reorderedImages = updatedImages.map((img, index) => ({
        ...img,
        order: index
      }));
      
      setExistingImages(reorderedImages);

      // Update the sublease document
      const subleaseRef = doc(db, "subleases", sublease.id);
      await updateDoc(subleaseRef, {
        images: reorderedImages,
        updatedAt: Timestamp.now()
      });
    } catch (error) {
      console.error("Error deleting image:", error);
    }
  };

  const moveImage = async (fromIndex, toIndex) => {
    const updatedImages = [...existingImages];
    const [movedImage] = updatedImages.splice(fromIndex, 1);
    updatedImages.splice(toIndex, 0, movedImage);
    
    // Update order for all images
    const reorderedImages = updatedImages.map((img, index) => ({
      ...img,
      order: index
    }));
    
    setExistingImages(reorderedImages);

    // Update the sublease document
    try {
      const subleaseRef = doc(db, "subleases", sublease.id);
      await updateDoc(subleaseRef, {
        images: reorderedImages,
        updatedAt: Timestamp.now()
      });
    } catch (error) {
      console.error("Error updating image order:", error);
    }
  };

  const handleSave = async () => {
    setIsSaving(true);
    setError(null);
    setSuccess(false);

    try {
      const subleaseRef = doc(db, 'subleases', sublease.id);
      await updateDoc(subleaseRef, {
        ...editingSublease,
        images: existingImages.map(img => ({
          url: img.url,
          name: img.name || img.path?.split('/').pop() || '',
          order: img.order || 0,
          path: img.path || `sublease_images/${sublease.id}/${img.name || img.path?.split('/').pop() || ''}`
        })),
        rent: parseFloat(editingSublease.rent),
        bedrooms: parseFloat(editingSublease.bedrooms),
        bathrooms: parseFloat(editingSublease.bathrooms),
        updatedAt: Timestamp.now()
      });
      setSuccess(true);
      
      // Show success toast
      toast.success('Sublease updated successfully! 🎉', {
        duration: 3000,
        position: 'top-center',
        style: {
          background: '#fff',
          color: '#000',
        },
      });
      
      // Call onUpdate callback if provided
      onUpdate && onUpdate();
      
      // Redirect to the sublease detail page after a short delay
      setTimeout(() => {
        navigate(`/sublease/${sublease.id}`);
      }, 1500);
    } catch (error) {
      console.error('Error updating sublease:', error);
      setError('Failed to update sublease. Please try again.');
      
      // Show error toast
      toast.error('Failed to update sublease. Please try again.', {
        duration: 3000,
        position: 'top-center',
        style: {
          background: '#f44336',
          color: '#fff',
        },
      });
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <div className="bg-white rounded-lg shadow p-6">
      <div className="space-y-6">
        {/* Host Information */}
        <div className="border-b pb-4">
          <h3 className="text-lg font-semibold mb-2">Host Information</h3>
          <div className="flex items-center justify-between">
            <div>
              <p className="text-sm text-gray-600">Host ID: {sublease.userId}</p>
              {hostProfile && (
                <p className="text-sm text-gray-600">
                  Name: {hostProfile.firstName} {hostProfile.lastName}
                </p>
              )}
            </div>
            <div className="flex space-x-2">
              <button
                onClick={() => setShowContactInfo(!showContactInfo)}
                className="px-4 py-2 bg-gray-200 text-gray-700 rounded hover:bg-gray-300 transition-colors"
              >
                {showContactInfo ? 'Hide Contact Info' : 'Show Contact Info'}
              </button>
              <button
                onClick={() => navigate(`/profile/${sublease.userId}`)}
                className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition-colors"
              >
                View Profile
              </button>
            </div>
          </div>
          
          {/* Contact Information Dropdown */}
          {showContactInfo && hostProfile && (
            <div className="mt-4 p-4 bg-gray-50 rounded-lg border border-gray-200">
              <h4 className="font-medium mb-2">Contact Information</h4>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div>
                  <p className="text-sm text-gray-600">
                    <span className="font-medium">Email:</span> {hostProfile.email || 'Not provided'}
                  </p>
                  <p className="text-sm text-gray-600">
                    <span className="font-medium">Phone:</span> {hostProfile.phoneNumber || 'Not provided'}
                  </p>
                </div>
                <div>
                  <p className="text-sm text-gray-600">
                    <span className="font-medium">Instagram:</span> {hostProfile.instagramHandle || 'Not provided'}
                  </p>
                  <p className="text-sm text-gray-600">
                    <span className="font-medium">LinkedIn:</span> {hostProfile.linkedinHandle || 'Not provided'}
                  </p>
                </div>
                <div>
                  <p className="text-sm text-gray-600">
                    <span className="font-medium">School:</span> {hostProfile.school || 'Not provided'}
                  </p>
                  <p className="text-sm text-gray-600">
                    <span className="font-medium">Major:</span> {hostProfile.major || 'Not provided'}
                  </p>
                </div>
                <div>
                  <p className="text-sm text-gray-600">
                    <span className="font-medium">Year:</span> {hostProfile.year || 'Not provided'}
                  </p>
                  <p className="text-sm text-gray-600">
                    <span className="font-medium">Preferred Contact:</span> {hostProfile.preferredContact || 'Not specified'}
                  </p>
                </div>
              </div>
            </div>
          )}
        </div>

        {/* Basic Information */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Apartment Name
            </label>
            <input
              type="text"
              value={editingSublease.apartmentName || ''}
              onChange={(e) => handleFieldChange('apartmentName', e.target.value)}
              className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Address
            </label>
            <input
              type="text"
              value={editingSublease.address || ''}
              onChange={(e) => handleFieldChange('address', e.target.value)}
              className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Rent (per month)
            </label>
            <input
              type="number"
              value={editingSublease.rent || ''}
              onChange={(e) => handleFieldChange('rent', e.target.value)}
              className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Status
            </label>
            <select
              value={editingSublease.status || 'active'}
              onChange={(e) => handleFieldChange('status', e.target.value)}
              className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              <option value="active">Active</option>
              <option value="pending">Pending</option>
              <option value="inactive">Inactive</option>
            </select>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Start Date
            </label>
            <DatePicker
              selected={editingSublease.startDate ? new Date(editingSublease.startDate) : null}
              onChange={(date) => handleFieldChange('startDate', date)}
              className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              dateFormat="MM/dd/yyyy"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              End Date
            </label>
            <DatePicker
              selected={editingSublease.endDate ? new Date(editingSublease.endDate) : null}
              onChange={(date) => handleFieldChange('endDate', date)}
              className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              dateFormat="MM/dd/yyyy"
            />
          </div>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Description
          </label>
          <textarea
            value={editingSublease.description || ''}
            onChange={(e) => handleFieldChange('description', e.target.value)}
            rows={4}
            className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>

        {/* Images Section */}
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Current Images (use arrows to reorder)
          </label>
          <div className="flex flex-wrap gap-2">
            {existingImages.map((img, index) => (
              <div key={index} className="relative group">
                <img 
                  src={img.url} 
                  alt={`Sublease ${index}`} 
                  className="w-36 h-36 object-cover rounded shadow-sm"
                />
                <div className="absolute top-0 right-0 flex gap-1 p-1">
                  {index > 0 && (
                    <button
                      type="button"
                      onClick={() => moveImage(index, index - 1)}
                      className="bg-white rounded-full w-6 h-6 flex items-center justify-center shadow hover:bg-gray-100"
                    >
                      ↑
                    </button>
                  )}
                  {index < existingImages.length - 1 && (
                    <button
                      type="button"
                      onClick={() => moveImage(index, index + 1)}
                      className="bg-white rounded-full w-6 h-6 flex items-center justify-center shadow hover:bg-gray-100"
                    >
                      ↓
                    </button>
                  )}
                  <button
                    type="button"
                    onClick={() => handleDeleteImage(img)}
                    className="bg-red-500 text-white rounded-full w-6 h-6 flex items-center justify-center hover:bg-red-600"
                  >
                    ×
                  </button>
                </div>
                <div className="absolute bottom-0 left-0 right-0 bg-black bg-opacity-50 text-white text-xs text-center">
                  {index + 1}
                </div>
              </div>
            ))}
          </div>

          <input
            type="file"
            multiple
            accept="image/*,.heic"
            onChange={handleImagesChange}
            className="mt-4 w-full"
          />
          
          {imageURLs.length > 0 && (
            <div className="mt-4">
              <p className="text-sm font-medium text-gray-700">New Images:</p>
              <div className="flex flex-wrap gap-2 mt-2">
                {imageURLs.map((url, index) => (
                  <div key={index} className="relative">
                    <img 
                      src={url}
                      alt={`New ${index}`}
                      className="w-20 h-20 object-cover rounded"
                    />
                    <div className="absolute bottom-0 left-0 right-0 bg-black bg-opacity-50 text-white text-xs text-center">
                      New
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>

        {/* Save Button */}
        <div className="flex justify-end">
          <button
            onClick={handleSave}
            disabled={isSaving}
            className={`px-6 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 ${
              isSaving ? 'opacity-50 cursor-not-allowed' : ''
            }`}
          >
            {isSaving ? 'Saving...' : 'Save Changes'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default SubleaseEditor; 