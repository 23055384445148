import { useEffect, useRef, useState } from "react";
import Filters from "../components/Filters/Filters";
import SlideInComponent from "../components/SlideInComp";
import { filterApts, filterInitialApts, sortByMaxPrice, sortByMinPrice } from "../backend/utils";
import { sortPremiumApartments, sortByWalkingDistance } from "../backend/utils";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../firebase/index";
import PopupLogin from "../components/PopupLogin";

import {
  fetchUgroupApartments,
  fetchSingleApartments,
  fetchBankierApartments,
  fetchAcademyApartments,
  fetchMHMApartments,
  fetchJSMApartments,
  fetchGSRApartments,
} from "../backend/dynamoAPI";

function useDeepCompareMemoize(value) {
  const ref = useRef();
  if (JSON.stringify(value) !== JSON.stringify(ref.current)) {
    ref.current = value;
  }
  return ref.current;
}

function ListingsPage() {
  const [user, loading, error] = useAuthState(auth);
  const [showModal, setShowModal] = useState(false);
  const [showBetaDisclaimer, setShowBetaDisclaimer] = useState(false);

  const [apartmentData, setApartmentData] = useState([]);
  const [filteredApartments, setFilteredApartments] = useState([]);
  const [dataLoading, setDataLoading] = useState(false); 
  const [dataError, setDataError] = useState(null); 

  const closeModal = () => {
    setShowModal(false);
  };

  useEffect(() => {
    if (!user && !loading) {
      const timer = setTimeout(() => {
        setShowModal(true);
        document.body.style.overflow = "hidden"; 
      }, 3000);
      return () => {
        clearTimeout(timer);
        document.body.style.overflow = "unset";
      };
    }
  }, [user, loading]);

  useEffect(() => {
    if (user && !loading) {
      const hasSeenDisclaimer = localStorage.getItem("hasSeenBetaDisclaimer");
      if (!hasSeenDisclaimer) {
        setShowBetaDisclaimer(true);
      }
    }
  }, [user, loading]);

  const handleDisclaimerClose = () => {
    localStorage.setItem("hasSeenBetaDisclaimer", "true");
    setShowBetaDisclaimer(false);
  };

  // Load filters from localStorage (if they exist)
  const loadSavedFilters = () => {
    const savedFilters = localStorage.getItem("filters");
    if (savedFilters) {
      const parsedFilters = JSON.parse(savedFilters);
      parsedFilters.mapOpen = false; // Always set mapOpen to false
      return parsedFilters;
    }
    return {
      priceRange: [300, 2000],
      bedRange: [0, 4],
      sortRent: "asc",
      sort: "Reccomended",
      amenities: [],
      selectedLocation: "Main Quad",
      distRange: 20,
      pricePerPerson: true,
      hoverAptID: null,
      mapOpen: false, // Ensure map is closed on load
      privateBathroom: false,
      availability: "Fall 2025",
      apartmentType: "Apartments",
      apartmentGroups: ["bankier", "ugroup", "mhm", "jsm", "academy"],
    };
  };

  const [filters, setFilters] = useState(loadSavedFilters);

  // Save filters to localStorage whenever they change
  useEffect(() => {
    localStorage.setItem("filters", JSON.stringify(filters));
  }, [filters]);

  const memoizedFilter = useDeepCompareMemoize({
    priceRange: filters.priceRange,
    bedRange: filters.bedRange,
    selectedLocation: filters.selectedLocation,
    distRange: filters.distRange,
    availability: filters.availability,
    privateBathroom: filters.privateBathroom,
    sort: filters.sort,
    apartmentType: filters.apartmentType,
    apartmentGroups: filters.apartmentGroups,
  });

  const loadApartmentData = async () => {
    setDataLoading(true);
    setApartmentData([]);
    setDataError(null);

    try {
      let init_apartments = [];
      
      // Use Promise.all to parallelize API requests
      if (filters.apartmentType === "Apartments") {
        const data = await fetchSingleApartments();
        init_apartments = sortPremiumApartments(filterInitialApts(data));
      } else if (filters.apartmentType === "Academy") {
        const data = await fetchAcademyApartments();
        init_apartments = sortByMaxPrice(filterInitialApts(data));
      } else if (filters.apartmentType === "Bankier") {
        const data = await fetchBankierApartments();
        init_apartments = sortByMinPrice(filterInitialApts(data));
      } else if (filters.apartmentType === "MHM") {
        const data = await fetchMHMApartments();
        init_apartments = sortByMaxPrice(filterInitialApts(data));
      } else if (filters.apartmentType === "UGroup") {
        const data = await fetchUgroupApartments();
        init_apartments = sortByMaxPrice(filterInitialApts(data));
      } else if (filters.apartmentType === "JSM") {
        const data = await fetchJSMApartments();
        init_apartments = sortByMaxPrice(filterInitialApts(data));
      } 
      else if (filters.apartmentType === "GSR") {
        const data = await fetchGSRApartments();
        init_apartments = sortByMaxPrice(filterInitialApts(data));
      }else {
        setApartmentData([]);
        return;
      }

      setApartmentData(init_apartments);
    } catch (err) {
      console.error("Error loading apartments:", err);
      setDataError(err);
    } finally {
      setDataLoading(false);
    }
  };

  useEffect(() => {
    loadApartmentData();
  }, [filters.apartmentType, filters.apartmentGroups]);

  useEffect(() => {
    let results = filterApts(apartmentData, filters);

    if (filters.sort === "Price") {
      results = sortByMinPrice(results);
    } else if (filters.sort === "Distance") {
      results = sortByWalkingDistance(results);
    }

    setFilteredApartments(results);
  }, [apartmentData, memoizedFilter]);

  return (
    <div>
      <div className="mx-auto">
        <Filters filters={filters} setFilters={setFilters} />
      </div>

      <div className="flex-grow overflow-hidden">
        <SlideInComponent
          apartments={filteredApartments}
          filters={filters}
          setFilters={setFilters}
          loading={dataLoading}
        />
      </div>

      {showModal && !user && <PopupLogin onClose={closeModal} />}
      {/* {false && (
        <BetaDisclaimer user={user} onClose={handleDisclaimerClose} />
      )} */}
    </div>
  );
}

export default ListingsPage;
