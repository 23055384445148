import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../../firebase";
import Logo from "../../data/new_abode_logo.svg";

export const AuthScreen = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const message = location.state?.message;
  const returnPath = location.state?.from || location.state?.returnPath || "/listings";

  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [loginError, setLoginError] = useState("");
  const [loginLoading, setLoginLoading] = useState(false);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoginLoading(true);
    setLoginError("");

    try {
      await signInWithEmailAndPassword(auth, formData.email, formData.password);
      navigate(returnPath);
    } catch (error) {
      setLoginError("Invalid email or password");
      setLoginLoading(false);
    }
  };

  const goToSignup = () => {
    navigate('/signup', { 
      state: { 
        from: returnPath,
        message: message || "Create an account to continue",
        returnPath: returnPath
      } 
    });
  };

  return (
    <div className="min-h-screen bg-gray-50 flex flex-col items-center justify-center p-4">
      <div className="text-center mb-8">
        <img src={Logo} alt="Abode Logo" className="h-12 mx-auto mb-4" />
        <h1 className="text-3xl font-bold text-gray-900 mb-2">Welcome to Abode</h1>
        <p className="text-gray-600">
          For Illinois students, by Illinois students
        </p>
      </div>

      <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-md">
        {message && (
          <div className="mb-6 p-4 bg-blue-50 border border-blue-200 rounded-md">
            <p className="text-blue-700 text-sm">{message}</p>
          </div>
        )}

        <form onSubmit={handleSubmit} className="space-y-6">
          <div>
            <label htmlFor="email" className="block text-sm font-medium text-gray-700 mb-1">
              Email
            </label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-logo_blue focus:border-transparent"
              placeholder="youremail@gmail.com"
              required
            />
          </div>

          <div>
            <label htmlFor="password" className="block text-sm font-medium text-gray-700 mb-1">
              Password
            </label>
            <input
              type="password"
              id="password"
              name="password"
              value={formData.password}
              onChange={handleChange}
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-logo_blue focus:border-transparent"
              placeholder="••••••••"
              required
            />
          </div>

          {loginError && (
            <p className="text-red-500 text-sm">{loginError}</p>
          )}

          <button
            type="submit"
            disabled={loginLoading}
            className="w-full bg-logo_blue text-white py-2 px-4 rounded-md hover:bg-blue-600 transition-colors disabled:opacity-50 disabled:cursor-not-allowed flex items-center justify-center gap-2"
          >
            {loginLoading ? (
              <>
                <i className="fa-solid fa-circle-notch fa-spin"></i>
                Signing in...
              </>
            ) : (
              "Sign In"
            )}
          </button>
        </form>

        <div className="mt-6 text-center">
          <p className="text-sm text-gray-600">
            Don't have an account?{" "}
            <button
              onClick={goToSignup}
              className="text-logo_blue hover:text-blue-600 font-medium"
            >
              Sign up
            </button>
          </p>
        </div>
      </div>

      <div className="mt-8 text-center text-sm text-gray-500">
        <p>Protected by UIUC student verification</p>
      </div>
    </div>
  );
}; 