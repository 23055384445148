import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../../firebase';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../../firebase';
import { subleaseProcessService } from '../../backend/subleaseAPI';
import { sendTourBookingEmail } from '../../backend/emailAPI';
import MainHeader from '../../components/Headers/MainHeader';
import Footer from '../../components/Headers/Footer';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { motion, AnimatePresence } from 'framer-motion';

function BookTour() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [user] = useAuthState(auth);
  const [sublease, setSublease] = useState(null);
  const [hostProfile, setHostProfile] = useState(null);
  const [loading, setLoading] = useState(true);
  const [formData, setFormData] = useState({
    date: '',
    time: '',
    type: 'in-person',
    location: '',
    notes: ''
  });
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedTime, setSelectedTime] = useState(null);
  const [showTimePicker, setShowTimePicker] = useState(false);
  const [isBooking, setIsBooking] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const subleaseDoc = await getDoc(doc(db, 'subleases', id));
        if (subleaseDoc.exists()) {
          const subleaseData = subleaseDoc.data();
          setSublease(subleaseData);
          setFormData(prev => ({
            ...prev,
            location: subleaseData.address || ''
          }));

          const hostDoc = await getDoc(doc(db, 'users', subleaseData.userId));
          if (hostDoc.exists()) {
            setHostProfile(hostDoc.data());
          }
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        setError('Failed to load sublease details');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [id]);

  const handleDateChange = (date) => {
    setSelectedDate(date);
    setFormData(prev => ({
      ...prev,
      date: date.toISOString().split('T')[0]
    }));
    setShowTimePicker(true);
  };

  const handleTimeChange = (time) => {
    setSelectedTime(time);
    setFormData(prev => ({
      ...prev,
      time: time.toLocaleTimeString('en-US', {
        hour: 'numeric',
        minute: '2-digit',
        hour12: true
      })
    }));
  };

  const validateForm = () => {
    if (!selectedDate) {
      setError('Please select a date for the tour');
      return false;
    }
    if (!formData.time) {
      setError('Please select a time for the tour');
      return false;
    }
    if (!formData.location) {
      setError('Location is required');
      return false;
    }
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setIsBooking(true);

    if (!validateForm()) {
      setIsBooking(false);
      return;
    }

    try {
      // Initialize the sublease process if it doesn't exist
      const processStatus = await subleaseProcessService.getProcessStatus(id);
      if (!processStatus) {
        await subleaseProcessService.initializeSubleaseProcess(id, [user.uid, sublease.userId]);
      }

      // Format the date for storage
      const formattedDate = selectedDate.toISOString().split('T')[0];

      // Track the tour booking
      await subleaseProcessService.trackTourBooking(id, {
        ...formData,
        date: formattedDate,
        bookedBy: user.uid,
        status: 'pending'
      });

      // Send email notifications
      await sendTourBookingEmail({
        subleaseId: id,
        participants: [user.uid, sublease.userId],
        tourDetails: {
          ...formData,
          date: formattedDate,
          chatLink: `/messages/${id}`
        }
      });

      setSuccess(true);
      setTimeout(() => {
        // Redirect to the conversation page
        navigate(`/messages/${id}`);
      }, 3000);
    } catch (error) {
      console.error('Error booking tour:', error);
      setError('Failed to book tour. Please try again.');
    } finally {
      setIsBooking(false);
    }
  };

  if (loading) {
    return (
      <div className="min-h-screen bg-gray-50">
        <MainHeader />
        <div className="container max-w-4xl mx-auto px-4 py-8">
          <div className="animate-pulse">
            <div className="h-8 bg-gray-200 rounded w-1/4 mb-4"></div>
            <div className="h-4 bg-gray-200 rounded w-1/2 mb-8"></div>
            <div className="space-y-4">
              <div className="h-12 bg-gray-200 rounded"></div>
              <div className="h-12 bg-gray-200 rounded"></div>
              <div className="h-12 bg-gray-200 rounded"></div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }

  if (!sublease || !hostProfile) {
    return (
      <div className="min-h-screen bg-gray-50">
        <MainHeader />
        <div className="container max-w-4xl mx-auto px-4 py-8">
          <div className="text-center">
            <h1 className="text-2xl font-bold text-gray-800 mb-4">Sublease Not Found</h1>
            <p className="text-gray-600">The sublease you're looking for doesn't exist or has been removed.</p>
          </div>
        </div>
        <Footer />
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50">
      <MainHeader />
      <div className="container max-w-4xl mx-auto px-4 py-8">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="bg-white rounded-lg shadow-sm p-6"
        >
          <h1 className="text-2xl font-bold text-gray-800 mb-2">Book a Tour</h1>
          <p className="text-gray-600 mb-6">
            Schedule a tour for {sublease.apartmentName} with {hostProfile.firstName}
          </p>

          {success ? (
            <motion.div
              initial={{ opacity: 0, scale: 0.9 }}
              animate={{ opacity: 1, scale: 1 }}
              className="text-center py-8"
            >
              <div className="w-16 h-16 mx-auto mb-4 rounded-full bg-green-100 flex items-center justify-center">
                <i className="fa-solid fa-check text-green-500 text-2xl"></i>
              </div>
              <h2 className="text-xl font-semibold mb-2">Tour Booked Successfully!</h2>
              <p className="text-gray-600 mb-4">
                You'll receive a confirmation email shortly. {hostProfile.firstName} will be in touch to confirm the details.
              </p>
              <p className="text-sm text-gray-500">
                Redirecting you back to the listing...
              </p>
            </motion.div>
          ) : (
            <form onSubmit={handleSubmit} className="space-y-6">
              <div className="space-y-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Select Date
                  </label>
                  <DatePicker
                    selected={selectedDate}
                    onChange={handleDateChange}
                    minDate={new Date()}
                    className="w-full p-3 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                    placeholderText="Select a date"
                    dateFormat="MMMM d, yyyy"
                  />
                </div>

                <AnimatePresence>
                  {showTimePicker && (
                    <motion.div
                      initial={{ opacity: 0, height: 0 }}
                      animate={{ opacity: 1, height: 'auto' }}
                      exit={{ opacity: 0, height: 0 }}
                      className="overflow-hidden"
                    >
                      <label className="block text-sm font-medium text-gray-700 mb-2">
                        Select Time
                      </label>
                      <DatePicker
                        selected={selectedTime}
                        onChange={handleTimeChange}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={15}
                        timeCaption="Time"
                        dateFormat="h:mm aa"
                        className="w-full p-3 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                        placeholderText="Select a time"
                      />
                    </motion.div>
                  )}
                </AnimatePresence>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Tour Type
                  </label>
                  <div className="grid grid-cols-2 gap-2">
                    <button
                      type="button"
                      onClick={() => setFormData(prev => ({ ...prev, type: 'in-person' }))}
                      className={`p-3 border rounded-lg text-center transition-colors ${
                        formData.type === 'in-person'
                          ? 'bg-logo_blue text-white border-logo_blue'
                          : 'hover:bg-gray-50'
                      }`}
                    >
                      In-Person Tour
                    </button>
                    <button
                      type="button"
                      onClick={() => setFormData(prev => ({ ...prev, type: 'virtual' }))}
                      className={`p-3 border rounded-lg text-center transition-colors ${
                        formData.type === 'virtual'
                          ? 'bg-logo_blue text-white border-logo_blue'
                          : 'hover:bg-gray-50'
                      }`}
                    >
                      Virtual Tour
                    </button>
                  </div>
                </div>

                {formData.type === 'in-person' && (
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-2">
                      Location
                    </label>
                    <input
                      type="text"
                      value={formData.location}
                      onChange={(e) => setFormData(prev => ({ ...prev, location: e.target.value }))}
                      className="w-full p-3 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                      placeholder="Enter tour location"
                      required
                    />
                  </div>
                )}

                {formData.type === 'virtual' && (
                  <motion.div
                    initial={{ opacity: 0, y: -10 }}
                    animate={{ opacity: 1, y: 0 }}
                    className="p-4 bg-blue-50 rounded-lg mb-4"
                  >
                    <p className="text-sm text-blue-700">
                      For virtual tours, please include the following in your notes below:
                    </p>
                    <ul className="mt-2 text-sm text-blue-600 list-disc list-inside">
                      <li>Video call link (Zoom, Google Meet, etc.)</li>
                      <li>Or your preferred contact number to facetime/video call</li>
                    </ul>
                  </motion.div>
                )}

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Additional Notes
                  </label>
                  <textarea
                    value={formData.notes}
                    onChange={(e) => setFormData(prev => ({ ...prev, notes: e.target.value }))}
                    className="w-full p-3 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                    rows="3"
                    placeholder={
                      formData.type === 'virtual'
                        ? "Include your video call link, contact number, and any specific areas you'd like to see..."
                        : "Any specific requirements or questions for the tour..."
                    }
                  />
                </div>
              </div>

              {error && (
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  className="text-red-500 text-sm p-3 bg-red-50 rounded-lg"
                >
                  {error}
                </motion.div>
              )}

              <div className="flex justify-end space-x-3">
                <button
                  type="button"
                  onClick={() => navigate(`/sublease/${id}`)}
                  className="px-4 py-2 text-gray-600 hover:text-gray-800"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  disabled={isBooking}
                  className="px-4 py-2 bg-logo_blue text-white rounded-lg hover:bg-logo_blue_dark focus:outline-none focus:ring-2 focus:ring-logo_blue focus:ring-offset-2 disabled:opacity-50 disabled:cursor-not-allowed flex items-center justify-center min-w-[100px]"
                >
                  {isBooking ? (
                    <>
                      <svg className="animate-spin -ml-1 mr-2 h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                      </svg>
                      Booking...
                    </>
                  ) : (
                    'Book Tour'
                  )}
                </button>
              </div>
            </form>
          )}
        </motion.div>
      </div>
      <Footer />
    </div>
  );
}

export default BookTour; 