import React, { useState, useEffect, useCallback } from "react";
import { useParams, useNavigate } from "react-router-dom";
import MainHeader from "../../components/Headers/MainHeader";
import ImageGallery from "../../components/ImageGallery";
import { ReactComponent as ChevronLeft } from "../../data/imgs/chevron-left.svg";
import Footer from "../../components/Headers/Footer";
import PhotoModal from "../../components/PhotoModal.js";
import "react-datepicker/dist/react-datepicker.css";
import { doc, getDoc, getDocs, collection, updateDoc, Timestamp } from "firebase/firestore";
import { db } from "../../firebase/index";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../firebase/index";
import { fetchUserData} from '../../backend/dbAPI';
import { chatService } from '../../services/chatService';
import ContactHost from './ContactHost';


const formatDate = (timestamp) => {
  if (!timestamp) return '';
  
  const date = timestamp.toDate ? timestamp.toDate() : new Date(timestamp);
  const now = new Date();
  const diffTime = now - date;
  const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
  
  // Today
  if (diffDays === 0) {
    return 'Listed Today 🚀';
  }
  // Yesterday
  if (diffDays === 1) {
    return 'Listed Yesterday 🚀';
  }
  // This week (within 7 days)
  if (diffDays < 7) {
    return `${diffDays} days ago 🚀`;
  }
  // This month (within 30 days)
  if (diffDays < 30) {
    const weeks = Math.floor(diffDays / 7);
    return `${weeks} ${weeks === 1 ? 'week' : 'weeks'} ago 🚀`;
  }
  
  // More than a month, say a month ago
  return 'A couple months ago ⭐';
};

const SkeletonLoading = () => {
  return (
    <div className="bg-white">
      {/* Header Skeleton */}
      <div className="hidden md:block">
        <div className="h-16 bg-gray-100"></div>
      </div>

      {/* Image Gallery Skeleton with stronger pulse */}
      <div className="animate-[pulse_1.5s_ease-in-out_infinite]">
        <div className="aspect-[16/9] bg-gradient-to-r from-gray-200 to-gray-300 w-full"></div>
        <div className="hidden md:grid grid-cols-4 gap-2 mt-2">
          {[...Array(4)].map((_, i) => (
            <div 
              key={i} 
              className="aspect-square bg-gradient-to-r from-gray-200 to-gray-300 rounded-lg"
              style={{
                animationDelay: `${i * 0.2}s`
              }}
            ></div>
          ))}
        </div>
      </div>

      <div className="container max-w-6xl mx-auto md:px-6">
        <div className="flex flex-col px-6 md:flex-row md:px-0">
          {/* Main Content Skeleton */}
          <div className="w-full pr-0 md:w-2/3 md:pr-12">
            {/* Title Skeleton with gradient */}
            <div className="py-6 animate-[pulse_1.5s_ease-in-out_infinite]">
              <div className="h-8 bg-gradient-to-r from-gray-200 to-gray-300 w-3/4 rounded-lg mb-4"></div>
              <div className="h-6 bg-gradient-to-r from-gray-200 to-gray-300 w-1/2 rounded-lg"></div>
            </div>

            <hr className="my-8" />

            {/* Host Profile Skeleton with staggered animation */}
            <div className="flex items-center animate-[pulse_1.5s_ease-in-out_infinite]" style={{ animationDelay: '0.2s' }}>
              <div className="w-16 h-16 bg-gradient-to-r from-gray-200 to-gray-300 rounded-full"></div>
              <div className="ml-4">
                <div className="h-6 bg-gradient-to-r from-gray-200 to-gray-300 w-48 rounded-lg mb-2"></div>
                <div className="h-4 bg-gradient-to-r from-gray-200 to-gray-300 w-32 rounded-lg"></div>
              </div>
            </div>

            <hr className="my-8" />

            {/* Description Skeleton */}
            <div className="space-y-2">
              <div className="h-4 bg-gray-200 w-full rounded-lg"></div>
              <div className="h-4 bg-gray-200 w-5/6 rounded-lg"></div>
              <div className="h-4 bg-gray-200 w-4/6 rounded-lg"></div>
            </div>

            <hr className="my-8" />

            {/* Details Skeleton */}
            {[...Array(4)].map((_, i) => (
              <div key={i}>
                <div className="h-6 bg-gray-200 w-1/4 rounded-lg mb-4"></div>
                <div className="h-4 bg-gray-200 w-2/3 rounded-lg mb-2"></div>
                <hr className="my-8" />
              </div>
            ))}
          </div>

          {/* Sidebar Skeleton */}
          <div className="hidden md:block w-full md:w-1/3">
            <div className="sticky top-24">
              <div className="bg-white shadow-md border rounded-lg p-6 animate-[pulse_1.5s_ease-in-out_infinite]" style={{ animationDelay: '0.4s' }}>
                <div className="h-8 bg-gradient-to-r from-gray-200 to-gray-300 w-1/2 rounded-lg mb-6"></div>
                <div className="h-20 bg-gradient-to-r from-gray-200 to-gray-300 rounded-lg mb-6"></div>
                <div className="h-12 bg-gradient-to-r from-gray-200 to-gray-300 rounded-lg"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

// Update the LoginPromptModal component
const LoginPromptModal = ({ isOpen, onClose, onLogin }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4">
      <div className="bg-white rounded-lg p-8 max-w-md w-full">
        <div className="text-center mb-6">
          <div className="w-16 h-16 mx-auto mb-4 bg-blue-50 rounded-full flex items-center justify-center">
            <i className="fa-solid fa-shield-halved text-logo_blue text-2xl"></i>
          </div>
          <h2 className="text-2xl font-bold mb-2">Protected Host Identity</h2>
          <p className="text-gray-600 mb-4">
            To protect our community, host details are only visible to verified UIUC students.
          </p>
          <div className="text-sm text-gray-500 bg-gray-50 p-3 rounded-lg mb-6">
            We verify your student status to ensure a safe and trusted environment.
          </div>
        </div>
        <div className="flex flex-col gap-3">
          <button
            onClick={onLogin}
            className="w-full px-6 py-3 bg-logo_blue text-white rounded-lg hover:bg-blue-600 transition-colors flex items-center justify-center gap-2"
          >
            <i className="fa-solid fa-graduation-cap"></i>
            Verify with Student Account
          </button>
          <button
            onClick={onClose}
            className="w-full px-6 py-3 text-gray-600 bg-gray-100 rounded-lg hover:bg-gray-200 transition-colors"
          >
            Continue Browsing
          </button>
        </div>
      </div>
    </div>
  );
};

function SubleaseDetail() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [showAllPhotos, setShowAllPhotos] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [contentReady, setContentReady] = useState(false);
  const [hostProfile, setHostProfile] = useState(null);
  const [sublease, setSublease] = useState(null);
  const [imageUrls, setImageUrls] = useState([]);
  const [user] = useAuthState(auth);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteStep, setDeleteStep] = useState(1);
  const [deleteReason, setDeleteReason] = useState("");
  const [isDeleting, setIsDeleting] = useState(false);
  const [deleteError, setDeleteError] = useState("");
  const [deleteSuccess, setDeleteSuccess] = useState(false);

  const [userProfile, setUserProfile] = useState({
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    instagramHandle: "",
    linkedinHandle: "",
    preferredContact: "",
    profilePic: "",
    description: ""
  });
  
  const [isOwner, setIsOwner] = useState(false);
  const [showContactModal, setShowContactModal] = useState(false);
  const [appLoaded, setAppLoaded] = useState(false);
  const [showLoginPrompt, setShowLoginPrompt] = useState(false);

  useEffect(() => {
    if (user && sublease) {
      setIsOwner(user.uid === sublease.userId);
    } else {
      setIsOwner(false);
    }
  }, [user, sublease]);

  useEffect(() => {
    const getUserData = async () => {
      if (!user) {
        setUserProfile({
          firstName: "",
          lastName: "",
          phone: "",
          email: "",
          instagramHandle: "",
          linkedinHandle: "",
          preferredContact: "",
          profilePic: null,
          description: "",
        });
        return;
      }

      try {
        const fetchedUserData = await fetchUserData(user.uid);
        if (fetchedUserData) {
          setUserProfile({
            firstName: fetchedUserData.firstName || '',
            lastName: fetchedUserData.lastName || '',
            phone: fetchedUserData.phone || '',
            email: fetchedUserData.email || '',
            instagramHandle: fetchedUserData.instagramHandle || '',
            linkedinHandle: fetchedUserData.linkedinHandle || '',
            preferredContact: fetchedUserData.preferredContact || '',
            profilePic: fetchedUserData.profilePicture || null,
            description: fetchedUserData.description || '',
          });
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };
    getUserData();
  }, [user]);

  // Combine all data fetching in one useEffect
  useEffect(() => {
    const loadAllData = async () => {
      setIsLoading(true);
      try {
        // Fetch sublease data
        const subleaseDocRef = doc(db, "subleases", id);
        const subleaseDocSnap = await getDoc(subleaseDocRef);
        
        if (!subleaseDocSnap.exists()) {
          console.error("Sublease not found");
          navigate("/not-found");
          return;
        }

        const subleaseData = {
          id: subleaseDocSnap.id,
          ...subleaseDocSnap.data(),
        };

        // Only proceed if the sublease is active/visible
        if (!subleaseData.showOnWebsite) {
          console.error("Sublease is not available");
          navigate("/not-found");
          return;
        }

        try {
          // Fetch roommates
          const roommatesCollectionRef = collection(subleaseDocRef, "roommates");
          const roommatesCollectionSnap = await getDocs(roommatesCollectionRef);
          const roommates = roommatesCollectionSnap.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          subleaseData.roommates = roommates;
        } catch (roommatesError) {
          // If we can't load roommates, just set an empty array
          console.warn("Could not load roommates:", roommatesError);
          subleaseData.roommates = [];
        }

        setSublease(subleaseData);

        // Fetch host profile
        if (subleaseData.userId) {
          try {
            const hostDocRef = doc(db, "users", subleaseData.userId);
            const hostDocSnap = await getDoc(hostDocRef);
            if (hostDocSnap.exists()) {
              const hostData = hostDocSnap.data();
              setHostProfile({
                userId: subleaseData.userId,
                firstName: hostData.firstName || 'Anonymous',
                lastName: hostData.lastName || '',
                pfpImg: hostData.profilePicture || null,
                schoolYear: hostData.year || '',
                major: hostData.major || '',
                school: "UIUC",
                contact: {
                  email: hostData.email || '',
                  phoneNumber: subleaseData.phoneNumber || '',
                  instagramHandle: hostData.instagramHandle || '',
                },
              });
            } else {
              // Set default host profile if host data doesn't exist
              setHostProfile({
                userId: subleaseData.userId,
                firstName: 'Anonymous',
                lastName: '',
                pfpImg: null,
                schoolYear: '',
                major: '',
                school: "UIUC",
                contact: {
                  email: '',
                  phoneNumber: '',
                  instagramHandle: '',
                },
              });
            }
          } catch (hostError) {
            console.warn("Could not load host profile:", hostError);
            // Set default host profile on error
            setHostProfile({
              userId: subleaseData.userId,
              firstName: 'Anonymous',
              lastName: '',
              pfpImg: null,
              schoolYear: '',
              major: '',
              school: "UIUC",
              contact: {
                email: '',
                phoneNumber: '',
                instagramHandle: '',
              },
            });
          }
        }

        // Handle images
        if (subleaseData.images && subleaseData.images.length > 0) {
          try {
            const sortedImages = [...subleaseData.images].sort((a, b) => a.order - b.order);
            const orderedUrls = sortedImages.map(img => img.url);
            
            await Promise.all(
              orderedUrls.map(url => {
                return new Promise((resolve) => {
                  const img = new Image();
                  img.src = url;
                  img.onload = resolve;
                  img.onerror = resolve; // Resolve even on error to prevent hanging
                });
              })
            );
            
            setImageUrls(orderedUrls);
          } catch (imageError) {
            console.warn("Could not load images:", imageError);
            setImageUrls([]);
          }
        } else {
          setImageUrls([]);
        }

      } catch (error) {
        console.error("Error loading data:", error);
        if (error.code === 'permission-denied') {
          navigate("/unauthorized");
        } else {
          navigate("/not-found");
        }
      } finally {
        setIsLoading(false);
        // Add a small delay before showing content to ensure smooth transition
        setTimeout(() => setContentReady(true), 100);
      }
    };

    loadAllData();
  }, [id, navigate]);

  // Add this useEffect to handle app loading state
  useEffect(() => {
    // Small delay to ensure AppContext has loaded
    const timer = setTimeout(() => {
      setAppLoaded(true);
    }, 500); // Adjust this value if needed

    return () => clearTimeout(timer);
  }, []);

  // Update the loading check
  if (!appLoaded || isLoading || !contentReady || !sublease || !hostProfile) {
    return (
      <div className="transition-opacity duration-300 ease-in-out">
        <SkeletonLoading />
      </div>
    );
  }

  const isMobile = () => {
    return /Mobi|Android/i.test(navigator.userAgent);
  };

  const toggleShowAllPhotos = () => {
    setShowAllPhotos(!showAllPhotos);
  };
  
  const handleShareClick = async () => {
    const shareUrl = `${window.location.origin}/sublease/${id}`;
    const shareData = {
      title: sublease.apartmentName,
      text: `Check out this apartment at ${sublease.apartmentName}`,
      url: shareUrl
    };

    try {
      if (navigator.share) {
        // Use Web Share API on mobile devices
        await navigator.share(shareData);
      } else {
        // Fallback for desktop
        await navigator.clipboard.writeText(shareUrl);
        alert('Link copied to clipboard!');
      }
    } catch (error) {
      console.error('Error sharing:', error);
      // Fallback to clipboard if Web Share API fails
      try {
        await navigator.clipboard.writeText(shareUrl);
        alert('Link copied to clipboard!');
      } catch (clipboardError) {
        console.error('Error copying to clipboard:', clipboardError);
        alert('Failed to share. Please try again.');
      }
    }
  };

  const handleSaveClick = () => {
    // Favorites functionality temporarily disabled
    // console.log(`${apartment.id} Save clicked`);
  };

  const navigateBack = () => {
    navigate(-1);
  };

  if (!sublease || !hostProfile || isLoading) {
    return <SkeletonLoading />;
  }

  const handleEdit = () => {
    navigate(`/sublease/edit/${id}`);
  };

  const handleDelete = async () => {
    if (!deleteReason) {
      setDeleteError("Please provide a reason for deletion");
      return;
    }

    setIsDeleting(true);
    setDeleteError("");

    try {
      const subleaseRef = doc(db, "subleases", id);
      await updateDoc(subleaseRef, {
        showOnWebsite: false,
        status: "deleted",
        deletedAt: Timestamp.now(),
        deleteReason: deleteReason
      });
      
      setDeleteSuccess(true);
      setDeleteStep(3);
      setTimeout(() => {
        navigate("/listings");
      }, 3000);
    } catch (error) {
      console.error("Error deleting sublease:", error);
      setDeleteError("Failed to delete sublease. Please try again.");
    } finally {
      setIsDeleting(false);
    }
  };

  const resetDeleteModal = () => {
    setShowDeleteModal(false);
    setDeleteStep(1);
    setDeleteError("");
    setDeleteSuccess(false);
    setDeleteReason("");
  };

  const Header = () => {
    return (
      <>
        <div className="hidden md:block">
          <MainHeader />
        </div>
        <div>
          <div className="flex items-center justify-between px-6 py-4 bg-white md:hidden">
            <ChevronLeft
              className="w-6 h-6 cursor-pointer"
              onClick={navigateBack}
            />
            <div className="flex gap-x-8 text-gray-700">
              {isOwner && (
                <>
                  <button onClick={handleEdit}>
                    <i className="fa-solid fa-edit fa-sm"></i>
                  </button>
                  <button onClick={() => setShowDeleteModal(true)}>
                    <i className="fa-solid fa-trash fa-sm text-red-500"></i>
                  </button>
                </>
              )}
              <button onClick={handleShareClick}>
                <i className="fa-solid fa-arrow-up-from-bracket fa-sm"></i>
              </button>
              {/* <button onClick={handleSaveClick}>
                <i className="fa-regular fa-heart fa-sm"></i>
              </button> */}
            </div>
          </div>
        </div>
      </>
    );
  };

  const Title = ({ title }) => {
    return (
      <div className="flex justify-between items-center pb-4 pt-6 hidden md:flex">
        <div className="flex items-center gap-4">
          <h1 className="text-2xl font-semibold">{title}</h1>
          {isOwner && (
            <button 
              onClick={() => setShowDeleteModal(true)}
              className="text-gray-400 hover:text-red-500 transition-colors text-sm flex items-center gap-1 px-3 py-1 rounded-md hover:bg-red-50"
            >
              <i className="fa-regular fa-trash-can fa-sm"></i>
              <span>Remove</span>
            </button>
          )}
        </div>
        <div className="flex gap-x-8 text-gray-700">
          {isOwner && (
            <button 
              onClick={handleEdit} 
              className="flex items-center bg-logo_blue text-white px-4 py-2 rounded-lg"
            >
              <i className="fa-solid fa-edit fa-sm mr-2"></i>
              <p className="text-sm font-semibold">Edit Listing</p>
            </button>
          )}
          <button onClick={handleShareClick} className="flex items-center">
            <i className="fa-solid fa-arrow-up-from-bracket fa-sm"></i>
            <p className="text-sm font-semibold ml-1">Share</p>
          </button>
          {/* <button onClick={handleSaveClick} className="flex items-center">
            <i className="fa-regular fa-heart fa-sm"></i>
            <p className="text-sm font-medium ml-1">Save</p>
          </button> */}
        </div>
      </div>
    );
  };

  const handleChat = async () => {
    if (!user) {
      setShowLoginPrompt(true);
      return;
    }

    try {
      const conversation = await chatService.startSubleaseChat(
        id,
        [user.uid, sublease.userId],
        sublease.userId
      );
      navigate(`/messages/${conversation.id}`);
    } catch (error) {
      console.error('Error starting conversation:', error);
    }
  };

  const handleContactHost = () => {
    if (!user) {
      setShowLoginPrompt(true);
      return;
    }
    setShowContactModal(true);
  };

  const handleLogin = () => {
    navigate('/login', { 
      state: { 
        from: `/sublease/${id}`,
        message: "Please log in or create an account to contact the host.",
        returnPath: `/sublease/${id}`
      } 
    });
  };

  return (
    <div className="bg-white relative transition-opacity duration-300 ease-in-out">
      <Header />
      <div className="container max-w-6xl mx-auto md:px-6">
        <Title title={sublease.apartmentName} />
        <ImageGallery images={imageUrls} onClick={toggleShowAllPhotos} />
        <div className="flex flex-col px-6 md:flex-row md:px-0">
          <div className="w-full pr-0 md:w-2/3 md:pr-12">
            <SubHeader
              name={sublease.apartmentName}
              subHeader={
                hostProfile.firstName + "'s Room at " + sublease.apartmentName
              }
              sublease={sublease}
            />
            <Divider />
            <HostProfile 
              user={hostProfile} 
              userProfile={userProfile} 
              onConnectWithHost={handleContactHost}
              isLoggedIn={!!user}
            />
            
            <Description
              description={sublease.description}
              toggleShowAllPhotos={toggleShowAllPhotos}
            />
            <Divider />
            <Availability start={sublease.startDate} end={sublease.endDate} />
            <Divider />
            <GenderRequirement genderRequirement={sublease.genderRequirement} />
            <Divider />
            {sublease.roommates && Array.isArray(sublease.roommates) && (
              <Roommates roommates={sublease.roommates} />
            )}
            <Divider />
            <RentAndFees rent={sublease.rent} feeDesc={sublease.feeInfo} />
            {/* <Divider /> */}
            
            <Divider />
            <Location address={sublease.address} />
          </div>
          <div className="hidden md:block w-full md:w-1/3 rounded-lg">
            <h1 className="text-lg font-semibold py-4 pt-6 text-end">
              92 Viewed 🔥
            </h1>
            <aside className="self-start sticky top-24 mt-0 col-span-1">
              {!isMobile() && (
                <ReservationSummary
                  hostProfile={hostProfile}
                  userProfile={userProfile}
                  communityLink={sublease.websiteLink}
                  rent={sublease.rent}
                  start={sublease.startDate}
                  end={sublease.endDate}
                  onConnectWithHost={handleContactHost}
                  isLoggedIn={!!user}
                />
              )}
            </aside>
          </div>
          <div className="fixed bottom-0 left-0 w-full bg-white text-black p-4 md:hidden border-t border-gray-300">
            <div className="flex w-full justify-between">
              <div></div>
              <button 
                className="bg-logo_blue text-white text-sm font-semibold py-2 px-8 rounded-md"
                onClick={handleContactHost}
              >
                Connect with {hostProfile.firstName}
              </button>
            </div>
          </div>
        </div>
        <Divider />
      </div>
      <PhotoModal
        showModal={showAllPhotos}
        toggleModal={toggleShowAllPhotos}
        images={imageUrls}
      />
      <Footer />

      {/* Contact Host Modal */}
      {showContactModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4">
          <div className="bg-white rounded-lg w-full max-w-7xl max-h-[90vh] overflow-y-auto relative">
            <button 
              onClick={() => setShowContactModal(false)}
              className="absolute top-4 right-4 text-gray-500 hover:text-gray-700 z-10"
            >
              <i className="fa-solid fa-times fa-lg"></i>
            </button>
            <ContactHost 
              sublease={{
                ...sublease,
                hostProfile: {
                  ...hostProfile,
                  contact: {
                    email: hostProfile.contact.email,
                    instagramHandle: hostProfile.contact.instagramHandle,
                    linkedinHandle: hostProfile.contact.linkedinHandle,
                    wechatHandle: hostProfile.contact.wechatHandle
                  }
                }
              }}
              onClose={() => setShowContactModal(false)}
            />
          </div>
        </div>
      )}

      {/* Login Prompt Modal */}
      <LoginPromptModal 
        isOpen={showLoginPrompt}
        onClose={() => setShowLoginPrompt(false)}
        onLogin={handleLogin}
      />

      {/* Delete Flow Modal */}
      {showDeleteModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4">
          <div className="bg-white rounded-xl w-full max-w-md p-8 relative">
            <button 
              onClick={resetDeleteModal}
              className="absolute top-4 right-4 text-gray-500 hover:text-gray-700"
            >
              <i className="fa-solid fa-times fa-lg"></i>
            </button>

            {/* Step 1: Success Check */}
            {deleteStep === 1 && (
              <div className="text-center">
                <div className="w-20 h-20 mx-auto mb-6 rounded-full bg-logo_blue bg-opacity-10 flex items-center justify-center">
                  <i className="fa-solid fa-house-circle-check text-logo_blue text-3xl"></i>
                </div>
                <h2 className="text-2xl font-bold mb-4">Successfully Subleased?</h2>
                <p className="text-gray-600 mb-8">
                  Let us know if you found a sublessee through Abode!
                </p>
                <div className="flex flex-col gap-3">
                  <button
                    onClick={() => {
                      setDeleteReason("Successfully subleased through Abode!");
                      handleDelete();
                    }}
                    className="w-full px-6 py-3 bg-logo_blue text-white rounded-lg hover:bg-blue-600 transition-colors flex items-center justify-center gap-2"
                  >
                    <i className="fa-solid fa-check"></i>
                    Yes, Found a Sublessee!
                  </button>
                  <button
                    onClick={() => setDeleteStep(2)}
                    className="w-full px-6 py-3 text-gray-600 bg-gray-100 rounded-lg hover:bg-gray-200 transition-colors"
                  >
                    No, Other Reason
                  </button>
                  <button
                    onClick={resetDeleteModal}
                    className="text-gray-500 hover:text-gray-700 text-sm mt-2"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            )}

            {/* Step 2: Other Reason Collection */}
            {deleteStep === 2 && (
              <div>
                <div className="w-16 h-16 mx-auto mb-4 rounded-full bg-gray-100 flex items-center justify-center">
                  <i className="fa-solid fa-clipboard-list text-gray-500 text-2xl"></i>
                </div>
                <h2 className="text-2xl font-bold mb-4 text-center">Why Remove Listing?</h2>
                <p className="text-gray-600 mb-6 text-center">
                  Help us improve by sharing your reason.
                </p>
                
                <div className="mb-6">
                  <textarea
                    value={deleteReason}
                    onChange={(e) => setDeleteReason(e.target.value)}
                    className="w-full px-4 py-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-logo_blue focus:border-transparent"
                    rows="4"
                    placeholder="Please share your reason..."
                  />
                  {deleteError && (
                    <p className="text-red-500 text-sm mt-2">{deleteError}</p>
                  )}
                </div>

                <div className="flex flex-col gap-3">
                  <button
                    onClick={handleDelete}
                    className="w-full px-6 py-3 bg-logo_blue text-white rounded-lg hover:bg-blue-600 transition-colors disabled:opacity-50 flex items-center justify-center gap-2"
                    disabled={isDeleting}
                  >
                    {isDeleting ? (
                      <>
                        <i className="fa-solid fa-circle-notch fa-spin"></i>
                        Processing...
                      </>
                    ) : (
                      "Submit & Remove"
                    )}
                  </button>
                  <button
                    onClick={() => setDeleteStep(1)}
                    className="w-full px-6 py-3 text-gray-600 bg-gray-100 rounded-lg hover:bg-gray-200 transition-colors"
                    disabled={isDeleting}
                  >
                    Back
                  </button>
                </div>
              </div>
            )}

            {/* Step 3: Success Celebration */}
            {deleteStep === 3 && (
              <div className="text-center">
                <div className="w-24 h-24 mx-auto mb-6 relative">
                  <div className="absolute inset-0 bg-logo_blue bg-opacity-20 rounded-full animate-ping"></div>
                  <div className="relative w-full h-full rounded-full bg-logo_blue bg-opacity-10 flex items-center justify-center">
                    {deleteReason.includes("Successfully subleased") ? (
                      <i className="fa-solid fa-house-circle-check text-logo_blue text-4xl"></i>
                    ) : (
                      <i className="fa-solid fa-check text-logo_blue text-4xl"></i>
                    )}
                  </div>
                </div>
                <h2 className="text-2xl font-bold mb-2">
                  {deleteReason.includes("Successfully subleased") ? 
                    "Congratulations! 🎉" : 
                    "Successfully Removed"}
                </h2>
                <p className="text-gray-600 mb-4">
                  {deleteReason.includes("Successfully subleased") ?
                    "We're glad we could help you find a sublessee!" :
                    "Thank you for your feedback. We appreciate your time with us!"}
                </p>
                <div className="text-sm text-gray-500">
                  Redirecting you in a moment...
                </div>
                <div className="mt-6 flex justify-center">
                  <div className="w-full bg-gray-200 rounded-full h-1.5">
                    <div className="bg-logo_blue h-1.5 rounded-full transition-all duration-300"
                         style={{ width: '100%', animation: 'progress 3s linear' }}>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      )}

      <style>{`
        @keyframes progress {
          from { width: 0; }
          to { width: 100%; }
        }
      `}</style>
    </div>
  );
}

const RentAndFees = ({ rent, feeDesc }) => {
  return (
    <div className="">
      <div className="">
        <h1 className="text-xl font-semibold pb-2">Rent</h1>
        <span className="text-md font-medium text-black">${rent}</span>
        <span className="text-sm font-medium ml-1">/month</span>
      </div>
      {feeDesc && (
        <div className="mt-4">
          <h1 className="text-xl font-semibold pb-2">Fees</h1>
          <span className="text-md font-medium text-black">{feeDesc}</span>
        </div>
      )}
    </div>
  );
};

const ReservationSummary = ({
  communityLink,
  hostProfile,
  userProfile,
  rent,
  start,
  end,
  onConnectWithHost,
  isLoggedIn
}) => {
  const handleCommunityLinkClick = () => {
    if (communityLink) {
      window.open(communityLink, "_blank");
    }
  };

  return (
    <div className="max-w-sm p-6 bg-white shadow-md border rounded-lg">
      <div className="text-xl font-semibold pb-4">
        <span className="text-black">${rent}</span>
        <span className="text-sm font-regular ml-1">month</span>
      </div>
      <div className="mt-4">
        <DateRangePicker start={start} end={end} />
        <button
          onClick={onConnectWithHost}
          className="w-full bg-gradient-blue-r text-white py-4 rounded-lg font-semibold mt-8 flex items-center justify-center gap-2"
        >
          {isLoggedIn ? (
            <>
              <i className="fa-solid fa-message"></i>
              Connect with Host
            </>
          ) : (
            <>
              <i className="fa-solid fa-lock"></i>
              Connect with Host
            </>
          )}
        </button>
{/* 
        {isLoggedIn && (
          <div className="mt-4 space-y-3">
            <button
              onClick={() => navigate(`/sublease/${id}/book-tour`)}
              className="w-full bg-white text-logo_blue border border-logo_blue py-3 rounded-lg font-semibold flex items-center justify-center gap-2 hover:bg-blue-50 transition-colors"
            >
              <i className="fa-solid fa-calendar-check"></i>
              Book a Tour
            </button>
            <button
              onClick={() => navigate(`/sublease/${id}/terms`)}
              className="w-full bg-white text-logo_blue border border-logo_blue py-3 rounded-lg font-semibold flex items-center justify-center gap-2 hover:bg-blue-50 transition-colors"
            >
              <i className="fa-solid fa-file-contract"></i>
              View Term Agreement
            </button>
          </div>
        )} */}

        {communityLink && (
          <div className="text-center mt-2">
            <button
              className="text-sm underline text-gray-500"
              onClick={handleCommunityLinkClick}
            >
              Visit Community Website
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

const DateRangePicker = ({ start, end }) => {
  return (
    <div className="w-full">
      <div className="border border-gray-300 rounded-lg flex">
        <div className="p-3 hover:bg-gray-50 cursor-pointer flex-1 border-r">
          <div className="text-xs font-bold uppercase text-gray-800">From</div>
          <div className="text-sm mt-1 text-gray-700">{start}</div>
        </div>
        <div className="p-3 hover:bg-gray-50 cursor-pointer flex-1">
          <div className="text-xs font-bold uppercase text-gray-800">To</div>
          <div className="text-sm mt-1 text-gray-700">{end}</div>
        </div>
      </div>
    </div>
  );
};

const Divider = () => {
  return <hr className="my-8" />;
};

const Location = ({ address }) => {
  return (
    <div className="">
      <h2 className="text-xl font-semibold mb-4">Location</h2>
      <div className="flex items-center gap-x-4">
        <i className="fa-solid fa-map-marker-alt fa-lg text-logo_blue"></i>
        <div>
          <div className="font-medium">{address}</div>
        </div>
      </div>
    </div>
  );
};

const Roommates = ({ roommates }) => {
  if (!roommates) {
    return <div>No roommates found.</div>;
  }

  return (
    <div className="">
      <h2 className="text-xl font-semibold mb-4">
        Your roommates ({roommates.length})
      </h2>
      {roommates.map((roommate, index) => (
        <div key={index} className="flex items-center mb-4">
          <div className="w-12 h-12 flex justify-center items-center rounded-full mr-2">
            <i className="fa-solid fa-user fa-lg"></i>
          </div>
          <div>
            <div className="font-semibold">Roommate {index + 1}</div>
            <div className="text-gray-600">
              {roommate.gender} • {roommate.age} years old •{" "}
              {roommate.occupation}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

const HostProfile = ({ user, userProfile, onConnectWithHost, isLoggedIn }) => {
  const majorYearFormat = `${user.major} ${user.schoolYear} at ${user.school}`;
  const nameFormat = isLoggedIn ? 
    `${user.firstName}'s place` : 
    "UIUC Student's place";

  return (
    <div className="items-center justify-between">
      <div className="flex items-center">
        <div className="relative">
          {!isLoggedIn && (
            <div className="absolute inset-0 bg-gray-200 rounded-full flex items-center justify-center">
              <i className="fa-solid fa-lock text-gray-400"></i>
            </div>
          )}
          <img
            src={isLoggedIn ? (user.pfpImg || "https://cdn-icons-png.flaticon.com/512/149/149071.png") : "https://cdn-icons-png.flaticon.com/512/149/149071.png"}
            alt="profile"
            className={`w-16 h-16 rounded-full object-cover ${!isLoggedIn && 'opacity-0'}`}
          />
        </div>
        <div className="ml-4">
          <h2 className="text-xl font-semibold">{nameFormat}</h2>
          <h2 className="text-sm text-gray-500">
            {isLoggedIn ? majorYearFormat : "Identity Protected • Verified Student"}
          </h2>
          <div className="flex space-x-2 mt-2 text-xs">
            <div className="flex items-center p-1 bg-black rounded-lg text-white px-2">
              <i className="fa fa-shield-halved mr-2"></i>
              Verified UIUC Student
            </div>
          </div>
        </div>
      </div>
      <div className="pt-8">
        <button
          className="px-4 py-3 rounded-lg text-white text-md font-medium bg-gradient-blue-r flex items-center gap-2"
          onClick={onConnectWithHost}
        >
          {isLoggedIn ? (
            <>
              <i className="fa-solid fa-message"></i>
              Contact {user.firstName}
            </>
          ) : (
            <>
              <i className="fa-solid fa-lock"></i>
              Verify to Contact Host
            </>
          )}
        </button>
      </div>
    </div>
  );
};

const Availability = ({ start, end }) => {
  return (
    <div>
      <h2 className="text-xl font-semibold mb-4">Availability</h2>
      <div className="flex items-center space-x-8">
        <i className="fa fa-calendar text-xl"></i>
        <div className="flex items-center space-x-8">
          <div>
            <h2 className="text-md font-semibold pb-1">Available From</h2>
            <h2 className="text-md font-medium text-gray-500">{start}</h2>
          </div>
          <div>
            <h2 className="text-md font-semibold pb-1">Available To</h2>
            <h2 className="text-md font-medium text-gray-500">{end}</h2>
          </div>
        </div>
      </div>
    </div>
  );
};

const SubHeader = ({ name, subHeader, sublease }) => {
  const floorPlan = sublease.configuration;
  const floorPlanNameFormat = `Floorplan: ${floorPlan}`;
  const bedBathFormat = `${sublease.bedrooms} Bed • ${sublease.bathrooms} Bath`;

  const getTagColor = (timestamp) => {
    if (!timestamp) return 'bg-gray-100';
    
    const date = timestamp.toDate ? timestamp.toDate() : new Date(timestamp);
    const now = new Date();
    const diffDays = Math.floor((now - date) / (1000 * 60 * 60 * 24));
    
    if (diffDays === 0) return 'bg-green-50 text-green-700'; // Today
    if (diffDays < 7) return 'bg-blue-50 text-blue-700'; // This week
    return 'bg-gray-50 text-gray-600'; // Older
  };

  return (
    <>
      {/* SubHeader for small screens */}
      <div className="container pt-4 md:hidden">
        <div className="flex items-center justify-between mb-2">
          <h1 className="text-2xl font-semibold">{name}</h1>
          {sublease.updatedAt && (
            <span className={`text-sm font-medium px-3 py-1 rounded-full ${getTagColor(sublease.updatedAt)}`}>
              {formatDate(sublease.updatedAt)}
            </span>
          )}
        </div>
        <h1 className="text-lg font-medium">{subHeader}</h1>
        <div className="text-md font-regular pt-1">{floorPlanNameFormat}</div>
        <div className="text-md font-regular">{bedBathFormat}</div>
      </div>

      {/* SubHeader for larger screens */}
      <div className="container pt-6 hidden md:block">
        <div className="flex items-center justify-between">
          <h1 className="text-xl font-semibold">{subHeader}</h1>
          {sublease.updatedAt && (
            <span className={`text-sm font-medium px-3 py-1 rounded-full ${getTagColor(sublease.updatedAt)}`}>
              {formatDate(sublease.updatedAt)}
            </span>
          )}
        </div>
        <div className="text-gray-600 pt-1">
          <div className="text-md font-medium">{floorPlanNameFormat}</div>
          <div className="text-md font-medium">{bedBathFormat}</div>
        </div>
      </div>
    </>
  );
};

const Description = ({ description }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleDescription = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div className="container">
      <Divider />
      <div className="mb-4">
        <p className={`overflow-hidden ${isExpanded ? "" : "line-clamp-3"}`}>
          {description}
        </p>
      </div>
      <button onClick={toggleDescription} className="font-medium underline">
        {isExpanded ? "Show Less" : "Show More >"}
      </button>
    </div>
  );
};

const GenderRequirement = ({ genderRequirement }) => {
  const allGenderRequirements = [
    { label: "Male", icon: "♂" },
    { label: "Female", icon: "♀" },
    { label: "Others", icon: "⚥" },
  ];

  const renderGenderRequirements = () => {
    if (genderRequirement === "Any") {
      return allGenderRequirements;
    }
    return allGenderRequirements.filter(
      (option) => option.label === genderRequirement
    );
  };

  const requirements = renderGenderRequirements();
  
  return (
    <div className="">
      <h2 className="text-xl font-semibold mb-4">Gender requirement: </h2>
      <div className="flex space-x-4">
        {requirements.map((req, index) => (
          <p key={index} className="flex text-lg font-medium items-center space-x-2 border border-gray-300 rounded-md px-4 py-2 hover:bg-gray-100">
            <span>{req.label}</span>
            <span>{req.icon}</span>
          </p>
        ))}
      </div>
    </div>
  );
};

const ChatButton = ({ subleaseId, hostId }) => {
  const navigate = useNavigate();

  const handleChat = async () => {
    if (!auth.currentUser) {
      navigate('/login');
      return;
    }

    try {
      const conversation = await chatService.getOrCreateConversation(
        subleaseId,
        [auth.currentUser.uid, hostId]
      );
      navigate(`/messages/${conversation.id}`);
    } catch (error) {
      console.error('Error starting conversation:', error);
    }
  };

  return (
    <button
      onClick={handleChat}
      className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded-lg"
    >
      Message Host
    </button>
  );
};

export default SubleaseDetail;
