import React from 'react';

const AnimatedName = ({ name }) => {
  if (!name) return null;
  
  const firstLetter = name.charAt(0).toUpperCase();
  
  return (
    <span className="inline-flex items-center">
      {firstLetter}
      <span 
        className="relative ml-1 inline-block h-6 bg-black rounded-sm overflow-hidden"
        style={{ width: `${(name.length - 1) * 0.6}em` }}
      >
        <span 
          className="absolute inset-0 bg-gradient-to-r from-transparent via-white/30 to-transparent animate-shimmer-1"
          style={{
            animation: 'shimmer-1 3s infinite',
            backgroundSize: '200% 100%',
          }}
        />
        <span 
          className="absolute inset-0 bg-gradient-to-r from-transparent via-white/20 to-transparent animate-shimmer-2"
          style={{
            animation: 'shimmer-2 2.5s infinite',
            backgroundSize: '200% 100%',
            animationDelay: '1s',
          }}
        />
        <span 
          className="absolute inset-0 bg-gradient-to-r from-transparent via-white/15 to-transparent animate-shimmer-3"
          style={{
            animation: 'shimmer-3 2s infinite',
            backgroundSize: '200% 100%',
            animationDelay: '2s',
          }}
        />
      </span>
    </span>
  );
};

// Add the shimmer animations to the global styles
const style = document.createElement('style');
style.textContent = `
  @keyframes shimmer-1 {
    0% {
      background-position: 200% 0;
    }
    100% {
      background-position: -200% 0;
    }
  }
  @keyframes shimmer-2 {
    0% {
      background-position: -200% 0;
    }
    100% {
      background-position: 200% 0;
    }
  }
  @keyframes shimmer-3 {
    0% {
      background-position: 200% 0;
    }
    100% {
      background-position: -200% 0;
    }
  }
`;
document.head.appendChild(style);

export default AnimatedName; 