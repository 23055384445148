import { 
  collection,
  query,
  getDocs,
  getDoc,
  doc,
  orderBy,
  limit,
  where
} from 'firebase/firestore';
import { db } from '../firebase/index';

export const adminService = {
  // Check if user is admin
  async isAdmin(userId) {
    try {
      const adminDoc = await getDoc(doc(db, 'admin', userId));
      return adminDoc.exists();
    } catch (error) {
      console.error('Error checking admin status:', error);
      return false;
    }
  },

  // Get all conversations with details
  async getAllConversations(limitCount = 50) {
    try {
      const q = query(
        collection(db, 'conversations'),
        orderBy('lastMessageAt', 'desc'),
        limit(limitCount)
      );

      const querySnapshot = await getDocs(q);
      const conversations = [];

      for (const doc of querySnapshot.docs) {
        const conv = {
          id: doc.id,
          ...doc.data()
        };

        // Get last message
        const lastMessageQuery = query(
          collection(db, 'messages'),
          where('conversationId', '==', doc.id),
          orderBy('timestamp', 'desc'),
          limit(1)
        );
        const lastMessageSnap = await getDocs(lastMessageQuery);
        
        if (!lastMessageSnap.empty) {
          conv.lastMessage = {
            id: lastMessageSnap.docs[0].id,
            ...lastMessageSnap.docs[0].data()
          };
        }

        // Add conversation type label
        conv.typeLabel = conv.type === 'roommate' ? 'Roommate Chat' : 'Sublease Chat';

        conversations.push(conv);
      }

      return conversations;
    } catch (error) {
      console.error('Error fetching conversations:', error);
      throw error;
    }
  },

  // Get conversation details with messages
  async getConversationDetails(conversationId) {
    try {
      const convDoc = await getDoc(doc(db, 'conversations', conversationId));
      if (!convDoc.exists()) {
        throw new Error('Conversation not found');
      }

      const messagesQuery = query(
        collection(db, 'messages'),
        where('conversationId', '==', conversationId),
        orderBy('timestamp', 'asc')
      );

      const messagesSnap = await getDocs(messagesQuery);
      const messages = messagesSnap.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));

      return {
        id: convDoc.id,
        ...convDoc.data(),
        messages,
        typeLabel: convDoc.data().type === 'roommate' ? 'Roommate Chat' : 'Sublease Chat'
      };
    } catch (error) {
      console.error('Error fetching conversation details:', error);
      throw error;
    }
  }
}; 