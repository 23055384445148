import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { db, auth } from "../firebase/index";
import { doc, getDoc } from "firebase/firestore";
import UserCards from "./Roommates/UserCards";
import MainHeader from "../components/Headers/MainHeader";
import AddToRoommateListPopup from "../components/Roommates/AddToRoommateListPopup";
import {
  isUserOnAptRoommateList,
  addUserToRoommateList,
} from "../backend/dbAPI";
import { fetchSpecificApartmentName } from "../backend/dynamoAPI";
import ProfileCarousel, { ProfileCard } from "./ExampleRoommateProfiles";
import { CgOptions } from "react-icons/cg";
import AnimatedName from "../components/AnimatedName";

function InterestedUsers() {
  const [my_user] = useAuthState(auth);
  const { apartmentId: baseApartmentId = "general", secondParam } = useParams();
  const [showModal, setShowModal] = useState(false);
  const [showStudentModal, setShowStudentModal] = useState(false);
  const [showAddToListPopup, setShowAddToListPopup] = useState(false);
  const [showFilterModal, setShowFilterModal] = useState(false); // New state for filters popup
  const [userProfile, setUserProfile] = useState(null);
  const [apartmentName, setApartmentName] = useState(null);
  const navigate = useNavigate();
  const [studentFilters, setStudentFilters] = useState({
    gender: "all", // Default to "all"
  });

  // Combine apartmentId and secondParam if secondParam exists
  const apartmentId = secondParam
    ? `${baseApartmentId}_${secondParam}`
    : baseApartmentId;
  const trueApartmentId = secondParam
    ? `${baseApartmentId}/${secondParam}`
    : baseApartmentId;

  useEffect(() => {
    // Scroll to top of the page on component mount
    window.scrollTo(0, 0);

    const fetchUserProfile = async () => {
      if (my_user) {
        const userDocRef = doc(db, "users", my_user.uid);
        const userDoc = await getDoc(userDocRef);
        if (userDoc.exists()) {
          const data = userDoc.data();
          if (data) {
            setUserProfile(data);
            // Set gender filter based on user's gender or default to "all"
            setStudentFilters((prevFilters) => ({
              ...prevFilters,
              gender: data.gender ? data.gender : "all",
              // gender: "all",
            }));

            // Check if the user is on the apartment roommate list
            if (data.showRoommateProfile != null) {
              const isOnList = await isUserOnAptRoommateList(
                my_user.uid,
                apartmentId
              );
              if (!isOnList) {
                // setShowAddToListPopup(true); // Show the popup if the user is not on the list
                handleAddToListConfirm();
              }
            } else {
              setShowModal(true);
            }
            if (data.schoolEmail === null) {
              setShowStudentModal(true);
            }
          } else {
            setShowModal(true);
          }
        } else {
          setShowModal(true);
        }
      } else {
        setShowModal(true);
      }
    };

    const fetchApartmentName = async () => {
      if (baseApartmentId !== "general") {
        try {
          const apt = await fetchSpecificApartmentName(trueApartmentId);
          setApartmentName(apt?.name || "Unknown Apartment"); // Fallback to a default value
        } catch (error) {
          console.error("Error fetching apartment name:", error);
        }
      }
    };

    fetchUserProfile();
    fetchApartmentName(); // Fetch the apartment name if baseApartmentId is not "general"
  }, [apartmentId, my_user, baseApartmentId, trueApartmentId]);

  // Function to handle opening and closing the filter modal
  const toggleFilterModal = () => {
    setShowFilterModal(!showFilterModal);
  };

  // Function to handle gender filter changes
  const handleGenderChange = (event) => {
    setStudentFilters((prevFilters) => ({
      ...prevFilters,
      gender: event.target.value,
    }));
  };

  const CreateRoommateProfileModal = () => {
    const [currentProfileIndex, setCurrentProfileIndex] = useState(0);
    
    const exampleProfiles = [
      {
        name: "Adarsh Dayalan",
        majorDesc: "Freshman in Computer Science",
        desc: "Interested in a place with a good gym and looking at 3-5 bed apartments. I'm an easygoing dude looking for a couple of chill roommates. Hit me up if you're interested!",
        profilePicture: "https://firebasestorage.googleapis.com/v0/b/adobe-64bfc.appspot.com/o/profile_pictures%2FAdarshInstagram.jpg?alt=media&token=5f67cf8e-1cc0-4b5a-8350-e93d7f9055db",
        instagramHandle: "@adarsh.dayalan",
        interests: ["Fitness", "Basketball", "Cooking", "Music"],
        priceRange: "$800-$1000",
      },
      {
        name: "Sarah Johnson",
        majorDesc: "Sophomore in Business",
        desc: "Looking for roommates who value cleanliness and study time. I prefer quiet hours after 10 PM and love cooking together. Open to 2-4 bed apartments near campus!",
        profilePicture: "https://firebasestorage.googleapis.com/v0/b/adobe-64bfc.appspot.com/o/profile_pictures%2FbHNtHx4zlnWNWMR4aSbbzOy5aSN2?alt=media&token=3c97b899-0fc5-4e1d-ac4c-4aff50ef40bf",
        instagramHandle: "@sarah.Johnson",
        interests: ["Cooking", "Yoga", "Photography", "Travel"],
        priceRange: "$700-$900",
      }
    ];

    const handleCreateProfileClick = () => {
      if (my_user) {
        window.location.href = `/create-roommate-profile/${apartmentId}`;
      } else {
        alert(
          "Please sign up and create a account first before creating a roommate profile."
        );
      }
    };

    const handleBackClick = () => {
      navigate("/listings#apartments");
    };

    const nextProfile = () => {
      setCurrentProfileIndex((prev) => (prev + 1) % exampleProfiles.length);
    };

    const prevProfile = () => {
      setCurrentProfileIndex((prev) => (prev - 1 + exampleProfiles.length) % exampleProfiles.length);
    };

    const currentProfile = exampleProfiles[currentProfileIndex];

    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
        <div className="bg-white rounded-2xl shadow-xl w-11/12 max-w-4xl overflow-hidden">
          {/* Header with gradient background */}
          <div className="relative h-28 bg-gradient-to-r from-logo_blue to-blue-600">
            <div className="absolute inset-0 bg-black bg-opacity-20"></div>
            <div className="absolute inset-0 flex items-center justify-center">
              <div className="text-center text-white px-4">
                <h1 className="text-2xl font-bold mb-1">Looking for Roommates or Connections at UIUC?</h1>
                <p className="text-sm opacity-90">Create a profile to connect with thousands of UIUC students looking for roommates!</p>
              </div>
            </div>
          </div>

          {/* Content */}
          <div className="p-5">
            <div className="flex flex-col md:flex-row gap-5">
              {/* Left side - Benefits */}
              <div className="w-full md:w-3/5">
                <div className="bg-white rounded-xl shadow-lg p-4 border border-gray-100">
                  {/* Social Proof */}
                  <div className="flex justify-between items-center mb-4 pb-4 border-b border-gray-100">
                    <div className="text-center">
                      <div className="text-2xl font-bold text-logo_blue">2K+</div>
                      <div className="text-xs text-gray-600">UIUC Students</div>
                    </div>
                    <div className="text-center">
                      <div className="text-2xl font-bold text-logo_blue">100+</div>
                      <div className="text-xs text-gray-600">Active Profiles</div>
                    </div>
                  </div>

                  <div className="space-y-3">
                    <p className="text-sm flex items-start">
                      <span className="text-green-500 mr-2 mt-1">✔</span>
                      <span>Match with students who <span className="underline">share your vibe</span>, living habits, and budget.</span>
                    </p>
                    <p className="text-sm flex items-start">
                      <span className="text-green-500 mr-2 mt-1">✔</span>
                      <span>View Instagram profiles, bios, and hobbies to find your perfect roommate.</span>
                    </p>
                    <p className="text-sm flex items-start">
                      <span className="text-green-500 mr-2 mt-1">✔</span>
                      <span>Filter by price range, living habits, major, and more.</span>
                    </p>
                    <p className="text-sm flex items-start">
                      <span className="text-green-500 mr-2 mt-1">✔</span>
                      <span>Take 3 minutes to ensure a great roommate experience for the year!</span>
                    </p>
                  </div>

                  <div className="flex flex-col mt-4">
                    <button
                      onClick={handleCreateProfileClick}
                      className="bg-logo_blue text-white px-4 py-2.5 rounded-xl font-medium hover:bg-blue-600 transition-colors duration-200 flex items-center justify-center space-x-2"
                    >
                      <span>Create Roommate Profile</span>
                      <i className="fas fa-arrow-right"></i>
                    </button>
                    <button
                      onClick={handleBackClick}
                      className="mt-3 text-gray-700 hover:text-gray-900 transition-colors duration-200 text-sm"
                    >
                      No thanks, take me back to apartments
                    </button>
                  </div>
                </div>
              </div>

              {/* Right side - Example Profile */}
              <div className="w-full md:w-2/5">
                <div className="bg-gray-50 rounded-xl p-3">
                  <h2 className="text-sm font-semibold mb-2">Example Student Profile</h2>
                  <div className="bg-white rounded-xl shadow-lg p-3">
                    {/* Profile Header */}
                    <div className="flex items-center space-x-2 mb-2">
                      <div className="relative">
                        <img
                          src={currentProfile.profilePicture}
                          alt="Example Profile"
                          className="w-12 h-12 rounded-full object-cover border-2 border-white shadow-md"
                        />
                        <div className="absolute bottom-0 right-0 w-3 h-3 bg-green-400 rounded-full border-2 border-white"></div>
                      </div>
                      <div>
                        <h3 className="text-sm font-semibold">
                          <AnimatedName name={currentProfile.name} />
                        </h3>
                        <p className="text-xs text-gray-600">{currentProfile.majorDesc}</p>
                      </div>
                    </div>

                    {/* Description */}
                    <div className="mb-2">
                      <h4 className="text-xs font-medium text-gray-500 mb-0.5">Description</h4>
                      <p className="text-xs text-gray-700 leading-relaxed">{currentProfile.desc}</p>
                    </div>

                    {/* Interests */}
                    <div className="mb-2">
                      <h4 className="text-xs font-medium text-gray-500 mb-0.5">Interests</h4>
                      <div className="flex flex-wrap gap-1">
                        {currentProfile.interests.map((interest, index) => (
                          <span
                            key={index}
                            className="px-1.5 py-0.5 bg-blue-50 text-blue-600 rounded-full text-xs"
                          >
                            {interest}
                          </span>
                        ))}
                      </div>
                    </div>

                    {/* Price Range */}
                    <div className="mb-2">
                      <h4 className="text-xs font-medium text-gray-500 mb-0.5">Price Range</h4>
                      <span className="px-1.5 py-0.5 bg-green-50 text-green-600 rounded-full text-xs">
                        {currentProfile.priceRange}
                      </span>
                    </div>

                    {/* Combined Navigation */}
                    <div className="flex items-center justify-between mt-2">
                      <button
                        onClick={prevProfile}
                        className="text-gray-400 hover:text-logo_blue transition-colors text-sm"
                        aria-label="Previous profile"
                      >
                        <i className="fas fa-chevron-left"></i>
                      </button>
                      
                      <div className="flex space-x-1">
                        {exampleProfiles.map((_, index) => (
                          <button
                            key={index}
                            onClick={() => setCurrentProfileIndex(index)}
                            className={`w-1.5 h-1.5 rounded-full transition-all duration-200 ${
                              index === currentProfileIndex ? 'bg-logo_blue w-3' : 'bg-gray-300'
                            }`}
                            aria-label={`Go to profile ${index + 1}`}
                          />
                        ))}
                      </div>

                      <button
                        onClick={nextProfile}
                        className="text-gray-400 hover:text-logo_blue transition-colors text-sm"
                        aria-label="Next profile"
                      >
                        <i className="fas fa-chevron-right"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const handleAddToListConfirm = async () => {
    try {
      await addUserToRoommateList(my_user.uid, apartmentId);
      setShowAddToListPopup(false);
    } catch (error) {
      console.error("Error adding user to roommate list:", error);
      alert("Failed to add you to the roommate list. Please try again.");
    }
  };

  const handleAddToListCancel = () => {
    setShowAddToListPopup(false);
  };

  // Render the filter modal
  const renderFilterModal = () => {
    return (
      <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-start p-4">
        <div className="bg-white p-6 rounded-lg shadow-lg max-w-md w-full mt-20 overflow-y-auto max-h-[80vh]">
          <h2 className="text-xl font-semibold mb-4">Filter Students</h2>
          <label htmlFor="genderFilter" className="block text-gray-700 mb-2">
            Gender:
          </label>
          <select
            id="genderFilter"
            value={studentFilters.gender}
            onChange={handleGenderChange}
            className="w-full p-2 border rounded-md mb-4"
          >
            <option value="all">All</option>
            <option value="male">Male</option>
            <option value="female">Female</option>
          </select>
          <div className="flex justify-end space-x-4">
            <button
              onClick={() => setShowFilterModal(false)}
              className="bg-gray-300 text-gray-800 px-4 py-2 rounded"
            >
              Cancel
            </button>
            <button
              onClick={() => setShowFilterModal(false)}
              className="bg-logo_blue text-white px-4 py-2 rounded"
            >
              Apply
            </button>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="bg-gray-50">
      {apartmentId !== "general" && <MainHeader />}

      <div className="relative min-h-screen p-4">
        {userProfile ? (
          <div>
            <div className="max-w-8xl mx-auto py-4 px-4 sm:px-6 lg:px-8">
              <div className="flex justify-between mb-4">
                <h1 className="text-2xl font-semibold">
                  Interested Students {apartmentName && ` - ${apartmentName}`}
                </h1>
                <button
                  onClick={toggleFilterModal}
                  className="flex items-center p-2 h-12 text-black bg-white border border-gray-300 rounded-md"
                >
                  <CgOptions className="h-12 mr-1" />
                  <span className="text-sm font-medium">Filters</span>
                </button>
              </div>
              <UserCards filters={studentFilters} apartmentId={apartmentId} />
            </div>
          </div>
        ) : (
          <div>Displaying example profiles...</div>
        )}
        {showStudentModal && <div></div>}
        {showModal && <CreateRoommateProfileModal />}
        {showAddToListPopup && (
          <AddToRoommateListPopup
            onConfirm={handleAddToListConfirm}
            onCancel={handleAddToListCancel}
          />
        )}
        {showFilterModal && renderFilterModal()} {/* Render the filter modal */}
      </div>
    </div>
  );
}

export default InterestedUsers;
