import React, { memo } from "react";

// Enhanced loading animation with shimmer effect
const LoadingAnimation = memo(function LoadingAnimation() {
  return (
    <div className="w-full p-4">
      <div className="bg-gray-100 rounded-lg overflow-hidden shadow-sm">
        {/* Image placeholder with shimmer effect */}
        <div className="w-full h-48 bg-gradient-to-r from-gray-200 via-gray-100 to-gray-200 relative overflow-hidden">
          <div className="absolute inset-0 bg-gradient-to-r from-transparent via-white to-transparent animate-shimmer" 
               style={{ 
                 backgroundSize: '200% 100%', 
                 animation: 'shimmer 1.5s infinite linear',
               }}></div>
        </div>
        
        {/* Text content placeholders */}
        <div className="p-4">
          {/* Title */}
          <div className="w-2/3 h-4 bg-gradient-to-r from-gray-200 via-gray-100 to-gray-200 rounded animate-pulse mb-2 overflow-hidden relative">
            <div className="absolute inset-0 bg-gradient-to-r from-transparent via-white to-transparent animate-shimmer"
                 style={{ backgroundSize: '200% 100%' }}></div>
          </div>
          
          {/* Subtitle */}
          <div className="w-1/2 h-3 bg-gradient-to-r from-gray-200 via-gray-100 to-gray-200 rounded animate-pulse mb-4 overflow-hidden relative">
            <div className="absolute inset-0 bg-gradient-to-r from-transparent via-white to-transparent animate-shimmer"
                 style={{ backgroundSize: '200% 100%', animationDelay: '0.1s' }}></div>
          </div>
          
          {/* Details */}
          <div className="w-1/3 h-3 bg-gradient-to-r from-gray-200 via-gray-100 to-gray-200 rounded animate-pulse mb-2 overflow-hidden relative">
            <div className="absolute inset-0 bg-gradient-to-r from-transparent via-white to-transparent animate-shimmer"
                 style={{ backgroundSize: '200% 100%', animationDelay: '0.2s' }}></div>
          </div>
          
          {/* Price */}
          <div className="w-2/5 h-5 bg-gradient-to-r from-gray-200 via-gray-100 to-gray-200 rounded animate-pulse overflow-hidden relative">
            <div className="absolute inset-0 bg-gradient-to-r from-transparent via-white to-transparent animate-shimmer"
                 style={{ backgroundSize: '200% 100%', animationDelay: '0.3s' }}></div>
          </div>
        </div>
      </div>
    </div>
  );
});

// Define shimmer animation styles
const shimmerStyles = `
  @keyframes shimmer {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(100%);
    }
  }
  
  .animate-shimmer {
    animation: shimmer 1.5s infinite linear;
  }
`;

// Add styles to document head
if (typeof document !== 'undefined') {
  const styleSheet = document.createElement('style');
  styleSheet.type = 'text/css';
  styleSheet.innerText = shimmerStyles;
  document.head.appendChild(styleSheet);
}

export default LoadingAnimation;