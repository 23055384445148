import { useState, useEffect } from "react";
import { motion } from "framer-motion";
import {
  Building2,
  Clock,
  Search,
  Users,
  Star,
  FileText,
  Instagram,
} from "lucide-react";
import { addPhoneNumber } from "../backend/dbAPI";
import AbodeListings from "../data/imgs/AbodeListings.png";
import { useNavigate } from "react-router-dom";

export default function QuickSignupPage() {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false); // Loading state
  const [confirmationMessage, setConfirmationMessage] = useState(""); // Confirmation message
  const [countdown, setCountdown] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });
  const navigate = useNavigate();

  useEffect(() => {
    const countDownDate = new Date("2024-09-19T00:00:00").getTime();

    const updateCountdown = () => {
      const now = new Date().getTime();
      const distance = countDownDate - now;

      setCountdown({
        days: Math.floor(distance / (1000 * 60 * 60 * 24)),
        hours: Math.floor(
          (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        ),
        minutes: Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)),
        seconds: Math.floor((distance % (1000 * 60)) / 1000),
      });
    };

    const timer = setInterval(updateCountdown, 1000);
    return () => clearInterval(timer);
  }, []);

  const isValidPhoneNumber = (number) => {
    const phoneRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
    return phoneRegex.test(number);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (isValidPhoneNumber(phoneNumber)) {
      setErrorMessage(""); // Clear error message if valid
      setLoading(true); // Set loading state
      setConfirmationMessage(""); // Clear any previous confirmation message

      try {
        localStorage.setItem("phoneNumber", phoneNumber);
        await addPhoneNumber(phoneNumber);
        setConfirmationMessage("We will text you when we launch :)"); // Show confirmation message
        setPhoneNumber(""); // Clear input field
      } catch (error) {
        setErrorMessage("Failed to add phone number. Please try again.");
        setConfirmationMessage(""); // Clear any previous confirmation message
      } finally {
        setLoading(false); // Remove loading state
      }
    } else {
      setErrorMessage("Please enter a valid phone number.");
      setConfirmationMessage(""); // Clear any previous confirmation message
    }
  };

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2,
        delayChildren: 0.3,
      },
    },
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { 
      opacity: 1, 
      y: 0,
      transition: {
        type: "spring",
        stiffness: 100,
      }
    },
  };

  return (
    <div className="flex flex-col min-h-screen bg-gradient-to-b from-white to-gray-50">
      <main className="flex-1">
        <section className="w-full py-12 md:py-24 lg:py-32 bg-white">
          <motion.div 
            className="container px-4 md:px-6 mx-auto"
            variants={containerVariants}
            initial="hidden"
            animate="visible"
          >
            <motion.div 
              className="flex flex-col items-center space-y-8 text-center"
              variants={containerVariants}
            >
              <motion.div 
                className="space-y-4 max-w-3xl"
                variants={itemVariants}
              >
                <motion.h1 
                  className="text-5xl sm:text-6xl md:text-7xl font-bold tracking-tighter bg-gradient-to-r from-logo_blue to-blue-600 bg-clip-text text-transparent"
                  initial={{ opacity: 0, y: -20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.6, ease: "easeOut" }}
                >
                  Find Your Next U of I Apartment in Minutes
                </motion.h1>
                
                <motion.p 
                  className="mx-auto max-w-[90%] sm:max-w-[700px] text-lg md:text-xl text-gray-600 leading-relaxed"
                  variants={itemVariants}
                >
                  Simplify your student housing.
                  <span className="block mt-2">Find Apartments. Find Roommates. Find Subleases.</span>
                </motion.p>
                
                <motion.p 
                  className="text-xl font-semibold text-logo_blue"
                  variants={itemVariants}
                >
                  All in one place. Trusted by thousands of students.
                </motion.p>
              </motion.div>

              <motion.div 
                className="w-full max-w-4xl py-8"
                variants={itemVariants}
              >
                <motion.div 
                  className="relative rounded-xl shadow-[0_8px_30px_rgb(0,0,0,0.12)]"
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.5 }}
                >
                  <img
                    src={AbodeListings}
                    alt="Apartment Search Screenshot"
                    className="w-full rounded-xl"
                  />
                </motion.div>
                
                <motion.p 
                  className="mt-6 text-lg md:text-xl text-gray-600"
                  variants={itemVariants}
                >
                  Join thousands of other verified U of I students using Abode
                </motion.p>
              </motion.div>

              <motion.button
                onClick={() => navigate("/listings")}
                className="bg-white text-logo_blue border-2 border-logo_blue px-6 py-3 rounded-lg font-semibold text-lg hover:bg-gray-50 transition duration-300 flex items-center gap-2"
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 0.98 }}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.3 }}
              >
                Explore Apartments Now
                <i className="fas fa-arrow-right text-sm"></i>
              </motion.button>
            </motion.div>
          </motion.div>
        </section>

        <section className="w-full py-12 md:py-24 lg:py-32 bg-white">
          <div className="container px-4 md:px-6 mx-auto">
            <div className="grid md:grid-cols-2 gap-8 items-center">
              <div className="space-y-4">
                <h2 className="text-3xl font-bold text-[#0A2463]">
                  All Apartments in One Place
                </h2>
                <p className="text-gray-500">
                  Browse through every apartment near UIUC, compare prices, and
                  find your perfect match with our intuitive search.
                </p>
                <motion.button
                  onClick={() => navigate("/listings#apartments")}
                  className="bg-white text-logo_blue border-2 border-logo_blue px-6 py-3 rounded-lg font-semibold text-lg hover:bg-gray-50 transition duration-300 flex items-center gap-2"
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                >
                  Browse Apartments
                  <i className="fas fa-arrow-right text-sm"></i>
                </motion.button>
              </div>
              <div className="space-y-4">
                <img
                  src={AbodeListings}
                  alt="Apartment Search Screenshot"
                  className="rounded-lg shadow-md w-full"
                />
              </div>
            </div>
          </div>
        </section>
        <section className="w-full py-12 md:py-24 lg:py-32 bg-gray-50">
          <div className="container px-4 md:px-6 mx-auto">
            <div className="grid md:grid-cols-2 gap-8 items-center">
              <div className="space-y-4 order-2 md:order-1">
                <video
                  src="https://abodedocumentsbucket.s3.us-east-2.amazonaws.com/AbodeRoommateDemo.mov"
                  className="rounded-lg shadow-md w-full"
                  alt="Roommate Matching Demo Video"
                  autoPlay
                  loop
                  muted
                  controls
                />
              </div>
              <div className="space-y-4 order-1 md:order-2">
                <h2 className="text-3xl font-bold text-[#0A2463]">
                  Connect with Students
                </h2>
                <p className="text-gray-500">
                  Find compatible roommates, explore student profiles, and
                  easily connect and message to coordinate your housing plans.
                </p>
                <motion.button
                  onClick={() => navigate("/listings#roommates")}
                  className="bg-white text-logo_blue border-2 border-logo_blue px-6 py-3 rounded-lg font-semibold text-lg hover:bg-gray-50 transition duration-300 flex items-center gap-2"
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                >
                  Find Roommates
                  <i className="fas fa-arrow-right text-sm"></i>
                </motion.button>
              </div>
            </div>
          </div>
        </section>

        <section className="w-full py-12 md:py-24 lg:py-32 bg-white">
          <div className="container px-4 md:px-6 mx-auto">
            <div className="grid md:grid-cols-2 gap-8 items-center">
              <div className="space-y-4">
                <h2 className="text-4xl font-bold text-[#0A2463]">
                  UIUC Subleases Made Simple
                </h2>
                <p className="text-gray-500">
                  Whether you're studying abroad or need to move out early, we make subleasing fast, easy, and safe. {" "}
                  Find your match in minutes.{" "}
                </p>
                <motion.button
                  onClick={() => navigate("/listings#subleases")}
                  className="bg-white text-logo_blue border-2 border-logo_blue px-6 py-3 rounded-lg font-semibold text-lg hover:bg-gray-50 transition duration-300 flex items-center gap-2"
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                >
                  View Subleases
                  <i className="fas fa-arrow-right text-sm"></i>
                </motion.button>
              </div>
              <div className="space-y-4 order-2 md:order-1">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/adobe-64bfc.appspot.com/o/Abode-sublease-hero.png?alt=media&token=fa6e8f78-4073-4279-b153-789f30415a4b"
                  alt="UIUC Subleases on Abode"
                  className="rounded-xl shadow-md w-full"
                />
              </div>
            </div>
          </div>
        </section>
        <section className="w-full py-12 md:py-24 lg:py-32 bg-gray-50">
          <div className="container max-w-screen-lg mx-auto px-4 md:px-6">
            <h2 className="text-3xl font-bold text-center mb-12 text-[#0A2463]">
              All Your Housing Needs in One Place
            </h2>
            <div className="grid gap-10 sm:grid-cols-2 lg:grid-cols-3">
              <div className="flex flex-col items-center space-y-3 text-center">
                <Building2 className="h-10 w-10 text-[#0A2463]" />
                <h3 className="text-xl font-bold">
                  All UIUC Apartments Listed
                </h3>
                <p className="text-sm text-gray-500">
                  Every single apartment near UIUC listed in one place.
                </p>
              </div>
              <div className="flex flex-col items-center space-y-3 text-center">
                <Users className="h-10 w-10 text-[#0A2463]" />
                <h3 className="text-xl font-bold">Connect with Roommates</h3>
                <p className="text-sm text-gray-500">
                  Easily connect with other student roommates.
                </p>
              </div>
              <div className="flex flex-col items-center space-y-3 text-center">
                <Search className="h-10 w-10 text-[#0A2463]" />
                <h3 className="text-xl font-bold">
                  Intuitive Apartment Search
                </h3>
                <p className="text-sm text-gray-500">
                  Find and sign for your apartment with our easy-to-use search.
                </p>
              </div>
              <div className="flex flex-col items-center space-y-3 text-center">
                <Clock className="h-10 w-10 text-[#0A2463]" />
                <h3 className="text-xl font-bold">Up-to-Date Information</h3>
                <p className="text-sm text-gray-500">
                  Get the latest prices, availability, and apartment
                  information.
                </p>
              </div>
              <div className="flex flex-col items-center space-y-3 text-center">
                <Star className="h-10 w-10 text-[#0A2463]" />
                <h3 className="text-xl font-bold">Real Student Reviews</h3>
                <p className="text-sm text-gray-500">
                  Read authentic reviews from fellow students.
                </p>
              </div>
              <div className="flex flex-col items-center space-y-3 text-center">
                <FileText className="h-10 w-10 text-[#0A2463]" />
                <h3 className="text-xl font-bold">Sublease Listings</h3>
                <p className="text-sm text-gray-500">
                  Find or post sublease opportunities easily.
                </p>
              </div>
            </div>
            <div className="flex flex-col items-center mt-8 text-center">
              <button
                onClick={() => {
                  navigate("/listings");
                }}
                type="submit"
                className={`bg-gradient-blue-r text-white mt-4 px-4 py-3 rounded font-semibold text-md sm:text-base ${"hover:bg-[#0A2463]/90"}`}
              >
                {"Explore Apartments Now"}
              </button>
            </div>
          </div>
        </section>
      </main>
      <footer className="flex flex-col gap-2 sm:flex-row py-6 w-full shrink-0 items-center px-4 md:px-6 border-t">
        <p className="text-xs text-gray-500 dark:text-gray-400">
          © 2024 Abode. All rights reserved.
        </p>
        <nav className="sm:ml-auto flex gap-4 sm:gap-6">
          <a
            href="https://abodedocumentsbucket.s3.us-east-2.amazonaws.com/Abode+Privacy+Policy.pdf"
            className="text-xs hover:underline underline-offset-4"
            target="_blank"
            rel="noopener noreferrer"
          >
            Terms of Service
          </a>
          <a
            href="https://abodedocumentsbucket.s3.us-east-2.amazonaws.com/Abode+Terms+of+Service.pdf"
            className="text-xs hover:underline underline-offset-4"
            target="_blank"
            rel="noopener noreferrer"
          >
            Privacy Policy
          </a>
        </nav>
      </footer>
    </div>
  );
}
