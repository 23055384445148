import React, { useState, useEffect } from 'react';
import Modal from '../Modal';
import ReactConfetti from 'react-confetti';
import { Switch } from '@headlessui/react';
import { motion } from 'framer-motion';
import { format, isBefore, isValid } from 'date-fns';
import { toast } from 'react-hot-toast';
import { doc, setDoc } from 'firebase/firestore';
import { auth } from '../../firebase';

const containerVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.2
    }
  }
};

const itemVariants = {
  hidden: { 
    opacity: 0,
    y: 20,
    scale: 0.95
  },
  visible: {
    opacity: 1,
    y: 0,
    scale: 1,
    transition: {
      type: "spring",
      stiffness: 100,
      damping: 10
    }
  }
};

const pageVariants = {
  hidden: { 
    opacity: 0,
    x: -20
  },
  visible: { 
    opacity: 1,
    x: 0,
    transition: {
      type: "spring",
      stiffness: 100,
      damping: 15
    }
  },
  exit: {
    opacity: 0,
    x: 20
  }
};

const SubleasePreferencesModal = ({ isOpen, onClose, onSave }) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const [showConfetti, setShowConfetti] = useState(false);

  // Unified state for all preferences
  const [preferences, setPreferences] = useState({
    subleaseUserIntent: [], // Changed to array to support multiple selections
    duration: {
      dateRange: {
        start: null,
        end: null
      },
      tags: []
    },
    priceRange: {
      min: 800,
      max: 1000
    },
    bedBath: {
      bedTypes: ['any'],
      privateBath: false
    },
    gender: {
      preferredGenders: ['any']
    },
    notifications: {
      emailUpdates: true,
      allowListing: true
    }
  });

  // Common terms for duration selection
  const commonTerms = [
    { 
      id: 'spring2025', 
      label: 'Spring 2025', 
      value: 'Spring 2025', 
      description: 'January - May',
      startDate: new Date(Date.UTC(2025, 0, 1, 12, 0, 0)), // January 1, 2025 at noon UTC
      endDate: new Date(Date.UTC(2025, 4, 31, 12, 0, 0)) // May 31, 2025 at noon UTC
    },
    { 
      id: 'summer2025', 
      label: 'Summer 2025', 
      value: 'Summer 2025', 
      description: 'May - August',
      startDate: new Date(Date.UTC(2025, 4, 1, 12, 0, 0)), // May 1, 2025 at noon UTC
      endDate: new Date(Date.UTC(2025, 7, 31, 12, 0, 0)) // August 31, 2025 at noon UTC
    },
    { 
      id: 'fall2025', 
      label: 'Fall 2025', 
      value: 'Fall 2025', 
      description: 'August - December',
      startDate: new Date(Date.UTC(2025, 7, 1, 12, 0, 0)), // August 1, 2025 at noon UTC
      endDate: new Date(Date.UTC(2025, 11, 31, 12, 0, 0)) // December 31, 2025 at noon UTC
    },
    { 
      id: 'fullYear2025', 
      label: 'Full Year 2025-2026', 
      value: 'Full Year 2025-2026', 
      description: 'August 2025 - May 2026',
      startDate: new Date(Date.UTC(2025, 7, 1, 12, 0, 0)), // August 1, 2025 at noon UTC
      endDate: new Date(Date.UTC(2026, 4, 31, 12, 0, 0)) // May 31, 2026 at noon UTC
    }
  ];

  // Price tiers
  const priceTiers = [
    {
      id: 'budget',
      label: 'Budget Friendly',
      description: 'Basic amenities, shared spaces',
      priceRange: { min: 500, max: 800 },
      features: ['Shared bathroom', 'Basic furnishings', 'Standard location'],
      icon: '💰'
    },
    {
      id: 'standard',
      label: 'Standard Living',
      description: 'Balanced comfort & value',
      priceRange: { min: 800, max: 1000 },
      features: ['Private bathroom', 'Modern furnishings', 'Prime location'],
      icon: '🏠'
    },
    {
      id: 'premium',
      label: 'Premium Experience',
      description: 'Luxury amenities & location',
      priceRange: { min: 1000, max: 1500 },
      features: ['Ensuite bathroom', 'High-end furnishings', 'Premium location'],
      icon: '✨'
    }
  ];

  // Bed options
  const bedOptions = [
    { value: 'studio', label: 'Studio' },
    { value: '1', label: '1 Bed' },
    { value: '2', label: '2 Beds'},
    { value: '3', label: '3 Beds'},
    { value: '4', label: '4 Beds'},
    { value: '5', label: '5 Beds'}
  ];

  // Gender options
  const genderOptions = [
    { value: 'any', label: 'Any Gender', description: 'Open to all genders', icon: '👥' },
    { value: 'male', label: 'Male', description: 'Male roommates', icon: '👨' },
    { value: 'female', label: 'Female', description: 'Female roommates', icon: '👩' },
    { value: 'other', label: 'Other', description: 'Non-binary/Other', icon: '🌈' }
  ];

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleOptionSelect = async (option) => {
    // console.log('SubleasePreferencesModal: Option selected', option, new Date().toISOString());
    
    setPreferences(prev => {
      const currentIntents = prev.subleaseUserIntent;
      let newIntents;

      if (option === 'any') {
        newIntents = ['any'];
      } else {
        const withoutAny = currentIntents.filter(i => i !== 'any');
        newIntents = withoutAny.includes(option)
          ? withoutAny.filter(i => i !== option)
          : [...withoutAny, option];
        
        if (newIntents.length === 0) {
          newIntents = ['any'];
        }
      }

      return { ...prev, subleaseUserIntent: newIntents };
    });
  };

  const handleBack = () => {
    setCurrentStep(prev => prev - 1);
  };

  const handleNext = async () => {
    // If looking_for_sublease is selected, proceed to next step
    if (preferences.subleaseUserIntent.includes('looking_for_sublease')) {
      setCurrentStep(prev => prev + 1);
    } else {
      // Otherwise, save just the intent and close
      try {
        // Call onSave for any additional handling
        await onSave({
          subleaseUserIntent: preferences.subleaseUserIntent,
          lastUpdated: new Date().toISOString()
        });
        
        // Show success toast
        toast.success('Preferences saved successfully! 🎉', {
          duration: 1000,
          position: 'top-center',
          style: {
            background: '#fff',
            color: '#000',
          },
        });
        
        // Show confetti
        setShowConfetti(true);
        
        // Close modal after a short delay
        setTimeout(() => {
          onClose();
        }, 1000);
      } catch (error) {
        console.error('Error saving preferences:', error);
        toast.error('Failed to save preferences. Please try again.', {
          duration: 1000,
          position: 'top-center',
          style: {
            background: '#f44336',
            color: '#fff',
          },
        });
      }
    }
  };

  const handleFinish = async () => {
    // console.log('SubleasePreferencesModal: Finishing preferences', preferences, new Date().toISOString());
    try {
      // If user is only looking to list, save and close immediately
      if (preferences.subleaseUserIntent.length === 1 && preferences.subleaseUserIntent[0] === 'looking_to_list') {
        // Call onSave for any additional handling
        await onSave({
          subleaseUserIntent: preferences.subleaseUserIntent,
          lastUpdated: new Date().toISOString()
        });
        onClose();
        return;
      }

      // Otherwise, save all preferences
      // Call onSave for any additional handling
      await onSave({
        subleaseUserIntent: preferences.subleaseUserIntent,
        subleasePreferences: {
          duration: preferences.duration,
          priceRange: preferences.priceRange,
          bedBath: preferences.bedBath,
          gender: preferences.gender,
          notifications: preferences.notifications,
          lastUpdated: new Date().toISOString()
        }
      });
      
      // Show success toast
      toast.success('Preferences saved successfully! 🎉', {
        duration: 1000,
        position: 'top-center',
        style: {
          background: '#fff',
          color: '#000',
        },
      });
      
      // Show confetti
      setShowConfetti(true);
      
      // Close modal after a short delay
      setTimeout(() => {
        onClose();
      }, 1000);
    } catch (error) {
      console.error('Error saving preferences:', error);
      toast.error('Failed to save preferences. Please try again.', {
        duration: 1000,
        position: 'top-center',
        style: {
          background: '#f44336',
          color: '#fff',
        },
      });
    }
  };

  const handleNotificationToggle = (key) => {
    setPreferences(prev => ({
      ...prev,
      notifications: {
        ...prev.notifications,
        [key]: !prev.notifications[key]
      }
    }));
  };

  // Duration handlers
  const handleTagToggle = (tag) => {
    setPreferences(prev => {
      const newTags = prev.duration.tags.includes(tag.value)
        ? prev.duration.tags.filter(t => t !== tag.value)
        : [...prev.duration.tags, tag.value];

      const selectedTerms = commonTerms.filter(term => newTags.includes(term.value));
      const startDate = selectedTerms.reduce((earliest, term) => {
        if (!earliest) return term.startDate;
        return isBefore(term.startDate, earliest) ? term.startDate : earliest;
      }, null);

      const endDate = selectedTerms.reduce((latest, term) => {
        if (!latest) return term.endDate;
        return isBefore(latest, term.endDate) ? term.endDate : latest;
      }, null);

      return {
        ...prev,
        duration: {
          ...prev.duration,
          tags: newTags,
          dateRange: {
            start: startDate?.toISOString() || prev.duration.dateRange.start,
            end: endDate?.toISOString() || prev.duration.dateRange.end
          }
        }
      };
    });
  };

  const handleDateChange = (type, value) => {
    const newDate = value ? new Date(value) : null;
    if (!newDate || isValid(newDate)) {
      setPreferences(prev => ({
        ...prev,
        duration: {
          ...prev.duration,
          dateRange: {
            ...prev.duration.dateRange,
            [type]: newDate?.toISOString()
          }
        }
      }));
    }
  };

  // Price range handlers
  const handleTierSelect = (tier) => {
    setPreferences(prev => ({
      ...prev,
      priceRange: tier.priceRange
    }));
  };

  const handleCustomRangeChange = (type, value) => {
    setPreferences(prev => ({
      ...prev,
      priceRange: {
        ...prev.priceRange,
        [type]: value
      }
    }));
  };

  // Bed/Bath handlers
  const handleBedSelect = (value) => {
    setPreferences(prev => {
      const currentTypes = prev.bedBath.bedTypes;
      let newTypes;

      if (value === 'any') {
        newTypes = ['any'];
      } else {
        const withoutAny = currentTypes.filter(t => t !== 'any');
        newTypes = withoutAny.includes(value)
          ? withoutAny.filter(t => t !== value)
          : [...withoutAny, value];
        
        if (newTypes.length === 0) {
          newTypes = ['any'];
        }
      }

      return {
        ...prev,
        bedBath: {
          ...prev.bedBath,
          bedTypes: newTypes
        }
      };
    });
  };

  const handlePrivateBathToggle = () => {
    setPreferences(prev => ({
      ...prev,
      bedBath: {
        ...prev.bedBath,
        privateBath: !prev.bedBath.privateBath
      }
    }));
  };

  // Gender handlers
  const handleGenderToggle = (gender) => {
    setPreferences(prev => {
      const currentGenders = prev.gender.preferredGenders;
      let newGenders;

      if (gender === 'any') {
        newGenders = ['any'];
      } else {
        const withoutAny = currentGenders.filter(g => g !== 'any');
        newGenders = withoutAny.includes(gender)
          ? withoutAny.filter(g => g !== gender)
          : [...withoutAny, gender];
        
        if (newGenders.length === 0) {
          newGenders = ['any'];
        }
      }

      return {
        ...prev,
        gender: {
          ...prev.gender,
          preferredGenders: newGenders
        }
      };
    });
  };

  const options = [
    {
      id: 'looking_for_sublease',
      title: 'Looking for Sublease',
      description: 'I want to find a sublease for my stay',
      icon: '🏠'
    },
    {
      id: 'looking_to_list',
      title: 'Looking to List',
      description: 'I want to list my place for sublease',
      icon: '📝'
    },
    {
      id: 'browsing',
      title: 'Just Browsing',
      description: 'I\'m just exploring options',
      icon: '👀'
    }
  ];

  const steps = [
    'Start', 'When', 'Budget', 'Space', 'People', 'Done'
  ];

  const ProgressBar = () => (
    <div className="h-2 bg-gray-100 rounded-full mb-4">
      <div 
        className="h-full bg-blue-500 rounded-full transition-all duration-500 ease-out"
        style={{ 
          width: `${(currentStep / (steps.length - 1)) * 100}%`,
        }}
      />
      <div className="flex justify-between px-1 mt-2">
        {steps.map((step, index) => (
          <div 
            key={index}
            className={`text-xs font-medium transition-colors duration-300 ${
              currentStep >= index ? 'text-blue-500' : 'text-gray-400'
            }`}
          >
            {step}
          </div>
        ))}
      </div>
    </div>
  );

  const renderStep = () => {
    switch (currentStep) {
      case 0:
        return (
          <motion.div 
            className="h-full flex flex-col justify-center space-y-6"
            variants={containerVariants}
            initial="hidden"
            animate="visible"
          >
            <motion.div 
              className="text-center"
              variants={itemVariants}
            >
              <h2 className="text-3xl sm:text-4xl font-bold mb-3 bg-gradient-to-r from-[#0A2463] via-[#226ACD] to-[#60A5FA] bg-clip-text text-transparent">
                Welcome to Abode Subleases
              </h2>
              <p className="text-base sm:text-lg text-gray-600 mb-8">
                Let's find your perfect space
              </p>
            </motion.div>
            
            <div className="space-y-4 max-w-xl mx-auto w-full">
              {options.map((option, index) => (
                <motion.button
                  key={option.id}
                  variants={itemVariants}
                  whileHover={{ 
                    scale: 1.02,
                    transition: { type: "spring", stiffness: 400 }
                  }}
                  whileTap={{ scale: 0.98 }}
                  onClick={() => handleOptionSelect(option.id)}
                  className={`w-full p-4 sm:p-6 rounded-xl border-2 transition-all duration-200 
                    ${preferences.subleaseUserIntent.includes(option.id)
                      ? 'bg-gradient-to-r from-[#0A2463] via-[#226ACD] to-[#60A5FA] text-white shadow-lg ring-1 ring-[#0A2463] ring-inset'
                      : 'border-gray-200 hover:border-transparent hover:bg-gradient-to-r hover:from-blue-100 hover:via-blue-50 hover:to-blue-100'
                    }`}
                >
                  <div className="flex items-center space-x-6">
                    <motion.div 
                      className="text-4xl"
                      animate={{ 
                        rotate: [0, 10, -10, 0],
                        scale: [1, 1.2, 1]
                      }}
                      transition={{ 
                        duration: 2,
                        repeat: Infinity,
                        repeatDelay: 1
                      }}
                    >
                      {option.icon}
                    </motion.div>
                    <div className="text-left">
                      <h3 className={`text-xl font-semibold mb-1 ${
                        preferences.subleaseUserIntent.includes(option.id) ? 'text-white' : 'text-gray-800'
                      }`}>
                        {option.title}
                      </h3>
                      <p className={
                        preferences.subleaseUserIntent.includes(option.id) ? 'text-white/90' : 'text-gray-600'
                      }>
                        {option.description}
                      </p>
                    </div>
                  </div>
                </motion.button>
              ))}
            </div>

            {/* Next Button */}
            {preferences.subleaseUserIntent.length > 0 && (
              <motion.div
                variants={itemVariants}
                className="flex justify-center mt-8"
              >
                <button
                  onClick={handleNext}
                  className="relative px-8 py-3 rounded-lg overflow-hidden group transition-all duration-200 bg-white border-2 border-gray-200 hover:border-gray-300"
                >
                  <span className="relative text-gray-700 font-medium text-lg">Continue</span>
                </button>
              </motion.div>
            )}
          </motion.div>
        );
      case 1:
        return (
          <motion.div
            variants={pageVariants}
            initial="hidden"
            animate="visible"
            exit="exit"
          >
            <div className="w-full">
              <motion.div 
                className="text-center mb-8"
                variants={itemVariants}
              >
                <h2 className="text-2xl font-bold text-gray-800 mb-2">
                  When are you looking to sublease?
                </h2>
                <p className="text-gray-600">
                  Select one or more terms or choose specific dates
                </p>
              </motion.div>

              {/* Common Terms */}
              <motion.div 
                className="mb-8"
                variants={itemVariants}
              >
                <h3 className="text-lg font-semibold text-gray-700 mb-4">
                  Common Terms
                </h3>
                <div className="grid grid-cols-2 gap-3">
                  {commonTerms.map((term) => (
                    <motion.button
                      key={term.id}
                      variants={itemVariants}
                      whileHover={{ scale: 1.02 }}
                      whileTap={{ scale: 0.98 }}
                      onClick={() => handleTagToggle(term)}
                      className={`p-4 rounded-lg border-2 transition-all duration-200 ${
                        preferences.duration.tags.includes(term.value)
                          ? 'border-blue-500 bg-blue-50'
                          : 'border-gray-200 hover:border-blue-300'
                      }`}
                    >
                      <div className="text-left">
                        <h4 className="font-semibold text-gray-800">{term.label}</h4>
                        <p className="text-sm text-gray-600">{term.description}</p>
                      </div>
                    </motion.button>
                  ))}
                </div>
              </motion.div>

              {/* Date Range Picker */}
              <motion.div 
                className="mb-8"
                variants={itemVariants}
              >
                <h3 className="text-lg font-semibold text-gray-700 mb-4">
                  Custom Dates
                </h3>
                <div className="grid grid-cols-2 gap-4">
                  <motion.div
                    variants={itemVariants}
                  >
                    <label className="block text-sm font-medium text-gray-700 mb-2">
                      Start Date
                    </label>
                    <input
                      type="date"
                      value={preferences.duration.dateRange.start ? format(new Date(preferences.duration.dateRange.start), 'yyyy-MM-dd') : ''}
                      onChange={(e) => handleDateChange('start', e.target.value)}
                      className="w-full p-2 border border-gray-200 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                      min={format(new Date(), 'yyyy-MM-dd')}
                    />
                  </motion.div>
                  <motion.div
                    variants={itemVariants}
                  >
                    <label className="block text-sm font-medium text-gray-700 mb-2">
                      End Date
                    </label>
                    <input
                      type="date"
                      value={preferences.duration.dateRange.end ? format(new Date(preferences.duration.dateRange.end), 'yyyy-MM-dd') : ''}
                      onChange={(e) => handleDateChange('end', e.target.value)}
                      className="w-full p-2 border border-gray-200 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                      min={preferences.duration.dateRange.start ? format(new Date(preferences.duration.dateRange.start), 'yyyy-MM-dd') : format(new Date(), 'yyyy-MM-dd')}
                    />
                  </motion.div>
                </div>
              </motion.div>
            </div>
          </motion.div>
        );
      case 2:
        return (
          <motion.div
            variants={pageVariants}
            initial="hidden"
            animate="visible"
            exit="exit"
          >
            <div className="w-full">
              <motion.div 
                className="text-center mb-8"
                variants={itemVariants}
              >
                <h2 className="text-2xl font-bold text-gray-800 mb-2">
                  What's Your Ideal Living Experience?
                </h2>
                <p className="text-gray-600">
                  Choose the lifestyle that best matches your preferences
                </p>
              </motion.div>

              {/* Price Tiers */}
              <motion.div 
                className="space-y-4 mb-8"
                variants={itemVariants}
              >
                {priceTiers.map((tier) => (
                  <motion.button
                    key={tier.id}
                    variants={itemVariants}
                    whileHover={{ scale: 1.02 }}
                    whileTap={{ scale: 0.98 }}
                    onClick={() => handleTierSelect(tier)}
                    className={`w-full p-4 rounded-xl border-2 transition-all duration-200 ${
                      preferences.priceRange.min === tier.priceRange.min && preferences.priceRange.max === tier.priceRange.max
                        ? 'border-blue-500 bg-blue-50'
                        : 'border-gray-200 hover:border-blue-300'
                    }`}
                  >
                    <div className="flex items-start space-x-4">
                      <div className="text-xl">{tier.icon}</div>
                      <div className="text-left flex-1">
                        <div className="flex justify-between items-start">
                          <div>
                            <h3 className="text-md font-semibold text-gray-800">{tier.label}</h3>
                            <p className="text-sm text-gray-600">{tier.description}</p>
                          </div>
                          <div className="text-right">
                            <div className="text-md font-bold text-gray-800">
                              ${tier.priceRange.min} - ${tier.priceRange.max}
                            </div>
                            <div className="text-sm text-gray-500">per month</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </motion.button>
                ))}
              </motion.div>

              {/* Custom Price Range */}
              <motion.div 
                className="mb-8"
                variants={itemVariants}
              >
                <h3 className="text-lg font-semibold text-gray-700 mb-2">
                  Price Range Preference
                </h3>
                <div className="grid grid-cols-2 gap-4">
                  <motion.div
                    variants={itemVariants}
                  >
                    <label className="block text-sm font-medium text-gray-700 mb-2">
                      Minimum
                    </label>
                    <div className="relative">
                      <span className="absolute left-3 top-2 text-gray-500">$</span>
                      <input
                        type="number"
                        value={preferences.priceRange.min}
                        onChange={(e) => handleCustomRangeChange('min', parseInt(e.target.value))}
                        className="w-full pl-7 p-2 border border-gray-200 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                        min="500"
                        max="1500"
                        step="100"
                      />
                    </div>
                  </motion.div>
                  <motion.div
                    variants={itemVariants}
                  >
                    <label className="block text-sm font-medium text-gray-700 mb-2">
                      Maximum
                    </label>
                    <div className="relative">
                      <span className="absolute left-3 top-2 text-gray-500">$</span>
                      <input
                        type="number"
                        value={preferences.priceRange.max}
                        onChange={(e) => handleCustomRangeChange('max', parseInt(e.target.value))}
                        className="w-full pl-7 p-2 border border-gray-200 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                        min="500"
                        max="1500"
                        step="100"
                      />
                    </div>
                  </motion.div>
                </div>
              </motion.div>
            </div>
          </motion.div>
        );
      case 3:
        return (
          <motion.div
            variants={pageVariants}
            initial="hidden"
            animate="visible"
            exit="exit"
          >
            <div className="w-full">
              <motion.div 
                className="text-center mb-8"
                variants={itemVariants}
              >
                <h2 className="text-2xl font-bold text-gray-800 mb-2">
                  Bedroom & Bathroom Preferences
                </h2>
                <p className="text-gray-600">
                  Select your preferred bedroom configurations
                </p>
              </motion.div>

              {/* Any Option */}
              <motion.div 
                className="mb-8"
                variants={itemVariants}
              >
                <motion.button
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                  onClick={() => handleBedSelect('any')}
                  className={`w-full p-4 rounded-xl border-2 transition-all duration-200 ${
                    preferences.bedBath.bedTypes.includes('any')
                      ? 'border-blue-500 bg-blue-50'
                      : 'border-gray-200 hover:border-blue-300'
                  }`}
                >
                  <div className="flex items-center space-x-4">
                    <div className="text-2xl">🏠</div>
                    <div className="text-left flex-1">
                      <div className="flex justify-between items-start">
                        <div>
                          <h3 className="text-md font-semibold text-gray-800">Any Configuration</h3>
                          <p className="text-sm text-gray-600">Open to any bedroom setup</p>
                        </div>
                        {preferences.bedBath.bedTypes.includes('any') && (
                          <div className="text-blue-500">
                            <span className="text-xl">✓</span>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </motion.button>

                <div className="grid grid-cols-2 sm:grid-cols-3 gap-3 mt-4">
                  {bedOptions.map((option) => (
                    <motion.button
                      key={option.value}
                      variants={itemVariants}
                      whileHover={{ scale: 1.02 }}
                      whileTap={{ scale: 0.98 }}
                      onClick={() => handleBedSelect(option.value)}
                      className={`p-4 rounded-xl border-2 transition-all duration-200 ${
                        preferences.bedBath.bedTypes.includes(option.value)
                          ? 'border-blue-500 bg-blue-50'
                          : 'border-gray-200 hover:border-blue-300'
                      }`}
                    >
                      <div className="flex items-center space-x-3">
                        <div className="text-xl">{option.icon}</div>
                        <div className="text-left">
                          <h3 className="text-sm font-semibold text-gray-800">{option.label}</h3>
                          <p className="text-xs text-gray-600">{option.description}</p>
                        </div>
                        {preferences.bedBath.bedTypes.includes(option.value) && (
                          <div className="ml-auto text-blue-500">
                            <span className="text-lg">✓</span>
                          </div>
                        )}
                      </div>
                    </motion.button>
                  ))}
                </div>
              </motion.div>

              {/* Bathroom Toggle */}
              <motion.div 
                className="mb-8"
                variants={itemVariants}
              >
                <h3 className="text-lg font-semibold text-gray-700 mb-2">
                  Bathroom Preference
                </h3>
                <motion.button
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                  onClick={handlePrivateBathToggle}
                  className={`w-full p-4 rounded-xl border-2 transition-all duration-200 ${
                    preferences.bedBath.privateBath
                      ? 'border-blue-500 bg-blue-50'
                      : 'border-gray-200 hover:border-blue-300'
                  }`}
                >
                  <div className="flex items-center justify-between">
                    <div className="flex items-center space-x-4">
                      <div className="text-2xl">🚽</div>
                      <div>
                        <h3 className="text-md font-semibold text-gray-800">Private Bathroom</h3>
                        <p className="text-sm text-gray-600">
                          {preferences.bedBath.privateBath 
                            ? 'Prefer a private bathroom'
                            : 'Shared bathroom is fine'}
                        </p>
                      </div>
                    </div>
                    {preferences.bedBath.privateBath && (
                      <div className="text-blue-500">
                        <span className="text-xl">✓</span>
                      </div>
                    )}
                  </div>
                </motion.button>
              </motion.div>
            </div>
          </motion.div>
        );
      case 4:
        return (
          <motion.div
            variants={pageVariants}
            initial="hidden"
            animate="visible"
            exit="exit"
          >
            <div className="w-full">
              <motion.div 
                className="text-center mb-8"
                variants={itemVariants}
              >
                <h2 className="text-2xl font-bold text-gray-800 mb-2">
                  Roommate Gender Preference
                </h2>
                <p className="text-gray-600">
                  Select preferred gender(s) for potential roommates
                </p>
              </motion.div>

              <motion.div 
                className="space-y-4"
                variants={itemVariants}
              >
                {genderOptions.map((option) => (
                  <motion.button
                    key={option.value}
                    variants={itemVariants}
                    whileHover={{ scale: 1.02 }}
                    whileTap={{ scale: 0.98 }}
                    onClick={() => handleGenderToggle(option.value)}
                    className={`w-full p-4 rounded-xl border-2 transition-all duration-200 ${
                      preferences.gender.preferredGenders.includes(option.value)
                        ? 'border-blue-500 bg-blue-50'
                        : 'border-gray-200 hover:border-blue-300'
                    }`}
                  >
                    <div className="flex items-center space-x-4">
                      <div className="text-2xl">{option.icon}</div>
                      <div className="text-left flex-1">
                        <div className="flex justify-between items-start">
                          <div>
                            <h3 className="text-md font-semibold text-gray-800">{option.label}</h3>
                            <p className="text-sm text-gray-600">{option.description}</p>
                          </div>
                          {preferences.gender.preferredGenders.includes(option.value) && (
                            <div className="text-blue-500">
                              <span className="text-xl">✓</span>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </motion.button>
                ))}
              </motion.div>
            </div>
          </motion.div>
        );
      case 5:
        return (
          <motion.div
            variants={containerVariants}
            initial="hidden"
            animate="visible"
            className="space-y-6"
          >
            {/* Success Header */}
            <motion.div 
              className="text-center"
              variants={itemVariants}
            >
              <h2 className="text-2xl font-bold text-gray-800 mb-2">
                Amazing! Your Perfect Space Awaits
              </h2>
              <p className="text-gray-600 mb-6">
                We'll help you find the ideal sublease
              </p>
            </motion.div>

            {/* Preferences Summary */}
            <motion.div 
              className="bg-gray-50 rounded-xl p-6"
              variants={itemVariants}
            >
              <h3 className="font-semibold text-gray-800 mb-4">Your Preferences</h3>
              <div className="grid grid-cols-2 gap-4 text-left text-sm">
                <div className="space-y-2">
                  <p className="flex items-center text-gray-600">
                    <span className="mr-2">📅</span>
                    {preferences.duration.tags.length > 0 
                      ? preferences.duration.tags.join(', ')
                      : `${format(new Date(preferences.duration.dateRange.start), 'MMM yyyy')} - ${format(new Date(preferences.duration.dateRange.end), 'MMM yyyy')}`}
                  </p>
                  <p className="flex items-center text-gray-600">
                    <span className="mr-2">💰</span>
                    {`$${preferences.priceRange.min} - $${preferences.priceRange.max}`}
                  </p>
                </div>
                <div className="space-y-2">
                  <p className="flex items-center text-gray-600">
                    <span className="mr-2">👥</span>
                    {preferences.gender.preferredGenders.includes('any')
                      ? 'Open to all genders'
                      : `Preferred: ${preferences.gender.preferredGenders.join(', ')}`}
                  </p>
                  <p className="flex items-center text-gray-600">
                    <span className="mr-2">🚽</span>
                    {preferences.bedBath.privateBath 
                      ? 'Private bathroom preferred'
                      : 'Shared bathroom okay'}
                  </p>
                </div>
              </div>
            </motion.div>

            {/* Notification Settings */}
            <motion.div 
              className="space-y-4"
              variants={itemVariants}
            >
              <div className="flex items-start justify-between p-4 rounded-xl border-2 border-gray-200">
                <div className="flex items-start space-x-4 flex-1 mr-4">
                  <div className="text-2xl mt-1">🚀</div>
                  <div className="text-left min-w-0">
                    <h3 className="text-md font-semibold text-gray-800">Get First Access to Matches</h3>
                    <p className="text-sm text-gray-600">
                      Be the first to know when new subleases matching your preferences become available
                    </p>
                  </div>
                </div>
                <div className="flex-shrink-0">
                  <Switch
                    checked={preferences.notifications.emailUpdates}
                    onChange={() => handleNotificationToggle('emailUpdates')}
                    className={`${
                      preferences.notifications.emailUpdates ? 'bg-blue-500' : 'bg-gray-200'
                    } relative inline-flex h-6 w-11 items-center rounded-full transition-colors focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2`}
                  >
                    <span className="sr-only">Enable email updates</span>
                    <span
                      className={`${
                        preferences.notifications.emailUpdates ? 'translate-x-6' : 'translate-x-1'
                      } inline-block h-4 w-4 transform rounded-full bg-white transition-transform`}
                    />
                  </Switch>
                </div>
              </div>

              <div className="flex items-start justify-between p-4 rounded-xl border-2 border-gray-200">
                <div className="flex items-start space-x-4 flex-1 mr-4">
                  <div className="text-2xl mt-1">🤝</div>
                  <div className="text-left min-w-0">
                    <h3 className="text-md font-semibold text-gray-800">Add me to the list of verified students</h3>
                    <p className="text-sm text-gray-600">
                      Double your chances of finding a sublease. Let verified students with matching subleases reach out to you
                    </p>
                  </div>
                </div>
                <div className="flex-shrink-0">
                  <Switch
                    checked={preferences.notifications.allowListing}
                    onChange={() => handleNotificationToggle('allowListing')}
                    className={`${
                      preferences.notifications.allowListing ? 'bg-blue-500' : 'bg-gray-200'
                    } relative inline-flex h-6 w-11 items-center rounded-full transition-colors focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2`}
                  >
                    <span className="sr-only">Enable listing</span>
                    <span
                      className={`${
                        preferences.notifications.allowListing ? 'translate-x-6' : 'translate-x-1'
                      } inline-block h-4 w-4 transform rounded-full bg-white transition-transform`}
                    />
                  </Switch>
                </div>
              </div>
            </motion.div>
          </motion.div>
        );
      default:
        return null;
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <div className="fixed inset-0 flex items-center justify-center p-4">
        <div className="w-full max-w-2xl bg-white rounded-2xl shadow-2xl">
          <div className="h-[85vh] flex flex-col">
            {/* Fixed header with progress */}
            {currentStep > 0 && (
              <div className="px-8 pt-6 pb-4 border-b border-gray-100">
                <ProgressBar />
              </div>
            )}

            {/* Content area with fixed height */}
            <div className="flex-1 px-8 py-6 overflow-y-auto">
              {renderStep()}
            </div>

            {/* Fixed footer with navigation */}
            {currentStep > 0 && (
              <div className="px-8 py-4 border-t border-gray-100 bg-white">
                <div className="flex justify-between items-center">
                  <button
                    onClick={handleBack}
                    className="px-6 py-2 text-gray-600 hover:text-gray-800 transition-colors"
                  >
                    Back
                  </button>
                  {currentStep < steps.length - 1 ? (
                    <button
                      onClick={handleNext}
                      className="relative px-6 py-2 rounded-lg overflow-hidden group transition-all duration-200"
                    >
                      {/* Gradient Background */}
                      <div className="absolute inset-0 bg-blue-500 text-white border-2 border-blue-500 transition-opacity duration-200 opacity-100" />
                      <span className="relative text-white font-medium">Next</span>
                    </button>
                  ) : (
                    <button
                      onClick={handleFinish}
                      className="relative px-6 py-2 rounded-lg overflow-hidden group transition-all duration-200"
                    >
                      {/* Gradient Background */}
                      <div className="absolute inset-0 bg-gradient-to-r from-[#0A2463] via-[#226ACD] to-[#60A5FA] transition-opacity duration-200 opacity-100" />
                      <span className="relative text-white font-medium">Complete</span>
                    </button>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {isOpen && currentStep === 0 && (
        <ReactConfetti
          width={windowSize.width}
          height={windowSize.height}
          recycle={false}
          numberOfPieces={300}
          gravity={0.15}
          colors={['#3B82F6', '#8B5CF6', '#EC4899', '#60A5FA', '#F472B6']}
          opacity={0.8}
          tweenDuration={5000}
        />
      )}
      {showConfetti && (
        <ReactConfetti
          width={windowSize.width}
          height={windowSize.height}
          recycle={false}
          numberOfPieces={500}
          gravity={0.3}
          colors={['#4CAF50', '#8BC34A', '#CDDC39', '#FFEB3B', '#FFC107']}
          opacity={0.8}
          tweenDuration={3000}
        />
      )}
    </Modal>
  );
};

export default SubleasePreferencesModal; 