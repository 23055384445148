import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { subleaseProcessService } from '../../backend/subleaseAPI';

const SubleaseStatusBar = ({ subleaseId, conversationId }) => {
  const navigate = useNavigate();
  const [status, setStatus] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchStatus = async () => {
      if (!subleaseId) {
        console.log('No subleaseId provided to SubleaseStatusBar');
        setLoading(false);
        return;
      }

      try {
        console.log('Fetching sublease status for ID:', subleaseId);
        const processStatus = await subleaseProcessService.getProcessStatus(subleaseId);
        console.log('Received sublease status:', processStatus);
        setStatus(processStatus);
      } catch (error) {
        console.error('Error fetching sublease status:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchStatus();
  }, [subleaseId]);

  // If no subleaseId is provided, don't render anything
  if (!subleaseId) {
    return null;
  }

  const getStatusColor = (status) => {
    console.log('Getting color for status:', status);
    switch (status) {
      case 'tour_scheduled':
        return 'bg-blue-100 text-blue-800';
      case 'tour_completed':
        return 'bg-green-100 text-green-800';
      case 'terms_agreed':
        return 'bg-purple-100 text-purple-800';
      default:
        return 'bg-gray-100 text-gray-800';
    }
  };

  const getStatusText = (status) => {
    console.log('Getting text for status:', status);
    switch (status) {
      case 'tour_scheduled':
        return 'Tour Scheduled';
      case 'tour_completed':
        return 'Tour Completed';
      case 'terms_agreed':
        return 'Terms Agreed';
      default:
        return 'Initial Chat';
    }
  };

  if (loading) {
    console.log('SubleaseStatusBar is loading...');
    return (
      <div className="p-2 bg-gray-50 border-b">
        <div className="animate-pulse h-4 w-24 bg-gray-200 rounded"></div>
      </div>
    );
  }

  console.log('Rendering SubleaseStatusBar with status:', status);
  return (
    <div className="p-2 bg-gray-50 border-b">
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-2">
          <span className={`px-2 py-1 rounded-full text-xs font-medium ${getStatusColor(status?.status)}`}>
            {getStatusText(status?.status)}
          </span>
        </div>
        <div className="flex gap-2">
          {status?.status === 'tour_scheduled' ? (
            <button
              onClick={() => navigate(`/sublease/${subleaseId}/tour-details`)}
              className="text-xs text-blue-600 hover:text-blue-800 hover:underline"
            >
              View Tour Details
            </button>
          ) : (
            <button
              onClick={() => navigate(`/sublease/${subleaseId}/book-tour`)}
              className="text-xs text-blue-600 hover:text-blue-800 hover:underline"
            >
              Book Tour
            </button>
          )}
          <button
            onClick={() => navigate(`/sublease/${subleaseId}/terms`)}
            className="text-xs text-blue-600 hover:text-blue-800 hover:underline"
          >
            View Terms
          </button>
        </div>
      </div>
    </div>
  );
};

export default SubleaseStatusBar; 