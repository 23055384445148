import { getDoc, doc } from 'firebase/firestore';
import { db } from '../firebase';
import { sendEmail } from '../backend/emailAPI';

class NotificationService {
  constructor() {
    this.notificationCache = new Map(); // Cache to track recent notifications
    this.CACHE_DURATION = 60 * 60 * 1000; // 1 hour in milliseconds
  }

  async sendNewConversationNotification(conversationId, participants) {
    const cacheKey = `conv_${conversationId}`;
    if (this.isNotificationCached(cacheKey)) return;

    try {
      // Get user details for both participants
      const [sender, receiver] = await Promise.all(
        participants.map(async (userId) => {
          const userDoc = await getDoc(doc(db, 'users', userId));
          const userData = userDoc.data();
          return {
            id: userId,
            email: userData.email,
            firstName: userData.firstName,
            lastName: userData.lastName
          };
        })
      );

      // Send notification to receiver
      await sendEmail(
        receiver.email,
        "contact@getabode.co",        
        `Abode: New Message from ${sender.firstName} ${sender.lastName}`,
        `${sender.firstName} ${sender.lastName} has started a conversation with you about your sublease.`,
        `
          <h2>New Conversation Started</h2>
          <p>${sender.firstName} ${sender.lastName} has started a conversation with you about your sublease.</p>
          <p><a href="${process.env.REACT_APP_BASE_URL}/messages/${conversationId}" style="color: #3B82F6; text-decoration: none;">Click here to view the conversation</a></p>
        `
      );

      // Cache the notification
      this.cacheNotification(cacheKey);
    } catch (error) {
      throw error;
    }
  }

  async sendNewMessageNotification(conversationId, message, participants) {
    const cacheKey = `msg_${conversationId}_${message.senderId}`;
    if (this.isNotificationCached(cacheKey)) return;

    try {
      // Get user details for both participants
      const [sender, receiver] = await Promise.all(
        participants.map(async (userId) => {
          const userDoc = await getDoc(doc(db, 'users', userId));
          const userData = userDoc.data();
          return {
            id: userId,
            email: userData.email,
            firstName: userData.firstName,
            lastName: userData.lastName
          };
        })
      );

      // Only send notification to receiver
      if (receiver.id !== message.senderId) {
        await sendEmail(
          receiver.email,
          "contact@getabode.co",
          `Abode: New Message from ${sender.firstName} ${sender.lastName}`,
          `${sender.firstName} ${sender.lastName} sent you a new message: ${message.content || 'Image message'}`,
          `
            <h2>Abode: New Message</h2>
            <p>${sender.firstName} ${sender.lastName} sent you a new message:</p>
            <p>${message.content || 'Image message'}</p>
            <p><a href="${process.env.REACT_APP_BASE_URL}/messages/${conversationId}" style="color: #3B82F6; text-decoration: none;">Click here to view the conversation</a></p>
          `
        );
      }

      // Cache the notification
      this.cacheNotification(cacheKey);
    } catch (error) {
      throw error;
    }
  }

  async sendTourBookingNotification(tourData) {
    const cacheKey = `tour_${tourData.id}`;
    if (this.isNotificationCached(cacheKey)) return;

    try {
      const adminEmail = "contact@getabode.co";
      
      const messageString = `
        New Tour Booking Request
        
        A new tour has been booked. Here are the details:
        
        Property: ${tourData.propertyName}
        Date: ${tourData.date}
        Time: ${tourData.time}
        
        Student Information:
        Name: ${tourData.studentName}
        Year: ${tourData.studentYear}
        Major: ${tourData.studentMajor}
        
        View more details on the Abode platform:
        ${process.env.REACT_APP_BASE_URL}/tours/${tourData.id}
        
        Best Wishes,
        Abode Team
      `;

      const htmlContent = `
        <html>
          <body>
            <h2>New Tour Booking Request</h2>
            <p>A new tour has been booked. Here are the details:</p>
            <ul>
              <li><strong>Property:</strong> ${tourData.propertyName}</li>
              <li><strong>Date:</strong> ${tourData.date}</li>
              <li><strong>Time:</strong> ${tourData.time}</li>
            </ul>
            <h3>Student Information:</h3>
            <ul>
              <li><strong>Name:</strong> ${tourData.studentName}</li>
              <li><strong>Year:</strong> ${tourData.studentYear}</li>
              <li><strong>Major:</strong> ${tourData.studentMajor}</li>
            </ul>
            <p>View more details on the Abode platform:</p>
            <p><a href="${process.env.REACT_APP_BASE_URL}/tours/${tourData.id}" style="color: #3B82F6; text-decoration: none;">View Tour Details</a></p>
            <p>Best Wishes,<br/>
            Abode Team</p>
          </body>
        </html>
      `;

      await sendEmail(
        adminEmail,
        "contact@getabode.co",
        "New Tour Booking Request",
        messageString,
        htmlContent
      );

      this.cacheNotification(cacheKey);
    } catch (error) {
      console.error('Error sending tour booking notification:', error);
      throw error;
    }
  }

  async sendTermAgreementNotification(agreementData) {
    const cacheKey = `term_${agreementData.id}`;
    if (this.isNotificationCached(cacheKey)) return;

    try {
      const adminEmail = "contact@getabode.co";
      
      const messageString = `
        New Term Agreement Submitted
        
        A new term agreement has been submitted. Here are the details:
        
        Property: ${agreementData.propertyName}
        Subleaser: ${agreementData.subleaserName}
        Subleasee: ${agreementData.subleaseeName}
        Start Date: ${agreementData.startDate}
        End Date: ${agreementData.endDate}
        Rent Amount: ${agreementData.rentAmount}
        
        View more details on the Abode platform:
        ${process.env.REACT_APP_BASE_URL}/subleases/${agreementData.id}
        
        Best Wishes,
        Abode Team
      `;

      const htmlContent = `
        <html>
          <body>
            <h2>New Term Agreement Submitted</h2>
            <p>A new term agreement has been submitted. Here are the details:</p>
            <ul>
              <li><strong>Property:</strong> ${agreementData.propertyName}</li>
              <li><strong>Subleaser:</strong> ${agreementData.subleaserName}</li>
              <li><strong>Subleasee:</strong> ${agreementData.subleaseeName}</li>
              <li><strong>Start Date:</strong> ${agreementData.startDate}</li>
              <li><strong>End Date:</strong> ${agreementData.endDate}</li>
              <li><strong>Rent Amount:</strong> ${agreementData.rentAmount}</li>
            </ul>
            <p>View more details on the Abode platform:</p>
            <p><a href="${process.env.REACT_APP_BASE_URL}/subleases/${agreementData.id}" style="color: #3B82F6; text-decoration: none;">View Agreement Details</a></p>
            <p>Best Wishes,<br/>
            Abode Team</p>
          </body>
        </html>
      `;

      await sendEmail(
        adminEmail,
        "contact@getabode.co",
        "New Term Agreement Submitted",
        messageString,
        htmlContent
      );

      this.cacheNotification(cacheKey);
    } catch (error) {
      console.error('Error sending term agreement notification:', error);
      throw error;
    }
  }

  async sendUserDeletionNotification(userData) {
    const cacheKey = `user_delete_${userData.id}`;
    if (this.isNotificationCached(cacheKey)) return;

    try {
      const adminEmail = "contact@getabode.co";
      
      const messageString = `
        User Account Deleted
        
        A user has deleted their account. Here are the details:
        
        Name: ${userData.firstName} ${userData.lastName}
        Email: ${userData.email}
        Deletion Date: ${new Date().toLocaleDateString()}
        Deletion Time: ${new Date().toLocaleTimeString()}
        
        Best Wishes,
        Abode Team
      `;

      const htmlContent = `
        <html>
          <body>
            <h2>User Account Deleted</h2>
            <p>A user has deleted their account. Here are the details:</p>
            <ul>
              <li><strong>Name:</strong> ${userData.firstName} ${userData.lastName}</li>
              <li><strong>Email:</strong> ${userData.email}</li>
              <li><strong>Deletion Date:</strong> ${new Date().toLocaleDateString()}</li>
              <li><strong>Deletion Time:</strong> ${new Date().toLocaleTimeString()}</li>
            </ul>
            <p>Best Wishes,<br/>
            Abode Team</p>
          </body>
        </html>
      `;

      await sendEmail(
        adminEmail,
        "contact@getabode.co",
        "User Account Deleted",
        messageString,
        htmlContent
      );

      this.cacheNotification(cacheKey);
    } catch (error) {
      console.error('Error sending user deletion notification:', error);
      throw error;
    }
  }

  isNotificationCached(key) {
    const cached = this.notificationCache.get(key);
    if (!cached) return false;
    
    const isExpired = Date.now() - cached.timestamp > this.CACHE_DURATION;
    if (isExpired) {
      this.notificationCache.delete(key);
      return false;
    }
    return true;
  }

  cacheNotification(key) {
    this.notificationCache.set(key, {
      timestamp: Date.now()
    });
  }
}

export const notificationService = new NotificationService(); 