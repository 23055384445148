import React, { useState, useEffect, useRef, Suspense, memo, useMemo } from "react";
import LoadingAnimation from "./LoadingAnimation";
import { useInView } from 'react-intersection-observer';

// Dynamically import ListingCard with custom loading
const ListingCard = React.lazy(() => import("./ListingCard"));

// Memoized individual card container to prevent unnecessary re-renders
const CardContainer = memo(({ apartment, filters, onMouseEnter, onMouseLeave }) => {
  // Using react-intersection-observer to track when cards enter viewport
  const { ref, inView } = useInView({
    threshold: 0.1,
    triggerOnce: false,
    rootMargin: '200px 0px',
  });

  return (
    <div
      ref={ref}
      key={apartment.id}
      onMouseEnter={() => onMouseEnter(apartment.id)}
      onMouseLeave={onMouseLeave}
      className="transform transition-opacity duration-300"
      style={{ opacity: inView ? 1 : 0 }}
    >
      {inView && (
        <Suspense fallback={<LoadingAnimation />}>
          <ListingCard apartment={apartment} filters={filters} />
        </Suspense>
      )}
      {!inView && <LoadingAnimation />}
    </div>
  );
});

function Listings({ apartments, filters, setFilters }) {
  const [itemsPerPage, setItemsPerPage] = useState(12);
  const loaderRef = useRef(null);

  // Memoize the current visible items to prevent unnecessary calculations
  const currentItems = useMemo(() => {
    return (apartments || []).slice(0, itemsPerPage);
  }, [apartments, itemsPerPage]);

  const handleMouseEnter = (apartmentId) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      hoverAptID: apartmentId,
    }));
  };

  const handleMouseLeave = () => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      hoverAptID: null,
    }));
  };

  // Infinite scroll with optimized observer
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && apartments && itemsPerPage < apartments.length) {
          // Incrementally load more items when user scrolls near bottom
          setItemsPerPage((prev) => Math.min(prev + 8, apartments ? apartments.length : 0));
        }
      },
      {
        root: null,
        rootMargin: '200px 0px',
        threshold: 0.1,
      }
    );

    if (loaderRef.current) {
      observer.observe(loaderRef.current);
    }

    return () => {
      if (loaderRef.current) {
        observer.unobserve(loaderRef.current);
      }
    };
  }, [itemsPerPage, apartments]);

  return (
    <div className="mx-auto p-4 md:px-16">
      <div
        className="grid gap-y-8 gap-x-8"
        style={{
          gridTemplateColumns: "repeat(auto-fit, minmax(250px, 1fr))",
        }}
      >
        {/* Only render the currently visible items */}
        {currentItems.map((apartment) => (
          <CardContainer
            key={apartment.id}
            apartment={apartment}
            filters={filters}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          />
        ))}
        
        {/* Loading indicator */}
        {apartments && itemsPerPage < apartments.length && (
          <div
            ref={loaderRef}
            className="col-span-full flex justify-center py-8"
            aria-hidden="true"
          >
            <div className="w-8 h-8 border-4 border-gray-200 border-t-gray-600 rounded-full animate-spin"></div>
          </div>
        )}
      </div>
    </div>
  );
}

export default memo(Listings);
