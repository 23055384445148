import React, { useState, useEffect, useContext, useMemo, memo, useCallback } from "react";
import { ImageSlider } from "./ImageSlider";
import { trackClick } from "../../backend/dbAPI";
import { auth } from "../../firebase/index";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import { analytics, logEvent } from "../../firebase/index";

import {
  distToWalkTime,
  getBedRange,
  getBathRange,
  getTotalPriceRange,
  getPerPersonPriceRange,
} from "../../backend/utils";

import AppContext from "../../AppContext";
const contractNoteSymbol = "*";

const ListingCard = memo(function ListingCard({
  apartment,
  filters = { pricePerPerson: true, hoverAptID: null },
}) {
  const navigate = useNavigate();
  const { favorites, addToFavorites, removeFromFavorites } =
    useContext(AppContext);

  // Memoize these calculations to prevent recalculations on re-renders
  const bedRange = useMemo(() => getBedRange(apartment), [apartment]);
  const bathRange = useMemo(() => getBathRange(apartment), [apartment]);
  const totalPriceRange = useMemo(() => getTotalPriceRange(apartment), [apartment]);
  const perPersPriceRange = useMemo(() => getPerPersonPriceRange(apartment), [apartment]);
  
  // Only process first image for initial render, load others on demand
  const thumbnailImage = apartment.images && apartment.images.length > 0 ? [apartment.images[0]] : [];
  const [fullImages, setFullImages] = useState(null);
  const [imagesLoaded, setImagesLoaded] = useState(false);
  
  // Add loadFullImages to useEffect dependencies and use useCallback
  const loadFullImages = useCallback(() => {
    if (fullImages) return;
    
    let images = apartment.images.slice(0, 10);
    // If images array has less than 5, populate the rest from apartment.floorPlans' images
    if (images.length < 5) {
      for (let i = 0; i < apartment.floorPlans.length; i++) {
        const floorPlanImages = apartment.floorPlans[i].images;
        if (floorPlanImages) {
          for (let j = 0; j < floorPlanImages.length && images.length < 5; j++) {
            images.push(floorPlanImages[j]);
          }
        }
        if (images.length >= 10) break;
      }
    }
    setFullImages(images);
    setImagesLoaded(true);
  }, [apartment.images, apartment.floorPlans, fullImages]);

  const [user] = useAuthState(auth);

  const link = apartment.id.includes("/")
    ? "/group-listing/" + apartment.id
    : "/listing/" + apartment.id;

  const [isFavorited, setIsFavorited] = useState(
    favorites.includes(apartment.id)
  );

  const anyPlanPricePerPersFalse = useMemo(() => 
    apartment.floorPlans.some(
      (plan) => plan.perPers === false && plan.beds !== 0 && plan.beds !== 1
    ), [apartment.floorPlans]);

  // Determine which price display function to use
  const PriceComp = useMemo(() =>
    filters.pricePerPerson && anyPlanPricePerPersFalse
      ? displayPerPersonPrice(perPersPriceRange, totalPriceRange)
      : displayNormalPrice(totalPriceRange),
    [filters.pricePerPerson, anyPlanPricePerPersFalse, perPersPriceRange, totalPriceRange]
  );

  const handleFavClick = async (e) => {
    e.stopPropagation();
    if (user) {
      setIsFavorited(!isFavorited);
      if (!isFavorited) {
        addToFavorites(user.uid, apartment.id);
        logEvent(analytics, "add_fav_click", {
          apartment: apartment.id,
          userId: user.uid,
          timestamp: Date.now(),
        });
      } else {
        removeFromFavorites(user.uid, apartment.id);
      }
    } else {
      alert("Please login to save apartments");
    }
  };

  useEffect(() => {
    if (favorites && favorites.includes(apartment.id)) {
      setIsFavorited(true);
    } else {
      setIsFavorited(false);
    }
  }, [favorites, apartment.id]);

  const onListingClick = (e) => {
    e.preventDefault();
    
    // Load full images when clicked
    loadFullImages();
    
    if (user) {
      logEvent(analytics, "apartment_click", {
        apartment: apartment.id,
        userId: user.uid,
        timestamp: Date.now(),
      });
    }

    if (link.startsWith("/")) {
      if (user) {
        navigate(link);
        trackClick(user.uid, apartment.id).catch((error) => {
          console.error("Error tracking click:", error);
        });
      } else {
        navigate(link);
      }
    } else {
      navigate(link);
    }
  };

  // Add intersection observer to load images when card enters viewport
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && !imagesLoaded) {
          loadFullImages();
        }
      },
      {
        rootMargin: '200px', // Start loading when card is 200px from viewport
        threshold: 0.1,
      }
    );
    
    const currentElement = document.getElementById(`apt-card-${apartment.id}`);
    if (currentElement) {
      observer.observe(currentElement);
    }

    return () => {
      if (currentElement) {
        observer.unobserve(currentElement);
      }
    };
  }, [apartment.id, imagesLoaded, loadFullImages]);

  return (
    <div className="relative" id={`apt-card-${apartment.id}`}>
      <div className="absolute top-0 right-0 z-10">
        <button onClick={handleFavClick} aria-label="Add to favorites">
          <div className="p-2 m-2">
            <i
              className={`fa-heart fa-lg hover:scale-125 ${
                isFavorited ? "fa-solid text-red-500" : "fa-regular text-white"
              }`}
            ></i>
          </div>
        </button>
      </div>
      <div>
        <div className="relative flex flex-col rounded-md overflow-hidden">
          {/* Wrap ImageSlider and overlay in a common relative container */}
          <div className="relative">
            <ImageSlider
              images={imagesLoaded ? fullImages : thumbnailImage}
              onImageClick={onListingClick}
              listingTag={
                apartment.listingTag ? apartment.listingTag.tag : null
              }
              priority={true}
            />
          </div>

          <div className="cursor-pointer py-1" onClick={onListingClick}>
            <div className="flex justify-between">
              <div className="flex flex-col flex-1 min-w-0 mr-4">
                <div className="text-md font-medium text-black truncate line-clamp-1">
                  {apartment.name.replace(/(, Champaign|, Urbana)(\s*[-,]\s*)?/gi, '')}
                </div>
                <div className="text-sm font-normal text-gray-600">
                  {formatBedRange(bedRange)} • {formatBathRange(bathRange)}
                </div>
              </div>
              <div className="flex flex-col justify-between text-sm whitespace-nowrap">
                <div className="text-black">
                  <i className="fa-solid fa-star fa-sm mr-1"></i>
                  4.53
                </div>
                {apartment.distanceKM && (
                  <div className="text-gray-700 text-sm mt-1">
                    <i className="fa-solid fa-person-walking fa-sm mr-1"></i>
                    {distToWalkTime(apartment.distanceKM)} min
                  </div>
                )}
              </div>
            </div>

            {PriceComp}
          </div>
        </div>
      </div>
    </div>
  );
});

function displayNormalPrice(totalPriceRange) {
  return (
    <div>
      <div className="flex mt-2 text-black">
        <p className="text-sm font-medium mr-1">{`$${formatPriceRange(
          totalPriceRange
        )}`}</p>

        <p className="text-sm">month</p>
      </div>
    </div>
  );
}

function displayPerPersonPrice(perPersonPriceRange, totalPriceRange) {
  return (
    <div>
      <div className="flex mt-2">
        <p className="text-sm font-medium mr-1">{`${contractNoteSymbol}$${formatPriceRange(
          perPersonPriceRange
        )}`}</p>

        <p className="text-sm">month</p>
      </div>
      <div className="text-xs text-neutral-500">
        {`$${formatPriceRange(totalPriceRange)} month (unit)`}
      </div>
    </div>
  );
}

export function InfoWindowCard({
  apartment,
  filters = { pricePerPerson: true, hoverAptID: null },
}) {
  const bedRange = getBedRange(apartment);
  const totalPriceRange = getTotalPriceRange(apartment);
  const perPersPriceRange = getPerPersonPriceRange(apartment);

  const link = apartment.id.includes("/")
    ? "/group-listing/" + apartment.id
    : "/listing/" + apartment.id;

  const anyPlanPricePerPersFalse = apartment.floorPlans.some(
    (plan) => plan.perPers === false && plan.beds !== 0 && plan.beds !== 1
  );

  // Determine which price display function to use
  const PriceComp =
    filters.pricePerPerson && anyPlanPricePerPersFalse
      ? displayPerPersonPrice(perPersPriceRange, totalPriceRange)
      : displayNormalPrice(totalPriceRange);

  return (
    <a
      key={apartment.id}
      href={link}
      // href={`listing/${apartment.floorPlans[0].link}`}
    >
      <div
        className="flex flex-col rounded-md overflow-hidden"
        // style={{ "max-width": "400px" }}
      >
        <div
          style={{ backgroundImage: `url(${apartment.images[0]})` }}
          className="w-full bg-cover bg-center rounded-md aspect-square"
        ></div>
        <div className="py-2">
          <div className="flex gap-5 justify-between">
            <div className="flex flex-col">
              {/* Apply truncate to ensure long names don't cause layout issues */}
              <div className="text-md font-medium text-black truncate line-clamp-1">
                {apartment.name}
              </div>
              <div className="text-sm text-neutral-700">
                {formatAddress(apartment.address)}
              </div>
            </div>
            <div className="flex flex-col justify-between text-sm h-full">
              <div className="text-neutral-700 self-end">
                {formatBedRange(bedRange)}
              </div>
              <div className="text-neutral-700 self-end">
                <i className="fa-solid fa-person-walking text-sm mr-1"></i>
                {distToWalkTime(apartment.distanceKM)} min
              </div>
            </div>
          </div>
          {PriceComp}
        </div>
      </div>
    </a>
  );
}

const formatBedRange = (bedRange) => {
  let minBed = bedRange.min === 0 ? "Studio" : `${bedRange.min}`;
  let maxBed = bedRange.max === 0 ? "Studio" : `${bedRange.max}`;
  if (minBed === maxBed) {
    return minBed === "Studio" ? minBed : `${minBed} Bed`;
  } else {
    return `${minBed}-${maxBed} Bed`;
  }
};

const formatBathRange = (bedRange) => {
  let minBed = `${bedRange.min}`;
  let maxBed = `${bedRange.max}`;
  if (minBed === maxBed) {
    return `${minBed} Bath`;
  } else {
    return `${minBed}-${maxBed} Bath`;
  }
};

const formatPriceRange = (priceRange) => {
  if (priceRange.min === priceRange.max) {
    return `${priceRange.min}`;
  } else {
    return `${priceRange.min}-${priceRange.max}`;
  }
};

const formatAddress = (address) => {
  if (address.length > 10) {
    return address.slice(0, -10);
  } else {
    return address;
  }
};

export default ListingCard;
